import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import PropTypes from 'prop-types';

import * as actions from '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios';

import classes from './TrailerSelection.module.scss';

import moment from 'moment';
import Input from '../../components/UI/Input/Input';
import { toggleDates, updateObject } from '../../shared/utility';
moment.locale('en-GB');
class TrailerSelection extends Component {
    state = {
        availableTrailers: [],
        assignedTrailers: [],
        endDate: moment('2999-12-28'),
        endDateFocused: false,
        startDateFocused: false,
        startDate: moment().startOf('week'),
        isValidDateRange: false,
        isValidStartDate: false,
        isValidEndDate: false
    };

    componentDidMount() {
        if (!this.props.trailers || this.props.trailers.length === 0) {
            this.props.onGetTrailers();
        }
        this.checkDateRange();
    }

    componentDidUpdate() {
        if (this.props.assignedTrailers !== this.state.assignedTrailers && this.props.show === true) {
            this.setAvailableTrailers();
        }
    }

    toggleDateHandler = identifier => {
        const date = toggleDates(identifier, this.state.startDate, this.state.endDate);
        this.dateChangedHandler(date, identifier);
    };
    setAvailableTrailers = () => {
        const available = this.props.trailers.filter(t => this.props.assignedTrailers.filter(s => s.trailerId === t.id).length === 0);
        this.setState({
            availableTrailers: available,
            assignedTrailers: this.props.assignedTrailers
        });
    };
    loading = (
        <ProgressIndicator page loading={this.props.isLoading}>
            <ProgressSpinner light />
            Loading Locations...
        </ProgressIndicator>
    );

    onRowSelectHandler = e => {
        this.handleChange(e);
    };
    onSelectionChangeHandler = e => {
        this.handleChange(e);
    };

    setSelectedTrailersHandler = () => {
        this.props.onSetSelectedTrailers(this.state.selectedTrailers, this.state.startDate, this.state.endDate);
        this.props.close();
    };

    handleChange = e => {
        let value;

        if (Array.isArray(e.value)) {
            value = e.value;
        }
        else {
            value = e.data;
        }

        if (!e.originalEvent.ctrlKey) {
            if (Array.isArray(value)) {
                if (
                    this.state.selectedTrailers &&
                    this.state.selectedTrailers.length === 1 &&
                    this.state.selectedTrailers.filter(r => r.id === value[0].id).length === 0
                ) {
                    this.setState([]);
                }
                else {
                    this.setState({ selectedTrailers: value });
                }
            }
            else {
                if (
                    this.state.selectedTrailers &&
                    this.state.selectedTrailers.length === 1 &&
                    this.state.selectedTrailers.filter(r => r.id === value.id).length > 0
                ) {
                    this.setState({ selectedTrailers: [] });
                }
                else {
                    this.setState({ selectedTrailers: value });
                }
            }
        }
        else if (Array.isArray(value)) {
            this.setState({ selectedTrailers: value });
        }
        else {
            this.setState({ selectedTrailers: [value] });
        }
    };

    focusedHandler = (event, identifier) => this.setState({ [`${identifier}Focused`]: event.focused });

    onFocusedHandler = event => event.currentTarget.select();

    dateChangedHandler = (event, identifier) => {
        const touched = updateObject(this.state.touched, { [identifier]: true });
        this.setState(
            () => ({ [identifier]: event, touched }),
            () => this.checkDateRange()
        );
    };

    checkDateRange = () => {
        let valid = true;

        let start = moment().startOf('day');
        let end = moment().startOf('day');

        if (this.state.startDate) start = this.state.startDate.startOf('day');
        if (this.state.endDate) end = this.state.endDate.startOf('day');

        if (start.isAfter(end.startOf('day')) || start.isBefore(moment('2019-01-06'))) valid = false;

        let validStart = false;
        let validEnd = false;

        if (this.state.startDate) validStart = this.state.startDate.format('dddd') === 'Sunday';
        if (this.state.endDate) validEnd = this.state.endDate.format('dddd') === 'Saturday';

        this.setState({
            isValidDateRange: valid,
            isValidStartDate: validStart,
            isValidEndDate: validEnd
        });

        return valid && validStart && validEnd;
    };

    getErrorMessage = type => {
        let errorMessage = '';

        if (!this.state.isValidDateRange) errorMessage = 'Invalid date range';

        if (!this.state[`isValid${type}`]) errorMessage = `Must be a ${type === 'StartDate' ? 'Sunday' : 'Saturday'}`;

        return errorMessage;
    };

    render() {
        if (this.props.isLoading) {
            return this.loading;
        }

        return (
            <Modal handleClose={this.props.close} open={this.props.show} className={classes.lunaDataTableStyle}>
                <ModalHeading element="h3">Select Trailers</ModalHeading>

                <GridWrapper>
                    <GridItem size="1/4">
                        <Input
                            label="Start Date"
                            id="startDate"
                            dateChanged={event => this.dateChangedHandler(event, 'startDate')}
                            onFocused={event => this.focusedHandler(event, 'startDate')}
                            focused={this.state.startDateFocused}
                            value={this.state.startDate}
                            invalid={!this.state.isValidDateRange}
                            errorMessage={this.getErrorMessage('StartDate')}
                            elementType="datelabelled"
                            wkNoClick={() => this.toggleDateHandler('startDate')}
                        />
                    </GridItem>
                    <GridItem size="1/4">
                        <Input
                            label="End Date"
                            id="endDate"
                            dateChanged={event => this.dateChangedHandler(event, 'endDate')}
                            onFocused={event => this.focusedHandler(event, 'endDate')}
                            focused={this.state.endDateFocused}
                            value={this.state.endDate}
                            invalid={!this.state.isValidDateRange}
                            errorMessage={this.getErrorMessage('EndDate')}
                            elementType="datelabelled"
                            wkNoClick={() => this.toggleDateHandler('endDate')}
                        />
                    </GridItem>
                    <GridItem size="1/2">
                        <DataTable
                            value={this.state.availableTrailers}
                            responsive
                            paginator
                            rows={8}
                            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
                            onSelectionChange={e => this.onSelectionChangeHandler(e)}
                            onRowSelect={e => this.onRowSelectHandler(e)}
                            selectionMode="multiple"
                            selection={this.state.selectedTrailers}
                        >
                            <Column field="code" header="Code" filter sortable className={classes.leftAlign} style={{ width: '150px' }} />
                            <Column
                                field="description"
                                header="Description"
                                filter
                                sortable
                                filterMatchMode="contains"
                                style={{ textAlign: 'left', width: '200px' }}
                            />
                            <Column field="rpeCapacity" header="RPE Max" filter sortable filterMatchMode="startsWith" style={{ width: '100px' }} />
                            <Column field="ulcCapacity" header="ULC Max" filter sortable filterMatchMode="startsWith" style={{ width: '100px' }} />
                            <Column field="bdCapacity" header="Pal MAX" filter sortable style={{ width: '100px' }} />
                        </DataTable>
                    </GridItem>
                </GridWrapper>
                <ButtonGroupWrapper actionBar>
                    <ButtonGroupPrimary>
                        <FilledButton onClick={this.setSelectedTrailersHandler}>Apply</FilledButton>
                    </ButtonGroupPrimary>
                    <ButtonGroupSecondary>
                        <TextButton onClick={this.props.close}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                </ButtonGroupWrapper>
            </Modal>
        );
    }
}

TrailerSelection.propTypes = {
    assignedTrailers: PropTypes.array,
    close: PropTypes.func,
    isLoading: PropTypes.bool,
    onGetTrailers: PropTypes.func,
    onSetSelectedTrailers: PropTypes.func,
    show: PropTypes.bool,
    trailers: PropTypes.array
};

const mapStateToProps = state => {
    return {
        isLoading: state.trailer.loadingTrailers,
        trailers: state.trailer.trailers,
        selectedTrailers: state.trailer.selectedTrailers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetTrailers: () => dispatch(actions.getTrailers()),
        onUpdateSite: site => dispatch(actions.updateUserSite(site)),
        onSetSelectedTrailers: (trl, fromDate, toDate) => dispatch(actions.setSelectedTrailers(trl, fromDate, toDate))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(TrailerSelection, axios));

import { AuthenticationContext } from 'react-adal';

export const adalConfig = {
    tenant: 'e11fd634-26b5-47f4-8b8c-908e466e9bdf',
    clientId: 'd456c411-09e1-4fcc-9ade-a1f64ce2a1eb',
    endpoints: {
        api: 'd456c411-09e1-4fcc-9ade-a1f64ce2a1eb'
    },
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    return new Promise((resolve, reject) => {
        authContext.acquireToken(authContext.config.clientId, err => {
            if (err) return reject(err);
            resolve(authContext.getCachedToken(authContext.config.clientId));
        });
    });
    // return authContext.getCachedToken(authContext.config.clientId);
};

// export const authContext = new AuthenticationContext(adalConfig);

// export const adalApiFetch = (fetch, url, options) =>
//     adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

// export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

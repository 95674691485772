import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeading} from '@jsluna/modal'
import { Table  } from '@jsluna/table';
import { ButtonGroupWrapper,
  ButtonGroupSecondary } from '@jsluna/button'

import { useEffect } from 'react';
import { IconButton } from '@jsluna/button'
import { Cancel } from '@jsluna/icons'
import { Alert, AlertIcon } from '@jsluna/alert'
import { ErrorCircle } from '@jsluna/icons'



const ScheduleRouteModal = ({isCreateMode, openScheduleRouteModal, closeScheduleRouteModal,setRouteData, route}) => {

    const [childRoute, setChildRoute] = useState();
    const [hasError, setError] = useState();

    useEffect(
      () => {
        setChildRoute(route);
        setError(false);
      },[route]
    );

    const updateRoute = (e, rowData, fieldType) =>{

      const update = childRoute.map(el => {
        if(el.index === rowData.index){
           el[fieldType] = e.target.value;
        }
        return el;
      });
      setChildRoute(update);
    }

   const handleAddRow = () => {
      const item = {
          index: getIndex() + 1,
          sourceDepotName: "", 
          sourceDepotId: "",
          destinationDepotName: "",
          destinationDepotId: ""
      };

      setChildRoute([...childRoute, item]);
  }


  const getIndex = () => {
    let greatest = 0;
    for(let i=0; i< childRoute.length; i++)
    {
      if (childRoute[i]["index"] > greatest) {
        greatest = childRoute[i]["index"];
      }
    }
    return greatest;
  }

  const handleInputField = (rowData, fieldType) => {
    if (isCreateMode) {
      return <input type="input" name={fieldType} value={rowData[fieldType]} onChange={(e) => { updateRoute(e, rowData, fieldType) }} />
    }
    return <label name={fieldType} value={rowData[fieldType]}>{rowData[fieldType]}</label>
  }

  const handleDeleteRow = (rowData) => {
    const rows = [...childRoute];
    const index = rows.indexOf(rowData);
    rows.splice(index, 1);
    setChildRoute(rows);
  }

  const handleSave = () => {
    const result = childRoute.filter(element => {
      if(element.sourceDepotName.length === 0 || element.sourceDepotId.length === 0
        || element.destinationDepotName.length === 0 || element.destinationDepotId.length === 0 ){
          return element;
        }
    });

    if(childRoute.length > 0 && result.length === 0){
      setError(false);
    setRouteData(childRoute);
    closeScheduleRouteModal();
    }
    else{
      //setError(true);
      alert("The table should have at least one row and none of the fields can be blank.");
    }
  }

  const handleClose = () => {
    closeScheduleRouteModal();
  }

  const addRow = 
  <ButtonGroupWrapper>
    <ButtonGroupSecondary>
      <button
        type="button"
        className="ln-c-button ln-c-button--outlined ln-u-margin-right"
        onClick={() => handleAddRow()}
      >
        Add Row
      </button>
      <button type="button" className="ln-c-button ln-c-button--outlined" onClick={() => handleSave()}>
        Save
      </button>
      <button type="button" className="ln-c-button ln-c-button--outlined" onClick={() => handleClose()}>
        Close
      </button>
    </ButtonGroupSecondary>
  </ButtonGroupWrapper>

  const errorList = 
  <Alert
      variant="error"
      open={hasError === true}
      onClose={function(){}}
      squared
  >
      <AlertIcon>
        <ErrorCircle aria-label="Error" role="img" />
      </AlertIcon>
      None of the fields can be blank.
  </Alert>

const close = 
<ButtonGroupWrapper>
  <ButtonGroupSecondary>
    <button
      type="button"
      className="ln-c-button ln-c-button--outlined ln-u-margin-right"
      onClick={() => handleClose()}
    >
      Close
    </button>
  </ButtonGroupSecondary>
</ButtonGroupWrapper>
            

    const routeTable = 
              <Table
                  rowKey="index"
                  responsive
                  data={childRoute}
                  columns={[
                    {
                      name: 'Source Depot',
                      accessor: (rowData) => ({row: rowData }),
                      hideLabel: true,
                      className: 'customCol',
                      render: ({row}) => {
                       return handleInputField(row,"sourceDepotName");
                      }
                    },
                    {
                      name: 'Source Depot ID',
                      accessor: rowData => ({
                        row: rowData
                      }),
                      className:'customCol' ,
                      render: ({row}) => {
                       return handleInputField(row,"sourceDepotId");
                      }
                    },
                    {
                      name: 'Destination Depot',
                      accessor: rowData => ({ row:rowData }),
                      hideLabel: true,
                      className: 'customCol',
                      render: ({row}) => {
                        return handleInputField(row,"destinationDepotName");
                      }
                    },
                    {
                      name: 'Destination Depot ID',
                      accessor: rowData => ({ row: rowData }),
                      hideLabel: true,
                      className: 'customCol',
                      render: ({row}) => {
                        return handleInputField(row,"destinationDepotId");
                      }
                    },
                    {
                      accessor: rowData => ({ row: rowData }),
                      render: ({row}) => {
                        if(isCreateMode){
                       return <IconButton variant="filled" hideLabel label="Delete" onClick={() => handleDeleteRow(row)}>
                          <Cancel />
                        </IconButton>
                        }
                      }
                    }
                  ]}
                />
    
        return <div className='scheduleRouteModal'>
          <Modal
            fullScreen
            restrictClose
            //alert
            handleClose={closeScheduleRouteModal}
            open={openScheduleRouteModal} >
            <ModalHeading element="h3">Route Setup</ModalHeading>
            <p>Please enter the route for this schedule.</p>
            {errorList}
            {routeTable}
            {isCreateMode? addRow: close}
        </Modal>
        </div>
    
  }

ScheduleRouteModal.propTypes = {
    isCreateMode: PropTypes.bool,
    openScheduleRouteModal: PropTypes.bool,
    closeScheduleRouteModal: PropTypes.func,
    setRouteData: PropTypes.func,
    route: PropTypes.array
};

export default ScheduleRouteModal


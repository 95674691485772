import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import asyncComponent from './hoc/asyncComponent/asyncComponent';

import AIPChange from './containers/Uploads/AIPChange/AIPChange';
import Compare from './containers/Uploads/Compare/Compare';
import Home from './components/Home/Home';
import Layout from './components/Layout/Layout';
import Summary from './containers/Summary/Summary';
import Location from './containers/Location/Location';
import Locations from './containers/Locations/Locations';
import MassUpload from './containers/MassUpload/MassUpload';
import NewCodeRequest from './containers/Uploads/NewCodeRequest/NewCodeRequest';
import NewLocation from './containers/Locations/NewLocation/NewLocation';
import LocationTrailers from './containers/Locations/LocationTrailers/LocationTrailers';
import Review from './containers/Location/Review/Review';
import Schedule from './containers/Schedule/Schedule';
import Schedules from './containers/Schedules/Schedules';
import Uploads from './containers/Uploads/Uploads';
import UserSettings from './containers/Settings/Settings';
import { userCanEdit } from './shared/utility';

import './App.scss';
import * as actions from './store/actions/index';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/css/primereact-argos-theme.css';

const asyncAuth = asyncComponent(() => {
    return import('./containers/Auth/Auth');
});

class App extends Component {
    state = {
        isAuthenticated: false
    };

    componentDidMount() {
        this.props.onTryAutoSignIn();
        // eslint-disable-next-line no-undef
        document.documentElement.classList.add(this.props.buildType);
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated === true && this.state.isAuthenticated !== true) this.updateState();
    }

    updateState = () => this.setState({ isAuthenticated: true });

    render() {
        let routes = null;

        if (this.state.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path="/auth" component={asyncAuth} />
                    <Route path="/location/edit/:id?" component={NewLocation} />
                    <Route path="/location/:id?" component={Location} />
                    <Route path="/summary" component={Summary} />
                    <Route path="/locations/newlocation" component={NewLocation} />
                    <Route path="/locations/locationtrailers" component={LocationTrailers} />
                    <Route path="/locations" exact component={Locations} />
                    <Route path="/review" component={Review} />
                    <Route path="/schedules/:wcdate?/:id?" component={Schedules} />
                    <Route path="/schedules/:wcdate?" component={Schedules} />
                    <Route path="/schedules/:id?" component={Schedules} />
                    <Route path="/schedule/:id?" component={Schedule} />
                    <Route path="/uploads" component={Uploads} />
                    <Route path="/massupload" component={MassUpload} />
                    {userCanEdit(this.props.roles) && <Route path="/aipchange" component={AIPChange} />}
                    {userCanEdit(this.props.roles) && <Route path="/newcode" component={NewCodeRequest} />}
                    {userCanEdit(this.props.roles) && <Route path="/settings" component={UserSettings} />}
                    <Route path="/compare" component={Compare} />
                    <Route path="/" exact component={Home} />
                    <Redirect to="/" />
                </Switch>
            );
        }

        return (
            <Layout buildType={this.props.buildType}>
                {routes}
                <div className="devLabel">{this.props.buildType}</div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        roles: state.auth.roles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignIn: () => dispatch(actions.authCheckState())
    };
};

App.propTypes = {
    buildType: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    onTryAutoSignIn: PropTypes.func.isRequired,
    roles: PropTypes.array
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

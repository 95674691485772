import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeading } from '@jsluna/modal';
import { SelectField } from '@jsluna/form';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';

import PropTypes from 'prop-types';

import * as actions from '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios';

class SiteSelection extends Component {
    state = {
        tempSiteId: null
    }

    changeSiteHandler = () => {
        this.props.close();
        this.props.onUpdateSite(this.state.tempSiteId);
    };

    handleChange = (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        const option = event.nativeEvent.target[index];
        this.setState({ tempSiteId: option.value });
    };

    render() {

        const currentSite = this.props.userSites.filter(m => m.isActive)[0];
        if (!currentSite)
            return '';

        // console.log(currentSite);
        return (
            <Modal
                small
                handleClose={this.props.close}
                open={this.props.show} >
                <ModalHeading element="h3">Change Site</ModalHeading>
                <SelectField
                    name="siteSelection"
                    label="Select Site"
                    defaultValue={currentSite.value.toString()}
                    options={this.props.userSites}
                    onChange={this.handleChange} />
                <ButtonGroupWrapper actionBar>
                    <ButtonGroupPrimary>
                        <FilledButton onClick={this.changeSiteHandler}>Apply</FilledButton>
                    </ButtonGroupPrimary>
                    <ButtonGroupSecondary>
                        <TextButton onClick={this.props.close}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                </ButtonGroupWrapper>
            </Modal>
        );
    }
}

SiteSelection.propTypes = {
    close: PropTypes.func,
    onUpdateSite: PropTypes.func,
    show: PropTypes.bool,
    userSites: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        userSites: state.user.userSites
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateSite: (site) => dispatch(actions.updateUserSite(site))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(SiteSelection, axios));

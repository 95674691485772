import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';
import { Modal, ModalHeading } from '@jsluna/modal'
import { FilledButton } from '@jsluna/button'

import classes from './Schedules.module.scss';
import './Schedules.scss';

import moment from 'moment';

import { AutocompleteField } from '@jsluna/autocomplete';
import { IconButton } from '@jsluna/button';
import { ToggleButton, ToggleButtonGroup } from '@jsluna/toggle-button';
import { Container } from '@jsluna/grid';
import { ListGroup, ListGroupItem } from '@jsluna/list';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Pagination } from '@jsluna/pagination';
import { Plus } from '@jsluna/icons'

import {
    TableBody, TableCell, TableContainer, TableHeader, TableHeaderCell,
    TableHeaderRow, TableRow
} from '@jsluna/table';
import { ArrowLeft, ArrowRight, Cancel, Download, Info } from '@jsluna/icons';

import Input from '../../components/UI/Input/Input';
import Icon from 'ol/style/Icon';

moment.locale('en-GB');

const COLUMN_DESCRIPTION = 'Description';
// const [isOpen, setIsOpen] = React.useState(false)

/* eslint-disable no-mixed-operators */
class Schedule extends Component {
    state = {
        currentPage: 1,
        isVisible: false,
        wcDateFocused: false,
        // wcDateValid: true,
        locationId: null,
        currentValue: '',
        whichView: null,
        setIsOpen: false,
        isModalOpen: false,
        modalMessage: ''
    }

    componentDidMount() {
        let id = null;
        let whichView;
        this.props.onClearLocationId();

        if (!this.props.match) {
            id = this.props.scheduleLocationId;
            whichView = 'Schedule';
        }
        else
            id = this.props.match.params.id;

        if (this.props.locations.length === 0)
            this.props.onGetLocations();

        if (!isNaN(+id))
            this.setLocation(+id, whichView);
        else {
            let wc = null;
            if (this.props.match.params.wcdate)
                wc = moment(this.props.match.params.wcdate);
            else if (this.props.wcDate)
                wc = moment(this.props.wcDate);
            else
                wc = moment().startOf('week');
            this.props.onChangeDate(moment(wc).startOf('week'));
            this.setPath(wc, id);
        }
    }

    handleNewRequest = () => this.props.history.push('/newlocation');

    toggleVisibility = () => {
        this.setState({
            isVisible: !this.state.isVisible
        });
    }



    wcDateFocusedHandler = event => this.setState({ wcDateFocused: event.focused });
    wcDateChangedHandler = event => {
        const wcDate = event.startOf('week');
        this.props.onGetSchedule(wcDate, this.props.locationId);
        this.setPath(wcDate, this.props.locationId);
    }
    prevDate = () => {
        const wcDate = this.props.wcDate.add(-7, 'days');
        this.props.onGetSchedule(wcDate, this.props.locationId);
        this.setPath(wcDate, this.props.locationId);
    }
    nextDate = () => {
        const wcDate = this.props.wcDate.add(7, 'days');
        this.props.onGetSchedule(wcDate, this.props.locationId);
        this.setPath(wcDate, this.props.locationId);
    }

    setPath = (wcDate, locationId) => {
        if (this.state.whichView && this.state.whichView !== null) {
            return;
        }

        if (wcDate && locationId !== undefined && locationId !== null && locationId !== -1) {
            window.history.pushState(null, null, `/schedules/${wcDate.format('YYYY-MM-DD')}/${locationId}`);
        }
        else if (wcDate && (!locationId || locationId === null || locationId === -1)) {
            window.history.pushState(null, null, `/schedules/${wcDate.format('YYYY-MM-DD')}`);
        }
        else if (!wcDate && locationId && locationId !== null && locationId !== -1) {
            window.history.pushState(null, null, `/schedules/${locationId}`);
        }
        else if (!wcDate && (!locationId || locationId === null)) {
            window.history.pushState(null, null, '/schedules');
        }
        if (wcDate)
            this.props.onChangeDate(moment(wcDate).startOf('week'));
    }
    changeDate = (days) => {
        this.props.onChangeDate(days);
    }

    setLocation = (locationId, whichView, usePropsWc) => {
        this.setState(() => ({
            locationId: locationId,
            currentValue: '',
            whichView: whichView
        }),
            () => {
                let wc = null;
                if (this.props.match) {
                    if (this.props.match.params.wcdate && !usePropsWc)
                        wc = this.props.match.params.wcdate;
                    else
                        wc = this.props.wcDate;
                }
                else
                    wc = this.props.scheduleDate;

                let wcDate = moment(wc).startOf('week');
                if (!wcDate)
                    wcDate = moment().startOf('week');

                if (locationId !== null)
                    this.props.onGetSchedule(wcDate, +locationId);
                else
                    this.props.onClearLocationId();

                // eslint-disable-next-line no-mixed-operators
                if (locationId === null && this.props.match || locationId === -1)
                    window.history.pushState(null, null, `/schedules/${wcDate.format('YYYY-MM-DD')}`);
            });
    };

    locationSelectHandler = event => {
        if (event && event.currentTarget) {
            this.setState({ currentValue: event.currentTarget.value });
            return;
        }

        if (!event || !event.value)
            return;

        this.setLocation(+event.value);
        // use the HTML5 history to change the URL, can't use React history.push as that also forces a reload which we don't need
        window.history.pushState(null, null, `/schedules/${moment(this.props.wcDate).format('YYYY-MM-DD')}/${event.value}`);
    }

    getSelected = () => {
        if ((this.props.locations || []).length > 0 && this.state.locationId) {
            const option = this.props.locations.filter(m => m.id === this.state.locationId)[0];
            if (!option)
                return null;

            return {
                value: option.id.toString(),
                label: `${option.locationNo} ${option.locationName}`
            };
        }
        else
            return null;
    }

    handleInfoIconClick = (event,currentDepotRoute, deliveryDCNo) =>{
        this.setState({ setIsOpen: true, isModalOpen: true });
        this.state.modalMessage = " It first delivers to " 
        + currentDepotRoute.destinationDepot +(deliveryDCNo == "" ?" and then to destination.": (" then to delivery DC: " + deliveryDCNo + " and then reaches destination"));
    }

    render() {
        const infoModal = (
            <Modal small
                            handleClose={() => this.setState({ setIsOpen: false, isModalOpen: false, modalMessage:'' })}
                            open={this.state.isModalOpen}
                            headingId="Route Info"
                        >
                            <ModalHeading element="h3">
                                Route Information
                            </ModalHeading>
                            <p>
                                This store doesnt directly deliver to destination.
                                {this.state.modalMessage}
                            </p>
            </Modal>
        );
        let locations = (
            <ProgressIndicator page loading={this.props.loading}>
                <ProgressSpinner light />
                Loading...
            </ProgressIndicator>
        );

        let n = 0;
        let deliveryDCNo = '';

        if (!this.props.loading && this.props.scheduleHeaders.length > 0 && this.state.locationId !== null) {
            const rowsPerPage = 15;
            const numOfPages = this.props.scheduleHeaders ? Math.ceil(this.props.scheduleHeaders.length / rowsPerPage) : 1;
            const pagination = page => [
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage];

            locations = (
                <Fragment>
                    <h2 className={classes.viewToSmall}>Please enlarge view</h2>
                    <TableContainer responsive className={[classes.smallText, classes.scheduleContainer].join(' ')}>
                        <TableHeader className={[classes.smallText, classes.scheduleHeader].join(' ')}>
                            <TableHeaderRow>
                                <TableHeaderCell className={classes.numberCell} key="no">No</TableHeaderCell>
                                <TableHeaderCell className={classes.sa_width_120} key="loc">Location</TableHeaderCell>
                                <TableHeaderCell className={classes.infoCell} key="info">Info</TableHeaderCell>
                                {this.props.scheduleDates.map((day, ind) => {
                                    const cell = [];
                                    if (day.substring(0, 3) === 'Sun')
                                        cell.push(<TableHeaderCell key={`${ind}b`} className={classes.gap}></TableHeaderCell>);

                                    cell.push(<TableHeaderCell key={ind} align="center">{day}</TableHeaderCell>);
                                    return cell;
                                })}
                                <TableHeaderCell className={classes.scrollGap} key="scrollgap"></TableHeaderCell>
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody className={classes.scheduleBody}>
                            {this.props.scheduleHeaders.slice(...pagination(this.state.currentPage)).map(row =>
                                row.aipScheduleLinks.map(link => {
                                    const delivery = link.delivery;
                                    const transport = link.transport;
                                    const tr = (
                                        <Fragment key={n++}>
                                            <TableRow key={n++}
                                                style={{ backgroundColor: '#F06C00', color: 'white' }}>
                                                <TableCell className={classes.numberCell} label={COLUMN_DESCRIPTION}>{row.locationNo}</TableCell>
                                                <TableCell className={classes.sa_width_120} label={COLUMN_DESCRIPTION}>{row.locationName}</TableCell>
                                                <TableCell className={classes.infoCell} label={COLUMN_DESCRIPTION}>AIP Delivery</TableCell>
                                                {link.delivery.map((day, ind) => {
                                                    const cell = [];

                                                    if (ind === 0 || ind === 7 || ind === 14)
                                                        cell.push(<TableCell className={classes.gap} key={`${ind}b`}></TableCell>);

                                                    const deliveryTimes = day.cellValue.replace('-', ' ').split(' ');
                                                    const dt = deliveryTimes[0] === 'No' ? '' : <Fragment>{deliveryTimes[0]}<br />{deliveryTimes[1]}</Fragment>;
                                                    cell.push(
                                                        <TableCell className={delivery[ind].windowAdjusted ? classes.windowAdjusted : ''}
                                                            key={ind} align="center"
                                                            label={day.cellValue}>{dt}
                                                        </TableCell>);

                                                    return cell;
                                                })}
                                            </TableRow>

                                            <TableRow key={n++}
                                                style={{ backgroundColor: '#404040', color: 'white' }}>
                                                <TableCell className={classes.numberCell} label={COLUMN_DESCRIPTION}></TableCell>
                                                <TableCell className={classes.sa_width_120} label={COLUMN_DESCRIPTION}></TableCell>
                                                <TableCell className={classes.infoCell} label={COLUMN_DESCRIPTION}>Transport Window</TableCell>
                                                {link.transport.map((day, ind) => {
                                                    const cell = [];

                                                    if (ind === 0 || ind === 7 || ind === 14)
                                                        cell.push(<TableCell className={classes.gap} key={`${ind}b`}></TableCell>);

                                                    const deliveryTimes = day.cellValue.replace('-', ' ').split(' ');
                                                    const dt = deliveryTimes[0] === 'No' ? '' : <Fragment>{deliveryTimes[0]}<br />{deliveryTimes[1]}</Fragment>;
                                                    cell.push(
                                                        <TableCell className={transport[ind].windowAdjusted ? classes.windowAdjusted : ''}
                                                            key={ind} align="center"
                                                            label={day.cellValue}>{dt}
                                                        </TableCell>);

                                                    return cell;
                                                })}
                                            </TableRow>

                                            {link.aipdcSchedule.map(schedule =>
                                                Object.keys(schedule).filter(arr => Array.isArray(schedule[arr])).map((obj, ind) => {
                                                    let row = null;
                                                    if (schedule[obj][0])
                                                        row = (
                                                            <TableRow className={[this.state.isVisible ||
                                                                (obj === 'dcPickTime' ||
                                                                    obj === 'dcXdocArrivalTime' && schedule.dcPickTime === null) ?
                                                                classes.isVisible : classes.isHidden,
                                                            obj === 'dcCycleNo' ? classes.dcCycleNo : '',
                                                            obj + this.state.isVisible].join(' ')}
                                                                key={n++}>
                                                                <TableCell className={classes.numberCell} label={COLUMN_DESCRIPTION}>
                                                                {/* {schedule.dcCycleNo === null ? '': <Info size='default'  className="ln-u-flex-1 ln-u-margin-bottom*2" ></Info>} */}

                                                                {this.state.isVisible && n === 1 ?
                                                                    schedule.dcNo : !this.state.isVisible
                                                                        && (obj === 'dcPickTime' ||
                                                                            obj === 'dcXdocArrivalTime' && schedule.dcPickTime === null) ?
                                                                 schedule.dcNo  : ind === 0 ? schedule.dcNo : ''
                                                                 }

                                                                    {(() => {
                                                                        if (schedule.dcCVLDay == null) {
                                                                            deliveryDCNo = schedule.dcNo
                                                                        } else {
                                                                            var currentDepotRoute = this.props.routes.find(x => x.sourceDepot == schedule.dcNo);
                                                                            if(currentDepotRoute === undefined){
                                                                                return'';
                                                                            }
                                                                            if ((ind !== 0 && !this.state.isVisible && currentDepotRoute.destinationDepot !== deliveryDCNo) || 
                                                                            (ind === 0 && this.state.isVisible && currentDepotRoute.destinationDepot !== deliveryDCNo)) {
                                                                                return <Info size='large' onClick={(event) => {this.handleInfoIconClick(event,currentDepotRoute, deliveryDCNo)}}
                                                                                className="ln-u-flex-1 ln-u-margin-bottom*2" 
                                                                                style={{marginTop: '12px', height:'12px',cursor: 'pointer'}}></Info>
                                                                            } else {
                                                                                return '';
                                                                            }
                                                                        }
                                                                    })()
                                                                    }
                                                            
                                                                 
                                                                </TableCell>
                                                                <TableCell className={classes.sa_width_120} label={COLUMN_DESCRIPTION}>
                                                                    {this.state.isVisible && n === 1 ?
                                                                        schedule.dcName : !this.state.isVisible
                                                                            && (obj === 'dcPickTime' ||
                                                                                obj === 'dcXdocArrivalTime' && schedule.dcPickTime === null) ?
                                                                            schedule.dcName : ind === 0 ? schedule.dcName : ''}
                                                                </TableCell>
                                                                <TableCell className={classes.infoCell} label={COLUMN_DESCRIPTION}>
                                                                    {schedule[obj][0].fieldName}
                                                                </TableCell>
                                                                {schedule[obj].map((day, ind) => {
                                                                    const cell = [];
                                                                    if (ind === 0 || ind === 7 || ind === 14)
                                                                        cell.push(<TableCell className={classes.gap} key={`${ind}b`}></TableCell>);

                                                                    if (delivery[ind]) {
                                                                        cell.push(
                                                                            <TableCell
                                                                                className={day.fieldName === 'Cycle No'
                                                                                    && delivery[ind].windowAdjusted ? classes.windowAdjusted : ''}
                                                                                key={ind} align="center"
                                                                                label={day.cellValue}>{day.cellValue}
                                                                            </TableCell>);
                                                                    }
                                                                    return cell;
                                                                })}
                                                            </TableRow>
                                                        );

                                                    return row;
                                                })
                                            )}
                                        </Fragment>
                                    );
                                    return tr;
                                })
                            )}
                        </TableBody>
                    </TableContainer>
                    <Pagination
                        className={['ln-u-push-top-lg', 'ln-u-text-align-center', classes.tablePagination].join(' ')}
                        showPages
                        showFirstAndLast
                        linkElement="button"
                        current={this.state.currentPage}
                        total={numOfPages}
                        onChange={(page, e) => {
                            e.preventDefault();
                            this.setState(() => ({ currentPage: page }));
                        }} />
                </Fragment>
            );
        }
        else if (!this.props.loading && this.state.locationId === null)
            locations = <h4 className={classes.noData}>Select a date, then select a location or click Get All</h4>;

        const locationOptions = this.props.locations.map(l => ({ label: `${l.locationNo} ${l.locationName}`, value: l.id.toString() }));
        const obj = this.getSelected();

        let locationSelect = null;

        if (locationOptions && !this.state.whichView)
            locationSelect = <AutocompleteField
                name="locationListAutocomplete"
                placeholder="Select a Location"
                label="Select Location"
                hideLabel
                onSelect={event => this.locationSelectHandler(event)}
                onChange={event => this.locationSelectHandler(event)}
                options={locationOptions}
                clearSelection={() => this.setLocation(null)}
                selectedItem={obj}
                value={!obj ? this.state.currentValue : obj.value}
            />;

        if (this.state.whichView)
            locationSelect = <Input
                disabled={true}
                id='location'
                value={!obj ? '' : obj.label}
                elementType="input" />;

        let showHideButtons = null;
        if (this.props.scheduleHeaders.length > 0 || this.state.whichView === 'Schedule') {
            let returnButton = null;
            if (this.state.whichView) {
                returnButton = <ListGroupItem element="a" href="#">
                    <IconButton
                        onClick={this.props.click}
                        variant="outlined"
                        label="Close">
                        <Cancel />
                    </IconButton>
                </ListGroupItem>;
            }

            showHideButtons = (
                <Fragment>
                    <ListGroupItem element="a" href="#">
                        <ToggleButtonGroup
                            inline
                            alpha
                            label="show / hide"
                            value={this.state.isVisible.toString()}
                            onChange={(event, value) => this.setState({ isVisible: value === 'true' })}>
                            <ToggleButton key={true} value='true'>show</ToggleButton>
                            <ToggleButton key={false} value='false'>hide</ToggleButton>
                        </ToggleButtonGroup>
                    </ListGroupItem>
                    {returnButton}
                </Fragment>
            );
        }

        return (
            <Container soft size="lg">
                <nav className={['ln-u-bg-color-white', 'schedulesNav'].join(' ')}
                    aria-describedby="list-group-2" style={{ border: '1px solid #d7d7d7' }}>
                    <ListGroup type="full" inline>
                        <ListGroupItem element="a" href="#">
                            <h3 className="ln-u-flush-bottom">Schedules</h3>
                        </ListGroupItem>
                        <ListGroupItem element="a" href="#">
                            {locationSelect}
                        </ListGroupItem>
                        {
                            this.state.whichView ? null : <ListGroupItem element="a" href="#">
                                <IconButton
                                    onClick={() => this.setLocation(-1, this.state.whichView, true)}
                                    variant="outlined"
                                    label="Get All">
                                    <Download />
                                </IconButton>
                            </ListGroupItem>
                        }
                        <ListGroupItem element="a" href="#" className={classes.datePickerWithNav}>
                            <IconButton
                                onClick={this.prevDate}
                                variant="outlined"
                                label="Prev Week"
                                hideLabel>
                                <ArrowLeft />
                            </IconButton>
                            <Input
                                style={{ marginBottom: 'none' }}
                                elementType="date"
                                id="wcDate"
                                focused={this.state.wcDateFocused}
                                onFocused={event => this.wcDateFocusedHandler(event)}
                                dateChanged={event => this.wcDateChangedHandler(event)}
                                value={this.props.wcDate} />
                            <IconButton
                                onClick={this.nextDate}
                                variant="outlined"
                                label="Prev Week"
                                hideLabel>
                                <ArrowRight />
                            </IconButton>
                        </ListGroupItem>
                        {showHideButtons}
                    </ListGroup>
                </nav>
                {locations}
                {infoModal}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.aipSchedule.loading,
        routes: state.aipSchedule.routes,
        scheduleDates: state.aipSchedule.scheduleDates,
        scheduleHeaders: state.aipSchedule.scheduleHeaders,
        locationId: state.aipSchedule.locationId,
        locations: state.location.locationHeaders,
        wcDate: state.aipSchedule.wcDate
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetSchedule: (locationId, wcDate) => {
            dispatch(actions.clearSchedule());
            dispatch(actions.getAipSchedule(wcDate, locationId));
        },
        onChangeDate: wcDate => dispatch(actions.changeDate(wcDate)),
        onClearLocationId: () => dispatch(actions.clearLocationId()),
        onGetLocations: () => dispatch(actions.getLocationHeaders())
    };
};

Schedule.propTypes = {
    click: PropTypes.func,
    history: PropTypes.object,
    loading: PropTypes.bool,
    locationId: PropTypes.number,
    locations: PropTypes.array,
    match: PropTypes.object,
    onChangeDate: PropTypes.func,
    onClearLocationId: PropTypes.func,
    onGetLocations: PropTypes.func.isRequired,
    onGetSchedule: PropTypes.func.isRequired,
    scheduleDate: PropTypes.any,
    scheduleDates: PropTypes.array.isRequired,
    scheduleHeaders: PropTypes.array.isRequired,
    scheduleLocationId: PropTypes.number,
    wcDate: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Schedule, axios));

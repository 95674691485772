import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { TableContainer, TableBody, TableRow, TableCell } from '@jsluna/table'
import { Pagination } from '@jsluna/pagination'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { IconButton } from '@jsluna/button';
import { Cancel, Edit } from '@jsluna/icons';
import { Checkbox } from '@jsluna/form';

import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';
import { isEqual } from 'lodash-es';
import { updateObject } from '../../../shared/utility';

import classes from './PaginatedTable.module.scss';

const PaginatedTable = ({ columns,
    error,
    loadingLocationsPage,
    locationsPage,
    onFilter,
    onGetLocationsPage,
    onMount,
    onSelect,
    onSort
}) => {
    const [filterInfo, setFilterInfo] = useState({});
    const [includeInactive, setIncludeInactive] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [locationRecords, setLocationRecords] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [requestInactive, setRequestInactive] = useState(false);
    const [requestedPage, setRequestedPage] = useState(0);
    const [requestedFilter, setRequestedFilter] = useState(false);
    const [requestedSort, setRequestedSort] = useState(false);
    const [selectedRows, setSelectedRows] = useState({ rows: [] });
    const [sortInfo, setSortInfo] = useState({});

    const [pageInfo, setPageInfo] = useState({
        currentPage: 1,
        filter: {},
        includeInactive: false,
        pageSize: 10,
        sortDirection: 'ascending',
        sortField: 'locationNo'
    });

    useEffect(() => {
        if (onMount) {
            onMount([includeInactive, setIncludeInactive]);
        }
    }, [includeInactive, onMount]);

    useEffect(() => {
        if (onFilter) {
            onFilter([filterInfo, setFilterInfo]);
        }
    }, [filterInfo, onFilter]);

    useEffect(() => {
        if (onSort) {
            onSort([sortInfo, setSortInfo]);
        }
    }, [onSort, sortInfo]);

    useEffect(() => {
        if (onSelect){
            onSelect(selectedRows.rows);
        }
    }, [onSelect, selectedRows]);

    useEffect(() => {
        if (locationRecords.length === 0 && !loadingLocationsPage && initialLoad) {
            onGetLocationsPage(pageInfo);
            setInitialLoad(false);
        }
    }, [initialLoad, loadingLocationsPage, locationRecords, onGetLocationsPage, pageInfo]);

    useEffect(() => {
        if (locationsPage && locationsPage.locations && !loadingLocationsPage && !initialLoad) {
            setLocationRecords(locationsPage.locations);
            setRecordCount(locationsPage.totalRecords);
        }
    }, [initialLoad, loadingLocationsPage, locationsPage]);

    useEffect(() => {
        if (pageInfo.includeInactive !== includeInactive) {
            const newPageInfo = updateObject(pageInfo, { includeInactive: includeInactive });

            setPageInfo(newPageInfo);
            setRequestInactive(true);
        }
    }, [includeInactive, pageInfo]);

    useEffect(() => {
        if (requestInactive) {
            onGetLocationsPage(pageInfo);
            setRequestInactive(false);
        }
    }, [onGetLocationsPage, pageInfo, requestInactive]);

    useEffect(() => {
        if (!isEqual(pageInfo.filter, filterInfo)) {
            const newFilterInfo = updateObject(pageInfo, { currentPage: 1, filter: filterInfo });
            setPageInfo(newFilterInfo);
            setRequestedFilter(true);
        }
    }, [filterInfo, pageInfo, requestedFilter]);

    useEffect(() => {
        if (requestedFilter) {
            const filterPage = updateObject(pageInfo, { filter: filterInfo })

            onGetLocationsPage(filterPage);
            setRequestedFilter(false);
        }
    }, [filterInfo, onGetLocationsPage, pageInfo, requestedFilter]);

    useEffect(() => {
        if (pageInfo.sortField !== sortInfo.sortField || pageInfo.sortDirection !== sortInfo.sortDirection) {
            const newSortInfo = updateObject(pageInfo, { sortField: sortInfo.sortField, sortDirection: sortInfo.sortDirection });
            setPageInfo(newSortInfo);
            setRequestedSort(true);
        }
    }, [pageInfo, requestedSort, sortInfo]);

    useEffect(() => {
        if (requestedSort) {
            const sortPage = updateObject(pageInfo, { sortField: sortInfo.sortField, sortDirection: sortInfo.sortDirection })

            onGetLocationsPage(sortPage);
            setRequestedSort(false);
        }
    }, [onGetLocationsPage, pageInfo, requestedSort, sortInfo]);

    useEffect(() => {
        if (requestedPage === pageInfo.currentPage) {
            onGetLocationsPage(pageInfo);
            setRequestedPage(0);
        }
    }, [onGetLocationsPage, pageInfo, requestedPage]);

    const isChecked = headerId => {
        return selectedRows.rows.includes(headerId);
    }

    const onSelectChange = event => {
        const headerId = +event.target.value;
        let selection = selectedRows.rows;

        if (event.target.checked) {
            selection.push(headerId)
        }
        else {
            selection = selection.filter(s => s !== headerId)
        }

        setSelectedRows({ all: false, rows: selection });
    }

    let datatable = (
        <ProgressIndicator loading={loadingLocationsPage}>
            <ProgressSpinner />
            Loading...
        </ProgressIndicator>
    )

    const onPageChange = (page, event) => {
        event.preventDefault();
        const newPageInfo = updateObject(pageInfo, { currentPage: page });
        setPageInfo(newPageInfo);
        setRequestedPage(page);
    }

    const actionTemplate = rowData => <Link to={{ pathname: `/location/${rowData.headerId}` }}><Edit /></Link>;

    const selectionCount = () => {
        if (selectedRows.rows.length > 0) {
            return <div className={classes.countParent}>
                <div className={classes.count}>Selected Locations: {selectedRows.rows.length}
                    <IconButton
                        className={classes.clearButton}
                        onClick={() => { setSelectedRows({ rows: [] }) }}
                        variant="outlined"
                        label="Clear Selection" >
                        <Cancel />
                    </IconButton>
                </div>
            </div>
        }
    }

    if (!loadingLocationsPage && !error) {
        datatable = (
            <Fragment>
                <TableContainer fixed>
                    <TableBody>
                        {(locationRecords || []).map(row => {
                            return (
                                <TableRow key={row.headerId}>
                                    {columns.map(column => {
                                        if (column.accessor === 'selector') {
                                            return <TableCell key={column.accessor} lastWhenStacked className="ln-u-hard-ends" style={{ width: column.width }}>
                                                <Checkbox inline hideLabel
                                                    name={`select-${row.headerId}`}
                                                    checked={isChecked(row.headerId)}
                                                    onChange={e => onSelectChange(e)}
                                                    value={row.headerId.toString()}
                                                />
                                            </TableCell>
                                        }
                                        else if (column.accessor === 'edit') {
                                            return <TableCell key={column.accessor} className="ln-u-hard-ends" style={{ width: column.width }}>{actionTemplate(row)}</TableCell>
                                        } else {
                                            return <TableCell key={column.accessor} style={{ width: column.width }}>{row[column.accessor]}</TableCell>
                                        }
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </TableContainer>
                <Pagination className="ln-u-push-top-lg ln-u-text-align-right"
                    showFirstAndLast
                    linkElement="button"
                    current={pageInfo.currentPage}
                    total={Math.ceil(recordCount / pageInfo.pageSize)}
                    onChange={onPageChange}
                />
                {selectionCount()}
            </Fragment>
        )
    };

    return (
        datatable
    );
}

PaginatedTable.propTypes = {
    columns: PropTypes.array,
    error: PropTypes.bool,
    loadingLocationsPage: PropTypes.bool,
    locationsPage: PropTypes.object,
    onMount: PropTypes.func,
    onGetLocationsPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    error: state.location.error,
    locationsPage: state.location.locationsPage,
    loadingLocationsPage: state.location.loadingLocationsPage
});

const mapDispatchToProps = dispatch => ({
    onGetLocationsPage: pageInfo => dispatch(actions.getLocationsPage(pageInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(PaginatedTable, axios));
/* eslint-disable no-undef */
import axios from 'axios';
import { getToken } from './adalConfig';

const instance = axios.create({
    baseURL: window._env_.REACT_APP_SERVER_URL + '/api/',
    validateStatus: status => status >= 100 && status < 600
});

instance.interceptors.request.use(async config => {
    const token = await getToken();

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
});

export default instance;

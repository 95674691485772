import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

// import * as actions from '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../../axios';

import DrawerToggleButton from './DrawerToggleButton/DrawerToggleButton';
import { GridView, Home, StoreLocation } from '@jsluna/icons';
import Dropdown from './Dropdown/Dropdown';

import './Toolbar.scss';

class toolbar extends Component {
    state = {
        mainMenu: [
            {
                id: 0,
                title: 'Summary Info',
                key: 'mainMenu',
                label: true,
                to: '/summary',
                description: 'View summary of distribution centre location participation'
            },
            {
                id: 1,
                title: 'All Locations',
                key: 'mainMenu',
                label: true,
                to: '/locations',
                description: 'View full location list'
            },
            {
                id: 2,
                title: 'Location Detail',
                key: 'mainMenu',
                label: true,
                to: '/location',
                description: 'View all details for a sinlge location'
            },
            {
                id: 3,
                title: 'Store Schedules',
                key: 'mainMenu',
                label: true,
                to: '/schedule',
                description: 'View all schedules for a single store'
            },
            {
                id: 4,
                title: 'Weekly Schedule',
                key: 'mainMenu',
                label: true,
                to: '/schedules',
                description: 'View the rolling schedule for a single store'
            },
            {
                id: 5,
                title: 'Downloads and Uploads',
                key: 'mainMenu',
                label: true,
                to: '/Uploads',
                description: 'Schedule, cycle, codes downloads. Schedule mass uploads. New code/cycles requests.'
            },
            {
                id: 6,
                title: 'Trailer Management',
                key: 'mainMenu',
                label: true,
                to: '/locations/locationtrailers',
                description: 'Mass assign Trailers/Locations'
            }
        ],
        settings: [
            {
                id: 0,
                title: 'Settings',
                key: 'settings',
                label: false,
                icon: 'Settings',
                to: '/settings'
            },
            {
                id: 1,
                title: 'Logout',
                key: 'settings',
                label: false,
                icon: 'Account',
                click: this.props.logOutHandler
            }
        ]
        // notifications: [
        //     {
        //         id: 0,
        //         title: 'Missing Trailer',
        //         label: 'Store SA-1234 doesnt have any assigned trailers',
        //         to: '/location/205'
        //     },
        //     {
        //         id: 1,
        //         title: 'Something else',
        //         label: 'Another notification',
        //         to: '/location/205'
        //     }
        // ]
    };

    // use this instead of props.history
    resetThenSet = (id, key) => {
        const temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach(item => item.selected = false);
        temp[id].selected = true;
        this.setState({
            [key]: temp
        });
    };

    counter = () => {
        if (!this.props.missingTrailers || this.props.missingTrailers.length === 0) return null;
        else return <span className="notificationCounter">!</span>;
    };

    render() {
        let drawerClasses = '';
        if (this.props.show) drawerClasses = 'open';

        let siteSelection = '';
        let menuSelection = '';

        if (this.props.currentSite) {
            siteSelection = (
                <li className="">
                    <button
                        onClick={this.props.openSiteHandler}
                        data-id="2"
                        data-site-id={this.props.currentSite.value}
                        type="button"
                        className={`sa-c-navigation__link sa-c-navigation__dropdown-toggle ${this.state.dropDownClass}`}
                    >
                        {this.props.currentSite.label}
                        <Home className="ln-u-margin-left" />
                    </button>
                </li>
            );

            if (!this.props.show) {
                menuSelection = (
                    <li className="">
                        <button
                            onClick={() => this.props.openMenuHandler(this.state.mainMenu)}
                            data-id="2"
                            data-site-id={this.props.currentSite.value}
                            type="button"
                            className={`sa-c-navigation__link sa-c-navigation__dropdown-toggle menu ${this.state.dropDownClass}`}
                        >
                            <GridView className="ln-u-margin-left" />
                        </button>
                    </li>
                );
            }
            else {
                menuSelection = <Dropdown name="Menu" list={this.state.mainMenu} resetThenSet={this.resetThenSet} />;
            }
        }

        return (
            <header className="toolbar ln-o-page__header">
                <nav className="toolbar__navigation sa-u-hard-right">
                    <div className="toolbar__toggle-button">
                        <DrawerToggleButton click={this.props.drawerClickHandler} show={this.props.show} />
                    </div>
                    <div className="toolbar__title">
                        <Link to="/">
                            <h1>Stores Hub</h1>
                        </Link>
                    </div>
                    <div className={`navigation-items-wrapper ${drawerClasses}`}>
                        <div className="toolbar_navigation-items inline">
                            <ul>{menuSelection}</ul>
                        </div>
                        <div className="spacer" />
                        <div className="toolbar_navigation-items inline">
                            <ul>
                                <li className="">
                                    <button
                                        // onClick={() => window.history.pushState(null, null, '/summary')}
                                        onClick={() => this.props.history.push('/summary')}
                                        data-id="notifications"
                                        type="button"
                                        style={{ height: '100%' }}
                                        className={'sa-c-navigation__link sa-c-navigation__dropdown-toggle notifications'}
                                    >
                                        <StoreLocation className="ln-u-margin-left" />
                                        {this.counter()}
                                    </button>
                                </li>
                                {siteSelection}
                                <Dropdown
                                    name={!this.props.username ? 'Login' : this.props.username}
                                    list={this.state.settings}
                                    resetThenSet={this.resetThenSet}
                                    icon="Account"
                                />
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

toolbar.propTypes = {
    currentSite: PropTypes.object,
    drawerClickHandler: PropTypes.func,
    history: PropTypes.object,
    logOutHandler: PropTypes.func,
    // notificationHandler: PropTypes.func,
    // notifications: PropTypes.array,
    missingTrailers: PropTypes.array,
    openMenuHandler: PropTypes.func,
    openSiteHandler: PropTypes.func,
    show: PropTypes.bool.isRequired,
    username: PropTypes.string
};

const mapStateToProps = state => {
    return {
        // notifications: state.user.notifications
        missingTrailers: state.location.missingTrailers
    };
};

const mapDispatchToProps = () => {
    return {
        // onGetNotifications: () => dispatch(actions.getNotifications())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(toolbar, axios)));

import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

import aipScheduleReducer from './store/reducers/aipSchedule';
import auditReducer from './store/reducers/audit';
import authReducer from './store/reducers/auth';
import locationReducer from './store/reducers/locations';
import trailerReducer from './store/reducers/trailers';
import orderCycleReducer from './store/reducers/orderCycles';
import scheduleReducer from './store/reducers/schedule';
import userReducer from './store/reducers/userSite';
import emailReducer from './store/reducers/email';
import generalReducer from './store/reducers/general';

import { authContext } from './adalConfig';
import { runWithAdal } from 'react-adal';

/*
    need to install redux devtools for this to work.
    visit: https://github.com/zalmoxisus/redux-devtools-extension
*/
/* eslint-disable-next-line no-undef*/
const buildType = process.env.REACT_APP_BUILD_TYPE;

/* eslint-disable-next-line no-undef*/
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const rootReducers = combineReducers({
    aipSchedule: aipScheduleReducer,
    audit: auditReducer,
    auth: authReducer,
    location: locationReducer,
    trailer: trailerReducer,
    orderCycle: orderCycleReducer,
    schedule: scheduleReducer,
    user: userReducer,
    email: emailReducer,
    general: generalReducer
});

// const saveToLocalStorage = state => {
//     try {
//         const serializedState = JSON.stringify(state);
//         localStorage.setItem('state', serializedState);
//         console.log('save state', serializedState);
//     }
//     catch (e) {
//         // console.log(e);
//     }
// };

// const loadFromLocalStorage = () => {
//     try {
//         const serializedState = localStorage.getItem('state');
//         if (serializedState === null) return undefined;
//         console.log(serializedState);
//         // return JSON.parse(serializedState);
//     }
//     catch (e) {
//         console.log(e);
//         return undefined;
//     }
// };

// const persistedState = loadFromLocalStorage();

const store = createStore(
    rootReducers,
    composeEnhancers(
        // persistedState
        applyMiddleware(thunk)
    )
);

// store.subscribe(() => saveToLocalStorage(store.getState()));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App buildType={buildType} />
        </BrowserRouter>
    </Provider>
);

runWithAdal(
    authContext,
    () => {
        ReactDOM.render(app, document.getElementById('root'));
    },
    false
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

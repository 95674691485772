import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FilledButton } from '@jsluna/button';
import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { List } from '@jsluna/list';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import { FileUpload } from 'primereact/fileupload';

import Input from '../../components/UI/Input/Input';

import { getToken } from '../../adalConfig';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { toggleDates, updateObject } from '../../shared/utility';

import * as actions from '../../store/actions';

import moment from 'moment';

moment.locale('en');

class MassUpload extends Component {
    state = {
        baseUrl: `${axios.defaults.baseURL}aipschedule`,
        endDate: null,
        startDate: null,
        endDateFocused: false,
        startDateFocused: false,
        isValidSelection: false,
        isValidDateRange: true,
        isValidEndDate: true,
        isValidStartDate: true,
        scheduleType: null,
        scheduleTypes: null,
        token: null,
        touched: {
            endDate: false,
            startDate: false
        },
        url: null,
        errors: null,
        uploadComplete: false
    };

    componentDidMount() {
        this.props.onGetScheduleTypes();
        getToken().then(token => this.setState({ token: token }));

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading)
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
    }

    componentDidUpdate(_, prevState) {
        if (!this.props.loading && this.props.types && !this.state.scheduleTypes)
            this.setScheduleTypes();

        if (prevState.scheduleType !== this.state.scheduleType || prevState.endDate !== this.state.endDate)
            this.setPathParams();
    }

    setScheduleTypes() {
        const scheduleTypes = this.props.types.map(type => ({ label: type.name, value: type.id }));
        this.setState({ scheduleTypes: scheduleTypes });
    }

    beforeSend = event => {
        this.setState({ errors: null });
        event.xhr.setRequestHeader('Authorization', `Bearer ${this.state.token}`);
    };

    afterUpload = event => {
        let errors = [];
        if (event.xhr.response !== '') {
            try {
                const result = JSON.parse(event.xhr.response);
                if (result instanceof Array) {
                    errors = result;
                }
                else {
                    errors.push(result);
                }
            }
            catch (e) {
                errors.push(event.xhr.response);
            }
        }

        this.setState({
            errors: errors.length === 0 ? null : errors,
            uploadComplete: true
        });
    };

    // afterUploadError = event => {
    //     const errors = [];
    //     if (event.xhr.response !== '')
    //         errors.push(event.xhr.response);

    //     this.setState({
    //         errors: errors.length === 0 ? null : errors,
    //         uploadComplete: true
    //     });
    // };

    resetMsg = (event, startOver) => {
        this.setState({ uploadComplete: false, errors: null });
        if (startOver)
            this.setState({ scheduleType: null, endDate: null, isValidSelection: false, isValidDateRange: true });
    };

    typeChangeHandler = event => {
        this.setState({
            isValidSelection: !!event.target.value,
            scheduleType: event.target.value,
            uploadComplete: false,
            errors: null
        });
    }

    focusedHandler = (event, identifier) => this.setState({ [`${identifier}Focused`]: event.focused });

    dateChangedHandler = (event, identifier) => {
        const touched = updateObject(this.state.touched, { [identifier]: true });
        this.setState(() => ({ [identifier]: event, touched, uploadComplete: false, errors: null }), () => this.checkDateRange(identifier));

        console.log(moment(event).isoWeek());
    }

    toggleDateHandler = identifier => {
        const date = toggleDates(identifier, this.state.startDate, this.state.endDate);
        this.dateChangedHandler(date, identifier);
    }

    checkDateRange = (identifier) => {
        let valid = true;
        switch (identifier) {
            case 'startDate':
                if (this.state.startDate)
                    valid = this.state.startDate.format('dddd') === 'Sunday';
                this.setState({
                    isValidDateRange: valid, isValidStartDate: valid
                });
                break;
            case 'endDate':
                if (this.state.endDate)
                    valid = this.state.endDate.format('dddd') === 'Saturday';
                this.setState({
                    isValidDateRange: valid, isValidEndDate: valid
                });
                break;
            default:
                this.setState({
                    isValidDateRange: true, isValidStartDate: valid, isValidEndDate: valid
                });
                break;
        }

    }

    setPathParams() {
        // add the path params
        const type = this.state.scheduleType;
        let url = `${this.state.baseUrl}/${type}`;
        if (this.state.endDate !== null)
            url += `/${this.state.endDate.format('YYYY-MM-DD')}`;

        this.setState({ url: url });
    }

    getErrorMessage = type => {
        let errorMessage = '';

        if (!this.state.isValidDateRange)
            errorMessage = 'Invalid date range';

        if (!this.state[`isValid${type}`])
            errorMessage = `Must be a ${type === 'StartDate' ? 'Sunday' : 'Saturday'}`;

        return errorMessage;
    }

    render() {
        const loading =
            <ProgressIndicator page loading={this.props.loading}>
                <ProgressSpinner light />
                Loading...
            </ProgressIndicator>;

        let errors = null;

        if (this.state.errors)
            errors = (
                <Fragment>
                    <p className='ln-u-color-red ln-u-display-1'>
                        {this.state.errors[0].substr(0, 7) === 'Problem' ?
                            'The file failed to upload' : 'The following stores failed to upload.'}
                    </p>
                    <List
                        className='ln-u-push-top ln-u-color-red'
                        items={this.state.errors}
                        type="bare"
                        spaced
                    />
                    <FilledButton className='ln-u-push-top-sm' onClick={event => this.resetMsg(event, true)}>Start Over</FilledButton>
                </Fragment>);

        if (this.state.uploadComplete && !this.state.errors)
            errors = <p className='ln-u-push-top ln-u-color-green ln-u-display-1'>All schedules uploaded successfully</p>;

        let uploaderDisplay = 'block';
        if (this.state.uploadComplete)
            uploaderDisplay = 'none';

        let uploader =
            <GridWrapper style={{ display: uploaderDisplay }}>
                <GridItem size="4/6">
                    <FileUpload
                        name="massupload"
                        onBeforeSend={event => this.beforeSend(event)}
                        onUpload={event => this.afterUpload(event)}
                        onError={event => this.afterUpload(event)}
                        onSelect={event => this.resetMsg(event)}
                        url={this.state.url}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        multiple={false}></FileUpload>
                </GridItem>
            </GridWrapper>;

        if (!this.state.isValidSelection || !this.state.isValidDateRange)
            uploader = null;

        return (
            this.props.loading || !this.state.scheduleTypes ? loading :
                <Container>
                    <Card>
                        <h3>Mass Uploader</h3>
                        <p>Please select the type of upload you want to run.</p>
                        <ul>
                            <li className='ln-u-body-2' style={{ listStyleType: 'none', marginLeft: '-1rem' }}>Info:</li>
                            <li>If you leave the date blank, the dates in the upload will be used.</li>
                            <li>For scheules - If you enter a date, the schedule in the upload will be created from the start date of
                            that week to the end date specified below.</li>
                            <li>For scheules - If the date you select is less than the last date in the upload file, the date in the upload will be used.</li>
                            <li>Click the week number button to toggle to the between high date, end of week or nothing</li>
                        </ul>
                        <GridWrapper>
                            <GridItem size="1/6">
                                <Input
                                    label="Type"
                                    id="type"
                                    changed={(event) => this.typeChangeHandler(event)}
                                    options={this.state.scheduleTypes}
                                    value={this.state.scheduleType}
                                    elementType="select" />
                            </GridItem>
                            <GridItem size="1/6">
                                <Input
                                    label="End Date"
                                    id="endDate"
                                    dateChanged={event => this.dateChangedHandler(event, 'endDate')}
                                    onFocused={event => this.focusedHandler(event, 'endDate')}
                                    focused={this.state.endDateFocused}
                                    value={this.state.endDate}
                                    invalid={!this.state.isValidDateRange}
                                    errorMessage={this.getErrorMessage('EndDate')}
                                    elementType="datelabelled"
                                    wkNoClick={() => this.toggleDateHandler('endDate')}
                                    yearStartExceptions = {this.props.yearStartExceptions} />
                            </GridItem>
                        </GridWrapper>
                        {uploader}
                        {errors}
                    </Card>
                </Container >
        );
    }
}

MassUpload.propTypes = {
    loading: PropTypes.bool,
    onGetScheduleTypes: PropTypes.func,
    onGetYearStartExceptions: PropTypes.func.isRequired,
    types: PropTypes.array,
    yearStartExceptions: PropTypes.array,
    yearStartExceptionsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        loading: state.aipSchedule.loading,
        types: state.aipSchedule.types,
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading,
        yearStartExceptionsError: state.general.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetScheduleTypes: () => dispatch(actions.getScheduleTypes()),
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MassUpload, axios));

import axios from '../../axios';

import * as actionTypes from './actionTypes';
import { adalConfig, authContext } from '../../adalConfig';
// import { setTimeout } from 'timers';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (email, givenName, roles) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        email: email,
        givenName: givenName,
        roles: roles
    };
};

export const authFail = (error) => {
    localStorage.removeItem('email');
    localStorage.removeItem('givenName');
    localStorage.removeItem('surname');
    localStorage.removeItem('exp');
    localStorage.removeItem('roles');

    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('givenName');
    localStorage.removeItem('surname');
    localStorage.removeItem('exp');
    localStorage.removeItem('roles');
    authContext.logOut();

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        if (expirationTime) {
            setTimeout(() => {
                dispatch(logout());
            }, expirationTime * 1000);
        }
    };
};

export const login = () => {
    authContext.login();
    return { type: actionTypes.AUTH_SUCCESS };
};

export const auth = () => {
    return dispatch => {
        dispatch(authStart());

        const url = '/User/GetUser';

        axios.get(url)
            .then(response => {
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('givenName', response.data.givenName);
                localStorage.setItem('surname', response.data.surname);
                localStorage.setItem('expires', response.data.expires);
                localStorage.setItem('roles', response.data.roles);
                // console.log(response.data.expires, 'exp date');
                // setTimeout(() => refreshToken(), response.data.expires * 1000 - new Date().getTime() - 60000);
                // setTimeout(() => refreshToken(), 20000);
                setTimeout(() => refreshToken(), 59 * 60 * 1000);
                dispatch(authSuccess(response.data.email, response.data.givenName, response.data.roles));
                // dispatch(checkAuthTimeout(response.data.exp));
            })
            .catch((error) => {
                console.log(error, 'auth error');
                dispatch(authFail('Failed getting user information'));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('givenName');
        if (!token) {
            // authContext.logOut();
            // dispatch(logout());
        }
        else {
            const expirationDate = localStorage.getItem('expires');

            if (expirationDate && new Date(expirationDate * 1000) < new Date()) {
                // dispatch(logout());
            }
            else {
                const email = localStorage.getItem('email');
                const givenName = localStorage.getItem('givenName');
                dispatch(authSuccess(email, givenName));
                // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            }
        }
    };
};

export const refreshToken = () => {
    authContext.acquireToken(adalConfig.clientId, (errorDesc, token, error) => {
        if (error) {
            console.log(error, 'error');
            authContext.acquireTokenRedirect(adalConfig.clientId);
            if (authContext.isCallback(window.location.hash)) {
                authContext.handleWindowCallback();
            }
        }
        else
            auth();
    });
};

import PropTypes from 'prop-types';
import React from 'react';
import './DrawerToggleButton.css';

const drawerToggleButton = props => {
    let drawerClasses = 'sa-c-header__toggle';
    if (props.show)
        drawerClasses = 'sa-c-header__toggle is-open';

    return (
        <button onClick={props.click} type="button" className={drawerClasses} aria-hidden="true" tabIndex="-1">
            <span className="sa-navigation-toggle">
                <span className="sa-c-navigation-toggle__item" />
            </span>
        </button>
    );
};

drawerToggleButton.propTypes = {
    click: PropTypes.func,
    show: PropTypes.bool
};

export default drawerToggleButton;


import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { ProgressBar, ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Search } from '@jsluna/icons';

import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions';

import { userCanEdit } from '../../shared/utility';

import classes from './Summary.module.scss';

import uniqBy from 'lodash-es/uniqBy';

/* eslint-disable react-hooks/rules-of-hooks */
const summary = props => {
    const [summaryDisplay, setSummaryDisplay] = useState(null);
    const {
        roles,
        onGetMissingDriveTimes,
        onGetMissingTrailers,
        onGetOutstandingLocationChangeRequestCount,
        summaryData,
        onGetSummary,
        missingTrailers
    } = props;

    // use this instead of props.history
    const history = useHistory();

    useEffect(() => {
        if (!summaryData) onGetSummary();
    }, [summaryData, onGetSummary]);

    useEffect(() => {
        if (userCanEdit(roles)) onGetOutstandingLocationChangeRequestCount();
        onGetMissingDriveTimes();
        if (!missingTrailers) onGetMissingTrailers();
    }, [roles, onGetMissingDriveTimes, onGetMissingTrailers, missingTrailers, onGetOutstandingLocationChangeRequestCount]);

    let loading = (
        <ProgressIndicator page loading={props.loading}>
            <ProgressSpinner light />
            Loading...
        </ProgressIndicator>
    );

    if (props.hideHeader)
        loading =
            props.loading || props.gettingMissingDriveTimes ? (
                <div>
                    Loading store breakdown...
                    <ProgressBar standalone />
                </div>
            ) : null;

    /* eslint-disable-next-line react/no-multi-comp */
    const summary = useCallback(() => {
        let allDCs = { reverse: 0 };
        const activeLocations = summaryData || [];
        const servicingDCs = uniqBy(activeLocations, l => l.servicingDC).sort((a, b) => {
            // ensure 'All' is always first
            if (b.servicingDC === 'All') return 999;
            if (a.servicingDC > b.servicingDC) return 1;
            if (a.servicingDC < b.servicingDC) return -1;

            return 0;
        });

        const sd = servicingDCs.map(svc => {
            const dcStores = activeLocations.filter(l => l.servicingDC === svc.servicingDC);
            const hubs = dcStores.find(l => l.shortName === 'SA-SHS' || l.shortName === 'SA-SISH');
            const darkHubs = dcStores.find(l => l.shortName === 'SA-DS');
            const spokes = dcStores.find(l => l.shortName === 'SA-S');
            const sis = dcStores.find(l => l.shortName === 'SA-SIS');
            const standalone = dcStores.find(l => l.shortName === 'SA-SSS');
            const rfc = dcStores.find(l => l.shortName === 'RFC');
            const delivered = dcStores.find(l => l.shortName === 'Delivered');
            const clearance = dcStores.find(l => l.shortName === 'CL');

            let dcInfo = {
                serviced: 0,
                hubStores: 0,
                spokeStores: 0,
                siStores: 0,
                standaloneStores: 0,
                rfcStores: 0,
                deliveredStores: 0,
                clearanceStores: 0,
                reverse: 0
            };

            if (hubs) {
                dcInfo.serviced += hubs.cnt;
                dcInfo.hubStores = hubs.cnt;
                if (darkHubs) {
                    dcInfo.serviced += darkHubs.cnt;
                    dcInfo.hubStores += darkHubs.cnt;
                }
            }

            if (spokes) {
                dcInfo.serviced += spokes.cnt;
                dcInfo.spokeStores = spokes.cnt;
            }

            if (sis) {
                dcInfo.serviced += sis.cnt;
                dcInfo.siStores = sis.cnt;
                dcInfo.spokeStores += dcInfo.siStores;
            }

            if (standalone) {
                dcInfo.serviced += standalone.cnt;
                dcInfo.standaloneStores = standalone.cnt;
            }

            if (rfc) {
                dcInfo.serviced += rfc.cnt;
                dcInfo.rfcStores = rfc.cnt;
            }

            if (delivered) dcInfo.deliveredStores = delivered.cnt;

            if (clearance) {
                dcInfo.serviced += clearance.cnt;
                dcInfo.clearanceStores = clearance.cnt;
            }

            dcInfo.reverse = dcInfo.serviced - dcInfo.deliveredStores;

            // Barton, Kettering and CHR pick for all stores
            switch (svc.servicingDC) {
                case 'Barton':
                case 'CHR':
                case 'Kettering Pick':
                    dcInfo = { ...allDCs, deliveredStores: dcInfo.deliveredStores };
                    break;
                case 'All':
                    allDCs = { ...dcInfo, deliveredStores: 0 };
                    break;
                default:
                    allDCs.reverse += dcInfo.reverse > 0 ? dcInfo.reverse : 0;
                    break;
            }

            return { [svc.servicingDC]: dcInfo };
        });

        if (sd[0]) sd[0].All.reverse = allDCs.reverse;

        return sd[0]
            ? sd.map(i => {
                    return Object.keys(i).map(info => {
                  const dcInfo = i[info];
                  const dc = Object.keys(i)[0];

                  return (
                      <GridItem key={dc} size="1/6" className="ln-u-push-top">
                          <Panel header={dc} className="noWrapElipses">
                              <GridWrapper>
                                  <GridItem size="3/4">Stores Serviced</GridItem>
                                  <GridItem size="1/4">{dcInfo.serviced}</GridItem>
                                  <GridItem size="3/4">Stores Delivered</GridItem>
                                  <GridItem size="1/4">{dcInfo.deliveredStores}</GridItem>
                                  <GridItem size="3/4">Reverse Stores</GridItem>
                                  <GridItem size="1/4">{dcInfo.reverse > 0 ? dcInfo.reverse : 0}</GridItem>CRET
                                  <GridItem size="3/4">Hub Stores</GridItem>
                                  <GridItem size="1/4">{dcInfo.hubStores}</GridItem>
                                  <GridItem size="3/4">Spoke Stores</GridItem>
                                  <GridItem size="1/4">{dcInfo.spokeStores}</GridItem>
                                  <GridItem size="3/4">Store In Stores</GridItem>
                                  <GridItem size="1/4">{dcInfo.siStores}</GridItem>
                                  <GridItem size="3/4">Standalone Stores</GridItem>
                                  <GridItem size="1/4">{dcInfo.standaloneStores}</GridItem>
                                  <GridItem size="3/4">RFCs</GridItem>
                                  <GridItem size="1/4">{dcInfo.rfcStores}</GridItem>
                                  <GridItem size="3/4">Clearance Centres</GridItem>
                                  <GridItem size="1/4">{dcInfo.clearanceStores}</GridItem>
                              </GridWrapper>
                          </Panel>
                      </GridItem>
                  );
              });
            }): null;
    }, [summaryData]);

    useEffect(() => {
        if (summaryData) setSummaryDisplay(summary());
    }, [summary, summaryData]);

    const driveTimes = !userCanEdit(props.roles) ? (
        <span></span>
    ) : (
        <GridItem size="2/6" className="ln-u-push-top">
            <Panel header={`Missing Drive Times (${(props.missingDriveTimes || []).length})`}>
                <ScrollPanel style={{ width: '100%', height: '170px' }}>
                    <GridWrapper>
                        {(props.missingDriveTimes || [])
                            .sort((a, b) => +a.locationNo - +b.locationNo)
                            .map((dt, idx) => (
                                <Fragment key={`missingdt_${idx}`}>
                                    <GridItem size="1/5">{dt.locationNo || ' '}</GridItem>
                                    <GridItem size="4/5">{dt.locationName || ' '}</GridItem>
                                </Fragment>
                            ))}
                    </GridWrapper>
                </ScrollPanel>
            </Panel>
        </GridItem>
    );

    const adminView = !userCanEdit(props.roles) ? (
        <span></span>
    ) : (
        <GridItem size="1/6" className="ln-u-push-top">
            <Panel header="Outstanding Requests" className={classes.fill2}>
                <GridWrapper>
                    <GridItem size="2/3">Location Updates: {props.locationRequestCount || 0}</GridItem>
                    <GridItem size="1/3">
                        <IconButton
                            size="small"
                            disabled={(props.locationRequestCount || 0) === 0}
                            variant="outlined"
                            label="Review"
                            onClick={() => history.push('/review')}
                            hideLabel={true}
                        >
                            <Search />
                        </IconButton>
                    </GridItem>
                </GridWrapper>
            </Panel>
        </GridItem>
    );

    const missingTrailersPanel = !userCanEdit(props.roles) ? (
        <span></span>
    ) : (
        <GridItem size="2/6" className="ln-u-push-top">
            <Panel header={`Locations Missing Trailers (${(props.missingTrailers || []).length})`}>
                <ScrollPanel style={{ width: '100%', height: '170px' }}>
                    <GridWrapper>
                        {(props.missingTrailers || [])
                            .sort((a, b) => +a.label - +b.label)
                            .map((l, idx) => {
                                const location = l.label.split('|');
                                return (
                                    <Fragment key={`missingTrailer_${idx}`}>
                                        <Link to={`location/${l.value}`}>
                                            <GridItem size="1/5">{location[1] || ' '}</GridItem>
                                            <GridItem size="4/5">{location[0] || ' '}</GridItem>
                                        </Link>
                                    </Fragment>
                                );
                            })}
                    </GridWrapper>
                </ScrollPanel>
            </Panel>
        </GridItem>
    );

    const header = props.hideHeader ? null : <h3>Location Summary View</h3>;

    return (
        <Container>
            <Card className={classes.unpad}>
                {header}
                {loading}
                <GridWrapper>
                    {summaryDisplay}
                    {adminView}
                    {(props.missingDriveTimes || []).length > 0 ? driveTimes : null}
                    {(props.missingTrailers || []).length > 0 ? missingTrailersPanel : null}
                </GridWrapper>
            </Card>
        </Container>
    );
};

summary.propTypes = {
    gettingMissingDriveTimes: PropTypes.bool,
    hideHeader: PropTypes.bool,
    loading: PropTypes.bool,
    locationRequestCount: PropTypes.number,
    missingDriveTimes: PropTypes.array,
    missingTrailers: PropTypes.array,
    onGetMissingDriveTimes: PropTypes.func,
    onGetMissingTrailers: PropTypes.func,
    onGetOutstandingLocationChangeRequestCount: PropTypes.func,
    onGetSummary: PropTypes.func.isRequired,
    roles: PropTypes.array,
    summaryData: PropTypes.array
};

const mapStateToProps = state => {
    return {
        gettingMissingDriveTimes: state.location.gettingMissingDriveTimes,
        loading: state.location.gettingSummary,
        locationRequestCount: state.location.requestCount,
        missingDriveTimes: state.location.missingDriveTimes,
        missingTrailers: state.location.missingTrailers,
        roles: state.auth.roles,
        summaryData: state.location.summaryData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetMissingTrailers: () => dispatch(actions.getMissingTrailers()),
        onGetMissingDriveTimes: () => dispatch(actions.getMissingDriveTimes()),
        onGetSummary: () => dispatch(actions.getLocationSummary()),
        onGetOutstandingLocationChangeRequestCount: () => dispatch(actions.getOutstandingChangeRequestCount())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(summary, axios));

import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { Modal, ModalHeading } from '@jsluna/modal';

const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {
        state = {
            error: null,
            toHome: false
        }

        componentDidMount() {
            this.reqInterceptor = axios.interceptors.request.use(req => {
                this.setState({ error: null });
                return req;
            });

            this.resInterceptor = axios.interceptors.response.use(res => res, error => {
                this.setState({ error: error });
            });
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        errorConfirmedHandler = () => {
            this.setState({ error: null, toHome: true });
        };

        render() {

            if (this.state.toHome === true) {
                this.setState({ toHome: false });
                return <Redirect to='/' />;
            }

            return (
                <Fragment>
                    <Modal
                        small
                        handleClose={this.errorConfirmedHandler}
                        open={this.state.error !== null} >
                        <ModalHeading element="h3">Oops, we've hit an error</ModalHeading>
                        <p>{this.state.error ? this.state.error.message : ''}</p>
                    </Modal>
                    <WrappedComponent {...this.props} />
                </Fragment>
            );
        }
    };
};

export default withErrorHandler;

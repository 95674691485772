import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableHeaderCell, TableRow } from '@jsluna/table';
import { ArrowRight } from '@jsluna/icons';

import Input from '../../../components/UI/Input/Input';

import classes from '../Schedule.module.scss';

import { minsToHHMM } from '../../../shared/utility';

import { Button } from 'primereact/button';

const scheduleRow = props => {
    const click = id => {
        props.click(id + 1);
    };

    const changed = (event, id) => {
        props.changed(event, id);
    };

    const focused = event => {
        props.focus(event);
    };

    const deleteAdditional = event => {
        props.deleteAdditional(event);
    };

    const calculateTimes = (heading, day, delWindow) => {
        if (delWindow === 'No Delivery')
            return;

        const windowStart = delWindow.substr(0, 5);
        const hm = windowStart.split(':');
        const windowStartMins = +hm[0] * 60 + +hm[1];

        const options = { weekday: 'short', hour: '2-digit', minute: '2-digit' };
        const dt = new Date('08/11/2019');

        let thisDay = new Date(dt.setDate(dt.getDate() + day));
        thisDay = new Date(thisDay.setMinutes(windowStartMins));

        let temp = new Date(thisDay);
        const departTime = new Date(temp.setMinutes(temp.getMinutes() - props.parameters.driveTime));

        temp = new Date(departTime);
        const defaultPickTime = new Date(temp.setMinutes(temp.getMinutes() - props.parameters.pickBeforeTime - props.parameters.loadBeforeDepart));

        switch (heading) {
            case 'Drive Time':
                return minsToHHMM(props.parameters.driveTime);
            case 'Depart Time':
                return departTime.toLocaleTimeString('en-GB', options);
            case 'Default Pick Time':
            case 'Default Xdoc Time':
                return defaultPickTime.toLocaleTimeString('en-GB', options);
            default:
                return '';
        }
    };

    let rowType = <TableCell></TableCell>;

    let deleteButton = null;

    if (props.value.indexOf('Additional') > -1)
        deleteButton = <Button disabled={props.disabled}
            style={{ float: 'right', color: !props.disabled ? '#f06c00' : '#8e8e8e', backgroundColor: 'transparent', marginRight: '5px' }}
            className={[classes.btn, !props.disabled ? classes.active : '', 'mini-button'].join(' ')}
            onClick={() => deleteAdditional(props.value)}
            type="button" icon="pi pi-trash" tooltip="Delete Additional Window" tooltipOptions={{ position: 'top' }} />;

    const cells = () => {
        if (props.isInput) {
            return Object.keys(props.cycle).map(d => {
                const day = props.cycle[d];
                let propsVal = props.value.toLowerCase();
                /* eslint-disable no-mixed-operators */
                propsVal = propsVal.replace(/\s/g, '');
                const overrideInput = propsVal === 'overridepicktime' && d.substr(0, 4) === 'pick'
                    || propsVal === 'overridexdoctime' && d.substr(0, 4) === 'xdoc';
                const checkboxInput = propsVal.substr(0, 3) === 'ndc' && d.substr(0, 3) === 'ndc'
                    || propsVal.substr(0, 3) === 'cdc' && d.substr(0, 3) === 'cdc';
                const additionalInput = propsVal.substr(0, 10) === 'additional' && d.substr(0, 10) === 'additional'
                    && propsVal === d.substr(0, d.indexOf('Week')).toLowerCase()
                    || propsVal === 'transportwindow' && d.substr(0, 4) === 'tran';
                /* eslint-enable no-mixed-operators */

                let content = null;

                let inputValue = props.cycle[d].value;
                if (additionalInput && inputValue === 'No Delivery') {
                    inputValue = '';
                }
                if(!inputValue){
                    inputValue = '';
                }

                if (overrideInput || checkboxInput || additionalInput) {
                    // const isDayTimeValid = isValidTime(props.cycle[d].value, true, 'dayTimeValid');
                    if (day.window !== 'No Delivery') {
                        content = <Input
                            disabled={props.disabled}
                            autoComplete='off'
                            errorMessage={day.valid ? '' : 'Invalid ' + day.elementConfig.type}
                            className={day.valid || checkboxInput ? '' : classes.invalid}
                            onFocused={additionalInput ? (event) => focused(event, d) : null}
                            changed={(event) => changed(event, d)}
                            value={inputValue}
                            id={d}
                            elementType={checkboxInput ? 'checkbox' : 'input'} />;
                    }

                    return (
                        <TableCell
                            className={[classes.noWrap, classes.p0, day.window === 'No Delivery' ? classes.noDelivery : '', classes.width].join(' ')}
                            key={d}
                            align='center' >
                            {content}
                        </TableCell>);
                }

                return null;
            });
        }
        else {
            return Object.keys(props.cycle).map((d, ind) => {
                const day = props.cycle[d];

                if (d.substr(0, 4) === 'pick') {
                    return (
                        <TableCell
                            className={[classes.noWrap, classes.p0, day.window === 'No Delivery' ? classes.noDelivery : '', classes.width].join(' ')}
                            key={d}
                            align='center'>
                            {calculateTimes(props.value, ind, day.window)}
                        </TableCell>
                    );
                }

                return null;
            });
        }
    };

    let rows = (
        <Fragment>
            <TableCell className={classes.noWrap}>{props.value}{deleteButton}</TableCell>
            {cells()}
        </Fragment>
    );

    if (props.heading) {
        rowType =
            <TableHeaderCell className={props.headingState}>
                <ArrowRight className={classes.spinIcon}
                    onClick={() => click(props.week)} />
            </TableHeaderCell>;

        rows = (
            <Fragment>
                <TableHeaderCell className={classes.noWrap}>Week {props.week + 1} {props.value}</TableHeaderCell>
                {Object.keys(props.cycle).map((d, ind) => {
                    const day = props.cycle[d];
                    const id = `window${ind}`;
                    if (d.substr(0, 4) === 'pick') {
                        return (
                            <TableHeaderCell
                                key={id}
                                align="center">
                                <Input
                                    disabled={props.disabled}
                                    autoComplete='off'
                                    errorMessage={day.windowValid ? '' : 'Invalid ' + day.elementConfig.type}
                                    className={[day.windowValid ? '' : classes.invalid, day.window === 'No Delivery' ? classes.noDelivery : ''].join(' ')}
                                    changed={(event) => changed(event, d)}
                                    onFocused={(event) => focused(event, d)}
                                    value={day.window}
                                    id={id} elementType='input' />
                            </TableHeaderCell>
                        );
                    }

                    return null;
                })}
            </Fragment>
        );
    }

    return (
        <TableRow className={!props.heading ? props.rowState : ''}>
            {rowType}
            {rows}
        </TableRow>
    );
};

scheduleRow.propTypes = {
    changed: PropTypes.func,
    click: PropTypes.func,
    cycle: PropTypes.object,
    deleteAdditional: PropTypes.func,
    disabled: PropTypes.bool,
    focus: PropTypes.func,
    heading: PropTypes.bool,
    headingState: PropTypes.any,
    isInput: PropTypes.bool,
    parameters: PropTypes.object,
    rowState: PropTypes.any,
    value: PropTypes.any,
    week: PropTypes.number
};

export default scheduleRow;

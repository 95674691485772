import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import { AutocompleteField } from '@jsluna/autocomplete';

class LocationSelect extends Component {
    componentDidMount() {
        if (this.props.locationHeaders.length === 0)
            this.props.onGetLocations();

        if (this.props.id !== null)
            this.props.onSetLocation(this.props.id);
    }
    getSelected = () => {
        if ((this.props.locationHeaders || []).length > 0 && this.props.id !== null) {
            const option = this.props.locationHeaders.filter(m => m.id === this.props.id)[0];
            if (!option)
                return null;

            return {
                value: option.id.toString(),
                label: `${option.locationNo} ${option.locationName}`
            };
        }
        else
            return null;
    }

    locationSelectHandler = event => {
        let locationId = null;
        if (event !== null && event.value)
            locationId = +event.value;

        // use the HTML5 history to change the URL, can't use React history.push as that also forces a reload which we don't need
        window.history.pushState(null, null, `/location/${locationId === null ? '' : locationId}`);

        this.props.onSetLocation(locationId);
    }

    render() {
        const locationOptions = this.props.locationHeaders.map(l => ({ label: `${l.locationNo} ${l.locationName}`, value: l.id.toString() }));
        const obj = this.getSelected();

        let locationSelect = null;

        if (locationOptions)
            locationSelect = <AutocompleteField
                name="locationListAutocomplete"
                placeholder="Select a Location"
                label="Select Location"
                hideLabel
                onSelect={event => this.locationSelectHandler(event)}
                // onChange={event => this.locationSelectHandler(event)}
                options={locationOptions}
                clearSelection={() => this.locationSelectHandler(null)}
                selectedItem={obj}
            // value={!obj ? '' : obj.value}
            />;

        return (
            locationSelect
        );
    }
}

LocationSelect.propTypes = {
    id: PropTypes.number,
    locationHeaders: PropTypes.array,
    onGetLocations: PropTypes.func,
    onSetLocation: PropTypes.func
};

const mapStateToProps = state => {
    return {
        id: state.location.id,
        locationHeaders: state.location.locationHeaders
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClearLocationId: () => dispatch(actions.clearLocationId()),
        onGetLocations: () => dispatch(actions.getLocationHeaders()),
        onSetLocation: id => dispatch(actions.setLocation(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(LocationSelect, axios));

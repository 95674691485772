import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import * as actions from '../../store/actions/index';

import { Container, FlagBody, FlagComponent, FlagWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { SwitchField, TextInput } from '@jsluna/form';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell } from '@jsluna/table'

import { Cancel, Download, Edit, Plus, Search } from '@jsluna/icons';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { saveAs } from 'file-saver';

import { updateObject } from '../../shared/utility';

import PaginatedTable from '../../components/UI/Table/PaginatedTable'
import { isEmpty } from 'lodash-es';

class Locations extends Component {
    state = {
        downloadEnabled: false,
        selectedRows: []
    }

    columns = [
        { accessor: 'selector', name: '', width: '4rem' },
        { accessor: 'edit', name: '', width: '3rem' },
        { accessor: 'placeId', name: 'Place Id', width: '7rem' },
        { accessor: 'locationNo', name: 'Location No', width: '8rem' },
        { accessor: 'locationName', name: 'Location Name' },
        { accessor: 'brand', name: 'Brand', width: '7rem' },
        { accessor: 'servicingDC', name: 'Servicing DC', width: '10rem' },
        { accessor: 'deliveringDC', name: 'Delivering DC', width: '10rem' },
        { accessor: 'division', name: 'Division', width: '10rem' },
        { accessor: 'region', name: 'Region', width: '12rem' }];

    includeInactive = '';
    setIncludeInactive;
    filterInfo = {};
    setFilterInfo;
    sortInfo = {};
    setSortInfo;

    componentDidUpdate() {
        if (!this.props.fileLoading && this.props.fileData && this.state.downloadEnabled) {
            this.saveSummaryDownload();
        }

        if (!this.props.regenerating && this.props.regenerateSuccess && this.state.selectedRows.length > 0) {
            this.growl.show({ severity: 'success', summary: 'Information', detail: 'Regeneration Complete' });
        }
        else if (this.props.regenerateError) {
            this.growl.show({ severity: 'error', summary: 'Information', detail: 'Regeneration error' });
        }
    }

    row = React.createRef();

    populateState() {
        const pageInfo = updateObject(this.state.pageInfo,
            {
                includesInactive: this.props.locationsPage.includesInactive
            });

        this.setState({
            locationRecords: this.props.locationsPage.locations,
            pageInfo: pageInfo,
            recordCount: this.props.locationsPage.totalRecords,
            requestedPage: (this.props.locationsPage.currentPage === this.state.requestedPage) ? 0 : this.state.requestedPage,
            requestedSort: (this.props.locationsPage.sortKey === this.state.requestedSort) ? '' : this.state.requestedSort,
            requestedFilter: (this.props.locationsPage.filtered === this.state.requestedFilter) ? -1 : this.state.requestedFilter
        });

        if (this.props.regenerateSuccess !== null && !this.props.regenerating) {
            if (this.props.regenerateSuccess)
                this.setState(() => ({ selectedLocations: [] }), () => this.props.onResetRegenerateSchedule());
            else if (this.regenerateError)
                this.props.onResetRegenerateSchedule();
        }
    }

    saveSummaryDownload = () => {
        const blob = new Blob([this.props.fileData], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'Locations.xlsx');
        this.setState({ downloadEnabled: false });
    }

    handleSummaryDownload = () => {
        // TODO:
        // processData() returns the current datatables visible data set, i.e. filtered and sorted if appropriate
        const visibleData = this.datatable.processData();
        this.setState({ downloadEnabled: true });
        // send data to api
        this.props.onSummaryDownload(visibleData);
    }

    handleDetailDownload = () => {
        // TODO:
        // processData() returns the current datatables visible data set, i.e. filtered and sorted if appropriate
        const visibleData = this.datatable.processData();
        this.setState({ downloadEnabled: true });
        // send data to api
        this.props.onDetailDownload(visibleData);
    }

    actionTemplate(rowData) {
        return <Link to={{ pathname: `/location/${rowData.headerId}` }}><Edit /></Link>;
    }

    userCanAdd = () => {
        if (!this.props.roles)
            return false;

        return this.props.roles.includes('Admin_Central') ||
            this.props.roles.includes('Admin_Depot');
    }

    handleNewLocationClick = () => {
        this.props.history.push('/locations/newlocation');
    };

    includeInactiveClickHandler = event => {
        this.setIncludeInactive(event.target.checked);
    }

    setSort = sortInfo => {
        const newSort = updateObject(this.sortInfo, { sortField: sortInfo.sortField, sortDirection: sortInfo.sortDirection })
        this.setSortInfo(newSort);
    }

    setFilter = (event, field, value) => {
        if (event.key === 'Enter') {
            const newFilter = updateObject(this.filterInfo, { [field]: value });
            // remove empty filters so the clear filter operator can test for an empty object properly
            Object.keys(newFilter).forEach(key => (newFilter[key] === '') && delete newFilter[key]);
            this.setFilterInfo(newFilter);
        }
    }

    getFilterValue = filterName => {
        return this.state.pageInfo.filter[filterName] || '';
    }

    onTableMount = tableIncludeInfo => {
        this.includeInactive = tableIncludeInfo[0];
        this.setIncludeInactive = tableIncludeInfo[1];
    }

    onTableFilter = tableFilterInfo => {
        this.filterInfo = tableFilterInfo[0];
        this.setFilterInfo = tableFilterInfo[1];
    }

    onTableSort = tableSortInfo => {
        this.sortInfo = tableSortInfo[0];
        this.setSortInfo = tableSortInfo[1];
    }

    onTableSelect = tableSelectedRows => {
        this.setState({ selectedRows: tableSelectedRows });
    }

    onRegenerateMultipleSchedules = () => {
        this.props.onRegenerateMultipleSchedules(this.state.selectedRows);
    };

    handleSummaryDownload = () => {
        this.setState({ downloadEnabled: true });
        this.props.onSummaryDownload(this.filterInfo, this.sortInfo, this.includeInactive);
    }

    handleDetailDownload = () => {
        this.setState({ downloadEnabled: true });
        this.props.onDetailDownload(this.filterInfo, this.sortInfo, this.includeInactive);
    }

    handleAllDownload = () => {
        this.setState({ downloadEnabled: true });
        this.props.onAllDownload(this.includeInactive);
    }

    clearFilter = () => {
        document.querySelectorAll('[id$="-filter"]').forEach(f => f.value = '')
        this.setFilterInfo({})
    }

    render() {
        const downloading = (
            <ProgressIndicator page loading={this.props.fileLoading}>
                <ProgressSpinner />
                Creating Download...
            </ProgressIndicator>
        );

        const regenerating = (
            <ProgressIndicator page loading={this.props.regenerating}>
                <ProgressSpinner />
                Regenerating schedules
            </ProgressIndicator>
        );

        let addNew = null;
        let regenerate = null;

        if (this.userCanAdd()) {
            addNew = (
                <FlagComponent className="ln-u-padding">
                    <IconButton
                        onClick={this.handleNewLocationClick}
                        variant="outlined"
                        label="Add New Location" >
                        <Plus />
                    </IconButton>
                </FlagComponent>
            );

            regenerate = (
                <FlagComponent className="ln-u-padding">
                    <Button
                        disabled={this.state.selectedRows.length === 0}
                        onClick={this.onRegenerateMultipleSchedules}
                        style={{ top: '-3px' }}
                        type="button"
                        icon="pi pi-refresh"
                        tooltip="Regenerate Schedules"
                        tooltipOptions={{ position: 'left' }} />
                </FlagComponent>
            );
        }

        const includeInactiveSwitch = (
            <div style={{ top: '-3rem', position: 'relative', width: '25%' }}>
                <SwitchField
                    name="include-inactive"
                    onChange={event => this.includeInactiveClickHandler(event)}
                    options={[{ value: 'includesInactive', label: 'Include inactive' }]} />
            </div>
        );

        const datatable = (
            <Fragment>
                <FlagWrapper respondAt="md" className="ln-u-push-bottom">
                    <FlagBody>
                        <h3 className="ln-u-flush-bottom">Location List</h3>
                    </FlagBody>
                    {regenerate}
                    {addNew}
                    {/* <FlagComponent className="ln-u-padding">
                        <IconButton
                            onClick={this.handleDetailDownload}
                            variant="outlined"
                            label="Detail" >
                            <Download />
                        </IconButton>
                    </FlagComponent> */}
                    <FlagComponent className="ln-u-padding">
                        <IconButton
                            onClick={this.handleSummaryDownload}
                            variant="outlined"
                            label="Summary" >
                            <Download />
                        </IconButton>
                    </FlagComponent>
                    <FlagComponent className="ln-u-padding">
                        <IconButton
                            onClick={this.handleAllDownload}
                            variant="outlined"
                            label="All Locations">
                            <Download />
                        </IconButton>
                    </FlagComponent>
                </FlagWrapper>
                <TableContainer fixed>
                    <TableHeader>
                        <TableHeaderRow>
                            {this.columns.map(column => {
                                if (column.accessor === 'selector') {
                                    return <TableHeaderCell key={column.accessor} style={{ width: column.width }} />
                                }
                                else if (column.accessor === 'edit') {
                                    return <TableHeaderCell key={column.accessor} className="ln-u-hard-ends" style={{ width: column.width }} />
                                }
                                else {
                                    return <TableHeaderCell
                                        key={column.accessor}
                                        style={{ width: column.width }}
                                        onSort={ascending => this.setSort({ sortField: column.accessor, sortDirection: ascending ? 'descending' : 'ascending' })}
                                        sortDirection={this.sortInfo.sortField === column.accessor ? this.sortInfo.sortDirection : undefined}
                                    >{column.name}
                                    </TableHeaderCell>
                                }
                            })}
                        </TableHeaderRow>
                        <TableHeaderRow>
                            {this.columns.map(column => {
                                if (column.accessor === 'selector') {
                                    return <TableHeaderCell key={column.accessor} style={{ width: column.width }} />
                                }
                                else if (column.accessor === 'edit') {
                                    return <TableHeaderCell key={column.accessor} className="ln-u-hard-ends" style={{ width: column.width }}>
                                        {isEmpty(this.filterInfo) ?
                                            <div style={{ position: 'relative' }}>
                                                <Search style={{ position: 'absolute', top: '-0.5rem' }} />
                                            </div>
                                            :
                                            <div style={{ position: 'relative' }}>
                                                <IconButton variant='outlined' label='Clear' hideLabel style={{ position: 'absolute', top: '-1.5rem', left: '-1rem' }} onClick={() => this.clearFilter()}>
                                                    <Cancel />
                                                </IconButton>
                                            </div>}
                                    </TableHeaderCell>
                                }
                                else {
                                    return <TableHeaderCell key={column.accessor}>
                                        <TextInput
                                            name={`${column.accessor}-filter`}
                                            onKeyDown={e => this.setFilter(e, column.accessor, e.target.value)}
                                            onBlur={e => this.setFilter({ key: 'Enter' }, column.accessor, e.target.value)}
                                        />
                                    </TableHeaderCell>
                                }
                            })}
                        </TableHeaderRow>
                    </TableHeader>
                </TableContainer>
                <PaginatedTable
                    onMount={this.onTableMount}
                    onFilter={this.onTableFilter}
                    onSelect={this.onTableSelect}
                    onSort={this.onTableSort}
                    columns={this.columns} />
            </Fragment>
        );

        return (
            <Container soft size="def">
                {downloading}
                {regenerating}
                {datatable}
                {this.props.loadingLocationsPage ? '' : includeInactiveSwitch}
                <Growl className='customGrowl' ref={el => this.growl = el}></Growl>
            </Container>
        );
    }
}

Locations.propTypes = {
    error: PropTypes.bool,
    fileData: PropTypes.any,
    fileLoading: PropTypes.bool,
    history: PropTypes.object.isRequired,
    loadingLocationsPage: PropTypes.bool,
    locationsPage: PropTypes.object,
    onAllDownload: PropTypes.func,
    onDetailDownload: PropTypes.func,
    onGetLocationsPage: PropTypes.func.isRequired,
    onRegenerateMultipleSchedules: PropTypes.func,
    onSummaryDownload: PropTypes.func,
    regenerateError: PropTypes.bool,
    regenerateSuccess: PropTypes.bool,
    regenerating: PropTypes.bool,
    roles: PropTypes.array
};

const mapStateToProps = state => {
    return {
        error: state.location.error,
        fileData: state.location.fileData,
        fileLoading: state.location.fileLoading,
        locationsPage: state.location.locationsPage,
        loadingLocations: state.location.loadingLocations,
        loadingLocationsPage: state.location.loadingLocationsPage,
        regenerateSuccess: state.schedule.regenerateSuccess,
        regenerateError: state.schedule.regenerateError,
        regenerating: state.schedule.regenerating,
        roles: state.auth.roles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAllDownload: includeInactive => dispatch(actions.allDownload(includeInactive)),
        onDetailDownload: (filterInfo, sortInfo, includeInactive) => dispatch(actions.detailDownload(filterInfo, sortInfo, includeInactive)),
        onGetLocationsPage: pageInfo => dispatch(actions.getLocationsPage(pageInfo)),
        onRegenerateMultipleSchedules: locationIds => dispatch(actions.regenerateMultipleSchedules(locationIds)),
        onSummaryDownload: (filterInfo, sortInfo, includeInactive) => dispatch(actions.summaryDownload(filterInfo, sortInfo, includeInactive)),
        onUpdateLocationDetails: id => dispatch(actions.updateLocationDetails(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Locations, axios));

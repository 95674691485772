import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { TableBody, TableCell, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, TableRow } from '@jsluna/table';
import { Messages } from '@jsluna/icons';

import Input from '../../../components/UI/Input/Input';

import { Growl } from 'primereact/growl';

import { isValidNumber, shortDays, updateObject } from '../../../shared/utility';

import axios from '../../../axios';

import * as actions from '../../../store/actions';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

class NewCodeRequest extends Component {
    state = {
        odd: { Sun: '0', Mon: '0', Tue: '0', Wed: '0', Thu: '0', Fri: '0', Sat: '0' },
        even: { Sun: '0', Mon: '0', Tue: '0', Wed: '0', Thu: '0', Fri: '0', Sat: '0' },
        code: 'A0068',
        codeCheck: false,
        valid: false,
        sent: false
    };

    componentDidMount() {
        this.props.onGetCycles();
    }

    componentDidUpdate() {
        if (this.state.codeCheck)
            this.checkExtantCode();

        // signify status of request submission
        if (!this.props.sending && !this.state.sent) {
            if (this.props.sendSuccess)
                this.successGrowl();
            else if (this.props.sendFail)
                this.failGrowl();
        }
    }

    checkExtantCode() {
        const odd = Object.values(this.state.odd).join('');
        const even = Object.values(this.state.even).join('');
        const cycle7 = odd === even || +odd === 0 ? even : '';
        const cycle14 = `${odd}${even}`;
        const extant = this.props.orderCycles.filter(oc => oc.cycleDays === cycle7 || oc.cycleDays === cycle14);
        const code = extant.length !== 0 ? extant.map(e => e.code).join(', ') : 'Not found';
        const valid = isValidNumber(cycle14, 0, Number.POSITIVE_INFINITY, true) && cycle14.length === 14;
        this.setState({ codeCheck: false, code: code, valid: valid });
    }

    successGrowl() {
        this.growl.show({ severity: 'success', summary: 'Order Cyle Code Request', detail: 'Request successfully submitted' });
        this.setState({ sent: true });
    }

    failGrowl() {
        this.growl.show({ severity: 'error', summary: 'AIP Change Request', detail: 'Change Request sending failed. Please try again.' });
    }

    checkValidNumber = value => isValidNumber(value, 0, 9, true);

    inputChangedHandler = (event, week, day) => {
        const updatedWeek = updateObject(this.state[week], { [day]: event.target.value });
        this.setState({ [week]: updatedWeek, codeCheck: true });
    }

    sendRequest = () => {
        const odd = Object.values(this.state.odd).join('');
        const even = Object.values(this.state.even).join('');

        this.setState({ sent: false });

        // send to the API for transmission by email
        this.props.onSendCodeRequest(even, odd);
    }

    render() {
        const loading = (
            <ProgressIndicator page loading={this.props.loading}>
                <ProgressSpinner />
                Loading...
            </ProgressIndicator>
        );

        const sending = (
            <ProgressIndicator page loading={this.props.sending}>
                <ProgressSpinner />
                Sending...
            </ProgressIndicator>
        );

        const requestButton = this.state.code === 'Not found' && this.state.valid ?
            <IconButton
                onClick={this.sendRequest}
                variant="outlined"
                label="Request New Code" >
                <Messages />
            </IconButton>
            : <br />;

        let content = (
            <Container>
                {sending}
                <GridWrapper>
                    <GridItem size="1/1" className="ln-u-push-top">
                        <h4>New Order Cycle Code Request</h4>
                    </GridItem>
                    <GridItem size="1/2">
                        <GridWrapper>
                            {['Even', 'Odd'].map(week => (
                                <GridItem size="1/1" key={week}>
                                    <TableContainer className="ln-u-push-bottom ln-u-push-top">
                                        <TableHeader>
                                            <TableHeaderRow>
                                                <TableHeaderCell>{week}</TableHeaderCell>
                                            </TableHeaderRow>
                                            <TableHeaderRow>
                                                {shortDays().map(day =>
                                                    <TableHeaderCell
                                                        key={`${week}${day}`}
                                                        style={{ textAlign: 'center' }}>{day}
                                                    </TableHeaderCell>)}
                                            </TableHeaderRow>
                                        </TableHeader>
                                        <TableBody>
                                            <TableRow>
                                                {shortDays().map(day => <TableCell key={`${week}Cell${day}`} style={{ textAlign: 'center' }}>
                                                    <Input
                                                        id={`${week.toLowerCase()}Input${day}`}
                                                        elementType="input"
                                                        value={this.state[week.toLowerCase()][day]}
                                                        style={{ textAlign: 'center' }}
                                                        errorMessage={this.checkValidNumber(this.state[week.toLowerCase()][day]) ? '' : 'invalid'}
                                                        invalid={this.checkValidNumber(this.state[week.toLowerCase()][day])}
                                                        changed={event => this.inputChangedHandler(event, week.toLowerCase(), day)} />
                                                </TableCell>)}
                                            </TableRow>
                                        </TableBody>
                                    </TableContainer>
                                </GridItem>
                            ))}
                        </GridWrapper>
                    </GridItem>
                    <GridItem size="1/2" className="ln-u-push-top">
                        <GridWrapper>
                            <GridItem size="1/1">
                                <h5>Existing code:
                                    <span className={`ln-u-color-${this.state.code === 'Not found' ? 'red' : 'green'}`}> {this.state.code}</span></h5>
                            </GridItem>
                            <GridItem size="1/1">
                                {requestButton}
                            </GridItem>
                        </GridWrapper>
                    </GridItem>
                </GridWrapper>
                <Growl ref={el => this.growl = el}></Growl>
            </Container>
        );

        if (this.props.loading)
            content = loading;

        return content;
    }
}

NewCodeRequest.propTypes = {
    loading: PropTypes.bool,
    onGetCycles: PropTypes.func.isRequired,
    onSendCodeRequest: PropTypes.func.isRequired,
    orderCycles: PropTypes.array,
    sendFail: PropTypes.bool,
    sendSuccess: PropTypes.bool,
    sending: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        loading: state.orderCycle.loading,
        orderCycles: state.orderCycle.orderCycles,
        sending: state.orderCycle.sending,
        sendFail: state.orderCycle.error,
        sendSuccess: state.orderCycle.success
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCycles: () => dispatch(actions.getOrderCycles()),
        onSendCodeRequest: (even, odd) => dispatch(actions.sendCodeRequest(even, odd))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(NewCodeRequest, axios));

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SET_LOCATION = 'SET_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';
export const SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS';
export const RESET_SELECTED_LOCATIONS = 'RESET_SELECTED_LOCATIONS';
export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';

export const GET_LOCATIONHEADERS_START = 'GET_LOCATIONHEADERS_START';
export const GET_LOCATIONHEADERS_SUCCESS = 'GET_LOCATIONHEADERS_SUCCESS';
export const GET_LOCATIONHEADERS_FAIL = 'GET_LOCATIONHEADERS_FAIL';

export const GET_DCLOCATIONS_SUCCESS = 'GET_DCLOCATIONS_SUCCESS';
export const GET_DCLOCATIONS_START = 'GET_DCLOCATIONS_START';
export const GET_DCLOCATIONS_FAIL = 'GET_DCLOCATIONS_FAIL';

export const UPDATE_LOCATION_DETAILS_START = 'UPDATE_GET_DETAILS_START';
export const UPDATE_LOCATION_DETAILS_SUCCESS = 'UPDATE_GET_DETAILS_SUCCESS';
export const UPDATE_LOCATION_DETAILS_FAIL = 'UPDATE_GET_DETAILS_FAIL';
export const UPDATE_LOCATION_DETAILS_RESET = 'UPDATE_GET_DETAILS_RESET';

export const UPDATE_LOCATION_DETAIL_START = 'UPDATE_GET_DETAIL_START';
export const UPDATE_LOCATION_DETAIL_SUCCESS = 'UPDATE_GET_DETAIL_SUCCESS';
export const UPDATE_LOCATION_DETAIL_FAIL = 'UPDATE_GET_DETAIL_FAIL';
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
export const GET_AIPSCHEDULE_START = 'GET_AIPSCHEDULE_START';
export const GET_AIPSCHEDULE_SUCCESS = 'GET_AIPSCHEDULE_SUCCESS';
export const GET_AIPSCHEDULE_FAIL = 'GET_AIPSCHEDULE_FAIL';

export const CHANGE_DATE_SUCCESS = 'CHANGE_DATE_SUCCESS';
export const CLEAR_LOCATION_ID_SUCCESS = 'CLEAR_LOCATION_ID_SUCCESS';
export const GET_SCHEDULES_START = 'GET_SCHEDULES_START';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_FAIL = 'GET_SCHEDULES_FAIL';

export const EMAIL_START = 'EMAIL_START';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAIL = 'EMAIL_FAIL';

export const DOWNLOAD_START = 'DOWNLOAD_START';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL';

export const DOWNLOAD_STORE_START = 'DOWNLOAD_STORE_START';
export const DOWNLOAD_STORE_SUCCESS = 'DOWNLOAD_STORE_SUCCESS';
export const DOWNLOAD_STORE_FAIL = 'DOWNLOAD_STORE_FAIL';

export const GET_USER_SITES_START = 'GET_USER_SITES_START';
export const GET_USER_SITES_SUCCESS = 'GET_USER_SITE_SUCCESS';
export const GET_USER_SITES_FAIL = 'GET_USER_SITES_FAIL';

export const UPDATE_USER_SITE_START = 'USER_UPDATE_SITE_START';
export const UPDATE_USER_SITE_SUCCESS = 'USER_UPDATE_SITE_SUCCESS';
export const UPDATE_USER_SITE_FAIL = 'USER_UPDATE_SITE_FAIL';

export const SCHEDULES_DOWNLOAD_START = 'SCHEDULES_DOWNLOAD_START';
export const SCHEDULES_DOWNLOAD_SUCCESS = 'SCHEDULES_DOWNLOAD_SUCCESS';
export const SCHEDULES_DOWNLOAD_FAIL = 'SCHEDULES_DOWNLOAD_FAIL';

export const CODES_DOWNLOAD_START = 'CODES_DOWNLOAD_START';
export const CODES_DOWNLOAD_SUCCESS = 'CODES_DOWNLOAD_SUCCESS';
export const CODES_DOWNLOAD_FAIL = 'CODES_DOWNLOAD_FAIL';

export const GET_SCHEDULE_TYPES_START = 'GET_SCHEDULE_TYPES_START';
export const GET_SCHEDULE_TYPES_SUCCESS = 'GET_SCHEDULE_TYPES_SUCCESS';
export const GET_SCHEDULE_TYPES_FAIL = 'GET_SCHEDULE_TYPES_FAIL';

export const GET_ORDER_CYCLE_START = 'GET_ORDER_CYCLE_START';
export const GET_ORDER_CYCLE_SUCCESS = 'GET_ORDER_CYCLE_SUCCESS';
export const GET_ORDER_CYCLE_FAIL = 'GET_ORDER_CYCLE_FAIL';
export const GET_ORDER_CYCLES_START = 'GET_ORDER_CYCLES_START';
export const GET_ORDER_CYCLES_SUCCESS = 'GET_ORDER_CYCLES_SUCCESS';
export const GET_ORDER_CYCLES_FAIL = 'GET_ORDER_CYCLES_FAIL';
export const GET_ORDER_CYCLE_CODES_START = 'GET_ORDER_CYCLE_CODES_START';
export const GET_ORDER_CYCLE_CODES_SUCCESS = 'GET_ORDER_CYCLE_CODES_SUCCESS';
export const GET_ORDER_CYCLE_CODES_FAIL = 'GET_ORDER_CYCLE_CODES_FAIL';

export const GET_GENERATED_CODE_START = 'GET_GENERATED_CODE_START';
export const GET_GENERATED_CODE_SUCCESS = 'GET_GENERATED_CODE_SUCCESS';
export const GET_GENERATED_CODE_FAIL = 'GET_GENERATED_CODE_FAIL';
export const GET_GENERATED_CODES_START = 'GET_GENERATED_CODES_START';
export const GET_GENERATED_CODES_SUCCESS = 'GET_GENERATED_CODES_SUCCESS';
export const GET_GENERATED_CODES_FAIL = 'GET_GENERATED_CODES_FAIL';

export const GET_SCHEDULE_LIST_START = 'GET_SCHEDULE_LIST_START';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const GET_SCHEDULE_LIST_FAIL = 'GET_SCHEDULE_LIST_FAIL';

export const SAVE_NEW_LOCATION_START = 'SAVE_NEW_LOCATION_START';
export const SAVE_NEW_LOCATION_SUCCESS = 'SAVE_NEW_LOCATION_SUCCESS';
export const SAVE_NEW_LOCATION_FAIL = 'SAVE_NEW_LOCATION_FAIL';

export const GET_LOCATION_TYPES_START = 'GET_LOCATION_TYPES_START';
export const GET_LOCATION_TYPES_FAIL = 'GET_LOCATION_TYPES_FAIL';
export const GET_LOCATION_TYPES_SUCCESS = 'GET_LOCATION_TYPES_SUCCESS';

export const GET_PROFILE_CODES_START = 'GET_PROFILE_CODES_START';
export const GET_PROFILE_CODES_FAIL = 'GET_PROFILE_CODES_FAIL';
export const GET_PROFILE_CODES_SUCCESS = 'GET_PROFILE_CODES_SUCCESS';

export const SEND_REQUESTS_START = 'SEND_REQUESTS_START';
export const SEND_REQUESTS_SUCCESS = 'SEND_REQUESTS_SUCCESS';
export const SEND_REQUESTS_FAIL = 'SEND_REQUESTS_FAIL';

export const GET_LOCATION_DCS_START = 'GET_LOCATION_DCS_START';
export const GET_LOCATION_DCS_FAIL = 'GET_LOCATION_DCS_FAIL';
export const GET_LOCATION_DCS_SUCCESS = 'GET_LOCATION_DCS_SUCCESS';

export const SEND_CODE_REQUEST_START = 'SEND_CODE_REQUEST_START';
export const SEND_CODE_REQUEST_FAIL = 'SEND_CODE_REQUEST_FAIL';
export const SEND_CODE_REQUEST_SUCCESS = 'SEND_CODE_REQUEST_SUCCESS';

export const TRANSPORT_DOWNLOAD_START = 'TRANSPORT_DOWNLOAD_START';
export const TRANSPORT_DOWNLOAD_SUCCESS = 'TRANSPORT_DOWNLOAD_SUCCESS';
export const TRANSPORT_DOWNLOAD_FAIL = 'TRANSPORT_DOWNLOAD_FAIL';

export const GET_MISSING_CODES_START = 'GET_MISSING_CODES_START';
export const GET_MISSING_CODES_SUCCESS = 'GET_MISSING_CODES_SUCCESS';
export const GET_MISSING_CODES_FAIL = 'GET_MISSING_CODES_FAIL';

export const SEND_ALL_CODE_REQUESTS_START = 'SEND_ALL_CODE_REQUESTS_START';
export const SEND_ALL_CODE_REQUESTS_SUCCESS = 'SEND_ALL_CODE_REQUESTS_SUCCESS';
export const SEND_ALL_CODE_REQUESTS_FAIL = 'SEND_ALL_CODE_REQUESTS_FAIL';

export const SAVE_SCHEDULE_START = 'SAVE_SCHEDULE_START';
export const SAVE_SCHEDULE_SUCCESS = 'SAVE_SCHEDULE_SUCCESS';
export const RESET_SCHEDULE_SUCCESS = 'RESET_SCHEDULE_SUCCESS';
export const SAVE_SCHEDULE_FAIL = 'SAVE_SCHEDULE_FAIL';

export const REGENERATE_SCHEDULE_START = 'REGENERATE_SCHEDULE_START';
export const REGENERATE_SCHEDULE_SUCCESS = 'REGENERATE_SCHEDULE_SUCCESS';
export const RESET_REGENERATE_SCHEDULE_SUCCESS = 'RESET_REGENERATE_SCHEDULE_SUCCESS';
export const REGENERATE_SCHEDULE_FAIL = 'REGENERATE_SCHEDULE_FAIL';

export const GET_LOCATION_FIELD_OPTIONS_START = 'GET_LOCATION_FIELD_OPTIONS_START';
export const GET_LOCATION_FIELD_OPTIONS_SUCCESS = 'GET_LOCATION_FIELD_OPTIONS_SUCCESS';
export const GET_LOCATION_FIELD_OPTIONS_FAIL = 'GET_LOCATION_FIELD_OPTIONS_FAIL';

export const ADD_NEW_FIELD_START = 'ADD_NEW_FIELD_START';
export const ADD_NEW_FIELD_SUCCESS = 'ADD_NEW_FIELD_SUCCESS';
export const ADD_NEW_FIELD_FAIL = 'ADD_NEW_FIELD_FAIL';

export const CYCLES_DOWNLOAD_START = 'CYCLES_DOWNLOAD_START';
export const CYCLES_DOWNLOAD_SUCCESS = 'CYCLES_DOWNLOAD_SUCCESS';
export const CYCLES_DOWNLOAD_FAIL = 'CYCLES_DOWNLOAD_FAIL';

export const SUBMIT_CHANGE_REQUEST_START = 'SUBMIT_CHANGE_REQUEST_START';
export const SUBMIT_CHANGE_REQUEST_SUCCESS = 'SUBMIT_CHANGE_REQUEST_SUCCESS';
export const SUBMIT_CHANGE_REQUEST_FAIL = 'SUBMIT_CHANGE_REQUEST_FAIL';

export const GET_DC2STORE_DRIVE_TIMES_START = 'GET_DC2STORE_DRIVE_TIMES_START';
export const GET_DC2STORE_DRIVE_TIMES_SUCCESS = 'GET_DC2STORE_DRIVE_TIMES_SUCCESS';
export const GET_DC2STORE_DRIVE_TIMES_FAIL = 'GET_DC2STORE_DRIVE_TIMES_FAIL';

export const GET_AUDIT_LOG_PAGE_START = 'GET_AUDIT_LOG_PAGE_START';
export const GET_AUDIT_LOG_PAGE_SUCCESS = 'GET_AUDIT_LOG_PAGE_SUCCESS';
export const GET_AUDIT_LOG_PAGE_FAIL = 'GET_AUDIT_LOG_PAGE_FAIL';
export const GET_AUDIT_LOG_COUNT_START = 'GET_AUDIT_LOG_COUNT_START';
export const GET_AUDIT_LOG_COUNT_SUCCESS = 'GET_AUDIT_LOG_COUNT_SUCCESS';
export const GET_AUDIT_LOG_COUNT_FAIL = 'GET_AUDIT_LOG_COUNT_FAIL';

export const GET_LOCATION_CHANGE_REQUEST_COUNT_START = 'GET_LOCATION_CHANGE_REQUEST_COUNT_START';
export const GET_LOCATION_CHANGE_REQUEST_COUNT_SUCCESS = 'GET_LOCATION_CHANGE_REQUEST_COUNT_SUCCESS';
export const GET_LOCATION_CHANGE_REQUEST_COUNT_FAIL = 'GET_LOCATION_CHANGE_REQUEST_COUNT_FAIL';
export const GET_LOCATION_CHANGE_REQUESTS_START = 'GET_LOCATION_CHANGE_REQUESTS_START';
export const GET_LOCATION_CHANGE_REQUESTS_SUCCESS = 'GET_LOCATION_CHANGE_REQUESTS_SUCCESS';
export const GET_LOCATION_CHANGE_REQUESTS_FAIL = 'GET_LOCATION_CHANGE_REQUESTS_FAIL';

export const SEND_REQUEST_REJECTION_START = 'SEND_REQUEST_REJECTION_START';
export const SEND_REQUEST_REJECTION_SUCCESS = 'SEND_REQUEST_REJECTION_SUCCESS';
export const SEND_REQUEST_REJECTION_FAIL = 'SEND_REQUEST_REJECTION_FAIL';
export const SEND_REQUEST_ACCEPTANCE_START = 'SEND_REQUEST_ACCEPTANCE_START';
export const SEND_REQUEST_ACCEPTANCE_SUCCESS = 'SEND_REQUEST_ACCEPTANCE_SUCCESS';
export const SEND_REQUEST_ACCEPTANCE_FAIL = 'SEND_REQUEST_ACCEPTANCE_FAIL';

export const GET_LOCATION_SUMMARY_START = 'GET_LOCATION_SUMMARY_START';
export const GET_LOCATION_SUMMARY_SUCCESS = 'GET_LOCATION_SUMMARY_SUCCESS';
export const GET_LOCATION_SUMMARY_FAIL = 'GET_LOCATION_SUMMARY_FAIL';

export const GET_LOCATION_PARAMETERS_START = 'GET_LOCATION_PARAMETERS_START';
export const GET_LOCATION_PARAMETERS_SUCCESS = 'GET_LOCATION_PARAMETERS_SUCCESS';
export const GET_LOCATION_PARAMETERS_FAIL = 'GET_LOCATION_PARAMETERS_FAIL';

export const GET_MISSING_DRIVE_TIMES_START = 'GET_MISSING_DRIVE_TIMES_START';
export const GET_MISSING_DRIVE_TIMES_SUCCESS = 'GET_MISSING_DRIVE_TIMES_SUCCESS';
export const GET_MISSING_DRIVE_TIMES_FAIL = 'GET_MISSING_DRIVE_TIMES_FAIL';

export const GET_MISSING_TRAILERS = 'GET_MISSING_TRAILERS';

export const SET_TRAILER = 'SET_TRAILER';
export const RESET_TRAILER = 'RESET_TRAILER';

export const GET_TRAILERS_START = 'GET_TRAILERS_START';
export const GET_TRAILERS_SUCCESS = 'GET_TRAILERS_SUCCESS';
export const GET_TRAILERS_FAIL = 'GET_TRAILERS_FAIL';

export const GET_LOCATIONTRAILERS_START = 'GET_LOCATIONTRAILERS_START';
export const GET_LOCATIONTRAILERS_SUCCESS = 'GET_LOCATIONTRAILERS_SUCCESS';
export const GET_LOCATIONTRAILERS_FAIL = 'GET_LOCATIONTRAILERS_FAIL';

export const SET_LOCATIONTRAILERS_START = 'SET_LOCATIONTRAILERS_START';
export const SET_LOCATIONTRAILERS_SUCCESS = 'SET_LOCATIONTRAILERS_SUCCESS';
export const SET_LOCATIONTRAILERS_FAIL = 'SET_LOCATIONTRAILERS_FAIL';
export const SET_LOCATIONTRAILERS_RESET = 'SET_LOCATIONTRAILERS_RESET';

export const SET_TRAILERLOCATIONS_START = 'SET_TRAILERLOCATIONS_START';
export const SET_TRAILERLOCATIONS_SUCCESS = 'SET_TRAILERLOCATIONS_SUCCESS';
export const SET_TRAILERLOCATIONS_FAIL = 'SET_TRAILERLOCATIONS_FAIL';
export const SET_TRAILERLOCATIONS_RESET = 'SET_TRAILERLOCATIONS_RESET';

export const GET_TRAILERLOCATIONS_START = 'GET_TRAILERLOCATIONS_START';
export const GET_TRAILERLOCATIONS_SUCCESS = 'GET_TRAILERLOCATIONS_SUCCESS';
export const GET_TRAILERLOCATIONS_FAIL = 'GET_TRAILERLOCATIONS_FAIL';
export const GET_TRAILERLOCATIONS_RESET = 'GET_TRAILERLOCATIONS_RESET';

export const GET_WEEK_NUMBER_START = 'GET_WEEK_NUMBER_START';
export const GET_WEEK_NUMBER_SUCCESS = 'GET_WEEK_NUMBER_SUCCESS';
export const GET_WEEK_NUMBER_FAIL = 'GET_WEEK_NUMBER_FAIL';

export const GET_WEEK_NUMBERS_START = 'GET_WEEK_NUMBERS_START';
export const GET_WEEK_NUMBERS_SUCCESS = 'GET_WEEK_NUMBERS_SUCCESS';
export const GET_WEEK_NUMBERS_FAIL = 'GET_WEEK_NUMBERS_FAIL';

export const GET_YEAR_START_EXCEPTIONS_START = 'GET_YEAR_START_EXCEPTIONS_START';
export const GET_YEAR_START_EXCEPTIONS_SUCCESS = 'GET_YEAR_START_EXCEPTIONS_SUCCESS';
export const GET_YEAR_START_EXCEPTIONS_FAIL = 'GET_YEAR_START_EXCEPTIONS_FAIL';

export const UPDATE_ASSIGNED_DATES_START = 'UPDATE_ASSIGNED_DATES_START';
export const UPDATE_ASSIGNED_DATES_SUCCESS = 'UPDATE_ASSIGNED_DATES_SUCCESS';
export const UPDATE_ASSIGNED_DATES_FAIL = 'UPDATE_ASSIGNED_DATES_FAIL';
export const UPDATE_ASSIGNED_DATES_RESET = 'UPDATE_ASSIGNED_DATES_RESET';

export const SET_SELECTED_TRAILERS = 'SET_SELECTED_TRAILERS';
export const RESET_SELECTED_TRAILERS = 'RESET_SELECTED_TRAILERS';

export const SET_SELECTED_DATERANGE = 'SET_SELECTED_DATERANGE';
export const RESET_SELECTED_DATERANGE = 'RESET_SELECTED_DATERANGE';

export const DELETE_LOCATIONTRAILERS_START = 'DELETE_LOCATIONTRAILERS_START';
export const DELETE_LOCATIONTRAILERS_SUCCESS = 'DELETE_LOCATIONTRAILERS_SUCCESS';
export const DELETE_LOCATIONTRAILERS_FAIL = 'DELETE_LOCATIONTRAILERS_FAIL';
export const DELETE_LOCATIONTRAILERS_RESET = 'DELETE_LOCATIONTRAILERS_RESET';

export const DELETE_TRAILERLOCATIONS_START = 'DELETE_TRAILERLOCATIONS_START';
export const DELETE_TRAILERLOCATIONS_SUCCESS = 'DELETE_TRAILERLOCATIONS_SUCCESS';
export const DELETE_TRAILERLOCATIONS_FAIL = 'DELETE_TRAILERLOCATIONS_FAIL';
export const DELETE_TRAILERLOCATIONS_RESET = 'DELETE_TRAILERLOCATIONS_RESET';

export const GET_LOCATIONS_PAGE_START = 'GET_LOCATIONS_PAGE_START';
export const GET_LOCATIONS_PAGE_SUCCESS = 'GET_LOCATIONS_PAGE_SUCCESS';
export const GET_LOCATIONS_PAGE_FAIL = 'GET_LOCATIONS_PAGE_FAIL';

export const DOWNLOAD_MISSING_START = "DOWNLOAD_MISSING_START";
export const DOWNLOAD_MISSING_FAIL = "DOWNLOAD_MISSING_FAIL";
export const DOWNLOAD_MISSING_SUCCESS = "DOWNLOAD_MISSING_SUCCESS";
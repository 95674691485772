import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    locations: [],
    locationsPage: {},
    selectedLocations: [],
    selectedLocationsFromDate: null,
    selectedLocationsToDate: null,
    loadingLocations: false,
    loadingLocationsPage: false,
    loadingDCs: false,
    loadingDC2StoreDriveTimes: false,
    locationSelectLists: {},
    id: null,
    error: false,
    fileData: null,
    locationHeaders: [],
    dc2StoreDriveTimes: null,
    loadingMissingTrailers: false,
    missingTrailers: null,
    missingTrailersError: null,
    updating: null,
    updatingSuccess: null
};

const setLocation = (state, action) => updateObject(state, { id: action.id });
const resetLocation = (state, action) => updateObject(state, { id: action.id, locations: action.locations });

const setSelectedLocations = (state, action) =>
    updateObject(state, {
        selectedLocations: action.selectedLocations,
        selectedLocationsFromDate: action.selectedLocationsFromDate,
        selectedLocationsToDate: action.selectedLocationsToDate
    });
const resetSelectedLocations = (state, action) => updateObject(state, { selectedLocations: action.selectedLocations });

const getLocationsStart = (state, action) => {
    return updateObject(state, {
        loadingLocations: action.loadingLocations === undefined ? state.loadingLocations : action.loadingLocations,
        id: action.id === undefined ? state.id : action.id
    });
};

const getLocationsSuccess = (state, action) => {
    return updateObject(state, {
        locations: action.locations === undefined ? state.locations : action.locations,
        locationParameters: action.locationParameters === undefined ? state.locationParameters : action.locationParameters,
        locationSelectLists: action.locationSelectLists === undefined ? state.locationSelectLists : action.locationSelectLists,
        loadingLocations: action.loadingLocations === undefined ? state.loadingLocations : action.loadingLocations
    });
};

const getLocationsFail = (state, action) => {
    return updateObject(state, {
        loadingLocations: action.loadingLocations === undefined ? state.loadingLocations : action.loadingLocations,
        error: action.error === undefined ? state.error : action.error
    });
};

const getDCLocationsStart = state => updateObject(state, { loadingDCs: true });

const getDCLocationsSuccess = (state, action) => {
    // if (action.locationSelectLists('DepotListView')) {
    const locationSelectLists = updateObject(state.locationSelectLists, action.locationSelectLists);
    // }

    return updateObject(state, {
        locationSelectLists: action.locationSelectLists === undefined ? state.locationSelectLists : locationSelectLists,
        // locationDCTypes: action.locationDCTypes,
        loadingDCs: false
    });
};
const getDCLocationsFail = state => updateObject(state, { loadingDCs: false, error: true });

const getLocationHeadersStart = (state, action) => {
    return updateObject(state, {
        loadingLocations: action.loadingLocations === undefined ? state.loadingLocations : action.loadingLocations
    });
};

const getLocationHeadersSuccess = (state, action) => {
    return updateObject(state, {
        locationHeaders: action.locationHeaders === undefined ? state.locationHeaders : action.locationHeaders,
        loadingLocations: action.loadingLocations === undefined ? state.loadingLocations : action.loadingLocations
    });
};

const getLocationHeadersFail = (state, action) => {
    return updateObject(state, {
        error: action.error === undefined ? state.error : action.error,
        loadingLocations: action.loadingLocations === undefined ? state.loadingLocations : action.loadingLocations
    });
};

const updateLocationDetailsSuccess = (state, action) => {
    return updateObject(state, {
        id: action.id,
        updating: false,
        updatingSuccess: true
    });
};

const updateLocationDetailsFail = (state, action) => {
    return updateObject(state, {
        id: action.id,
        updating: false,
        updatingSuccess: false
    });
};

const updateLocationDetailsStart = (state, action) => {
    return updateObject(state, {
        id: action.id,
        updating: true,
        updatingSuccess: null
    });
};

const updateLocationDetailStart = state =>
    updateObject(state, {
        updating: true,
        updatingSuccess: null
    });
const updateLocationDetailSuccess = state =>
    updateObject(state, {
        updating: false,
        updatingSuccess: true
    });
const updateLocationDetailFail = state =>
    updateObject(state, {
        updating: false,
        updatingSuccess: false
    });

const updateLocationDetailsReset = state =>
    updateObject(state, {
        updating: null,
        updatingSuccess: null
    });

const downloadStart = state => {
    return updateObject(state, { fileLoading: true });
};

const downloadSuccess = (state, action) => {
    return updateObject(state, {
        fileData: action.fileData,
        fileLoading: false
    });
};

const downloadFail = state => {
    return updateObject(state, {
        fileLoading: false,
        error: true
    });
};

const downloadStoreStart = state => {
    return updateObject(state, { fileLoading: true });
};

const downloadStoreSuccess = (state, action) => {
    return updateObject(state, {
        fileData: action.fileData,
        fileLoading: false
    });
};

const downloadStoreFail = state => {
    return updateObject(state, {
        fileLoading: false,
        error: true
    });
};

const getLocationTypesStart = state => updateObject(state, { loadingLocationTypes: true });
const getLocationTypesSuccess = (state, action) => {
    // if (action.locationSelectLists('DepotListView')) {
    const locationSelectLists = updateObject(state.locationSelectLists, action.locationSelectLists);
    // }

    // return updateObject(state, {
    //     locationSelectLists: action.locationSelectLists === undefined ? state.locationSelectLists : locationSelectLists,
    //     // locationDCTypes: action.locationDCTypes,
    //     loadingDCs: false
    // });

    return updateObject(state, {
        locationSelectLists: action.locationSelectLists === undefined ? state.locationSelectLists : locationSelectLists,
        loadingLocationTypes: false
    });
};

const getLocationTypesFail = state => updateObject(state, { loadingLocationTypes: false, error: true });

const saveNewLocationStart = state => updateObject(state, { submitting: true });
const saveNewLocationSuccess = (state, action) => updateObject(state, { submitting: false, saveNewSuccess: action.saveNewSuccess });
const saveNewLocationFail = state => updateObject(state, { submitting: false, saveNewError: true });

const getLocationDCsStart = state => updateObject(state, { loadingDCs: true });
const getLocationDCsSuccess = (state, action) => updateObject(state, { locationDCs: action.locationDCs, loadingDCs: false });
const getLocationDCsFail = state => updateObject(state, { loadingDCs: false, error: true });

const getLocationFieldOptionsStart = state => updateObject(state, { fieldOptionsLoading: true });
const getLocationFieldOptionsSuccess = (state, action) => updateObject(state, { fieldOptions: action.fieldOptions, fieldOptionsLoading: false });
const getLocationFieldOptionsFail = state => updateObject(state, { fieldOptionsLoading: false, error: true });

const addNewFieldStart = state => updateObject(state, { addingNewField: true });
const addNewFieldSuccess = (state, action) => updateObject(state, { addingNewField: false, addNewFieldSuccess: action.addNewFieldSuccess });
const addNewFieldFail = state => updateObject(state, { addingNewField: false, error: true });

const submitChangeRequestStart = state => updateObject(state, { submitting: true });
const submitChangeRequestSuccess = (state, action) => updateObject(state, { submitting: false, requestSuccess: action.requestSuccess });
const submitChangeRequestFail = state => updateObject(state, { submitting: false, error: true });

const getDC2StoreDriveTimesStart = state => updateObject(state, { loadingDC2StoreDriveTimes: true });
const getDC2StoreDriveTimesSuccess = (state, action) => {
    return updateObject(state, {
        dc2StoreDriveTimes: action.dc2StoreDriveTimes,
        loadingDC2StoreDriveTimes: false
    });
};
const getDC2StoreDriveTimesFail = state => updateObject(state, { loadingDC2StoreDriveTimes: false, error: true });

const getOutstandingChangeRequestCountStart = state => updateObject(state, { gettingCount: true });
const getOutstandingChangeRequestCountSuccess = (state, action) => updateObject(state, { gettingCount: false, requestCount: action.requestCount });
const getOutstandingChangeRequestCountFail = state => updateObject(state, { gettingCount: false, requestCountError: true });

const getOutstandingChangeRequestsStart = state => updateObject(state, { gettingRequests: true });
const getOutstandingChangeRequestsSuccess = (state, action) => updateObject(state, { gettingRequests: false, requests: action.requests });
const getOutstandingChangeRequestsFail = state => updateObject(state, { gettingRequests: false, requestsError: true });

const requestRejectionStart = state => updateObject(state, { sendingRejection: true });
const requestRejectionSuccess = (state, action) => updateObject(state, { sendingRejection: false, rejectionSent: action.rejectionSent });
const requestRejectionFail = state => updateObject(state, { sendingRejection: false, sendRejectionError: true });

const requestAcceptanceStart = state => updateObject(state, { sendingAcceptance: true });
const requestAcceptanceSuccess = (state, action) => updateObject(state, { sendingAcceptance: false, acceptanceSent: action.acceptanceSent });
const requestAcceptanceFail = state => updateObject(state, { sendingAcceptance: false, sendAcceptanceError: true });

const getLocationSummaryStart = state => updateObject(state, { gettingSummary: true });
const getLocationSummarySuccess = (state, action) => updateObject(state, { gettingSummary: false, summaryData: action.summaryData });
const getLocationSummaryFail = state => updateObject(state, { gettingSummary: false, summaryError: true });
const getLocationParametersStart = state => updateObject(state, { loadingParameters: true });
const getLocationParametersSuccess = (state, action) => updateObject(state, { loadingParameters: false, parameters: action.parameters });
const getLocationParametersFail = state => updateObject(state, { loadingParameters: false, parametersError: true });

const getMissingDriveTimesStart = state => updateObject(state, { gettingMissingDriveTimes: true });
const getMissingDriveTimesSuccess = (state, action) => updateObject(state, { gettingMissingDriveTimes: false, missingDriveTimes: action.missingDriveTimes });
const getMissingDriveTimesFail = state => updateObject(state, { gettingMissingDriveTimes: false, missingDriveTimesError: true });

const getMissingTrailers = (state, action) =>
    updateObject(state, {
        loadingMissingTrailers: action.loadingMissingTrailers === undefined ? state.loadingMissingTrailers : action.loadingMissingTrailers,
        missingTrailers: action.missingTrailers === undefined ? state.missingTrailers : action.missingTrailers,
        missingTrailersError: action.missingTrailersError === undefined ? state.missingTrailersError : action.missingTrailersError
    });

const getLocationsPageStart = state => updateObject(state, { loadingLocationsPage: true });
const getLocationsPageSuccess = (state, action) => {
    return updateObject(state, { loadingLocationsPage: false, locationsPage: action.locationsPage })
};
const getLocationsPageFail = state => updateObject(state, { loadingLocationsPage: false, locationsPageError: true });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_LOCATION:
            return resetLocation(state, action);
        case actionTypes.SET_LOCATION:
            return setLocation(state, action);
        case actionTypes.RESET_SELECTED_LOCATIONS:
            return resetSelectedLocations(state, action);
        case actionTypes.SET_SELECTED_LOCATIONS:
            return setSelectedLocations(state, action);
        case actionTypes.GET_LOCATIONS_START:
            return getLocationsStart(state, action);
        case actionTypes.GET_LOCATIONS_SUCCESS:
            return getLocationsSuccess(state, action);
        case actionTypes.GET_LOCATIONS_FAIL:
            return getLocationsFail(state, action);
        case actionTypes.GET_LOCATIONHEADERS_START:
            return getLocationHeadersStart(state, action);
        case actionTypes.GET_LOCATIONHEADERS_SUCCESS:
            return getLocationHeadersSuccess(state, action);
        case actionTypes.GET_LOCATIONHEADERS_FAIL:
            return getLocationHeadersFail(state, action);
        case actionTypes.GET_DCLOCATIONS_SUCCESS:
            return getDCLocationsSuccess(state, action);
        case actionTypes.GET_DCLOCATIONS_START:
            return getDCLocationsStart(state);
        case actionTypes.GET_DCLOCATIONS_FAIL:
            return getDCLocationsFail(state);
        case actionTypes.UPDATE_LOCATION_DETAILS_START:
            return updateLocationDetailsStart(state, action);
        case actionTypes.UPDATE_LOCATION_DETAILS_SUCCESS:
            return updateLocationDetailsSuccess(state, action);
        case actionTypes.UPDATE_LOCATION_DETAILS_FAIL:
            return updateLocationDetailsFail(state, action);
        case actionTypes.UPDATE_LOCATION_DETAILS_RESET:
            return updateLocationDetailsReset(state);
        case actionTypes.DOWNLOAD_START:
            return downloadStart(state);
        case actionTypes.DOWNLOAD_SUCCESS:
            return downloadSuccess(state, action);
        case actionTypes.DOWNLOAD_FAIL:
            return downloadFail(state);
        case actionTypes.DOWNLOAD_STORE_START:
            return downloadStoreStart(state);
        case actionTypes.DOWNLOAD_STORE_SUCCESS:
            return downloadStoreSuccess(state, action);
        case actionTypes.DOWNLOAD_STORE_FAIL:
            return downloadStoreFail(state);
        case actionTypes.GET_LOCATION_TYPES_START:
            return getLocationTypesStart(state);
        case actionTypes.GET_LOCATION_TYPES_SUCCESS:
            return getLocationTypesSuccess(state, action);
        case actionTypes.GET_LOCATION_TYPES_FAIL:
            return getLocationTypesFail(state);
        case actionTypes.SAVE_NEW_LOCATION_START:
            return saveNewLocationStart(state, action);
        case actionTypes.SAVE_NEW_LOCATION_SUCCESS:
            return saveNewLocationSuccess(state, action);
        case actionTypes.SAVE_NEW_LOCATION_FAIL:
            return saveNewLocationFail(state, action);
        case actionTypes.GET_LOCATION_DCS_START:
            return getLocationDCsStart(state);
        case actionTypes.GET_LOCATION_DCS_SUCCESS:
            return getLocationDCsSuccess(state, action);
        case actionTypes.GET_LOCATION_DCS_FAIL:
            return getLocationDCsFail(state);
        case actionTypes.UPDATE_LOCATION_DETAIL_START:
            return updateLocationDetailStart(state);
        case actionTypes.UPDATE_LOCATION_DETAIL_SUCCESS:
            return updateLocationDetailSuccess(state, action);
        case actionTypes.UPDATE_LOCATION_DETAIL_FAIL:
            return updateLocationDetailFail(state);
        case actionTypes.GET_LOCATION_FIELD_OPTIONS_START:
            return getLocationFieldOptionsStart(state);
        case actionTypes.GET_LOCATION_FIELD_OPTIONS_SUCCESS:
            return getLocationFieldOptionsSuccess(state, action);
        case actionTypes.GET_LOCATION_FIELD_OPTIONS_FAIL:
            return getLocationFieldOptionsFail(state);
        case actionTypes.ADD_NEW_FIELD_START:
            return addNewFieldStart(state);
        case actionTypes.ADD_NEW_FIELD_SUCCESS:
            return addNewFieldSuccess(state, action);
        case actionTypes.ADD_NEW_FIELD_FAIL:
            return addNewFieldFail(state);
        case actionTypes.SUBMIT_CHANGE_REQUEST_START:
            return submitChangeRequestStart(state);
        case actionTypes.SUBMIT_CHANGE_REQUEST_SUCCESS:
            return submitChangeRequestSuccess(state, action);
        case actionTypes.SUBMIT_CHANGE_REQUEST_FAIL:
            return submitChangeRequestFail(state);
        case actionTypes.GET_DC2STORE_DRIVE_TIMES_START:
            return getDC2StoreDriveTimesStart(state);
        case actionTypes.GET_DC2STORE_DRIVE_TIMES_SUCCESS:
            return getDC2StoreDriveTimesSuccess(state, action);
        case actionTypes.GET_DC2STORE_DRIVE_TIMES_FAIL:
            return getDC2StoreDriveTimesFail(state);
        case actionTypes.GET_LOCATION_CHANGE_REQUEST_COUNT_START:
            return getOutstandingChangeRequestCountStart(state);
        case actionTypes.GET_LOCATION_CHANGE_REQUEST_COUNT_SUCCESS:
            return getOutstandingChangeRequestCountSuccess(state, action);
        case actionTypes.GET_LOCATION_CHANGE_REQUEST_COUNT_FAIL:
            return getOutstandingChangeRequestCountFail(state);
        case actionTypes.GET_LOCATION_CHANGE_REQUESTS_START:
            return getOutstandingChangeRequestsStart(state);
        case actionTypes.GET_LOCATION_CHANGE_REQUESTS_SUCCESS:
            return getOutstandingChangeRequestsSuccess(state, action);
        case actionTypes.GET_LOCATION_CHANGE_REQUESTS_FAIL:
            return getOutstandingChangeRequestsFail(state);
        case actionTypes.SEND_REQUEST_REJECTION_START:
            return requestRejectionStart(state);
        case actionTypes.SEND_REQUEST_REJECTION_SUCCESS:
            return requestRejectionSuccess(state, action);
        case actionTypes.SEND_REQUEST_REJECTION_FAIL:
            return requestRejectionFail(state);
        case actionTypes.SEND_REQUEST_ACCEPTANCE_START:
            return requestAcceptanceStart(state);
        case actionTypes.SEND_REQUEST_ACCEPTANCE_SUCCESS:
            return requestAcceptanceSuccess(state, action);
        case actionTypes.SEND_REQUEST_ACCEPTANCE_FAIL:
            return requestAcceptanceFail(state);
        case actionTypes.GET_LOCATION_SUMMARY_START:
            return getLocationSummaryStart(state);
        case actionTypes.GET_LOCATION_SUMMARY_SUCCESS:
            return getLocationSummarySuccess(state, action);
        case actionTypes.GET_LOCATION_SUMMARY_FAIL:
            return getLocationSummaryFail(state);
        case actionTypes.GET_LOCATION_PARAMETERS_START:
            return getLocationParametersStart(state);
        case actionTypes.GET_LOCATION_PARAMETERS_SUCCESS:
            return getLocationParametersSuccess(state, action);
        case actionTypes.GET_LOCATION_PARAMETERS_FAIL:
            return getLocationParametersFail(state);
        case actionTypes.GET_MISSING_DRIVE_TIMES_START:
            return getMissingDriveTimesStart(state);
        case actionTypes.GET_MISSING_DRIVE_TIMES_SUCCESS:
            return getMissingDriveTimesSuccess(state, action);
        case actionTypes.GET_MISSING_DRIVE_TIMES_FAIL:
            return getMissingDriveTimesFail(state);
        case actionTypes.GET_MISSING_TRAILERS:
            return getMissingTrailers(state, action);
        case actionTypes.GET_LOCATIONS_PAGE_START:
            return getLocationsPageStart(state);
        case actionTypes.GET_LOCATIONS_PAGE_SUCCESS:
            return getLocationsPageSuccess(state, action);
        case actionTypes.GET_LOCATIONS_PAGE_FAIL:
            return getLocationsPageFail(state);
        default:
            return state;
    }
};

export default reducer;

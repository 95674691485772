import moment from 'moment';

moment.locale('en');
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const userCanEdit = roles => {
    if (!roles)
        return false;

    return roles.includes('Admin_Central') ||
        roles.includes('Admin_Depot');
};

export const minsToHHMM = minutes => {
    if (minutes === null)
        return '';

    if (minutes.toString().indexOf(':') > -1) {
        if (isValidTime(minutes, true))
            return minutes;
        else
            return '';
    }
    const sign = minutes < 0 ? '-' : '';
    const min = Math.floor(Math.abs(minutes / 60));
    const sec = Math.floor(Math.abs(minutes / 60) * 60) % 60;
    return `${sign}${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
};

export const HHMMTomins = time => {
    const hms = time.split(':');
    if (hms.length !== 2)
        return null;
    return +hms[0] * 60 + +hms[1];
};

export const shortDays = () => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// *** Validation functions ***

// validate a time - looks for hh:mm or [h]h:mm am/pm (if twentyFourHourFormat = false), returns null if not valid
export const isValidTime = (timeInput, twentyFourHourFormat = true, type) => {
    let regExp;
    if (type === 'windowValid') {
        // regExp = /([0-1][0-9]:[0-5][0-9]-[0-1][0-9]:[0-5][0-9])|(No Delivery)/gi;
        regExp = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]-(([0-1][0-9])|(2[0-3])):[0-5][0-9]$|(No Delivery)/gi;
    }
    else if (type === 'dayTimeValid') {
        if (timeInput === '')
            return true;
        const twentyFour = /(Sun|Mon|Tue|Wed|Thu|Fri|Sat)\s(([0-1][0-9])|(2[0-3])):[0-5][0-9]/;
        const twelve = /^(Sun|Mon|Tue|Wed|Thu|Fri|Sat)(0?[1-9]|1[012])(:[0-5]\d)\s?(AM|PM)$/gi;
        regExp = twentyFourHourFormat ? twentyFour : twelve;
    }
    else {
        if (timeInput === '')
            return true;
        const twentyFour = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
        const twelve = /^(0?[1-9]|1[012])(:[0-5]\d)\s?(AM|PM)$/gi;
        regExp = twentyFourHourFormat ? twentyFour : twelve;
    }

    return regExp.test(timeInput);
};

export const getDayName = (index, shortName) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    index = index % 7;
    return shortName ? days[index].substr(0, 3) : days[index];
};

export const getDayIndex = (dayName, shortName) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return shortName ? days.findIndex(m => m.substr(0, 3) === dayName) : days.findIndex(m => m === dayName);
};

export const toTitleCase = str => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};

export const isValidDay = (dayInput, format = '') => {
    const daysShort = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const daysLong = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    let days;

    switch (format.toLowerCase()) {
        case 'short':
            days = daysShort;
            break;
        case 'long':
            days = daysLong;
            break;
        default:
            days = daysShort.concat(daysLong);
    }

    const valid = days.includes(dayInput.toUpperCase());

    return valid;
};

// Check if 'numberInput' is a valid number
// between the option ranges of min and max
// optionally check for an undefine/null value
export const isValidNumber = (numberInput, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY, emptyCheck = false) => {
    if (emptyCheck)
        // is the input value null or undefined?
        if ((numberInput || '') === '')
            return false;

    // convert to a number
    const num = +numberInput;

    // has the number conversion succeeded?
    if (isNaN(num))
        return false;

    // does the number fall within the set min and max values?
    if (num < min || num > max)
        return false;

    return true;
};

export const isValidDate = date => {
    // UK Government standard regex
    /* eslint-disable-next-line max-len */
    const exp = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
    return exp.test(date);
};

export const isValidPostCode = postcode => {
    // UK Government standard regex
    /* eslint-disable-next-line max-len */
    const exp = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
    return exp.test(postcode);
};

export const isValidLatLon = (latlon, type) => {
    const latExp = /^[+-]?(([1-8]?[0-9])(\.[0-9]{1,8})?|90(\.0{1,8})?)$/;
    const lonExp = /^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,8})?)|180(\.0{1,8})?)$/;

    return type === 'latitude' ? latExp.test(latlon) : lonExp.test(latlon);
};

export const isValidPhoneNumber = phoneNumber => {
    /* eslint-disable-next-line no-useless-escape */
    const exp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return exp.test(phoneNumber);
};

// only allow a-z A-Z 0-9 _ {space} ( ) / -
export const isValidText = textInput => {
    const isValid = /^[\w ()-/]*$/.test(textInput);
    return isValid;
};

// as above but returns the invalid character
export const getInvalidText = textInput => {
    const invalidChar = textInput.match(/([^\w ()-/])/);
    return invalidChar ? invalidChar[0] : null;
};

// ****************************

export const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
        const x = a[key];
        const y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
};

export const sortByNestedKey = (prop, arr) => {
    prop = prop.split('.');
    const len = prop.length;

    arr.sort(function (a, b) {
        let i = 0;
        while (i < len) {
            a = a[prop[i]];
            b = b[prop[i]];
            i++;
        }
        if (a < b)
            return -1;
        else if (a > b)
            return 1;
        else
            return 0;
    });
    return arr;
};

export const createUid = () => {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        /* eslint-disable-next-line no-mixed-operators */
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
};

// get the Damerau-Levenshtein distance
export const getLevenshtein = (input, test, limit) => {
    if (limit === -1)
        limit = undefined;

    const slen = input.length;
    const tlen = test.length;

    if (limit && Math.abs(slen - tlen) >= limit)
        return limit;

    const s = input.split('');
    const t = test.split('');
    const numcolumns = tlen + 1;
    const d = [];

    for (let i = 0; i < slen; i++)
        d[i * numcolumns] = i;

    for (let j = 0; j < tlen; j++)
        d[j] = j;

    for (let i = 1; i < slen; i++) {
        const ipos = i * numcolumns;
        let best = limit;

        for (let j = 1; j < tlen; j++) {
            const addcost = s[i] !== t[j] ? 1 : 0;
            const val = Math.min(
                d[ipos - numcolumns + j] + 1,
                d[ipos + j - 1] + 1,
                d[ipos - numcolumns + j - 1] + addcost);
            d[ipos + j] = val;
            if (i > 1 && j > 1 && s[i] === t[j - 1] && s[i - 1] === t[j])
                d[ipos + j] = Math.min(val, d[ipos - numcolumns - numcolumns + j - 2] + addcost);

            if (limit && val < best)
                best = val;
        }
        if (limit && best >= limit)
            return limit;
    }

    return d[d.length - 1];
};

/*  */
export const toggleDates = (identifier, startDate, endDate, weOnly) => {

    let date;
    let currentdate;
    let minMaxDate = moment();

    // check that both the start/end date is a moment and if not, set it to one
    if (startDate)
        startDate = !moment.isDate(startDate) ? moment(startDate) : null;

    if (endDate)
        endDate = !moment.isDate(endDate) ? moment(endDate) : null;

    // the fromDate/startDate can be set to either today, the start of this week, the highdate or nothing
    // the toDate/endDate can be set to either the end of this week, the highdate or nothing
    switch (identifier) {
        case 'toDate':
        case 'endDate':
            date = moment().endOf('week');
            currentdate = endDate ? endDate.format('YYYY-MM-DD') : '';
            minMaxDate = moment('2999-12-28');
            break;
        case 'fromDate':
        case 'startDate':
            date = moment().startOf('week');
            currentdate = startDate ? startDate.format('YYYY-MM-DD') : '';
            if (weOnly) minMaxDate = date;
            break;
        default: date = moment();
            break;
    }

    if (date.format('YYYY-MM-DD') === currentdate) {
        date = date === minMaxDate ? minMaxDate.format('dddd') === 'Sunday' ? weOnly ? null : moment() : null : minMaxDate;
    }
    else if (currentdate === minMaxDate.format('YYYY-MM-DD')) {
        date = null;
    }

    return date;
};

export const removeSpecialCharacters = value => {
    if (!value) return value;
    /* eslint-disable-next-line no-useless-escape */
    const exp = /[^0-9a-zA-Z-/\. ()_]/g;
    return value.toString().replace(exp, '');
};

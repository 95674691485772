import React, { Component, Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { List, ListView, Settings } from '@jsluna/icons';

import { userCanEdit } from '../../shared/utility';

import classes from './Settings.module.scss';

const AuditLog = React.lazy(() => import('./Audit/Audit'));
const Fields = React.lazy(() => import('./Fields/Fields'));

class UserSettings extends Component {
    state = {
        selectedPage: null
    };

    render() {
        const isUserRole = !userCanEdit(this.props.roles);
        const loading = (
            <ProgressIndicator page loading={true}>
                <ProgressSpinner light />
                Loading...
            </ProgressIndicator>
        );

        let selected = null;
        let content = (
            <Fragment>
                <h2 className="ln-u-push-top">Settings Page</h2>
                <p>Select a settings page from the options on the left</p>
            </Fragment>
        );

        // lazy load the required option
        switch (this.state.selectedPage) {
            case 'fields':
                selected = <Fields />;
                break;
            case 'audit':
                selected = <AuditLog />;
                break;
            default:
                break;
        }

        if (selected)
            content = <Suspense fallback={loading}>{selected}</Suspense>;

        const newLocClasses = [classes.menuItem];

        if (isUserRole)
            newLocClasses.push(classes.disabled);

        return (
            <Container className={classes.container}>
                <GridWrapper className="ln-u-push-top">
                    <GridItem size="1/4" className={classes.menuContainer}>
                        <GridWrapper>
                            <GridItem size="1/1" className={classes.settingsIcon}>
                                <Settings />
                                <h6>Settings</h6>
                            </GridItem>
                            <GridItem size="1/1" >
                                <div className={newLocClasses.join(' ')} onClick={() => isUserRole ? null : this.setState({ selectedPage: 'fields' })}>
                                    <List className={classes.icon} />
                                    <h5>Add New Location Field</h5>
                                </div>
                            </GridItem>
                            <GridItem size="1/1" >
                                <div className={classes.menuItem} onClick={() => this.setState({ selectedPage: 'audit' })}>
                                    <ListView className={classes.icon} />
                                    <h5>Audit Log</h5>
                                </div>
                            </GridItem>
                        </GridWrapper>
                    </GridItem>
                    <GridItem size="2/3">
                        {content}
                    </GridItem>
                </GridWrapper>
            </Container>
        );
    }
}

UserSettings.propTypes = {
    roles: PropTypes.array
};

const mapStateToProps = state => {
    return {
        roles: state.auth.roles
    };
};

export default connect(mapStateToProps)(withErrorHandler(UserSettings, axios));

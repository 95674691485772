import React from 'react';
import PropTypes from 'prop-types';

import classes from './Backdrop.module.scss';

const backdrop = props => {
    const overlayClass = () => props.show ? classes.my_overlay : '';
    return (
        <div className={overlayClass() + ' ' + classes.backdrop} onClick={props.click} />
    );
};

backdrop.propTypes = {
    click: PropTypes.func,
    show: PropTypes.bool
};

export default backdrop;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

import * as actions from '../../../store/actions/index';

import { BreadcrumbsItem, BreadcrumbsWrapper } from '@jsluna/breadcrumbs';
import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { Modal, ModalHeading } from '@jsluna/modal';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Tick } from '@jsluna/icons';

import Input from '../../../components/UI/Input/Input';

import { getInvalidText, isValidLatLon, isValidPhoneNumber, isValidPostCode } from '../../../shared/utility';

import { Growl } from 'primereact/growl';

class NewLocation extends Component {

    state = {
        id:0,
        addressLineOne: '',
        addressLineOneInvalidText: null,
        addressLineOneValid: true,
        addressLineTwo: '',
        addressLineTwoInvalidText: null,
        addressLineTwoValid: true,
        addressLineThree: '',
        addressLineThreeInvalidText: null,
        addressLineThreeValid: true,
        brand: null,
        brands: [],
        brandValid: true,
        city: '',
        cityInvalidText: null,
        cityValid: true,
        county: '',
        countyInvalidText: null,
        countyValid: true,
        country: '',
        countryInvalidText: null,
        countryValid: true,
        dcs: [],
        postCode: '',
        postCodeValid: true,
        phone: '',
        phoneValid: true,
        placeId: null,
        placeIdValid: true,
        placeIdInvalidText: null,
        altPhone: '',
        altPhoneValid: true,
        latitude: '0',
        latitudeValid: true,
        longitude: '0',
        longitudeValid: true,
        locationName: '',
        locationNameInvalidText: null,
        locationNameValid: true,
        locationNumber: '',
        locationNumberMessage: '',
        locationNumberValid: true,
        legacyId: '',
        legacyIdMessage: '',
        legacyIdValid: true,
        locationType: null,
        locationTypes: [],
        locationTypeValid: true,
        servicing: '',
        servicingValid: true,
        delivery: '',
        deliveryValid: true,
        activeDate: null,
        activeDateFocused: false,
        activeDateValid: true,
        submitted: false,
        modalOpen:false,
        modalHeading:'',
        modalMessage:''
    };

    componentDidMount() {
        // verify access level
        if (!this.userCanAdd())
            this.props.history.push('/');

        this.props.onGetLocationTypes();
        this.props.onGetDCLocations();
        this.props.onClearLocations();

        const id = this.props.match.params.id;

        if (!isNaN(id)) {
            this.props.onGetLocations(+id);
        }
    }

    componentDidUpdate() {
        if (this.state.locationTypes.length === 0 && this.props.locationSelectLists['LocationTypesView'])
            this.setLocationTypes();

        if (!this.props.submitting && !this.state.submitted) {
            if (this.props.success)
                this.successGrowl();
            else if (this.props.error)
                this.failGrowl();
        }

        if (!this.props.dcsLoading && this.state.dcs.length === 0 && this.props.locationSelectLists['DepotListView'])
            this.setDCs();

            /* eslint-disable-next-line no-mixed-operators */
        if (!this.props.loadingLocations && !this.state.id) {
            this.populateState();
        }
    }

    populateState(){
        if (!this.props.locations || this.props.locations.length === 0) return;

        const location = this.props.locations[0];

        if (!location) return;

        console.log(location);

        this.setState({
            id:location.headerId,
            addressLineOne: location.address ? location.address.addressLineOne : '',
            addressLineOneInvalidText: null,
            addressLineOneValid: true,
            addressLineTwo: location.address ? location.address.addressLineTwo : '',
            addressLineTwoInvalidText: null,
            addressLineTwoValid: true,
            addressLineThree: location.address ? location.address.addressLineThree : '',
            addressLineThreeInvalidText: null,
            addressLineThreeValid: true,
            brand: location.brandId,
            brandValid: true,
            city: location.address ? location.address.city : '',
            cityInvalidText: null,
            cityValid: true,
            county: location.address ? location.address.county : '',
            countyInvalidText: null,
            countyValid: true,
            country: location.address ? location.address.country : '',
            countryInvalidText: null,
            countryValid: true,
            postCode: location.address ? location.address.postCode : '',
            postCodeValid: true,
            phone: location.address && location.address.phoneNumbers.length > 0 ? location.address.phoneNumbers[0] : '',
            phoneValid: true,
            placeId: location.placeId,
            placeIdValid: true,
            placeIdInvalidText: null,
            altPhone: location.address && location.address.phoneNumbers.length > 1 ? location.address.phoneNumbers[1] : '',
            altPhoneValid: true,
            latitude: location.address && location.address.position ? location.address.position.latitude.toString() : '0',
            latitudeValid: true,
            longitude: location.address && location.address.position ? location.address.position.longitude.toString() : '0',
            longitudeValid: true,
            locationName: location.locationName,
            locationNameInvalidText: null,
            locationNameValid: true,
            locationNumber: location.locationNo,
            locationNumberMessage: '',
            locationNumberValid: true,
            locationType: location.locationType,
            locationTypeValid: true,
            servicing: '',
            servicingValid: true,
            delivery: '',
            deliveryValid: true,
            activeDate: null,
            activeDateValid: true,
            submitted: false,
            legacyId:location.legacyId,
            legacyIdMessage:'',
            legacyIdValid:true,
            modalOpen:false,
            modalHeading:'',
            modalMessage:''
        });
    }

    setDCs() {
        this.setState({
            dcs:
                this.props.locationSelectLists['DepotListView'].sort((a, b) => +a.label - +b.label).map(
                    m => ({ label: m.label, value: m.value }))
        });
    }

    setLocationTypes() {
        // const types = this.props.locationSelectLists.map(t => ({ label: t.name, value: t.id }));
        // this.setState({ locationTypes: types });
        this.setState({
            locationTypes:
                this.props.locationSelectLists['LocationTypesView'].sort((a, b) => +a.label - +b.label).map(
                    m => ({ label: m.label, value: m.value })),
            brands:
                this.props.locationSelectLists['Brands'].sort((a, b) => +a.label - +b.label).map(
                    m => ({ label: m.label, value: m.value }))
        });
    }

    userCanAdd = () => {
        if (!this.props.roles)
            return false;

        /* eslint-disable-next-line no-multi-spaces */
        return this.props.roles.includes('Admin_Central') || this.props.roles.includes('Admin_Depot');
    }

    checkFormValidity() {
        let valid = this.state.locationNameValid;
        valid = valid && this.state.locationNumberValid;
        valid = valid && this.state.locationTypeValid;
        valid = valid && this.state.addressLineOneValid;
        valid = valid && this.state.addressLineTwoValid;
        valid = valid && this.state.addressLineThreeValid;
        valid = valid && this.state.cityValid;
        valid = valid && this.state.countyValid;
        valid = valid && this.state.countryValid;
        valid = valid && this.state.postCodeValid;
        valid = valid && this.state.phoneValid;
        valid = valid && this.state.altPhoneValid;
        valid = valid && this.state.latitudeValid;
        valid = valid && this.state.longitudeValid;
        valid = valid && this.state.servicingValid;
        valid = valid && this.state.deliveryValid;
        valid = valid && this.state.activeDateValid;
        valid = valid && this.state.legacyIdValid;
        valid = valid && this.state.placeIdValid;

        let mandatory = this.state.locationNumber.length > 0;
        mandatory = mandatory && this.state.locationName.length > 0;
        mandatory = mandatory && this.state.legacyId.length > 0;
        if(!this.state.id){
            mandatory = mandatory && (this.state.locationType || -1) > -1;
            mandatory = mandatory && this.state.servicing.length > 0;
            mandatory = mandatory && this.state.delivery.length > 0;
            mandatory = mandatory && this.state.activeDate;
        }
        return valid && mandatory;
    }

    locationNumberChangeHandler = event => {
        let valid = true;
        let msg = 'Invalid number';
        const value = event.target.value;

        // TODO: need to allow for SA- and JS- but based on the allowed brand prefixes.
        if ((value || '').length === 0)
            valid = false;

        // does the value already exist?
        if (valid) {
            const brandId = this.state.brand;
            const extant = this.props.locations.find(l => l.locationNo === value.toString() && l.brandId === brandId);
            if (extant && value.toString() !== this.state.id.toString()) {
                valid = false;
                msg = 'Already exists';
            }
        }

        // display a message to the user informing them of the result (via component state)
        this.setState({
            locationNumber: value,
            locationNumberValid: valid,
            locationNumberMessage: msg,
            submitted:false,
            modalOpen:false,
            modalHeading:'',
            modalMessage:'' });
    }

    inputChangeHandler = (event, entry) => {
        const value = event.target.value;
        const invalidText = getInvalidText(value);
        let valid = value.length > (entry === 'locationName' ? 1 : -1) && !invalidText;

        if (value.length === 0)
            valid = true;

        if (valid && entry === 'locationName') {
            const extant = this.props.locations.find(l => l.locationName === value);
            if (extant)
                this.setState({ locationNameWarning: 'Name already exists' });
            else
                this.setState({ locationNameWarning: '' });
        }

        this.setState({
            [entry]: value,
            [`${entry}Valid`]: valid,
            [`${entry}InvalidText`]: invalidText,
            submitted:false,
            modalOpen:false,
            modalHeading:'',
            modalMessage:'' });
    }

    /* eslint-disable-next-line no-unused-vars */
    legacyIdChangeHandler = (event, entry) => {
        const value = event.target.value;
        const invalidText = getInvalidText(value);
        const valid = value.length > 0 && !invalidText;

        this.setState({
            legacyId:value,
            legacyIdValid:valid,
            legacyIdMessage:invalidText,
            submitted:false,
            modalOpen:false,
            modalHeading:'',
            modalMessage:''});
    }

    latlonChangeHandler = (event, entry) => {
        const value = event.target.value;
        let valid = isValidLatLon(value, entry);

        if ((value || '').length === 0)
            valid = true;

        this.setState({ [entry]: value, [`${entry}Valid`]: valid, submitted:false, modalOpen:false, modalHeading:'', modalMessage:'' });
    }

    phoneNumberChangeHandler = (event, entry) => {
        const value = event.target.value;
        let valid = isValidPhoneNumber(value);

        if ((value || '').length === 0)
            valid = true;

        this.setState({ [entry]: value, [`${entry}Valid`]: valid, submitted:false, modalOpen:false, modalHeading:'', modalMessage:'' });
    }

    postCodeChangeHandler = event => {
        const value = event.target.value;
        let valid = isValidPostCode(value);

        if ((value || '').length === 0)
            valid = true;

        this.setState({ postCode: value, postCodeValid: valid, submitted:false, modalOpen:false, modalHeading:'', modalMessage:'' });
    }

    selectChangeHandler = (event, entry) => {
        const value = event.target.value;
        this.setState({ [entry]: value, [`${entry}Valid`]: !!value.length, submitted:false, modalOpen:false, modalHeading:'', modalMessage:'' });
    }

    focusedHandler = event => this.setState({ activeDateFocused: event.focused });

    dateChangedHandler = event => this.setState({ activeDate: event, submitted:false, modalOpen:false, modalHeading:'', modalMessage:'' });

    handleNewLocationClick = () => {
        if(this.state.submitted) return;
        // map the data to a LocationHeaderViewModel
        const phoneNumbers = [];

        if (this.state.phone.length > 0)
            phoneNumbers.push(this.state.phone);

        if (this.state.altPhone.length > 0)
            phoneNumbers.push(this.state.altPhone);

        const model = {
            headerId:this.state.id,
            locationNo: this.state.locationNumber,
            locationName: this.state.locationName,
            placeId: this.state.placeId,
            address: {
                addressLineOne: this.state.addressLineOne,
                addressLineTwo: this.state.addressLineTwo,
                addressLineThree: this.state.addressLineThree,
                city: this.state.city,
                county: this.state.county,
                country: this.state.country,
                postCode: this.state.postCode,
                phoneNumbers: phoneNumbers,
                position: {
                    latitude: this.state.latitude,
                    longitude: this.state.longitude
                }
            },
            detail: [
                { fieldOptionId: 1, fromDate: this.state.activeDate, value: 'true' },
                { fieldOptionId: 3, fromDate: this.state.activeDate, value: this.state.locationType },
                { fieldOptionId: 7, fromDate: this.state.activeDate, value: this.state.servicing },
                { fieldOptionId: 9, fromDate: this.state.activeDate, value: this.state.delivery }
            ],
            brandId: this.state.brand,
            legacyId:this.state.legacyId
        };

        // submit the data
        this.setState({ submitted: false }, this.props.onSaveNewLocation(model));
    }

    successGrowl() {
        this.growl.show({ severity: 'success', summary: 'New Location Request', detail: 'Request successfully submitted.' });
        this.setState({ submitted: true, modalOpen:true, modalHeading: 'Success', modalMessage: 'Record successfully saved' });
    }

    failGrowl() {
        this.growl.show({ severity: 'error', summary: 'New Location Request', detail: 'Request submission failed. Please try again.' });
        this.setState({ submitted: true, modalOpen:true, modalHeading: 'Error', modalMessage: 'Request submission failed. Please try again.' });
    }

    render() {
        const loading = (
            <ProgressIndicator page loading={this.props.loading || this.props.dcsLoading}>
                <ProgressSpinner light />
                Loading...
            </ProgressIndicator>
        );

        const submitting = (
            <ProgressIndicator page loading={this.props.submitting}>
                <ProgressSpinner color="light" />
                Adding new location...
            </ProgressIndicator>
        );

        const invalidName = (this.state.locationNameInvalidText || '').length > 0 ?
            `'${this.state.locationNameInvalidText}' not allowed` :
            'Name too short';

        return (
            <Container className="ln-u-push-top">
                {loading}
                {submitting}
                <h4>{this.state.id ? 'Edit' : 'New'} Location</h4>
                {this.state.id > 0 &&
                    <BreadcrumbsWrapper backToParent>
                        <BreadcrumbsItem>
                            <a href={'/location/' + this.state.id}>Location</a>
                        </BreadcrumbsItem>
                        <BreadcrumbsItem active>
                            <a href="#current">Edit Location</a>
                        </BreadcrumbsItem>
                    </BreadcrumbsWrapper>
                }
                <Card>
                    <GridWrapper>
                        <GridItem size="1/1">
                            <h4 className="ln-u-color-red"> * Mandatory Fields</h4>
                        </GridItem>
                        <GridItem size="1/6">
                            <Input
                                elementType="select"
                                errorMessage={this.state.brandValid ? '' : 'Brand required'}
                                label="Brand"
                                id="brand"
                                changed={event => this.selectChangeHandler(event, 'brand')}
                                value={this.state.brand}
                                options={this.state.brands} />
                        </GridItem>
                        <GridItem size="1/6">
                            <Input
                                elementType="input"
                                errorMessage={this.state.locationNumberValid ? '' : this.state.locationNumberMessage}
                                label="Location No"
                                id="locationNo"
                                value={this.state.locationNumber}
                                changed={event => this.locationNumberChangeHandler(event)} />
                        </GridItem>
                        <GridItem size="1/6">
                            <Input
                                elementType="input"
                                errorMessage={this.state.locationNameValid ? '' : invalidName}
                                warning={this.state.locationNameWarning}
                                label="Location Name"
                                id="locationName"
                                value={this.state.locationName}
                                changed={event => this.inputChangeHandler(event, 'locationName')} />
                        </GridItem>

                        {!this.state.id && <GridItem size="1/6">
                            <Input
                                elementType="select"
                                errorMessage={this.state.locationTypeValid ? '' : 'Location type required'}
                                label="Type"
                                id="locationType"
                                changed={event => this.selectChangeHandler(event, 'locationType')}
                                value={this.state.locationType}
                                options={this.state.locationTypes} />
                        </GridItem>
                        }
                        {!this.state.id && <GridItem size="1/6">
                            <Input
                                elementType="select"
                                errorMessage={this.state.servicingValid ? '' : 'Servicing DC required'}
                                label="Servicing DC"
                                id="svc"
                                changed={event => this.selectChangeHandler(event, 'servicing')}
                                value={this.state.servicing}
                                options={this.state.dcs} />
                        </GridItem>
                        }
                        {!this.state.id && <GridItem size="1/6">
                            <Input
                                elementType="select"
                                errorMessage={this.state.deliveryValid ? '' : 'Delivering DC required'}
                                label="Delivering DC"
                                id="sdc"
                                changed={event => this.selectChangeHandler(event, 'delivery')}
                                value={this.state.delivery}
                                options={this.state.dcs} />
                        </GridItem>
                        }
                        {!this.state.id && <GridItem size="1/6">
                            <Input
                                label="Date Active"
                                id="activeDate"
                                dateChanged={event => this.dateChangedHandler(event)}
                                onFocused={event => this.focusedHandler(event)}
                                focused={this.state.activeDateFocused}
                                value={this.state.activeDate}
                                invalid={!this.state.activeDateValid}
                                errorMessage={this.state.activeDateValid ? '' : 'Invalid date'}
                                elementType="datelabelled" />
                        </GridItem>
                                }
                        <GridItem size="1/6">
                            <Input
                                elementType="input"
                                errorMessage={this.state.legacyIdValid ? '' : this.state.legacyIdMessage}
                                label="Legacy Id"
                                id="legacyId"
                                value={this.state.legacyId}
                                changed={event => this.legacyIdChangeHandler(event)} />
                        </GridItem>
                    </GridWrapper>
                </Card>
                <Card className="ln-u-push-top">
                    <GridWrapper>
                        <GridItem size="1/1">
                            <h4>Optional Fields</h4>
                        </GridItem>
                        <GridItem size="1/6">
                            <GridWrapper>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.addressLineOneValid ? '' : `'${this.state.addressLineOneInvalidText}' not allowed`}
                                        label="Address Line One"
                                        id="addressOne"
                                        value={this.state.addressLineOne ? this.state.addressLineOne : ''}
                                        changed={event => this.inputChangeHandler(event, 'addressLineOne')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.addressLineTwoValid ? '' : `'${this.state.addressLineTwoInvalidText}' not allowed`}
                                        label="Address Line Two"
                                        id="addressTwo"
                                        value={this.state.addressLineTwo ? this.state.addressLineTwo : ''}
                                        changed={event => this.inputChangeHandler(event, 'addressLineTwo')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.addressLineThreeValid ? '' : `'${this.state.addressLineThreeInvalidText}' not allowed`}
                                        label="Address Line Three"
                                        id="addressThree"
                                        value={this.state.addressLineThree ? this.state.addressLineThree : ''}
                                        changed={event => this.inputChangeHandler(event, 'addressLineThree')} />
                                </GridItem>
                            </GridWrapper>
                        </GridItem>
                        <GridItem size="1/6">
                            <GridWrapper>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.cityValid ? '' : `'${this.state.cityInvalidText}' not allowed`}
                                        label="City"
                                        id="city"
                                        value={this.state.city ? this.state.city : ''}
                                        changed={event => this.inputChangeHandler(event, 'city')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.countyValid ? '' : `'${this.state.countyInvalidText}' not allowed`}
                                        label="County"
                                        id="county"
                                        value={this.state.county ? this.state.county : ''}
                                        changed={event => this.inputChangeHandler(event, 'county')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.countryValid ? '' : `'${this.state.countryInvalidText}' not allowed`}
                                        label="Country"
                                        id="country"
                                        value={this.state.country ? this.state.country : ''}
                                        changed={event => this.inputChangeHandler(event, 'country')} />
                                </GridItem>
                            </GridWrapper>
                        </GridItem>
                        <GridItem size="1/6">
                            <GridWrapper>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.postCodeValid ? '' : 'Invalid'}
                                        label="Post Code"
                                        id="postcode"
                                        value={this.state.postCode ? this.state.postCode : ''}
                                        changed={event => this.postCodeChangeHandler(event)} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.phoneValid ? '' : 'Invalid'}
                                        label="Phone Number"
                                        id="phone"
                                        value={this.state.phone ? this.state.phone : ''}
                                        changed={event => this.phoneNumberChangeHandler(event, 'phone')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.altPhoneValid ? '' : 'Invalid'}
                                        label="Alternate Phone Number"
                                        id="altphone"
                                        value={this.state.altPhone ? this.state.altPhone : ''}
                                        changed={event => this.phoneNumberChangeHandler(event, 'altPhone')} />
                                </GridItem>
                            </GridWrapper>
                        </GridItem>
                        <GridItem size="1/6">
                            <GridWrapper>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.latitudeValid ? '' : 'Invalid'}
                                        label="Latitude"
                                        id="latitude"
                                        value={this.state.latitude}
                                        changed={event => this.latlonChangeHandler(event, 'latitude')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.longitudeValid ? '' : 'Invalid'}
                                        label="Longitude"
                                        id="longitude"
                                        value={this.state.longitude}
                                        changed={event => this.latlonChangeHandler(event, 'longitude')} />
                                </GridItem>
                                <GridItem size="1/1">
                                    <Input
                                        elementType="input"
                                        errorMessage={this.state.placeIdValid ? '' : `'${this.state.placeIdInvalidText}' not allowed`}
                                        label="Place Id"
                                        id="placeId"
                                        value={this.state.placeId ? this.state.placeId : ''}
                                        changed={event => this.inputChangeHandler(event, 'placeId')} />
                                </GridItem>
                            </GridWrapper>
                        </GridItem>
                        <GridItem size="1/3" style={{ verticalAlign: 'bottom', textAlign: 'right' }}>
                            <IconButton
                                onClick={this.handleNewLocationClick}
                                variant="outlined"
                                disabled={!this.checkFormValidity()}
                                label={this.state.id ? 'Save Location' : 'Add Location'} >
                                <Tick />
                            </IconButton>
                        </GridItem>
                    </GridWrapper>
                </Card>
                <Growl ref={el => this.growl = el}></Growl>
                <Modal small
                handleClose={() => this.setState({modalOpen:false, modalHeading:'', modalMessage:''})}
                open={this.state.modalOpen}
                headingId="Submission Result"
                >
                <ModalHeading element="h3">
                {this.state.modalHeading}
                </ModalHeading>
                <p>
                    {this.state.modalMessage}
                    </p>
                </Modal>
            </Container>
        );
    }
}

NewLocation.propTypes = {
    dcsLoading: PropTypes.bool,
    error: PropTypes.bool,
    history: PropTypes.any,
    loading: PropTypes.bool,
    loadingLocations: PropTypes.bool,
    locationSelectLists: PropTypes.object,
    locations: PropTypes.array,
    match: PropTypes.any,
    onClearLocations: PropTypes.func,
    onGetDCLocations: PropTypes.func.isRequired,
    onGetLocationTypes: PropTypes.func.isRequired,
    onGetLocations: PropTypes.func,
    onSaveNewLocation: PropTypes.func.isRequired,
    roles: PropTypes.array,
    submitting: PropTypes.bool,
    success: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        locationSelectLists: state.location.locationSelectLists,
        dcsLoading: state.location.dcsLoading,
        error: state.location.saveNewError,
        loading: state.location.loading,
        locations: state.location.locations,
        roles: state.auth.roles,
        submitting: state.location.submitting,
        success: state.location.saveNewSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetDCLocations: () => dispatch(actions.getDCLocations()),
        onGetLocationTypes: () => dispatch(actions.getLocationTypes()),
        onSaveNewLocation: model => dispatch(actions.saveNewLocation(model)),
        onGetLocations: id => dispatch(actions.getLocation(id)),
        onClearLocations: () => dispatch(actions.clearLocations())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(NewLocation, axios));

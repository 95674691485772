import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios';
import { setLocation } from './locations';

// export const getScheduleList = () => {
//     const url = '/schedule/list';

//     return dispatch => {
//         dispatch(getScheduleListStart());
//         axios.get(url)
//             .then(res => {
//                 dispatch(getScheduleListSuccess(res.data));
//             })
//             .catch(err => dispatch(getScheduleListFail(err)));
//     };
// };

// export const getScheduleListStart = () => ({ type: actionTypes.GET_SCHEDULE_LIST_START });
// export const getScheduleListSuccess = scheduleList => ({ type: actionTypes.GET_SCHEDULE_LIST_SUCCESS, scheduleList: scheduleList });
// export const getScheduleListFail = error => ({ type: actionTypes.GET_SCHEDULE_LIST_FAIL, error: error });

/* get schedules */
export const getSchedules = (id) => {
    let url = '/schedule';

    if (id)
        url = `${url}/${id}`;

    return dispatch => {
        dispatch(getSchedulesStart());
        dispatch(setLocation(id));
        axios.get(url)
            .then(res => {
                dispatch(getSchedulesSuccess(res.data, id));
            })
            .catch(err => {
                dispatch(getSchedulesFail(err));
            });
    };
};

export const getSchedulesStart = () => {
    return {
        type: actionTypes.GET_SCHEDULES_START,
        schedules: null
    };
};

export const getSchedulesSuccess = (schedules, locationId) => {
    return {
        type: actionTypes.GET_SCHEDULES_SUCCESS,
        schedules: Object.keys(schedules).length === 0 ? null : schedules,
        locationId: locationId
    };
};

export const getSchedulesFail = (error) => {
    return {
        type: actionTypes.GET_SCHEDULES_FAIL,
        error: error,
        schedules: null
    };
};

/* save schedule(s) */
export const saveSchedule = (schedule, changes) => {
    const url = '/schedule/save';

    return dispatch => {
        dispatch(saveScheduleStart());
        axios.put(url, schedule, changes)
            .then(res => {
                dispatch(saveScheduleSuccess(res.data, res.statusText === 'OK'));
            })
            .catch(err => dispatch(saveScheduleFail(err)));
    };
};

export const saveScheduleStart = () => {
    return {
        type: actionTypes.SAVE_SCHEDULE_START,
        schedules: null,
        saveSuccess: false
    };
};

export const saveScheduleSuccess = (schedules, success) => {
    return {
        type: actionTypes.SAVE_SCHEDULE_SUCCESS,
        schedules: Object.keys(schedules).length === 0 ? null : schedules,
        saveSuccess: success
    };
};

export const resetScheduleSuccess = () => {
    return {
        type: actionTypes.RESET_SCHEDULE_SUCCESS,
        saveSuccess: false
    };
};

export const saveScheduleFail = error => {
    return {
        type: actionTypes.SAVE_SCHEDULE_FAIL,
        saveError: error,
        schedules: null,
        saveSuccess: false
    };
};

/* regenerate schedules */
export const regenerateSchedule = (locationId) => {
    const url = `schedule/regenerate/${locationId}`;

    return dispatch => {
        dispatch(regenerateScheduleStart());
        axios.put(url)
            .then(res => {
                dispatch(regenerateScheduleSuccess(res.statusText === 'OK'));
            })
            .catch(err => dispatch(regenerateScheduleFail(err)));
    };
};

export const regenerateMultipleSchedules = (locationIds) => {
    const url = 'schedule/regeneratemultiple';

    return dispatch => {
        dispatch(regenerateScheduleStart());
        axios.post(url, locationIds)
            .then(res => {
                if (!res || res.status === 500) {
                    dispatch(regenerateScheduleFail('Unknown error, please contact support'));
                }
                else {
                    dispatch(regenerateScheduleSuccess(res.status === 200));
                }
            })
            .catch(err => {
                dispatch(regenerateScheduleFail(err));
            });
    };
};

export const regenerateScheduleStart = () => {
    return {
        type: actionTypes.REGENERATE_SCHEDULE_START,
        regenerating: true,
        regenerateSuccess: false,
        regenerateError: false
    };
};

export const regenerateScheduleSuccess = success => {
    return {
        type: actionTypes.REGENERATE_SCHEDULE_SUCCESS,
        regenerateSuccess: success,
        regenerating: false,
        regenerateError: false
    };
};

export const resetRegenerateScheduleSuccess = () => {
    return {
        type: actionTypes.RESET_REGENERATE_SCHEDULE_SUCCESS,
        regenerateSuccess: null
    };
};
export const regenerateScheduleFail = error => {
    return {
        type: actionTypes.REGENERATE_SCHEDULE_FAIL,
        regenerateSuccess: false,
        regenerating: false,
        regenerateError: error
    };
};

import React from 'react';
import { connect } from 'react-redux';
import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import Intro from './Intro';

import { Card, StatusCard } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import classes from './Home.module.scss';

const home = () => {
    const menuList = [
        {
            id: 0,
            title: 'Summary Info',
            key: 'mainMenu',
            label: true,
            to: '/summary',
            description: 'View summary of distribution centre location participation'
        },
        {
            id: 1,
            title: 'All Locations',
            key: 'mainMenu',
            label: true,
            to: '/locations',
            description: 'View full location list'
        },
        {
            id: 2,
            title: 'Location Detail',
            key: 'mainMenu',
            label: true,
            to: '/location',
            description: 'View all details for a sinlge location'
        },
        {
            id: 3,
            title: 'Store Schedules',
            key: 'mainMenu',
            label: true,
            to: '/schedule',
            description: 'View all schedules for a single store'
        },
        {
            id: 4,
            title: 'Weekly Schedule',
            key: 'mainMenu',
            label: true,
            to: '/schedules',
            description: 'View the rolling schedule for a single store'
        },
        {
            id: 5,
            title: 'Downloads and Uploads',
            key: 'mainMenu',
            label: true,
            to: '/Uploads',
            description: 'Schedule, cycle, codes downloads. Schedule mass uploads. New code/cycles requests.'
        },
        {
            id: 6,
            title: 'Trailer Management',
            key: 'mainMenu',
            label: true,
            to: '/locations/locationtrailers',
            description: 'Mass assign Trailers/Locations'
        }
    ];

    const menuItems = () => {
        if (!menuList)
            return;

        return menuList.map((m, ind) =>
            <GridItem key={ind} element="li" size={{ md: '1/3'}}>
                <StatusCard link href={m.to} element="a" className={classes.cardLink} style={{ height: '100%' }}>
                        <h3>{m.title}</h3>
                        <p>{m.description}</p>
                    </StatusCard>
            </GridItem>
        );
    };

    return (
        <Container soft size="def">
            <Card style={{ paddingBottom: '0' }}>
                <Intro />
                <GridWrapper matrix element="ul">
                {menuItems()}
                </GridWrapper>
            </Card>
        </Container>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.givenName !== null
    };
};

export default connect(mapStateToProps)(withErrorHandler(home, axios));

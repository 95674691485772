import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions';

import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, IconButton, OutlinedButton } from '@jsluna/button';
import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';
import { ProgressBar, ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { SelectField, TextAreaField } from '@jsluna/form';

import { Cancel, Tick} from '@jsluna/icons';

import { Growl } from 'primereact/growl';

import classes from './Review.module.scss';

import moment from 'moment';

moment.locale('en-GB');

// disable rules of hooks to stop it whining about 'review' allegedly not being a component when it actually is (case issue)
// the downside to this is we lose other rules-of-hooks checks
/* eslint-disable react-hooks/rules-of-hooks */
const review = props => {
    // setup state
    const [ differences, setDifferences ] = useState(null);
    const [ display, setDisplay ] = useState(null);
    const [ enableRejectionSend, setEnableRejectionSend ] = useState(false);
    const [ rejectModalOpen, setRejectModalOpen ] = useState(false);
    const [ rejectionReason, setRejectionReason ] = useState('');
    const [ requestList, setRequestList ] = useState([]);
    const [ requestor, setRequestor ] = useState('');
    const [ selected, setSelected ] = useState(null);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ sendAcceptance, setSendAcceptance ] = useState(false);
    const [ sendRejection, setSendRejection ] = useState(false);
    // deconstruct props for use in useEffect
    const { onGetLocation, onGetOutstandingChangeRequests, requests, location, sendingRejection, rejectionSent,
        onGetLocationFieldOptions, fieldOptions, types, formats, dcTypes, sendingAcceptance, acceptanceSent } = props;

    // get the change requests
    useEffect(() => {
        if (onGetOutstandingChangeRequests && requestList.length === 0)
            onGetOutstandingChangeRequests();
    }, [onGetOutstandingChangeRequests, requestList]);

    // get a list of field options - essentially runs in the background
    useEffect(() => {
        if (onGetLocationFieldOptions && (fieldOptions || []).length === 0)
            onGetLocationFieldOptions();
    }, [onGetLocationFieldOptions, fieldOptions]);

    // once we have the change requests, populate the drop down list
    useEffect(() => {
        if (requests && requests.length > 0) {
            const requestList = requests.map(r => {
                const sdate = moment(r.dateRequested).format('DD/MM/YYYY HH:mm');
                return { label: `Request ${r.id} submitted ${sdate}`, value: r.id.toString() };
            });

            setRequestList(requestList);
        }
    }, [requests]);

    // get the original location data when a change request is selected
    useEffect(() => {
        if (selected) {
            onGetLocation(selected.HeaderId);
            setDifferences(null);
        }

    }, [selected, onGetLocation]);

    // find the differences between the change request and the original location details
    // useCallback prevents this function being called on every render
    const getDiffs = useCallback(() => {
        if (selected && location && location.detail) {
            const diffs = [];

            selected.Detail.forEach(d => {
                let orig = location.detail.find(ld => ld.detailId === d.DetailId);

                // check for schedule links
                if (d.FieldOptionId === 0)
                    orig = location.detail.find(ld => ld.locationLinkNo !== null && +ld.locationLinkNo === +d.LocationLinkNo);

                orig = orig || {};
                let value = d.Value;

                if (value === 'false') value = '0';
                if (value === 'true') value = '1';

                // replace lookup values with human readable content
                const headerLookups = fieldOptions.filter(fo => fo.constrainedTable === 'LocationHeaders').map(fo => fo.id);
                const typeLookups = fieldOptions.filter(fo => fo.constrainedTable === 'LocationTypes').map(fo => fo.id);
                const formatLookups = fieldOptions.filter(fo => fo.constrainedTable === 'LocationFormats').map(fo => fo.id);

                if (!(orig.value === value && orig.fromDate === d.FromDate && orig.toDate === d.ToDate)) {
                    if (headerLookups.includes(+orig.fieldOptionId)) {
                        const header = dcTypes.find(dc => dc.headerId === +orig.value);
                        if (header)
                            orig.value = header.locationName;
                    }

                    if (typeLookups.includes(+orig.fieldOptionId)) {
                        const type = types.find(t => t.value === orig.value);
                        if (type)
                            orig.value = type.label;
                    }

                    if (formatLookups.includes(+orig.fieldOptionId)) {
                        const format = formats.find(f => f.value === orig.value);
                        if (format)
                            orig.value = format.label;
                    }

                    if (headerLookups.includes(+d.FieldOptionId)) {
                        const header = dcTypes.find(dc => dc.headerId === +d.Value);
                        if (header)
                            d.Value = header.locationName;
                    }

                    if (typeLookups.includes(+d.FieldOptionId)) {
                        const type = types.find(t => t.value === d.Value);
                        if (type)
                            d.Value = type.label;
                    }

                    if (formatLookups.includes(+d.FieldOptionId)) {
                        const format = formats.find(f => f.value === d.Value);
                        if (format)
                            d.Value = format.label;
                    }

                    diffs.push({
                        original: orig,
                        requested: d,
                        diffValue: orig.value !== value,
                        diffFromDate: !moment(orig.fromDate).isSame(moment(d.FromDate)),
                        diffToDate: !moment(orig.toDate).isSame(moment(d.ToDate))
                    });
                }
            });

            setDifferences(diffs);
        }
        // if we add 'selected' as a dep here, the code runs twice per selection change
        // and causes the change requests to merge into each other. This suggests there's
        // something wrong with the logic, but as I'm unable to figure out what, I've
        // added the eslint-disable line for now - AO
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [location, dcTypes, fieldOptions, formats, types]);

    // trigger once the location data changes after a change request selection is made
    useEffect(() => {
        getDiffs();
    }, [location, getDiffs]);

    // create the display of differences once the difference array has been populated
    useEffect(() => {
        if (differences) {
            const view = differences.map((d, idx) => {
                let content = 'No current entry';
                const o = d.original;
                const r = d.requested;

                if (o || r)
                    content = (
                        <Fragment key={`o${idx}`}>
                            <GridItem size="1/6">{o.fieldOption || r.FieldOption || ''}</GridItem>
                            <GridItem size="1/6" className={d.diffValue ? 'ln-u-color-orange' : null}>{o.value || '{blank}'}</GridItem>
                            <GridItem size="1/12" className={d.diffFromDate ? 'ln-u-color-orange' : null}>
                                {o.fromDate ? moment(o.fromDate).format('DD/MM/YY') : '{blank}'}
                            </GridItem>
                            <GridItem size="1/12" className={d.diffToDate ? 'ln-u-color-orange': null}>
                                {o.toDate ? moment(o.toDate).format('DD/MM/YY') : '{blank}'}
                            </GridItem>
                            <GridItem size="1/6">{r.FieldOption || o.fieldOptions || ' '}</GridItem>
                            <GridItem size="1/6" className={d.diffValue ? 'ln-u-color-orange' : null}>{r.Value || '{blank}'}</GridItem>
                            <GridItem size="1/12" className={d.diffFromDate ? 'ln-u-color-orange' : null}>
                                {r.FromDate ? moment(r.FromDate).format('DD/MM/YY') : '{blank}'}
                            </GridItem>
                            <GridItem size="1/12" className={d.diffToDate ? 'ln-u-color-orange': null}>
                                {r.ToDate ? moment(r.ToDate).format('DD/MM/YY') : '{blank}'}
                            </GridItem>
                        </Fragment>
                    );

                return content;
            });

            setDisplay(view);
        }
        else {
            setDisplay(null);
        }
    }, [differences]);

    let growl;

    // user feedback on completion of the rejection action
    useEffect(() => {
        if (!sendingRejection && rejectionSent !== null && sendRejection) {
            if (rejectionSent) {
                // force a refresh of the requests
                setRequestList([]);
                setSelected(null);
                growl.show({ severity: 'success', summary: 'Send Rejection', detail: 'Rejection sent' });
            }
            else
                growl.show({ severity: 'error', summary: 'Send Rejection', detail: 'Failed to send rejection email' });

            setSendRejection(false);
        }
    }, [sendingRejection, rejectionSent, sendRejection, growl]);

    // user feedback on completion of the acceptance action
    useEffect(() => {
        if (!sendingAcceptance && acceptanceSent !== null && sendAcceptance) {
            if (acceptanceSent) {
                // force a refresh of the requests
                setRequestList([]);
                setSelected(null);
                growl.show({ severity: 'success', summary: 'Send Acceptance', detail: 'Acceptance sent' });
            }
            else
                growl.show({ severity: 'error', summary: 'Send Acceptance', detail: 'Failed to send acceptance email' });

            setSendAcceptance(false);
        }
    }, [sendingAcceptance, acceptanceSent, sendAcceptance, growl]);

    const selectHandler = event => {
        const id = event.target.value;
        const record = requests.find(r => r.id === +id);

        setDifferences(null);

        if (!record) {
            setSelected(null);
            return;
        }

        setRequestor(record.requestedBy);
        setSelectedId(id);
        setSelected(JSON.parse(record.request));
    };

    const loc = location || {};

    const rejectionReasonChangeHandler = event => {
        setRejectionReason(event.target.value);

        if (!enableRejectionSend && event.target.value.length > 0)
            setEnableRejectionSend(true);
    };

    const sendRejectionEmail = () => {
        const model = {
            Location: location,
            Reasons: rejectionReason,
            RequestId: selectedId,
            Requestor: requestor
        };

        setSendRejection(true);
        props.onSendRejection(model);
        setRejectModalOpen(false);
    };

    const acceptChangeRequestHandler = () => {
        const model = {
            Location: location,
            RequestId: selectedId,
            Requestor: requestor
        };

        setSendAcceptance(true);
        props.onSendAcceptance(model);
    };

    const showLoader = props.gettingRequests || sendRejection || sendAcceptance;
    const loading = (
        <ProgressIndicator page loading={showLoader}>
            <ProgressSpinner light />
            Please wait...
        </ProgressIndicator>
    );

    const headings = (
        <Fragment>
            <GridItem size="1/6" className="ln-u-font-weight-bold">Option</GridItem>
            <GridItem size="1/6" className="ln-u-font-weight-bold">Value</GridItem>
            <GridItem size="1/12" className="ln-u-font-weight-bold">From</GridItem>
            <GridItem size="1/12" className="ln-u-font-weight-bold">To</GridItem>
        </Fragment>
    );

    const rejectModal = (
        <Modal
            restrictClose
            alert
            handleClose={() => setRejectModalOpen(false)}
            open={rejectModalOpen}>
            <ModalHeading element="h3">Reject Change Request</ModalHeading>
            <p>Please specify your reasons for rejecting this change request.
                An email will be sent to the requestor informing them of your decision and reasons for rejection.
            </p>
            <TextAreaField
                required
                name="rejection-reasons"
                label="Rejection Reasons"
                value={rejectionReason}
                onChange={event => rejectionReasonChangeHandler(event)} />
            <ButtonGroupWrapper actionBar>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => sendRejectionEmail()} disabled={!enableRejectionSend}>Send</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <OutlinedButton onClick={() => setRejectModalOpen(false)}>Cancel</OutlinedButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    let reviewView = (
        <Fragment>
            <GridItem size="1/1" className="ln-u-push-bottom">
                <Card color="beta">
                    <h4 className={`${classes.inline} ${classes.nomargin}`}>{loc.locationNo} {loc.locationName}</h4>
                    <div className={`${classes.inline} ln-u-push-left`}>(Requested by user: {requestor})</div>
                </Card>
            </GridItem>
            <GridItem size="1/2">
                <h5>Current Values</h5>
            </GridItem>
            <GridItem size="1/2">
                <h5>Requested Changes</h5>
            </GridItem>
            <GridItem size="1/1">
                {<GridWrapper>
                    {headings}
                    {headings}
                    {display}
                </GridWrapper>}
            </GridItem>
        </Fragment>
    );

    let buttons = (
        <GridItem size="1/3" className={classes.buttons}>
            <IconButton
                className="ln-u-push-top"
                variant="outlined"
                onClick={() => acceptChangeRequestHandler()}
                label="Accept" >
                <Tick />
            </IconButton>
            <IconButton
                className="ln-u-push-left"
                variant="filled"
                onClick={() => setRejectModalOpen(true)}
                label="Reject" >
                <Cancel />
            </IconButton>
        </GridItem>
    );

    if (!selected || !location) {
        buttons = null;
        reviewView = null;
    }

    if (selected && !location)
        reviewView = <div className="ln-u-soft">Loading store request...<ProgressBar standalone /></div>;

    return (
        <Container>
            {loading}
            {rejectModal}
            <Card>
                <GridWrapper>
                    <GridItem size="1/1" className="ln-u-push-bottom">
                        <h3>Review Change Requests</h3>
                    </GridItem>
                    <GridItem size="1/3">
                        <SelectField
                            name="requestSelect"
                            onChange={event => selectHandler(event)}
                            label="Select the request to review"
                            placeholder="Please select a request"
                            options={requestList} />
                    </GridItem>
                    {buttons}
                    {reviewView}
                </GridWrapper>
            </Card>
            <Growl ref={el => growl = el}></Growl>
        </Container>
    );
};

review.propTypes = {
    acceptanceSent: PropTypes.bool,
    dcTypes: PropTypes.array,
    fieldOptions: PropTypes.array,
    formats: PropTypes.array,
    gettingRequests: PropTypes.bool,
    location: PropTypes.object,
    onGetLocation: PropTypes.func,
    onGetLocationFieldOptions: PropTypes.func,
    onGetOutstandingChangeRequests: PropTypes.func,
    onSendAcceptance: PropTypes.func,
    onSendRejection: PropTypes.func,
    rejectionSent: PropTypes.bool,
    requests: PropTypes.array,
    sendingAcceptance: PropTypes.bool,
    sendingRejection: PropTypes.bool,
    types: PropTypes.array
};

const mapStateToProps = state => {
    return {
        acceptanceSent: state.location.acceptanceSent,
        dcTypes: state.location.locationDCTypes,
        fieldOptions: state.location.fieldOptions,
        formats: state.location.locationFormats,
        gettingRequests: state.location.gettingRequests,
        loadingLocation: state.location.loadingLocations,
        location: state.location.locations[0],
        rejectionSent: state.location.rejectionSent,
        requests: state.location.requests,
        sendingAcceptance: state.location.sendingAcceptance,
        sendingRejection: state.location.sendingRejection,
        types: state.location.locationTypes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetLocation: headerId => dispatch(actions.getLocation(headerId)),
        onGetLocationFieldOptions: () => dispatch(actions.getLocationFieldOptions()),
        onGetOutstandingChangeRequests: () => dispatch(actions.getOutstandingChangeRequests()),
        onSendAcceptance: model => dispatch(actions.requestAcceptance(model)),
        onSendRejection: model => dispatch(actions.requestRejection(model))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(review, axios));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

import { AutocompleteField } from '@jsluna/autocomplete';

class TrailerSelect extends Component {
    componentDidMount() {
        if (this.props.trailers.length === 0) this.props.onGetTrailers();

        if (this.props.id !== null) this.props.onSetTrailer(this.props.id);
    }

    getSelected = () => {
        if ((this.props.trailers || []).length > 0 && this.props.id !== null) {
            const option = this.props.trailers.filter(m => m.id === this.props.id)[0];
            if (!option) return null;

            return {
                value: option.id.toString(),
                label: `[${option.code}]: ${option.description}`
            };
        }
        else return null;
    };

    itemSelectHandler = event => {
        let itemId = null;
        if (event !== null && event.value) itemId = +event.value;

        // use the HTML5 history to change the URL, can't use React history.push as that also forces a reload which we don't need
        window.history.pushState(null, null, `/Trailer/${itemId === null ? '' : itemId}`);

        this.props.onSetTrailer(itemId);
    };

    render() {
        const itemOptions = this.props.trailers.map(l => ({ label: `[${l.code}]: ${l.description}`, value: l.id.toString() }));
        const obj = this.getSelected();

        let itemSelect = null;

        if (itemOptions)
            itemSelect = (
                <AutocompleteField
                    name="ltrailerListAutocomplete"
                    placeholder="Select a Trailer"
                    label="Select Trailer"
                    hideLabel
                    onSelect={event => this.itemSelectHandler(event)}
                    options={itemOptions}
                    clearSelection={() => this.itemSelectHandler(null)}
                    selectedItem={obj}
                    // value={!obj ? '' : obj.value}
                />
            );

        return itemSelect;
    }
}

TrailerSelect.propTypes = {
    id: PropTypes.number,
    onGetTrailers: PropTypes.func,
    onSetTrailer: PropTypes.func,
    trailers: PropTypes.array
};

const mapStateToProps = state => {
    return {
        id: state.trailer.trailer,
        trailers: state.trailer.trailers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClearTrailerId: () => dispatch(actions.setTrailer(null)),
        onGetTrailers: () => dispatch(actions.getTrailers()),
        onSetTrailer: id => dispatch(actions.setTrailer(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(TrailerSelect, axios));

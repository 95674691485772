import * as actionTypes from './actionTypes';
import axios from '../../axios';
import moment from 'moment';

export const getOrderCycleByCycleDays = cycleDays => {
    const url = `/ordercycles/cycledays/${cycleDays}`;

    return dispatch => {
        dispatch(getOrderCycleStart());
        axios.get(url)
            .then(res => dispatch(getOrderCycleSuccess(res.data)))
            .catch(err => dispatch(getOrderCycleFail(err)));
    };
};

export const getOrderCycleStart = () => ({ type: actionTypes.GET_ORDER_CYCLE_START });
export const getOrderCycleSuccess = orderCycle => ({ type: actionTypes.GET_ORDER_CYCLE_SUCCESS, orderCycle: orderCycle });
export const getOrderCycleFail = error => ({ type: actionTypes.GET_ORDER_CYCLE_FAIL, error: true, errorObject: error });

export const getOrderCycleCodes = () => {
    const url = '/ordercycles/codes';

    return dispatch => {
        dispatch(getOrderCycleCodesStart());
        axios.get(url)
            .then(res => dispatch(getOrderCycleCodesSuccess(res.data)))
            .catch(err => dispatch(getOrderCycleCodesFail(err)));
    };
};

export const getOrderCycleCodesStart = () => ({ type: actionTypes.GET_ORDER_CYCLE_CODES_START });
export const getOrderCycleCodesSuccess = orderCycleCodes => ({ type: actionTypes.GET_ORDER_CYCLE_CODES_SUCCESS, orderCycleCodes: orderCycleCodes });
export const getOrderCycleCodesFail = error => ({ type: actionTypes.GET_ORDER_CYCLE_CODES_FAIL, error: true, errorObject: error });

export const getOrderCycles = () => {
    const url = '/ordercycles';

    return dispatch => {
        dispatch(getOrderCyclesStart());
        axios.get(url)
            .then(res => dispatch(getOrderCyclesSuccess(res.data)))
            .catch(err => dispatch(getOrderCyclesFail(err)));
    };
};

export const getOrderCyclesStart = () => ({ type: actionTypes.GET_ORDER_CYCLES_START });
export const getOrderCyclesSuccess = orderCycles => ({ type: actionTypes.GET_ORDER_CYCLES_SUCCESS, orderCycles: orderCycles });
export const getOrderCyclesFail = error => ({ type: actionTypes.GET_ORDER_CYCLES_FAIL, error: true, errorObject: error });

export const sendCodeRequest = (even, odd) => {
    const url = '/ordercycles';
    const codes = {Item1: even, Item2: odd};

    return dispatch => {
        dispatch(sendCodeRequestStart());
        axios.post(url, codes)
            .then(res => dispatch(sendCodeRequestSuccess(res.data)))
            .catch(err => dispatch(sendCodeRequestFail(err)));
    };
};

export const sendCodeRequestStart = () => ({ type: actionTypes.SEND_CODE_REQUEST_START });
export const sendCodeRequestSuccess = success => ({ type: actionTypes.SEND_CODE_REQUEST_SUCCESS, success: success });
export const sendCodeRequestFail = error => ({ type: actionTypes.SEND_CODE_REQUEST_FAIL, error: true, errorObject: error });

export const sendAllCodeRequests = wcDate => {
    const url = `/ordercycles/requestall/${wcDate}`;

    return dispatch => {
        dispatch(sendAllCodeRequestsStart());
        axios.get(url)
            .then(res => dispatch(sendAllCodeRequestsSuccess(res.data)))
            .catch(err => dispatch(sendAllCodeRequestsFail(err)));
    };
};

export const sendAllCodeRequestsStart = () => ({ type: actionTypes.SEND_ALL_CODE_REQUESTS_START });
export const sendAllCodeRequestsSuccess = success => ({ type: actionTypes.SEND_ALL_CODE_REQUESTS_SUCCESS, success: success });
export const sendAllCodeRequestsFail = error => ({ type: actionTypes.SEND_CODE_REQUEST_FAIL, error: true, errorObject: error });

export const downloadOrderCycles = downloadDate => {
    const date = moment(downloadDate).format('YYYY-MM-DD');
    const url = `/aipschedule/downloadcycles/${date}`;

    return dispatch => {
        dispatch(downloadOrderCyclesStart());
        axios.get(url, { responseType: 'blob' })
            .then(response => dispatch(downloadOrderCyclesSuccess(response.data)))
            .catch(err => dispatch(downloadOrderCyclesFail(err)));
    };
};

export const downloadOrderCyclesStart = () => ({ type: actionTypes.CYCLES_DOWNLOAD_START });
export const downloadOrderCyclesSuccess = cyclesDownload => ({ type: actionTypes.CYCLES_DOWNLOAD_SUCCESS, cyclesDownload: cyclesDownload });
export const downloadOrderCyclesFail = error => ({ type: actionTypes.CYCLES_DOWNLOAD_FAIL, error: true, errorObject: error });

export const downloadMissing = downloadDate => {
    const date = moment(downloadDate).format('YYYY-MM-DD');
    const url = `/aipcodes/missinginfo/${date}`;

    return dispatch => {
        dispatch(downloadMissingStart());
        axios.get(url, { responseType: 'blob' })
            .then(response => dispatch(downloadMissingSuccess(response.data)))
            .catch(err => dispatch(downloadMissingFail(err)));
    };
};

export const downloadMissingStart = () => ({ type: actionTypes.DOWNLOAD_MISSING_START });
export const downloadMissingSuccess = missingDownload => ({ type: actionTypes.DOWNLOAD_MISSING_SUCCESS, missingDownload: missingDownload });
export const downloadMissingFail = error => ({ type: actionTypes.DOWNLOAD_MISSING_FAIL, error: true, errorObject: error });
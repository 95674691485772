import * as actionTypes from './actionTypes';
import axios from '../../axios';
import moment from 'moment';

export const clearSchedule = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULE
    };
};

export const getAipScheduleStart = () => {
    return {
        type: actionTypes.GET_AIPSCHEDULE_START
    };
};

export const getAipScheduleSuccess = (scheduleHeaders, scheduleDates, locationId, wcDate, routes) => {
    return {
        type: actionTypes.GET_AIPSCHEDULE_SUCCESS,
        scheduleHeaders: scheduleHeaders,
        scheduleDates: scheduleDates,
        locationId: locationId,
        wcDate: wcDate,
        routes: routes
    };
};

export const getAipScheduleFail = error => {
    return {
        type: actionTypes.GET_AIPSCHEDULE_FAIL,
        error: error
    };
};

export const getAipSchedule = (id, date) => {

    if (id === null)
        return dispatch => dispatch(changeDateSuccess(date));

    let url = '/aipschedule';

    if (date && id !== null && id !== -1)
        url = `${url}/${date.format('YYYY-MM-DD')}/${id}`;
    else
        url = `${url}/${date.format('YYYY-MM-DD')}`;

    return dispatch => {
        dispatch(getAipScheduleStart());
        axios.get(url)
            .then(res => {
                const scheduleHeaders = [];
                const scheduleDates = res.data.scheduleDates;
                for (const key in res.data.scheduleHeaders) {
                    scheduleHeaders.push({
                        ...res.data.scheduleHeaders[key],
                        id: key
                    });
                }
                const routes = res.data.routes;
                const dateParts = scheduleDates[7].substr(4).split('/');
                // changed the '/' to '-' to ensure date conforms to ISO format for moment, stops it whining - AO
                date = moment(`20${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
                dispatch(getAipScheduleSuccess(scheduleHeaders, scheduleDates, id, date,routes));
            })
            .catch(err => {
                dispatch(getAipScheduleFail(err));
            });
    };
};

export const changeDateSuccess = wcDate => {
    return {
        type: actionTypes.CHANGE_DATE_SUCCESS,
        wcDate: wcDate
    };
};

export const changeDate = date => {
    return dispatch => {
        dispatch(changeDateSuccess(date));
    };
};

export const clearLocationIdSuccess = () => {
    return {
        type: actionTypes.CLEAR_LOCATION_ID_SUCCESS,
        locationId: null,
        scheduleHeaders: []
    };
};

export const clearLocationId = () => {
    return dispatch => {
        dispatch(clearLocationIdSuccess());
    };
};

export const schedulesDownload = (downloadDate, downloadWeeks) => {
    const date = moment(downloadDate).format('YYYY-MM-DD');
    const url = `/aipschedule/download/${date}/${downloadWeeks}`;

    return dispatch => {
        dispatch(schedulesDownloadStart());
        axios.get(url, { responseType: 'blob' })
            .then(response => dispatch(schedulesDownloadSuccess(response.data)))
            .catch(err => dispatch(schedulesDownloadFail(err)));
    };
};

export const codesDownload = downloadDate => {
    const date = moment(downloadDate).format('YYYY-MM-DD');
    const url = `/aipcodes/download/${date}`;

    return dispatch => {
        dispatch(codesDownloadStart());
        axios.get(url, { responseType: 'blob' })
            .then(response => dispatch(codesDownloadSuccess(response.data)))
            .catch(err => dispatch(codesDownloadFail(err)));
    };
};

export const getScheduleTypes = () => {
    return dispatch => {
        dispatch(getScheduleTypesStart());
        axios.get('/aipschedule/gettypes')
            .then(response => dispatch(getScheduleTypesSuccess(response.data)))
            .catch(err => dispatch(getScheduleTypesFail(err)));
    };
};

export const getGeneratedCode = (locationId, wcDate) => {
    return dispatch => {
        dispatch(getGeneratedCodeStart());
        axios.get(`/aipcodes/${wcDate}/${locationId}`)
            .then(response => dispatch(getGeneratedCodeSuccess(response.data)))
            .catch(err => dispatch(getGeneratedCodeFail(err)));
    };
};

export const getGeneratedCodes = wcDate => {
    return dispatch => {
        dispatch(getGeneratedCodesStart());
        axios.get(`/aipcodes/${wcDate}`)
            .then(response => dispatch(getGeneratedCodesSuccess(response.data)))
            .catch(err => dispatch(getGeneratedCodesFail(err)));
    };
};

export const getProfileCodes = () => {
    const url = '/aipcodes/profiles';

    return dispatch => {
        dispatch(getProfileCodesStart());
        axios.get(url)
            .then(res => dispatch(getProfileCodesSuccess(res.data)))
            .catch(err => dispatch(getProfileCodesFail(err)));
    };
};

export const sendRequests = requests => {
    const url = '/aipcodes';

    return dispatch => {
        dispatch(sendRequestsStart());
        axios.post(url, requests)
            .then(res => dispatch(sendRequestsSuccess(res.data)))
            .catch(err => dispatch(sendRequestsFail(err)));
    };
};

export const transportDownload = wcDate => {
    const url = `/aipschedule/transport/${wcDate}`;

    return dispatch => {
        dispatch(transportDownloadStart());
        axios.get(url, { responseType: 'blob' })
            .then(res => dispatch(transportDownloadSuccess(res.data)))
            .catch(err => dispatch(transportDownloadFail(err)));
    };
};

export const getMissingCodes = wcDate => {
    const url = `/aipcodes/missing/${wcDate}`;

    return dispatch => {
        dispatch(getMissingCodesStart());
        axios.get(url)
            .then(res => dispatch(getMissingCodesSuccess(res.data)))
            .catch(err => dispatch(getMissingCodesFail(err)));
    };
};

export const schedulesDownloadStart = () => ({ type: actionTypes.SCHEDULES_DOWNLOAD_START });
export const schedulesDownloadSuccess = fileData => ({ type: actionTypes.SCHEDULES_DOWNLOAD_SUCCESS, fileData: fileData });
export const schedulesDownloadFail = error => ({ type: actionTypes.SCHEDULES_DOWNLOAD_FAIL, error: true, errorObject: error });
export const codesDownloadStart = () => ({ type: actionTypes.CODES_DOWNLOAD_START });
export const codesDownloadSuccess = fileData => ({ type: actionTypes.CODES_DOWNLOAD_SUCCESS, fileData: fileData });
export const codesDownloadFail = error => ({ type: actionTypes.CODES_DOWNLOAD_FAIL, error: true, errorObject: error });
export const getScheduleTypesStart = () => ({ type: actionTypes.GET_SCHEDULE_TYPES_START });
export const getScheduleTypesSuccess = types => ({ type: actionTypes.GET_SCHEDULE_TYPES_SUCCESS, types: types });
export const getScheduleTypesFail = error => ({ type: actionTypes.GET_SCHEDULE_TYPES_FAIL, error: true, errorObject: error });
export const getGeneratedCodeStart = () => ({ type: actionTypes.GET_GENERATED_CODE_START });
export const getGeneratedCodeSuccess = code => ({ type: actionTypes.GET_GENERATED_CODE_SUCCESS, code: code });
export const getGeneratedCodeFail = error => ({ type: actionTypes.GET_GENERATED_CODE_FAIL, error: true, errorObject: error });
export const getGeneratedCodesStart = () => ({ type: actionTypes.GET_GENERATED_CODES_START });
export const getGeneratedCodesSuccess = codes => ({ type: actionTypes.GET_GENERATED_CODES_SUCCESS, codes: codes });
export const getGeneratedCodesFail = error => ({ type: actionTypes.GET_GENERATED_CODES_FAIL, error: true, errorObject: error });
export const getProfileCodesStart = () => ({ type: actionTypes.GET_PROFILE_CODES_START });
export const getProfileCodesSuccess = codes => ({ type: actionTypes.GET_PROFILE_CODES_SUCCESS, profileCodes: codes });
export const getProfileCodesFail = error => ({ type: actionTypes.GET_PROFILE_CODES_FAIL, error: true, errorObject: error });
export const sendRequestsStart = () => ({ type: actionTypes.SEND_REQUESTS_START });
export const sendRequestsSuccess = success => ({ type: actionTypes.SEND_REQUESTS_SUCCESS, success: success });
export const sendRequestsFail = error => ({ type: actionTypes.SEND_REQUESTS_FAIL, error: true, errorObject: error });
export const transportDownloadStart = () => ({ type: actionTypes.TRANSPORT_DOWNLOAD_START });
export const transportDownloadSuccess = transportData => ({ type: actionTypes.TRANSPORT_DOWNLOAD_SUCCESS, transportData: transportData });
export const transportDownloadFail = error => ({ type: actionTypes.TRANSPORT_DOWNLOAD_FAIL, error: true, errorObject: error });
export const getMissingCodesStart = () => ({ type: actionTypes.GET_MISSING_CODES_START });
export const getMissingCodesSuccess = cycles => ({ type: actionTypes.GET_MISSING_CODES_SUCCESS, cycles: cycles });
export const getMissingCodesFail = error => ({ type: actionTypes.GET_MISSING_CODES_FAIL, error: true, errorObject: error });

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    fileData: null,
    fileLoading: false,
    id: null,
    loading: false,
    loadingTypes: false,
    profileCodes: [],
    scheduleDates: [],
    scheduleHeaders: [],
    locationId: null
};

const clearSchedule = (state) => {
    return updateObject(state, {
        scheduleHeaders: [],
        scheduleDates: []
    });
};

/* get aip schedule(s) */
const getAipScheduleStart = (state) => {
    return updateObject(state, { loading: true });
};

const getAipScheduleSuccess = (state, action) => {
    return updateObject(state, {
        scheduleHeaders: action.scheduleHeaders,
        scheduleDates: action.scheduleDates,
        routes: action.routes,
        locationId: action.locationId,
        wcDate: action.wcDate,
        loading: false
    });
};

const getAipScheduleFail = (state) => {
    return updateObject(state, { loading: false });
};

const changeDateSuccess = (state, action) => updateObject(state, { wcDate: action.wcDate });
const clearLocationIdSuccess = (state, action) => updateObject(state, { locationId: action.locationId, scheduleHeaders: action.scheduleHeaders });

const schedulesDownloadStart = state => updateObject(state, { schedulesLoading: true });
const schedulesDownloadSuccess = (state, action) => updateObject(state, { schedulesFileData: action.fileData, schedulesLoading: false });
const schedulesDownloadFail = state => updateObject(state, { schedulesLoading: false, error: true });

const codesDownloadStart = state => updateObject(state, { fileLoading: true });
const codesDownloadSuccess = (state, action) => updateObject(state, { fileData: action.fileData, fileLoading: false });
const codesDownloadFail = state => updateObject(state, { fileLoading: false, error: true });

const scheduleTypesStart = state => updateObject(state, { loadingTypes: true });
const scheduleTypesSuccess = (state, action) => updateObject(state, { types: action.types, loadingTypes: false });
const scheduleTypesFail = state => updateObject(state, { loadingTypes: false, error: true });

const getGeneratedCodeStart = state => updateObject(state, { loading: true });
const getGeneratedCodeSuccess = (state, action) => updateObject(state, { code: action.code, loading: false });
const getGeneratedCodeFail = state => updateObject(state, { loading: false, error: true });

const getGeneratedCodesStart = state => updateObject(state, { loading: true });
const getGeneratedCodesSuccess = (state, action) => updateObject(state, { codes: action.codes, loading: false });
const getGeneratedCodesFail = state => updateObject(state, { loading: false, error: true });

const getProfileCodesStart = state => updateObject(state, { loading: true });
const getProfileCodesSuccess = (state, action) => updateObject(state, { profileCodes: action.profileCodes, loading: false });
const getProfileCodesFail = state => updateObject(state, { loading: false, error: true });

const sendRequestsStart = state => updateObject(state, { sending: true });
const sendRequestsSuccess = (state, action) => updateObject(state, { success: action.success, sending: false });
const sendRequestsFail = state => updateObject(state, { sending: false, error: true });

const transportDownloadStart = state => updateObject(state, { transDownloading: true });
const transportDownloadSuccess = (state, action) => updateObject(state, { transportData: action.transportData, transDownloading: false });
const transportDownloadFail = state => updateObject(state, { transDownloading: false, error: true });

const getMissingCodesStart = state => updateObject(state, { loadingCodes: true });
const getMissingCodesSuccess = (state, action) => updateObject(state, { cycles: action.cycles, loadingCodes: false });
const getMissingCodesFail = state => updateObject(state, { loadingCodes: false, error: true });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_DATE_SUCCESS: return changeDateSuccess(state, action);
        case actionTypes.CLEAR_LOCATION_ID_SUCCESS: return clearLocationIdSuccess(state, action);
        case actionTypes.GET_AIPSCHEDULE_START: return getAipScheduleStart(state);
        case actionTypes.GET_AIPSCHEDULE_SUCCESS: return getAipScheduleSuccess(state, action);
        case actionTypes.GET_AIPSCHEDULE_FAIL: return getAipScheduleFail(state);
        case actionTypes.CLEAR_SCHEDULE: return clearSchedule(state);
        case actionTypes.SCHEDULES_DOWNLOAD_START: return schedulesDownloadStart(state);
        case actionTypes.SCHEDULES_DOWNLOAD_SUCCESS: return schedulesDownloadSuccess(state, action);
        case actionTypes.SCHEDULES_DOWNLOAD_FAIL: return schedulesDownloadFail(state);
        case actionTypes.CODES_DOWNLOAD_START: return codesDownloadStart(state);
        case actionTypes.CODES_DOWNLOAD_SUCCESS: return codesDownloadSuccess(state, action);
        case actionTypes.CODES_DOWNLOAD_FAIL: return codesDownloadFail(state);
        case actionTypes.GET_SCHEDULE_TYPES_START: return scheduleTypesStart(state);
        case actionTypes.GET_SCHEDULE_TYPES_SUCCESS: return scheduleTypesSuccess(state, action);
        case actionTypes.GET_SCHEDULE_TYPES_FAIL: return scheduleTypesFail(state);
        case actionTypes.GET_GENERATED_CODE_START: return getGeneratedCodeStart(state);
        case actionTypes.GET_GENERATED_CODE_SUCCESS: return getGeneratedCodeSuccess(state, action);
        case actionTypes.GET_GENERATED_CODE_FAIL: return getGeneratedCodeFail(state);
        case actionTypes.GET_GENERATED_CODES_START: return getGeneratedCodesStart(state);
        case actionTypes.GET_GENERATED_CODES_SUCCESS: return getGeneratedCodesSuccess(state, action);
        case actionTypes.GET_GENERATED_CODES_FAIL: return getGeneratedCodesFail(state);
        case actionTypes.GET_PROFILE_CODES_START: return getProfileCodesStart(state);
        case actionTypes.GET_PROFILE_CODES_SUCCESS: return getProfileCodesSuccess(state, action);
        case actionTypes.GET_PROFILE_CODES_FAIL: return getProfileCodesFail(state);
        case actionTypes.SEND_REQUESTS_START: return sendRequestsStart(state);
        case actionTypes.SEND_REQUESTS_SUCCESS: return sendRequestsSuccess(state, action);
        case actionTypes.SEND_REQUESTS_FAIL: return sendRequestsFail(state);
        case actionTypes.TRANSPORT_DOWNLOAD_START: return transportDownloadStart(state);
        case actionTypes.TRANSPORT_DOWNLOAD_SUCCESS: return transportDownloadSuccess(state, action);
        case actionTypes.TRANSPORT_DOWNLOAD_FAIL: return transportDownloadFail(state);
        case actionTypes.GET_MISSING_CODES_START: return getMissingCodesStart(state);
        case actionTypes.GET_MISSING_CODES_SUCCESS: return getMissingCodesSuccess(state, action);
        case actionTypes.GET_MISSING_CODES_FAIL: return getMissingCodesFail(state);
        default: return state;
    }
};

export default reducer;

import React from 'react';

import './Intro.css';

/* eslint-disable max-len */
const Intro = () => {
    return (
        <div className="logoWrapper">
            <svg
                id="logo"
                width="622"
                height="85"
                viewBox="0 0 622 85"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M54.2 60.92C54.2 33.08 16.76 41.36 16.76 26.72C16.76 21.32 21.56 17.96 30.56 17.96C36.32 17.96 42.56 19.52 48.2 21.68L48.8 8.96C44.12 7.39999 36.32 5.71999 29.48 5.71999C10.88 5.71999 0.679993 14.84 0.679993 28.76C0.679993 56.48 38 47.72 38 62.96C38 69.08 32.24 71.84 24.56 71.84C17.72 71.84 8.47999 69.2 2.11999 65.6L1.39999 79.4C8.35999 82.4 17.12 84.2 25.28 84.2C41.48 84.2 54.2 77.12 54.2 60.92Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M108.049 69.56C105.289 71.24 101.569 72.68 98.2091 72.68C93.2891 72.68 91.8491 69.68 91.8491 64.16V36.08H105.289V24.8H91.8491V6.44L75.8891 10.4V24.8L66.7691 27.08V36.08H75.8891V64.4C75.8891 71.96 76.9691 76.16 79.9691 79.4C82.7291 82.28 87.5291 84.08 93.1691 84.08C98.5691 84.08 104.329 82.52 107.689 80.24L108.049 69.56Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M178.615 53.96C178.615 35.12 168.535 23.72 150.295 23.72C132.055 23.72 121.975 35.12 121.975 53.96C121.975 72.8 132.055 84.08 150.295 84.08C168.535 84.08 178.615 72.8 178.615 53.96ZM162.415 53.96C162.415 68.12 157.735 73.16 150.295 73.16C142.855 73.16 138.175 68.12 138.175 53.96C138.175 39.8 142.855 34.64 150.295 34.64C157.735 34.64 162.415 39.8 162.415 53.96Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M210.944 28.4C210.584 25.88 209.264 24.8 206.744 24.8H192.104V36.08H199.064V83H215.024V50.96C215.024 45.92 215.624 42.32 217.904 39.56C219.824 37.28 222.704 36.32 226.184 36.32C227.624 36.32 229.904 36.44 231.104 36.68V24.08C230.024 23.84 228.584 23.72 227.144 23.72C220.424 23.72 214.544 27.2 211.664 32.96L210.944 28.4Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M294.959 50.48C294.959 34.28 284.399 23.72 268.559 23.72C252.239 23.72 241.079 35 241.079 54.2C241.079 72.32 251.399 84.08 269.999 84.08C277.799 84.08 286.199 82.04 291.719 78.08L292.319 67.16C287.279 70.64 279.959 73.04 273.239 73.04C264.359 73.04 258.599 68.48 257.519 59.12H287.759C293.159 59.12 294.959 56.72 294.959 50.48ZM279.599 48.8C279.599 49.64 279.359 50 278.519 50H257.279C257.759 39.2 262.439 34.52 268.559 34.52C275.399 34.52 279.599 39.68 279.599 48.8Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M354.851 65.72C354.851 44.6 324.731 49.88 324.731 40.52C324.731 36.68 329.051 34.52 335.051 34.52C340.811 34.52 346.211 35.84 350.291 37.52V26.24C346.091 24.68 339.491 23.72 333.971 23.72C319.811 23.72 309.971 30.56 309.971 42.08C309.971 63.56 340.091 57.2 340.091 67.4C340.091 71.24 336.491 73.16 330.251 73.16C324.371 73.16 316.211 71.12 311.171 68.12L310.211 80.12C316.091 82.76 324.011 84.08 331.331 84.08C344.651 84.08 354.851 77.96 354.851 65.72Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M466.777 7.04H450.097V38.36H423.097V7.04H406.417V83H423.097V51.56H450.097V83H466.777V7.04Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M548.017 71.6C547.057 72.32 544.897 73.16 543.457 73.16C541.537 73.16 540.577 72.08 540.577 69.56V24.8H524.617V58.16C524.617 62.6 524.137 65.84 522.217 68.36C520.537 70.52 517.897 72.08 514.057 72.08C510.577 72.08 508.057 70.88 506.497 68.84C504.937 66.68 504.577 64.16 504.577 60.32V24.8H488.617V62.84C488.617 69.44 489.937 74.48 493.657 78.44C497.257 82.16 502.777 84.08 509.617 84.08C516.817 84.08 522.577 81.68 526.417 77.72C527.977 81.8 532.177 83.72 536.737 83.72C540.937 83.72 544.777 82.4 547.417 80.6L548.017 71.6Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
                <path
                    d="M604.954 53.48C604.954 61.28 603.514 67.16 599.674 70.52C597.274 72.68 594.034 73.64 589.834 73.64C587.914 73.64 584.194 73.04 582.874 72.44V47.72C582.874 43.4 583.594 40.64 585.514 38.6C587.314 36.56 590.194 35.48 593.554 35.48C601.234 35.48 604.954 41.24 604.954 53.48ZM582.874 4.76C582.874 2.24 581.914 0.919998 579.154 0.919998H559.954V12.2H566.914V80.48C573.274 82.52 582.274 84.08 590.194 84.08C600.514 84.08 608.554 81.8 613.954 75.92C618.874 70.64 621.154 62.6 621.154 53.12C621.154 34.88 613.474 23.72 597.994 23.72C591.514 23.72 586.354 26 582.874 29.6V4.76Z"
                    stroke="#F06C00"
                    strokeWidth="2"
                />
            </svg>

            <svg
                id="logo-strapline"
                width="490"
                height="32"
                viewBox="0 0 490 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.072 24.972C15.84 25.676 14.256 26.204 13.024 26.204C11.484 26.204 11.088 25.236 11.088 23.652V14.808H15.884V9.66H11.088V2.928L3.696 4.6V9.66L0.220001 10.496V14.808H3.696V23.564C3.696 26.776 4.136 28.536 5.456 29.812C6.556 30.868 8.36 31.396 10.472 31.396C12.716 31.396 15.488 30.692 16.896 29.68L17.072 24.972Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M43.9127 16.92C43.9127 14.632 43.6047 13.356 42.9447 12.256C41.8007 10.32 39.3807 9.264 36.5647 9.264C34.4527 9.264 32.4287 10.056 31.0207 11.376V0.904H23.6287V31H31.0207V18.68C31.0207 17.712 31.1087 16.7 31.5927 15.996C32.0327 15.292 32.8247 14.764 33.9687 14.764C34.9807 14.764 35.7727 15.248 36.1247 15.864C36.4767 16.436 36.5207 17.184 36.5207 18.064V31H43.9127V16.92Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M70.8259 18.812C70.8259 12.872 66.5579 9.264 60.4859 9.264C54.4139 9.264 49.8379 13.268 49.8379 20.44C49.8379 27.172 53.8859 31.396 60.9259 31.396C64.0059 31.396 67.2619 30.648 69.5939 28.932L69.8579 24.224C67.8779 25.588 65.1499 26.468 62.5979 26.468C59.6499 26.468 57.9779 25.104 57.4939 22.508H67.8339C68.7579 22.508 69.5059 22.376 70.0339 21.804C70.6499 21.144 70.8259 20.044 70.8259 18.812ZM63.6539 18.372C63.6539 18.768 63.4779 18.944 63.1699 18.944H57.3179C57.3619 15.688 58.5939 14.06 60.4859 14.06C62.7299 14.06 63.6539 16.172 63.6539 18.372Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M110.43 16.92C110.43 14.632 110.122 13.356 109.462 12.256C108.318 10.32 105.898 9.264 103.082 9.264C100.97 9.264 98.946 10.056 97.538 11.376V0.904H90.146V31H97.538V18.68C97.538 17.712 97.626 16.7 98.11 15.996C98.55 15.292 99.342 14.764 100.486 14.764C101.498 14.764 102.29 15.248 102.642 15.864C102.994 16.436 103.038 17.184 103.038 18.064V31H110.43V16.92Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M138.267 20.352C138.267 13.708 134.351 9.264 127.311 9.264C120.271 9.264 116.355 13.708 116.355 20.352C116.355 26.996 120.271 31.396 127.311 31.396C134.307 31.396 138.267 26.996 138.267 20.352ZM130.787 20.352C130.787 24.312 130.039 26.468 127.311 26.468C124.583 26.468 123.835 24.312 123.835 20.352C123.835 16.392 124.583 14.192 127.311 14.192C130.039 14.192 130.787 16.392 130.787 20.352Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M142.801 9.66V14.808H145.397V31H152.789V18.724C152.789 17.756 152.833 16.7 153.317 15.996C153.757 15.292 154.461 14.764 155.605 14.764C156.617 14.764 157.321 15.248 157.673 15.864C158.025 16.436 158.069 17.184 158.069 18.064V31H165.461V18.724C165.461 17.756 165.505 16.7 165.989 15.996C166.429 15.292 167.133 14.764 168.277 14.764C169.289 14.764 169.993 15.248 170.345 15.864C170.697 16.436 170.741 17.184 170.741 18.064V31H178.133V16.92C178.133 14.632 177.825 13.532 177.165 12.432C175.977 10.408 173.645 9.264 170.829 9.264C168.233 9.264 165.945 10.408 164.361 12.212C163.129 10.364 160.885 9.264 158.157 9.264C155.165 9.264 152.613 10.452 151.073 12.652L150.897 11.288C150.721 10.012 150.325 9.66 149.049 9.66H142.801Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M205.077 18.812C205.077 12.872 200.809 9.264 194.737 9.264C188.665 9.264 184.089 13.268 184.089 20.44C184.089 27.172 188.137 31.396 195.177 31.396C198.257 31.396 201.513 30.648 203.845 28.932L204.109 24.224C202.129 25.588 199.401 26.468 196.849 26.468C193.901 26.468 192.229 25.104 191.745 22.508H202.085C203.009 22.508 203.757 22.376 204.285 21.804C204.901 21.144 205.077 20.044 205.077 18.812ZM197.905 18.372C197.905 18.768 197.729 18.944 197.421 18.944H191.569C191.613 15.688 192.845 14.06 194.737 14.06C196.981 14.06 197.905 16.172 197.905 18.372Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M244.99 20.352C244.99 13.708 241.074 9.264 234.034 9.264C226.994 9.264 223.078 13.708 223.078 20.352C223.078 26.996 226.994 31.396 234.034 31.396C241.03 31.396 244.99 26.996 244.99 20.352ZM237.51 20.352C237.51 24.312 236.762 26.468 234.034 26.468C231.306 26.468 230.558 24.312 230.558 20.352C230.558 16.392 231.306 14.192 234.034 14.192C236.762 14.192 237.51 16.392 237.51 20.352Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M266.199 1.3C264.835 0.815998 262.415 0.375999 260.611 0.375999C257.839 0.375999 255.507 1.124 254.231 2.532C252.911 3.984 252.603 5.744 252.603 8.296V9.66L249.171 10.496V14.808H252.603V31H259.995V14.808H265.099V9.66H259.995V7.724C259.995 6.14 260.699 5.348 262.239 5.348C263.427 5.348 265.187 5.7 266.199 6.052V1.3Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M282.056 0.904V6.052H284.652V25.896C284.652 27.612 284.872 28.624 285.4 29.46C286.192 30.736 287.6 31.352 289.888 31.352C291.692 31.352 293.54 30.78 294.64 30.032L294.86 25.984C294.508 26.248 293.672 26.556 293.056 26.556C292.396 26.556 292.044 26.16 292.044 25.368V2.532C292.044 1.388 291.56 0.904 290.284 0.904H282.056Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M321.118 20.352C321.118 13.708 317.202 9.264 310.162 9.264C303.122 9.264 299.206 13.708 299.206 20.352C299.206 26.996 303.122 31.396 310.162 31.396C317.158 31.396 321.118 26.996 321.118 20.352ZM313.638 20.352C313.638 24.312 312.89 26.468 310.162 26.468C307.434 26.468 306.686 24.312 306.686 20.352C306.686 16.392 307.434 14.192 310.162 14.192C312.89 14.192 313.638 16.392 313.638 20.352Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M344.484 10.672C342.68 9.836 339.996 9.264 337.708 9.264C330.448 9.264 326.048 13.488 326.048 20.396C326.048 27.744 330.36 31.396 337.136 31.396C340.216 31.396 342.724 30.472 344.616 29.284L344.924 24.268C342.9 25.588 340.788 26.38 338.808 26.38C335.332 26.38 333.528 24.224 333.528 20.088C333.528 16.084 335.728 14.148 338.72 14.148C340.612 14.148 342.46 14.808 344.088 15.82L344.484 10.672Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M372.983 25.984C372.631 26.248 371.795 26.556 371.179 26.556C370.519 26.556 370.167 26.16 370.167 25.368V17.184C370.167 14.632 369.595 12.74 368.099 11.464C366.295 9.924 363.787 9.264 360.135 9.264C357.275 9.264 354.195 9.924 352.215 10.716V15.556C354.019 14.632 356.791 13.884 358.991 13.884C360.355 13.884 361.499 14.192 362.071 14.808C362.687 15.468 362.775 16.304 362.775 17.448V18.064C361.499 17.58 359.959 17.316 358.507 17.316C353.711 17.316 350.323 19.736 350.323 24.4C350.323 28.888 353.227 31.396 357.803 31.396C360.267 31.396 362.555 30.472 363.743 28.976C364.315 30.56 365.943 31.352 367.923 31.352C369.727 31.352 371.619 30.78 372.719 30.032L372.983 25.984ZM362.775 24.224C362.775 25.016 362.511 25.72 362.115 26.116C361.763 26.512 361.147 26.776 360.399 26.776C358.815 26.776 357.803 25.808 357.803 23.96C357.803 22.244 358.595 20.968 360.487 20.968C361.411 20.968 362.203 21.232 362.775 21.452V24.224Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M393.215 24.972C391.983 25.676 390.399 26.204 389.167 26.204C387.627 26.204 387.231 25.236 387.231 23.652V14.808H392.027V9.66H387.231V2.928L379.839 4.6V9.66L376.363 10.496V14.808H379.839V23.564C379.839 26.776 380.279 28.536 381.599 29.812C382.699 30.868 384.503 31.396 386.615 31.396C388.859 31.396 391.631 30.692 393.039 29.68L393.215 24.972Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M408.352 4.028C408.352 1.74 406.548 0.112 404.392 0.112C402.236 0.112 400.476 1.784 400.476 4.028C400.476 6.316 402.192 7.988 404.392 7.988C406.548 7.988 408.352 6.316 408.352 4.028ZM408.308 11.288C408.308 10.144 407.824 9.66 406.548 9.66H398.32V14.808H400.916V25.896C400.916 27.612 401.136 28.624 401.664 29.46C402.456 30.736 403.864 31.352 406.152 31.352C407.956 31.352 409.804 30.78 410.904 30.032L411.124 25.984C410.772 26.248 409.936 26.556 409.32 26.556C408.66 26.556 408.308 26.16 408.308 25.368V11.288Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M437.452 20.352C437.452 13.708 433.536 9.264 426.496 9.264C419.456 9.264 415.54 13.708 415.54 20.352C415.54 26.996 419.456 31.396 426.496 31.396C433.492 31.396 437.452 26.996 437.452 20.352ZM429.972 20.352C429.972 24.312 429.224 26.468 426.496 26.468C423.768 26.468 423.02 24.312 423.02 20.352C423.02 16.392 423.768 14.192 426.496 14.192C429.224 14.192 429.972 16.392 429.972 20.352Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M450.081 11.288C449.905 10.012 449.509 9.66 448.233 9.66H441.986V14.808H444.581V31H451.973V18.68C451.973 17.712 452.062 16.7 452.546 15.996C452.986 15.292 453.777 14.764 454.921 14.764C455.933 14.764 456.725 15.248 457.077 15.864C457.429 16.436 457.473 17.184 457.473 18.064V31H464.865V16.92C464.865 14.632 464.557 13.356 463.897 12.256C462.709 10.232 460.333 9.264 457.517 9.264C454.569 9.264 451.753 10.496 450.257 12.652L450.081 11.288Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
                <path
                    d="M489.513 24.268C489.513 16.084 478.513 18.328 478.513 15.644C478.513 14.456 480.097 14.06 481.813 14.06C483.661 14.06 485.949 14.5 487.665 15.204V10.232C485.949 9.616 483.133 9.264 481.197 9.264C476.621 9.264 471.649 11.068 471.649 16.436C471.649 24.664 482.693 22.2 482.693 25.06C482.693 26.072 481.593 26.468 479.789 26.468C477.545 26.468 474.245 25.632 472.353 24.444L472.001 29.9C474.465 30.956 477.765 31.396 480.449 31.396C485.025 31.396 489.513 29.504 489.513 24.268Z"
                    stroke="#4C4C4C"
                    strokeWidth="1"
                />
            </svg>
        </div>
    );
};

export default Intro;

import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import * as actions from '../../store/actions/index';

import { Button } from 'primereact/button';

import { AutocompleteField } from '@jsluna/autocomplete';
import {
    ButtonGroupPrimary,
    ButtonGroupSecondary,
    ButtonGroupWrapper,
    FilledButton,
    IconButton,
    TextButton,
    LinkButton
} from '@jsluna/button';
import { Card } from '@jsluna/card';
import { Container, FlagBody, FlagWrapper, GridItem, GridWrapper } from '@jsluna/grid';
import { Form, RadioButtonField } from '@jsluna/form';
import { ListGroup, ListGroupItem, ListItem } from '@jsluna/list';
import { Modal, ModalHeading } from '@jsluna/modal';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import {
    TableBody, TableCell, TableContainer, TableHeader,
    TableHeaderCell, TableHeaderRow
} from '@jsluna/table';

import { Calendar, Cancel, Delivery, Edit, Plus, StoreLocation, Tick } from '@jsluna/icons';

import Input from '../../components/UI/Input/Input';
import classes from './Schedule.module.scss';
import ScheduleRow from './ScheduleRow/ScheduleRow';
import Confirmation from '../../components/UI/Confirmation/Confirmation';

import Schedules from '../Schedules/Schedules';

import { HHMMTomins, getDayIndex, getDayName, getInvalidText, isValidTime, minsToHHMM, toTitleCase, updateObject, userCanEdit } from '../../shared/utility';

import { Growl } from 'primereact/growl';

import moment from 'moment';
import ScheduleRouteModal from './ScheduleRoute/SetupRoute';

moment.locale('en-GB');

class Schedule extends Component {
    state = {
        scheduleRows: [
            { name: 'AIP Window', heading: true },
            { name: 'Drive Time' },
            { name: 'Depart Time' },
            { name: 'Default Pick Time' },
            { name: 'Override Pick Time', input: true },
            { name: 'Default Xdoc Time' },
            { name: 'Override Xdoc Time', input: true },
            { name: 'NDC Pick Day', input: true },
            { name: 'CDC Pick Day', input: true },
            { name: 'Transport Window', input: true }
        ],
        info: [
            { code: 'name', label: 'Schedule Name' },
            { code: 'activeDate', label: 'Date From' },
            { code: 'deactiveDate', label: 'Date To' }
        ],
        scheduleOptions: null,
        schedule: null,
        currentScheduleId: null,
        weeks: [
            { id: 1, isOpen: false },
            { id: 2, isOpen: false }
        ],
        parameters: {
            driveTime: 0,
            pickBeforeTime: 300,
            xdockBeforeTime: 300,
            loadBeforeDepart: 60
        },
        scheduleDetails: null,
        isOpen: false,
        editMode: false,
        saved: null,
        update: false,
        locationError: false,
        scheduleTypes: null,
        isConfirmationOpen: false,
        isValidSelection: false,
        scheduleTypeId: null,
        // start and end date for current schedule
        endDate: moment().endOf('week'),
        endDateFocused: false,
        startDateFocused: false,
        startDate: moment().startOf('week'),
        isValidDateRange: false,
        isValidStartDate: false,
        isValidEndDate: false,
        // start and end date for new schedule
        newStartDate: moment().startOf('week'),
        newStartDateFocused: false,
        newEndDate: moment().endOf('week'),
        newEndDateFocused: false,
        isValidNewDateRange: false,
        isValidNewStartDate: false,
        isValidNewEndDate: false,
        copySchedule: false,
        originalScheduleId: null,
        touched: {
            startDate: false,
            endDate: false,
            activeDate: false,
            deactiveDate: false
        },
        schedulesView: false,
        schedulesViewLoaded: false,
        yearStartExceptions: null,
        isScheduleRouteModalOpen: false,
        route: [{
            index: 0,
            sourceDepotName: "", 
            sourceDepotId: "",
            destinationDepotName: "",
            destinationDepotId: ""
        }],
        isCreateMode: false
    };

    componentDidMount() {
        let id = +this.props.match.params.id;
        if (isNaN(id)) id = '';

        if (this.props.id !== null) id = this.props.id;

        if (id === this.props.id) this.props.onGetSchedules(id);

        if (!this.props.isAuthenticated) {
            this.props.onSetAuthRedirectPath(`/schedule/${id}`);
            this.props.history.push(`/schedule/${id}`);
        }

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading) {
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
        }

        this.props.onGetScheduleTypes();

        if (this.props.schedules === null && id !== '' && !this.state.schedulesView) this.setLocation(id);

        if (this.props.locations.length === 0) this.props.onGetLocations();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== null && prevProps.id !== this.props.id) this.props.onGetSchedules(this.props.id);

        const schedulesChanged = JSON.stringify(prevProps.schedules) !== JSON.stringify(this.props.schedules);
        const driveTimeChanged = JSON.stringify(prevProps.dc2StoreDriveTimes) !== JSON.stringify(this.props.dc2StoreDriveTimes);

        if (driveTimeChanged) this.setDriveTime();

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading) {
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
        }

        if (!this.props.loadingTypes && this.props.types && this.state.scheduleTypes === null) this.setScheduleTypes();

        if (this.props.schedules !== null && this.state.scheduleOptions === null && schedulesChanged) this.setScheduleOptions();

        if (!this.props.loading && this.props.schedules && schedulesChanged)
            if (
                prevState.scheduleTypeId !== this.state.scheduleTypeId ||
                !prevState.startDate.isSame(this.state.startDate) ||
                !prevState.endDate.isSame(this.state.endDate)
            ) {
                this.checkDateRange();
            }

        /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
        if ((!this.props.saving && this.state.saved) || (!this.props.regenerating && this.props.regenerateSuccess)) {
            if (this.props.saveSuccess || this.props.regenerateSuccess) this.successGrowl(this.props.saveSuccess ? 'save' : 'regenerate');
            else if (this.props.saveError) this.failGrowl();
        }

        if (this.state.update) this.populateState();
    }

    successGrowl(type) {
        let summary = '';
        let detail = '';
        let life = 3000;
        if (type === 'save') {
            summary = 'Schedule Saved';
            detail = 'Schedule saved successfully';
        }
        else {
            summary = 'Schedules Regenerated';
            detail = 'All schedules successfully regenerated. If issues still exist please contact support.';
            life = 6000;
        }

        setTimeout(() => {
            this.growl.clear();
        }, life);

        this.growl.show({ severity: 'success', summary, detail, closable: false, stick: true, life });
        this.setState({ saved: null });
        this.props.onResetSchedule();
        this.props.onResetRegenerateSchedule();
    }

    failGrowl() {
        setTimeout(() => {
            this.growl.clear();
        }, 2000);
        this.growl.show({ severity: 'error', summary: 'Schedule Not Saved', detail: 'Schedule not save, please check and try again' });
        this.setState({ saved: null });
        this.props.onResetSchedule();
        this.props.onResetRegenerateSchedule();
    }

    setDriveTime = () => {
        if (!this.state.schedule) return;
        const dc2StoreDriveTime = this.props.dc2StoreDriveTimes.filter(
            m =>
                moment(m.fromDate.substr(0, 10)) <= moment(this.state.schedule.activeDate) &&
                moment(m.toDate.substr(0, 10)) > moment(this.state.schedule.activeDate)
        )[0];

        let driveTime = 0;
        if (dc2StoreDriveTime) driveTime = dc2StoreDriveTime.driveTime * 60;

        const updatedParameters = updateObject(this.state.parameters, {
            driveTime: driveTime
        });

        this.setState(() => ({ parameters: updatedParameters }));
    };

    setLocation = id => {
        if (this.state.schedulesView) return;

        if (this.state.editMode) {
            this.growl.show({ severity: 'error', summary: 'Edit Mode', detail: 'You can\'t change location whilst in edit mode' });
            return;
        }

        this.setState(
            () => ({
                schedule: null,
                scheduleDetails: null,
                currentScheduleId: null,
                scheduleOptions: null
            }),
            () => {
                this.props.onSetLocation(id);
                if (id === null) window.history.pushState(null, null, '/schedule');
            }
        );
    };

    closeSchedules = () => this.setState(() => ({ schedulesView: false }));

    loadSchedules = (id, wcDate) => {
        return (
            <Fragment key="schedules">
                <Schedules scheduleLocationId={id} scheduleDate={wcDate} click={this.closeSchedules} />
            </Fragment>
        );
    };

    viewClickHandler = () => this.setState(() => ({ schedulesView: true }));

    locationSelectHandler = event => {
        if (!event || !event.value) return;
        this.setLocation(+event.value);
        // use the HTML5 history to change the URL, can't use React history.push as that also forces a reload which we don't need
        window.history.pushState(null, null, `/schedule/${event.value}`);
    };

    populateState = () => {
        const storeDetailsObject = {};
        if (!this.state.schedule) return;

        const schedule = this.state.schedule;
        this.state.info.forEach(info => {
            const type = info.code === 'name' ? 'textarea' : 'datelabelled';
            storeDetailsObject[info.code] = {
                elementType: type,
                elementConfig: {
                    type: type,
                    placeholder: info.code === 'name' ? 'Enter Name' : 'Please Select',
                    label: info.label
                },
                value: info.code === 'name' ? schedule[info.code] : moment(schedule[info.code]).format('DD/MM/YYYY'),
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            };
        });

        /* eslint-disable-next-line no-extra-parens */
        const cycle = (storeDetailsObject['cycle'] = {});

        schedule.cycle.forEach((week, ind) => {
            const type = ['pickTime', 'xdocArrivalTime', 'ndc', 'cdc', 'additionalWindows', 'transportWindow'];

            /* eslint-disable-next-line no-extra-parens */
            const wk = (cycle[`week${ind + 1}`] = {});
            type.forEach((t, index) => {
                // only add the additional window rows once
                if (t === 'additionalWindows' && ind === 0) {
                    const maxAdditionals = week.reduce(
                        /* eslint-disable-next-line no-extra-parens */
                        (max, cycle) => (!cycle && cycle.additionalWindows.length > max ? cycle.additionalWindows.length : max),
                        week[0].additionalWindows.length
                    );

                    const addRemoved = this.state.scheduleRows.filter(x => x.name.indexOf('Additional') === -1);
                    this.setState(
                        () => ({ scheduleRows: Object.values(addRemoved) }),
                        () => {
                            for (let w = 0; w < maxAdditionals; w++) {
                                this.state.scheduleRows.push({
                                    name: `Additional Window ${w + 1}`,
                                    input: true
                                });
                            }
                        }
                    );
                }

                let elementType = 'input';
                let configType = 'time';
                if (t === 'ndc' || t === 'cdc') {
                    elementType = 'checkbox';
                    configType = 'input';
                }

                week.sort((a, b) => a.position - b.position).forEach((day, d) => {
                    let dayIndex = d - day[index === 0 ? 'pickDayOffset' : 'xdocArrivalDayOffset'];
                    dayIndex = dayIndex < 0 ? 7 + dayIndex : dayIndex;

                    let value = day[t];
                    if (t === 'pickTime' || t === 'xdocArrivalTime') value = day[t] === null ? '' : `${getDayName(dayIndex, true)} ${minsToHHMM(day[t])}`;

                    if (t === 'additionalWindows') {
                        for (let w = 0; w < value.length; w++) {
                            wk[`additionalWindow${w + 1}Week${ind + 1}Day${d + 1}`] = {
                                elementType: elementType,
                                elementConfig: {
                                    type: configType,
                                    placeholder: 'type info'
                                },
                                value: value[w].window,
                                validation: {
                                    required: true
                                },
                                valid: true,
                                windowValid: true,
                                touched: false,
                                window: day.window
                            };
                        }
                    }
                    else {
                        wk[`${t}Week${ind + 1}Day${d + 1}`] = {
                            elementType: elementType,
                            elementConfig: {
                                type: configType,
                                placeholder: 'type info'
                            },
                            value: value,
                            validation: {
                                required: true
                            },
                            valid: true,
                            windowValid: true,
                            touched: false,
                            window: day.window
                        };
                    }
                });
            });
        });

        let driveTime = 0;
        if (schedule.driveTime) driveTime = schedule.driveTime;

        if (!schedule.driveTime && this.props.dc2StoreDriveTimes !== null && this.props.dc2StoreDriveTimes.length > 0) {
            driveTime = this.props.dc2StoreDriveTimes[0].driveTime;
        }

        const updatedParameters = updateObject(this.state.parameters, {
            driveTime: driveTime * 60
        });

        this.setState(
            () => ({
                scheduleDetails: storeDetailsObject,
                startDate: moment(schedule.activeDate),
                endDate: moment(schedule.deactiveDate),
                parameters: updatedParameters,
                update: false
            }),
            () => {
                this.checkDateRange();
            }
        );
    };

    getSelected = () => {
        if ((this.props.locations || []).length > 0 && this.props.id !== null) {
            const option = this.props.locations.filter(m => m.id === this.props.id)[0];
            if (!option) {
                window.history.pushState(null, null, '/schedule');
                return 'error';
            }

            return {
                value: option.id.toString(),
                label: `${option.locationNo} ${option.locationName}`
            };
        }
    };

    viewLocation = () => {
        this.props.history.push(`/location/${this.props.id !== null ? this.props.id : this.props.match.params.id}`);
    };

    setScheduleOptions = () => {
        let newOptions = [];
        if (this.props.schedules !== null) {
            let selectedSchedule = null;
            let currentScheduleId = this.state.currentScheduleId;
            if (!Object.values(this.props.schedules).filter(m => m.id === currentScheduleId)[0]) {
                this.setState({ scheduleOptions: null });
                currentScheduleId = null;
            }

            if (currentScheduleId === undefined || currentScheduleId === null) {
                const currentSchedule = Object.values(this.props.schedules).filter(
                    m => moment(m.activeDate) <= moment().startOf('week') && moment(m.deactiveDate) > moment().startOf('week')
                )[0];

                if (currentSchedule) currentScheduleId = +currentSchedule.id;
                else currentScheduleId = +Object.values(this.props.schedules)[0].id;
            }
            else currentScheduleId = +currentScheduleId;

            Object.keys(this.props.schedules).forEach((m, ind) => {
                const schedule = this.props.schedules[m];
                if (schedule.locationId !== this.props.id) return;
                const name = schedule.name.split(schedule.scheduleTypeName);
                newOptions.push({
                    value: schedule.id.toString(),
                    label: (
                        <Fragment>
                            {name[0].trim()}
                            <br />
                            {schedule.scheduleTypeName} {name[1]}
                        </Fragment>
                    ),
                    defaultChecked: currentScheduleId === undefined || currentScheduleId === null ? ind === 0 : schedule.id === currentScheduleId,
                    activedate: moment(schedule.activeDate).format('YYYY-MM-DD'),
                    deactivedate: moment(schedule.deactiveDate).format('YYYY-MM-DD')
                });

                // eslint-disable-next-line no-extra-parens
                if (schedule.id === currentScheduleId) selectedSchedule = schedule;
            });

            newOptions = newOptions.sort((a, b) => moment(b.activedate) - moment(a.activedate));

            if (newOptions.length > 0 && selectedSchedule !== null) {
                this.setState(
                    () => ({
                        schedule: selectedSchedule,
                        currentScheduleId: selectedSchedule.id,
                        scheduleOptions: Object.values(newOptions),
                        route: selectedSchedule.route
                    }),
                    () => this.populateState()
                );
            }
        }
        else {
            if (this.state.scheduleOptions === null)
                this.setState({
                    schedule: null,
                    currentScheduleId: null,
                    scheduleDetails: null,
                    scheduleOptions: null,
                    editMode: false
                });
        }
    };

    createNewSchedule = () => {
        if (this.state.editMode) {
            // edit the selected schedule
            const schedule = this.state.schedule;
            // first get the original type to split the name
            let scheduleTypeName = this.state.scheduleTypes.filter(m => m.value === this.state.schedule.scheduleTypeId)[0].label;
            const name = schedule.name.split(scheduleTypeName);
            // now get the new name from the schedule selected
            scheduleTypeName = this.state.scheduleTypes.filter(m => m.value === this.state.scheduleTypeId)[0].label;
            const newName = `${name[0].trim()} ${scheduleTypeName} ${moment(this.state.startDate).format('DD/MM/YYYY')}`;

            const updatedOption = updateObject(this.state.scheduleOptions[this.state.currentScheduleId.toString()], {
                value: this.state.currentScheduleId.toString(),
                label: (
                    <Fragment>
                        {name[0].trim()}
                        <br />
                        {scheduleTypeName} {moment(this.state.startDate).format('DD-MM-YYYY')}
                    </Fragment>
                ),
                defaultChecked: true,
                name: newName,
                activedate: moment(this.state.startDate).format('YYYY-MM-DD'),
                deactivedate: moment(this.state.endDate).format('YYYY-MM-DD')
            });

            const ind = this.state.scheduleOptions.findIndex(m => m.value === this.state.currentScheduleId.toString());

            const updatedOptions = updateObject(this.state.scheduleOptions, {
                [ind]: updatedOption
            });

            const updatedScheduleDetails = updateObject(this.state.scheduleDetails, {
                name: {
                    elementType: 'textarea',
                    elementConfig: {
                        type: 'textarea',
                        placeholder: 'Enter Name',
                        label: 'Schedule Name'
                    },
                    value: newName,
                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                }
            });

            const updatedSchedule = updateObject(this.state.schedule, {
                name: newName,
                activeDate: this.state.startDate,
                deactiveDate: this.state.endDate,
                scheduleTypeId: this.state.scheduleTypeId,
                scheduleTypeName: this.state.scheduleTypes[+this.state.scheduleTypeId - 1].label
            });

            this.setState(
                () => ({
                    isOpen: false,
                    scheduleOptions: Object.values(updatedOptions),
                    scheduleDetails: updatedScheduleDetails,
                    schedule: updatedSchedule,
                    scheduleTypeId: null,
                    saved: false
                }),
                () => {
                    this.growl.show({
                        severity: 'info',
                        summary: 'Schedule Updated',
                        detail: 'Click Save to update the Database',
                        closable: false,
                        life: 5000
                    });
                }
            );

            return;
        }

        // create a new schedule
        this.props.onGetDC2StoreDriveTimes(this.props.id);

        const location = this.props.locations.filter(m => m.id === this.props.id)[0];
        const typeId = +this.state.scheduleTypeId;
        const id = 0;

        if (!location) {
            alert('Location Not Found!');
            return;
        }

        let week = [];
        if (this.state.copySchedule) {
            for (let i = 0; i < this.state.scheduleDetails.cycle.length; i++) {
                week = week.concat(this.state.scheduleDetails.cycle[i]);
            }
        }
        else {
            [1, 2, 3, 4, 5, 6, 7].forEach(d =>
                week.push({
                    dailyPatternId: 3,
                    pickDayOffset: null,
                    pickTime: null,
                    position: d,
                    window: 'No Delivery',
                    xdocArrivalDayOffset: null,
                    xdocArrivalTime: null,
                    additionalWindows: []
                })
            );
            week = [week];
        }

        const typeName = this.state.scheduleTypes[typeId - 1].label;
        const scheduleName = `${location.locationName} ${typeName} ${this.state.newStartDate.format('DD/MM/YYYY')}`;
        const schedule = {
            id: id,
            locationId: location.id,
            name: scheduleName,
            activeDate: this.state.newStartDate.format('YYYY-MM-DD'),
            deactiveDate: this.state.newEndDate.format('YYYY-MM-DD'),
            isActive: this.state.newStartDate <= moment().startOf('week') && this.state.newEndDate >= moment().endOf('week'),
            startPos: 1,
            cycle: week,
            scheduleTypeId: typeId,
            scheduleTypeName: typeName
        };

        const storeDetailsObject = {};

        this.state.info.forEach(info => {
            const type = info.code === 'name' ? 'textarea' : 'datelabelled';
            storeDetailsObject[info.code] = {
                elementType: type,
                elementConfig: {
                    type: type,
                    placeholder: info.code === 'name' ? 'Enter Name' : 'Please Select',
                    label: info.label
                },
                value: info.code === 'name' ? schedule[info.code] : moment(schedule[info.code]).format('YYYY-MM-DD'),
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            };
        });

        /* eslint-disable-next-line no-extra-parens */
        const cycle = (storeDetailsObject['cycle'] = {});

        schedule.cycle.forEach((week, ind) => {
            const type = ['pickTime', 'xdocArrivalTime', 'ndc', 'cdc', 'transportWindow'];
            /* eslint-disable-next-line no-extra-parens */
            const wk = (cycle['week' + (ind + 1)] = {});
            type.forEach(t => {
                week.sort((a, b) => a.position - b.position).forEach(day => {
                    wk[`${t}Week${ind + 1}Day${day.position}`] = {
                        elementType: 'input',
                        elementConfig: {
                            type: 'time',
                            placeholder: 'type info'
                        },
                        value: day[t],
                        validation: {
                            required: true
                        },
                        valid: true,
                        windowValid: true,
                        touched: false,
                        window: day.window
                    };
                });
            });
        });

        let ind = 0;
        let currentIndex = 0;
        let updatedOption = null;
        let updatedOptions = null;

        if (this.state.scheduleOptions) {
            ind = this.state.scheduleOptions.length;
            currentIndex = this.state.scheduleOptions.findIndex(m => m.value === this.state.currentScheduleId.toString());

            updatedOption = updateObject(this.state.scheduleOptions[currentIndex], {
                defaultChecked: false
            });

            updatedOptions = updateObject(this.state.scheduleOptions, {
                [currentIndex]: updatedOption
            });

            updatedOption = updateObject(this.state.scheduleOptions[id.toString()], {
                value: id.toString(),
                label: (
                    <Fragment>
                        {location.locationName.trim()}
                        <br />
                        {schedule.scheduleTypeName} {moment(this.state.newStartDate).format('DD/MM/YYYY')}
                    </Fragment>
                ),
                defaultChecked: true,
                activedate: moment(this.state.newStartDate).format('YYYY-MM-DD'),
                deactivedate: moment(this.state.newEndDate).format('YYYY-MM-DD')
            });
        }
        else {
            updatedOption = {
                value: id.toString(),
                label: (
                    <Fragment>
                        {location.locationName.trim()}
                        <br />
                        {schedule.scheduleTypeName}
                        {moment(this.state.newStartDate).format('DD/MM/YYYY')}
                    </Fragment>
                ),
                defaultChecked: true,
                activedate: moment(this.state.newStartDate).format('YYYY-MM-DD'),
                deactivedate: moment(this.state.newEndDate).format('YYYY-MM-DD')
            };
        }

        updatedOptions = updateObject(updatedOptions, {
            [ind === 0 ? 0 : this.state.scheduleOptions.length]: updatedOption
        });

        updatedOptions = Object.values(updatedOptions).sort((a, b) => moment(a.activedate) - moment(b.activedate));

        this.setState(
            () => ({
                scheduleDetails: storeDetailsObject,
                schedule,
                isOpen: false,
                currentScheduleId: id,
                startDate: this.state.newStartDate,
                endDate: this.state.newEndDate,
                scheduleOptions: Object.values(updatedOptions),
                editMode: true,
                saved: false
            }),
            () => {
                this.populateState();
                this.growl.show({
                    severity: 'info',
                    summary: 'Schedule Added',
                    detail: 'Click Save to update the Database',
                    closable: false,
                    stick: true,
                    life: 5000
                });
            }
        );
    };

    saveSchedule = () => {
        const { cycle } = this.state.scheduleDetails;

        let translated = {};
        const cycleArr = [];
        Object.keys(cycle).forEach((week, wkIndex) => {
            Object.keys(cycle[week]).forEach((px, ind) => {
                const obj = cycle[week][px];
                let dayIndex = 0;
                let dayOffset = 0;

                const field = px.substr(0, px.indexOf('Week'));
                const info = px.replace(field, '');
                const wk = +week.replace('week', '');
                const day = +info.substr(info.indexOf('Day') + 3, 2);

                // get the array index - taking into account if the weeks have been reversed.
                const arrIndex = (wk - 1) * 7 + day - 1;

                if (px.substr(0, 4) === 'pick' || px.substr(0, 4) === 'xdoc') {
                    dayIndex = getDayIndex(obj.value.substr(0, 3), true);
                    if (dayIndex > -1) dayIndex += wkIndex * 7;
                    dayOffset = dayIndex === -1 ? 0 : arrIndex - dayIndex;
                    if (dayOffset < 0) dayOffset += 7;
                }

                if (px.substr(0, 4) === 'pick') {
                    cycleArr.push({
                        ...obj,
                        position: wkIndex * 7 + ind + 1,
                        window: obj.window,
                        pickTime: HHMMTomins(obj.value.substr(4)),
                        pickDayOffset: obj.value === '' ? null : dayOffset
                    });
                }
                else if (px.substr(0, 4) === 'xdoc') {
                    cycleArr[arrIndex]['xdocArrivalTime'] = HHMMTomins(obj.value.substr(4));
                    cycleArr[arrIndex]['xdocArrivalDayOffset'] = obj.value === '' ? null : dayOffset;
                }
                else if (px.substr(1, 2) === 'dc') {
                    console.log(arrIndex, px.substr(0, 3), obj.value);
                    cycleArr[arrIndex][px.substr(0, 3)] = obj.value;
                }
                else if (px.substr(0, 10) === 'additional') {
                    if (!cycleArr[arrIndex]['additionalWindows']) cycleArr[arrIndex]['additionalWindows'] = [];
                    const offset = (wk - 1) * 7;
                    cycleArr[arrIndex]['additionalWindows'].push({
                        delivery: +field.replace('additionalWindow', '') + 1,
                        position: day + offset,
                        window: obj.value
                    });
                }
                else if (px.substr(0, 4) === 'tran') {
                    cycleArr[arrIndex]['transportWindow'] = obj.value === '' ? null : obj.value;
                }
            });
        });

        const scheduleName = this.state.schedule.name;
        const scheduleTypeName = this.state.scheduleTypes.filter(m => m.value === this.state.schedule.scheduleTypeId)[0].label;

        translated = updateObject(translated, {
            ...this.state.schedule,
            activeDate: moment(this.state.schedule.activeDate).format('YYYY-MM-DD'),
            cycle: [cycleArr],
            deactiveDate: moment(this.state.schedule.deactiveDate).format('YYYY-MM-DD'),
            id: this.state.currentScheduleId,
            name: scheduleName,
            scheduleTypeId: this.state.schedule.scheduleTypeId,
            scheduleTypeName: scheduleTypeName,
            originalScheduleId: this.state.originalScheduleId,
            route: this.state.route
        });

        let originalSchedule = null;

        if (this.props.schedules) {
            const originalScheduleName = Object.keys(this.props.schedules).filter(m => this.props.schedules[m].id === +this.state.currentScheduleId);
            originalSchedule = this.props.schedules[originalScheduleName];
        }

        let currentScheduleId = this.state.currentScheduleId;
        if (cycleArr.length === 0) currentScheduleId = null;

        const scheduleChanges = this.getScheduleDifferences(originalSchedule, translated);

        const scheduleWithChanges = updateObject(translated, {
            changes: scheduleChanges
        });

        if(!this.isRouteValid(scheduleWithChanges) && this.state.isCreateMode)
        {
            this.growl.show({
                severity: 'error',
                summary: 'Route not set',
                detail: 'Please set up route to proceed',
                closable: false,
                life: 5000
            });

            return;
        }

        this.setState(
            () => ({
                saved: true,
                schedule: null,
                editMode: false,
                scheduleOptions: null,
                currentScheduleId,
                scheduleDetails: null,
                isCreateMode: false
            }),
            () => {
                this.props.onSaveSchedule(scheduleWithChanges);
            }
        );
    };

    isRouteValid = (scheduleWithChanges) => {
        if(scheduleWithChanges.route.length === 0){
            return false;
        }

        const result = scheduleWithChanges.route.filter(element => {
            if(element.sourceDepotName.length === 0 || element.sourceDepotId.length === 0
              || element.destinationDepotName.length === 0 || element.destinationDepotId.length === 0 ){
                return element;
              }
          });

          if(result.length === 0){
            return true;
          }
          else{
            return false;
          }
    };

    getScheduleDifferences = (obj1, obj2) => {
        const diffs = {};
        const changes = [];
        let key;

        // if obj1 is undefined then we are adding a new schedule so don't record each day change
        if (!obj1) {
            changes.push({ type: 'scheduleCreation', key: 'newSchedule', dayNo: -1, oldValue: null, newValue: obj2.name });
            diffs['scheduleCreation'] = { oldValue: null, newValue: obj2.name };
            return changes;
        }

        // if obj2 cycle length is 0 then we are deleting the schedule
        if (obj2 && obj2.cycle[0].length === 0) {
            changes.push({ type: 'scheduleDeletion', key: 'deleteSchedule', dayNo: -1, oldValue: obj1.name, newValue: null });
            diffs['scheduleDeletion'] = { oldValue: obj1.name, newValue: null };
            return changes;
        }

        // if obj1.cycle[1] is undefined and obj2 cycle length is 14 then we are adding a new week
        if (obj1 && !obj1.cycle[1] && obj2 && obj2.cycle[0].length === 14) {
            changes.push({ type: 'addWeek', key: 'addWeek', dayNo: -1, oldValue: null, newValue: null });
            diffs['addWeek'] = { oldValue: null, newValue: null };
            return changes;
        }

        const compareArrays = (wk, arr1, arr2) => {
            // check the items in the cycle array that can change
            // loop through each key that can change and compare the cycles
            const keys = [
                'window', 'pickTime', 'pickDayOffset', 'xdocArrivalTime', 'xdocArrivalDayOffset', 'ndc', 'cdc', 'transportWindow', 'additionalWindows'
            ];
            for (let d = 0; d < 7; d++) {
                keys.forEach(key => {
                    if (compareCycle(key, wk + 1, wk * 7 + d + 1, !arr1 ? arr1 : arr1[d], !arr2 ? arr2 : arr2[wk * 7 + d])) {
                        d = 7;
                        return;
                    }
                });
            }
        };

        const compareCycle = (key, wk, day, arr1, arr2) => {
            if (key === 'additionalWindows') {
                let array1 = null;
                let array2 = null;
                // loop throught the additional windows
                // eslint-disable-next-line no-mixed-operators, no-extra-parens
                if ((arr1[key] && !arr2[key]) || arr1[key].length > arr2[key].length) {
                    array1 = arr1[key];
                    array2 = !arr2[key] ? null : arr2[key];
                    if (array2 === null || array2.length < array1.length) {
                        for (let a = 0; a < array1.length - (array2 === null ? 0 : array2.length); a++) {
                            changes.push({
                                type: 'additionalWindow',
                                key: 'deleteWindow',
                                dayNo: -1,
                                oldValue: null,
                                newValue: `Additional Window ${arr1[key][arr1[key].length - 1].delivery - 1} Deleted`
                            });
                            diffs['additionalWindowDeleted'] = { oldValue: null, newValue: null };
                        }
                        return true;
                    }
                }
                else {
                    array1 = !arr1[key] ? null : arr1[key];
                    array2 = !arr2[key] ? null : arr2[key];
                    if (array2.length > array1.length) {
                        for (let a = 0; a < array2.length - array1.length; a++) {
                            changes.push({
                                type: 'additionalWindow',
                                key: 'addWindow',
                                dayNo: -1,
                                oldValue: null,
                                newValue: `Additional Window ${arr2[key][arr2[key].length - 1].delivery - 1} Added`
                            });
                            diffs['additionalWindowCreated'] = { oldValue: null, newValue: null };
                        }
                        return true;
                    }
                }

                // if the arrays are the same length then check the daily values for a change
                array1.forEach((c, ind) => {
                    const value1 = c.window;
                    const value2 = !array2 || !array2[ind] ? null : array2[ind].window;
                    if (!array2 || value1 !== value2) {
                        const oldValue = !arr1 ? null : value1;
                        const newValue = !arr2 ? null : value2;
                        const key = `additionalWindow${c.delivery - 1}`;
                        if (oldValue !== newValue) {
                            changes.push({ type: 'additionalWindow', key, dayNo: c.position - 1, oldValue, newValue });
                            diffs[`additionalWindow${c.delivery - 1}Week${wk}Day${day}`] = { oldValue: oldValue, newValue: newValue };
                        }
                    }
                });
            }
            else {
                // eslint-disable-next-line no-mixed-operators, no-extra-parens
                if ((arr1 && !arr2) || (!arr1 && arr2) || arr1[key] !== arr2[key]) {
                    let oldValue = !arr1 ? null : arr1[key];
                    let newValue = !arr2 ? null : arr2[key];
                    if (oldValue !== newValue) {
                        if (key === 'ndc' || key === 'cdc') {
                            oldValue = oldValue ? 'Pick Day' : 'Non Pick Day';
                            newValue = newValue ? 'Pick Day' : 'Non Pick Day';
                        }
                        changes.push({ type: 'cycle', key, dayNo: day, oldValue, newValue });
                        diffs[`${key}Week${wk}Day${day}`] = { oldValue: oldValue, newValue: newValue };
                    }
                }
            }
        };

        const compare = (item1, item2, key) => {
            // Get the object type
            const type1 = Object.prototype.toString.call(item1);
            const type2 = Object.prototype.toString.call(item2);

            // If type2 is undefined it has been removed
            if (type2 === '[object Undefined]') {
                diffs[key] = { oldValue: !obj1 ? null : obj1[key], newValue: null };
                return;
            }

            // If an array it must be the cycle, compare the inner arrays
            if (type1 === '[object Array]' && key === 'cycle') {
                if (item2)
                    if (item2[0].length < item1.length * 7 && item1.length === 2) {
                        changes.push({ type: 'weekDeleted', key: 'deleteWeek', dayNo: -1, oldValue: null, newValue: null });
                        diffs['weekDeleted'] = { oldValue: null, newValue: null };
                        return;
                    }
                const weeks = item1.length < item2.length ? item2.length : item1.length;
                for (let wk = 0; wk < weeks; wk++) {
                    compareArrays(wk, item1[wk], item2[0]);
                }
                return;
            }

            // If an array it must be the cycle, compare the inner arrays
            if (type1 === '[object Array]' && key === 'cycle') {
                if (item2[0].length < item1.length * 7 && item1.length === 2) {
                    changes.push({ type: 'weekDeleted', key: 'deleteWeek', dayNo: -1, oldValue: null, newValue: null });
                    diffs['weekDeleted'] = { oldValue: null, newValue: null };
                    return;
                }

                const weeks = item1.length < item2.length ? item2.length : item1.length;
                for (let wk = 0; wk < weeks; wk++) {
                    compareArrays(wk, item1[wk], item2[0]);
                }
                return;
            }
        };

        // Loop through the first object
        for (key in obj1) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj1.hasOwnProperty(key)) compare(obj1[key], !obj2 ? null : obj2[key], key);
        }

        // Loop through the second object and find missing items
        for (key in obj2) {
            if (key.indexOf('cycle') > -1) continue;
            // eslint-disable-next-line no-prototype-builtins
            if (obj2.hasOwnProperty(key)) {
                if (key.indexOf('Date') > -1) {
                    // eslint-disable-next-line no-mixed-operators, no-extra-parens
                    if ((!obj1 && obj2) || (obj1 && !obj2) || moment(obj1[key].substr(0, 10)).diff(obj2[key].substr(0, 10), 'days')) {
                        changes.push({
                            type: 'header',
                            key: key,
                            dayNo: -1,
                            oldValue: !obj1 ? null : obj1[key].substr(0, 10),
                            newValue: obj2[key].substr(0, 10)
                        });
                        diffs[key] = { old: !obj1 ? null : obj1[key], new: obj2[key] };
                    }
                }
                else {
                    // eslint-disable-next-line no-mixed-operators, no-extra-parens
                    if ((!obj1 && obj2) || (obj1 && !obj2) || obj1[key] !== obj2[key]) {
                        // we don't need to send the changes to the schedule type as this information will be captured with the name change
                        if (key === 'scheduleTypeId' || key === 'scheduleTypeName') continue;

                        changes.push({ type: 'header', key: key, dayNo: -1, oldValue: !obj1 ? null : obj1[key], newValue: obj2[key] });
                        diffs[key] = { old: !obj1 ? null : obj1[key], new: obj2[key] };
                    }
                }
            }
        }

        if (this.state.copySchedule) {
            changes.originalScheduleId = this.state.currentScheduleId;
        }
        // return the differences
        return changes;
    };

    handleDetailViewToggle = id => {
        const weeks = this.state.weeks;
        weeks.forEach(week => {
            if (week.id === id) week.isOpen = !week.isOpen;
            else week.isOpen = false;
        });
        this.setState({ weeks: weeks });
    };

    inputChangedHandler = (event, field, week, objectName) => {
        const obj = this.state.scheduleDetails;
        let updatedFormElement = null;
        let updatedRecordInfo = null;

        let updateField = 'value';
        let validField = 'valid';
        let validationType = 'dayTimeValid';

        let value = event.target.value;

        if (objectName === 'AIP Window' || objectName.substr(0, 10) === 'Additional' || objectName === 'Transport Window') {
            if (objectName === 'AIP Window') updateField = 'window';

            validField = 'windowValid';
            validationType = validField;

            if (
                /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
                (event.target.value === '' && 'No Delivery'.indexOf(obj['cycle'][week][field].window) === -1) ||
                /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
                (event.target.value === '' && obj['cycle'][week][field].window === 'No Delivery')
            ) {
                event.target.value = 'No Delivery';
                event.target.select();
            }
        }

        if (week) {
            let updatedWeekInfo;

            if (objectName === 'AIP Window' || objectName.substr(0, 10) === 'Additional' || objectName === 'Transport Window') {
                const updField = field.replace('pickTime', '');

                const fieldsToUpdate = Object.keys(obj['cycle'][week]).filter(m => m.indexOf(updField) > -1);

                const objToUpdate = {};

                fieldsToUpdate.forEach(f => {
                    objToUpdate[f] = updateObject(obj['cycle'][week][f], {
                        [updateField]: event.target.value,
                        [validField]: isValidTime(event.target.value, true, validationType),
                        valid: isValidTime(event.target.value, true, validationType),
                        touched: true
                    });
                    if ((f.substring(0, 3) === 'cdc' || f.substring(0, 3) === 'ndc') && event.target.defaultValue === 'No Delivery') {
                        objToUpdate[f] = updateObject(objToUpdate[f], {
                            value: true
                        });
                    }
                });

                updatedWeekInfo = updateObject(obj['cycle'][week], objToUpdate);
            }
            else {
                if (event.target.selectionStart === 3) value = toTitleCase(value);

                let valid;
                if (field.substr(0, 3) === 'ndc' || field.substr(0, 3) === 'cdc') {
                    value = value !== '1';
                    valid = true;
                }
                else valid = isValidTime(value, true, validationType);

                const updatedFormElement = updateObject(obj['cycle'][week][field], {
                    [updateField]: value,
                    [validField]: valid,
                    touched: true
                });

                updatedWeekInfo = updateObject(obj['cycle'][week], {
                    [field]: updatedFormElement
                });
            }

            const updatedCycleInfo = updateObject(obj['cycle'], {
                [week]: updatedWeekInfo
            });

            updatedRecordInfo = updateObject(obj, {
                cycle: updatedCycleInfo
            });
        }
        else {
            updatedFormElement = updateObject(obj[field], {
                value: event.target.value,
                valid: true,
                touched: true
            });

            updatedRecordInfo = updateObject(obj, {
                [field]: updatedFormElement
            });
        }

        this.setState({ scheduleDetails: updatedRecordInfo });
    };

    checkInvalidText = inputText => {
        const t = getInvalidText(inputText);
        let msg = null;

        if (t) msg = `${t} is not permitted`;

        return msg;
    };

    onChangeScheduleHandler = event => {
        if (this.state.editMode) {
            this.growl.show({ severity: 'warn', summary: 'Edit Mode', detail: 'You can\'t change schedule whilst in edit mode' });
            event.preventDefault();
            return;
        }

        const scheduleName = Object.keys(this.props.schedules).filter(m => this.props.schedules[m].id === +event.currentTarget.value);
        const schedule = this.props.schedules[scheduleName];

        if (this.state.currentScheduleId !== schedule.id) {
            this.setState({
                schedule: schedule,
                currentScheduleId: schedule.id,
                update: true,
                route: schedule.route
            });
        }
    };

    changeSchedule = id => {
        const scheduleName = Object.keys(this.props.schedules).filter(m => this.props.schedules[m].id === +id);
        const schedule = this.props.schedules[scheduleName];

        if (this.state.currentScheduleId !== schedule.id) {
            this.setState({
                schedule: schedule,
                currentScheduleId: schedule.id,
                update: true
            });
        }
    };

    typeChangeHandler = event => {
        let typeId = +event.target.value;
        const valid = this.state.scheduleTypes.filter(m => m.value === typeId).length > 0;
        if (!valid) typeId = -1;

        this.setState({
            isValidSelection: valid,
            scheduleTypeId: typeId
        });
    };

    focusedHandler = (event, identifier) => this.setState({ [`${identifier}Focused`]: event.focused });

    onFocusedHandler = event => event.currentTarget.select();

    dateChangedHandler = (event, identifier) => {
        const touched = updateObject(this.state.touched, { [identifier]: true });
        this.setState(
            () => ({ [identifier]: event, touched }),
            () => this.validateAll(identifier.substr(0, 3) === 'new' ? 'new' : '')
        );
    };

    validateAll = type => {
        const datesValid = this.checkDateRange(type);
        if (!this.state.schedule) return;

        const allValid = datesValid && this.state.scheduleTypes.filter(m => m.value === this.state.schedule.scheduleTypeId).length > 0;
        this.setState(() => ({ isValidSelection: allValid }));
    };

    checkDateRange = type => {
        let valid = true;
        let start, end;

        if (this.state.newStartDate === null || this.state.newEndDate === null) return;

        if (type === 'new') {
            start = this.state.newStartDate.startOf('day');
            end = this.state.newEndDate.startOf('day');
        }
        else {
            start = this.state.startDate.startOf('day');
            end = this.state.endDate.startOf('day');
        }

        if (start.isAfter(end.startOf('day')) || start.isBefore(moment('2019-01-06'))) valid = false;

        let validStart = true,
            validEnd = false;
        if (type === 'new') {
            validStart = this.state.newStartDate.format('dddd') === 'Sunday';
            validEnd = this.state.newEndDate.format('dddd') === 'Saturday';
        }
        else {
            validStart = this.state.startDate.format('dddd') === 'Sunday';
            validEnd = this.state.endDate.format('dddd') === 'Saturday';
        }

        this.setState({
            isValidDateRange: valid,
            isValidStartDate: validStart,
            isValidEndDate: validEnd
        });

        return valid && validStart && validEnd;
    };

    getErrorMessage = type => {
        let errorMessage = '';

        if (!this.state.isValidDateRange) errorMessage = 'Invalid date range';

        if (!this.state[`isValid${type}`]) errorMessage = `Must be a ${type === 'StartDate' ? 'Sunday' : 'Saturday'}`;

        return errorMessage;
    };

    setScheduleTypes() {
        const scheduleTypes = this.props.types.map(type => ({ label: type.name, value: type.id }));
        this.setState({ scheduleTypes: scheduleTypes });
    }

    openModal = type => {
        if (this.state.editMode && type !== 'edit') {
            this.growl.show({ severity: 'warn', summary: 'Edit Mode', detail: 'You can\'t add a new schedule whilst in edit mode' });
            return;
        }

        if (this.state.editMode) this.setState({ isOpen: true, scheduleTypeId: this.state.schedule.scheduleTypeId });
        else this.setState({ isOpen: true, route: [{
            index: 0,
            sourceDepotName: "", 
            sourceDepotId: "",
            destinationDepotName: "",
            destinationDepotId: ""
        }],
        isCreateMode: true });

        this.checkDateRange();
    };

    openModalCopy = type => {
        if (this.state.editMode && type !== 'edit') {
            this.growl.show({ severity: 'warn', summary: 'Edit Mode', detail: 'You can\'t add a new schedule whilst in edit mode' });
            return;
        }

        if (this.state.editMode)
            this.setState({
                isOpen: true,
                scheduleTypeId: this.state.schedule.scheduleTypeId,
                copySchedule: true,
                originalScheduleId: this.state.currentScheduleId
            });
        else
            this.setState({ isOpen: true, copySchedule: true, originalScheduleId: this.state.currentScheduleId });

        this.checkDateRange();
    }

    closeModal = type => {
        if (type === 'cancel') {
            this.populateState();
            this.setState({ editMode: false, originalScheduleId: null });
        }
        this.setState({ isOpen: false, originalScheduleId: null });
    };

    toggleEditMode = action => {
        if (this.state.editMode && this.state.saved === false) {
            this.setState({ isConfirmationOpen: true });
            return;
        }

        if (action === 'cancel') {
            this.populateState();
            this.growl.clear();
        }

        if(this.state.editMode){
            this.setState({ isCreateMode: false });
        }

        this.setState({
            editMode: !this.state.editMode
        });
    };

    addWeek = () => {
        const obj = this.state.scheduleDetails;

        let currentWeeks = 0;
        let cycleObject = {};

        if (obj.cycle) {
            currentWeeks = Object.keys(obj.cycle).length;
            cycleObject = obj.cycle;
        }

        if (currentWeeks === 2) {
            this.growl.show({ severity: 'error', summary: 'Max Weeks', detail: 'You can only have a max of 2 weeks.' });
            return;
        }

        const types = ['pickTime', 'xdocArrivalTime', 'ndc', 'cdc', 'transportWindow'];
        /* eslint-disable-next-line no-extra-parens */
        const cycle = (obj['cycle'] = cycleObject);

        for (let wk = 2; wk > currentWeeks; wk--) {
            /* eslint-disable-next-line no-extra-parens */
            const week = (cycle[`week${wk}`] = {});
            types.forEach(type => {
                [1, 2, 3, 4, 5, 6, 7].forEach(day => {
                    week[`${type}Week${wk}Day${day}`] = {
                        elementType: 'input',
                        elementConfig: {
                            type: 'time',
                            placeholder: 'type info'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: true,
                        windowValid: true,
                        touched: false,
                        window: 'No Delivery'
                    };
                });
            });
        }

        const updatedCycle = updateObject(obj, {
            cycle: cycle
        });

        this.setState({ scheduleDetails: updatedCycle });

        this.growl.show({ severity: 'info', summary: 'Week 2 Added', detail: 'Click Save to update the Database', closable: false, life: 5000 });
    };

    deleteAdditionalWindow = e => {
        const obj = this.state.scheduleDetails;

        let additional = e.toLowerCase();
        additional = additional.replace(/\s/g, '');

        if (!obj.cycle) return;

        let updatedCycle = updateObject(this.state.scheduleDetails.cycle, {

        });
        // remove all the additional windows from schedule rows
        const addRemoved = this.state.scheduleRows.filter(x => x.name.indexOf('Additional') === -1);
        this.setState(
            () => ({ scheduleRows: Object.values(addRemoved) }),
            () => {
                const additionals = [];
                let delivery = 1;

                // remove the additional window row from the object
                Object.keys(obj.cycle).forEach(m => {
                    const updatedAdditionals = {};
                    Object.keys(obj.cycle[m]).forEach(a => {
                        if (a.toLowerCase().indexOf(additional) > -1) delete obj.cycle[m][a];
                        else if (a.toLowerCase().indexOf('additionalwindow') > -1) {
                            // store the contents of the current additional
                            const currentAdditional = obj.cycle[m][a];
                            // store the additional name
                            const additionalName = a.substr(0, a.indexOf('Week'));
                            // get the weekXDayX as this wont change
                            const weekDay = a.replace(additionalName, '');
                            // get the current delivery number

                            if (!additionals.find(m => m.name === additionalName)) {
                                additionals.push({
                                    name: additionalName,
                                    delivery: delivery
                                });
                                this.state.scheduleRows.push({
                                    name: `Additional Window ${delivery}`,
                                    input: true
                                });
                            }

                            // delete the current additional
                            delete obj.cycle[m][a];

                            const thisDelivery = additionals.find(m => m.name === additionalName).delivery;
                            // add the updated additional delivery with the new delivery count
                            updatedAdditionals[`additionalWindow${thisDelivery}${weekDay}`] = currentAdditional;
                            delivery++;
                        }
                    });

                    const updatedWeek = updateObject(this.state.scheduleDetails.cycle[m], updatedAdditionals);

                    updatedCycle = updateObject(updatedCycle, {
                        [m]: updatedWeek
                    });
                });

                const updatedObj = updateObject(this.state.scheduleDetails, {
                    cycle: updatedCycle
                });

                // update and inform
                this.setState(
                    () => ({ scheduleDetails: updatedObj }),
                    () => {
                        this.growl.show({
                            severity: 'info',
                            summary: 'Additional Window Deleted',
                            detail: 'Click Save to update the Database',
                            closable: false,
                            life: 3000
                        });
                    }
                );
            }
        );
    };

    addAdditionalWindow = () => {
        // store the schedule details object
        const obj = this.state.scheduleDetails;

        // if there is no cycle get out (should never happen)
        if (!obj.cycle) return;

        // get a count of the weeks in the schedule
        const currentWeeks = Object.keys(obj.cycle).length;
        const cycleObject = obj.cycle;

        // get a count of the current additional windows
        const currentAdditionals = Object.keys(cycleObject['week1']).filter(m => m.indexOf('additionalWindow') !== -1).length / 7;

        // loop through the weeks of the schedule and add the addition window details for input creation
        for (let wk = 1; wk <= currentWeeks; wk++) {
            const week = cycleObject[`week${wk}`];
            if (week) {
                [1, 2, 3, 4, 5, 6, 7].forEach(day => {
                    const dayNo = day;
                    week[`additionalWindow${currentAdditionals + 1}Week${wk}Day${dayNo}`] = {
                        elementType: 'input',
                        elementConfig: {
                            type: 'time',

                            placeholder: 'type info'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: true,
                        windowValid: true,
                        touched: false,
                        window: week[`pickTimeWeek${wk}Day${dayNo}`].window
                    };
                });
            }
        }

        // add the additional window to the schedule rows
        this.state.scheduleRows.push({
            name: `Additional Window ${currentAdditionals + 1}`,
            input: true
        });

        // update the cycle object
        const updatedCycle = updateObject(obj, {
            cycle: cycleObject
        });

        // update the schedule details and inform the user
        this.setState(
            () => ({ scheduleDetails: updatedCycle }),
            () => {
                this.growl.show({
                    severity: 'info',
                    summary: 'Additional Window Added',
                    detail: 'Click Save to update the Database',
                    closable: false,
                    life: 3000
                });
            }
        );
    };

    toggleConfirmation = () => {
        if (!this.state.isConfirmationOpen) {
            const obj = this.state.scheduleDetails;

            let currentWeeks = 0;

            if (obj.cycle) currentWeeks = Object.keys(obj.cycle).length;

            if (currentWeeks === 0) return;
        }

        this.setState({ isConfirmationOpen: !this.state.isConfirmationOpen });
    };

    deleteWeek = () => {
        const obj = this.state.scheduleDetails;

        let currentWeeks = 0;

        if (obj.cycle) currentWeeks = Object.keys(obj.cycle).length;

        if (currentWeeks === 0) return;

        delete obj.cycle[`week${currentWeeks}`];
        this.setState(
            () => ({ scheduleDetails: obj, isConfirmationOpen: false }),
            () => {
                this.growl.show({
                    severity: 'info',
                    summary: `Week ${currentWeeks} Deleted`,
                    detail: 'Click Save to update the Database',
                    closable: false,
                    life: 5000
                });
            }
        );
    };

    cancelScheduleCreation = () => {
        const updatedOptionValues = this.state.scheduleOptions.filter(m => m.value !== '0');

        let currentScheduleId = null;

        const currentSchedule = updatedOptionValues.filter(
            m => moment(m.activeDate) <= moment().startOf('week') && moment(m.deactiveDate) > moment().startOf('week')
        )[0];

        if (currentSchedule) currentScheduleId = currentSchedule.value;
        else currentScheduleId = updatedOptionValues.length === 0 ? null : updatedOptionValues[0].value;
        
        this.setState({route: [{
            index: 0,
            sourceDepotName: "", 
            sourceDepotId: "",
            destinationDepotName: "",
            destinationDepotId: ""
        }],
        isCreateMode: false});

        this.setState(
            () => ({
                isConfirmationOpen: false,
                editMode: false,
                saved: null,
                scheduleOptions: null,
                currentScheduleId
            }),
            () => this.setScheduleOptions()
        );

        this.growl.show({ severity: 'info', summary: 'Schedule Removed', detail: 'Schedule successfully removed', life: 2000 });
    };

    reverseWeeks = () => {
        const obj = this.state.scheduleDetails;

        let currentWeeks = 0;

        if (obj.cycle) currentWeeks = Object.keys(obj.cycle).length;

        if (currentWeeks === 0) return;

        if (currentWeeks === 1) {
            this.growl.show({ severity: 'error', summary: 'Unable to Reverse Weeks', detail: 'You must have 2 weeks to use this function' });
            return;
        }

        const week1 = obj.cycle['week1'];
        const week2 = obj.cycle['week2'];

        const updatedCycle = updateObject(obj, {
            cycle: { week1: week2, week2: week1 }
        });

        this.setState({ scheduleDetails: updatedCycle });
        this.growl.show({ severity: 'info', summary: 'Weeks Reversed' });
    };

    handleRouteSetup = () => {
        this.setState({ isScheduleRouteModalOpen: !this.state.isScheduleRouteModalOpen });
    }

    closeScheduleRouteModal = () => {
        this.setState({ isScheduleRouteModalOpen: !this.state.isScheduleRouteModalOpen });
    }

    setRouteData = (route) => {
        this.setState({route: route});
    }

    render() {
        let scheduleName = 'Please select Schedule Dates and Type';
        let typeId = null;
        const isReadOnly = !userCanEdit(this.props.roles);

        if (this.props.locations && this.props.locations.length > 0 && this.state.scheduleTypes !== null) {
            let location = null;
            let startDate = null;
            if (this.state.editMode && this.state.schedule !== null) {
                location = this.props.locations.filter(m => m.id === this.props.id)[0];
                typeId = this.state.scheduleTypeId;
                startDate = this.state.startDate;
            }
            else {
                location = this.props.locations.filter(m => m.id === this.props.id)[0];
                typeId = this.state.scheduleTypeId;
                startDate = this.state.newStartDate;
            }
            if (typeId !== null) {
                scheduleName = (
                    <Fragment>
                        Schedule Name:
                        <br />
                        {location.locationName} {this.state.scheduleTypes.filter(m => m.value === +typeId)[0].label} {startDate.format('DD/MM/YYYY')}
                    </Fragment>
                );
            }
        }

        let createButtonDisabled = <FilledButton onClick={() => this.createNewSchedule()}>{this.state.editMode ? 'Update' : 'Create'}</FilledButton>;

        if (!(this.state.isValidSelection && this.state.isValidDateRange && this.state.isValidStartDate && this.state.isValidEndDate))
            createButtonDisabled = (
                <FilledButton disabled onClick={() => this.createNewSchedule()}>
                    {this.state.editMode ? 'Update' : 'Create'}
                </FilledButton>
            );

        const weeks = this.state.scheduleDetails && this.state.scheduleDetails !== null ? Object.keys(this.state.scheduleDetails.cycle).length : 0;

        const confirmation = {
            message: '',
            action: null,
            heading: '',
            isOpen: this.state.isConfirmationOpen,
            handleClose: () => this.toggleConfirmation()
        };

        const scheduleRouteModalConfig = {
            openModal: this.state.isScheduleRouteModalOpen,
            closeModal: () => this.closeScheduleRouteModal(),
            isCreateMode: this.state.isCreateMode
        };

        if (this.state.isConfirmationOpen) {
            // if in edit mode and not saved then it is a newly created schedule, if just edit mode then we are deleting a week
            if (this.state.editMode && !this.state.saved && this.state.saved !== null) {
                confirmation.heading = 'Cancel Schedule Creation';
                confirmation.message = (
                    <Fragment>
                        <p>You have not saved {this.state.schedule.name}.</p>
                        <p>Cancelling at this point will remove the schedule.</p>
                        <p>Continue cancelletion of new schedule?</p>
                    </Fragment>
                );
                confirmation.action = () => this.cancelScheduleCreation();
            }
            else {
                confirmation.heading = weeks === 1 ? 'Delete Schedule' : `Delete Week ${weeks}`;
                confirmation.message = (
                    <Fragment>
                        <p>
                            This will delete{' '}
                            {weeks === 1
                                ? 'the schedule.'
                                : `Week ${weeks} of the schedule. ` +
                                'If you want to delete Week 1, reverse the weeks first and then click the delete icon again'}
                        </p>
                        <p>Continue deleting {weeks === 1 ? 'the schedule' : 'Week ' + weeks}?</p>
                    </Fragment>
                );
                confirmation.action = () => this.deleteWeek();
            }
        }

        const confirmationModal = (
            <Confirmation confirmation={confirmation} toggleClose={confirmation.handleClose} isOpen={confirmation.isOpen} clicked={confirmation.action} />
        );
        
        const scheduleRouteModal = (
            <ScheduleRouteModal  
                isCreateMode={scheduleRouteModalConfig.isCreateMode}
                openScheduleRouteModal={scheduleRouteModalConfig.openModal}
                closeScheduleRouteModal={scheduleRouteModalConfig.closeModal}
                setRouteData={this.setRouteData}
                route={this.state.route} >
            </ScheduleRouteModal>
        )

        let startlabel = 'startDate';
        let endlabel = 'endDate';
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let startDateFocused = this.state.startDateFocused;
        let endDateFocused = this.state.endDateFocused;

        if (!this.state.editMode) {
            startlabel = 'newStartDate';
            endlabel = 'newEndDate';
            startDate = this.state.newStartDate;
            endDate = this.state.newEndDate;
            startDateFocused = this.state.newStartDateFocused;
            endDateFocused = this.state.newEndDateFocused;
        }

        const newScheduleForm = (
            <Modal fullScreen restrictClose alert handleClose={() => this.closeModal()} open={this.state.isOpen}>
                <ModalHeading element="h3">{this.state.editMode ? 'Edit' : 'Create New'} Schedule</ModalHeading>

                <ListGroup inline className="ln-u-push" style={{ paddingLeft: '30px' }}>
                    <ListGroupItem className="dateInput">
                        <Input
                            label="Start Date"
                            id={startlabel}
                            dateChanged={event => this.dateChangedHandler(event, startlabel)}
                            onFocused={event => this.focusedHandler(event, startlabel)}
                            focused={startDateFocused}
                            value={startDate}
                            invalid={!this.state.isValidDateRange}
                            errorMessage={this.getErrorMessage('StartDate')}
                            elementType="datelabelled"
                            yearStartExceptions={this.state.yearStartExceptions}
                        />
                    </ListGroupItem>
                    <ListGroupItem>
                        <Input
                            label="End Date"
                            id={endlabel}
                            dateChanged={event => this.dateChangedHandler(event, endlabel)}
                            onFocused={event => this.focusedHandler(event, endlabel)}
                            focused={endDateFocused}
                            value={endDate}
                            invalid={!this.state.isValidDateRange}
                            errorMessage={this.getErrorMessage('EndDate')}
                            elementType="datelabelled"
                            yearStartExceptions={this.state.yearStartExceptions}
                        />
                    </ListGroupItem>
                    <ListGroupItem>
                        <Input
                            key="nfTypeI"
                            label="Type"
                            id={'type'}
                            changed={event => this.typeChangeHandler(event)}
                            options={this.state.scheduleTypes}
                            value={typeId}
                            elementType="select"
                        />
                    </ListGroupItem>
                </ListGroup>
                <p>Please select the Schedule dates and type</p>
                <li>The schedule must start on a Sunday</li>
                <li>The schedule must end on a Saturday</li>
                <li>A schedule type is required</li>
                <p
                    style={{
                        paddingTop: '1.5rem',
                        minHeight: '4rem'
                    }}
                >
                    You will be able to create/amend the daily information once the template is created.
                </p>
                <ButtonGroupWrapper actionBar style={{ padding: '0px', marginBottom: '0px' }}>
                    <ButtonGroupPrimary>
                        {createButtonDisabled}
                        <h6 className={classes.formScheduleName}>{scheduleName}</h6>
                    </ButtonGroupPrimary>
                    <ButtonGroupSecondary>
                        <TextButton onClick={() => this.closeModal('cancel')}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                </ButtonGroupWrapper>
            </Modal>
        );

        const options = this.props.locations.map(l => ({ label: `${l.locationNo} ${l.locationName}`, value: l.id.toString() }));
        const obj = this.getSelected();

        let locationSelect = (
            <AutocompleteField
                name="siteListAutocomplete"
                placeholder="Select a Location"
                label="Select Location"
                onSelect={event => this.locationSelectHandler(event)}
                options={options}
                clearSelection={() => this.setLocation(null)}
                selectedItem={obj === 'error' ? null : obj}
            />
        );

        // eslint-disable-next-line no-mixed-operators, no-extra-parens
        if (((this.props.schedules === null || this.state.scheduleOptions === null) && this.props.id === null) || obj === 'error') {
            let locationError = null;
            if (obj === 'error') locationError = <p>Location not found, please check and try again</p>;

            locationSelect = (
                <Container soft size="def">
                    <Card>
                        <GridWrapper>
                            <GridItem size="1/4">
                                <h3>Store Schedules</h3>
                                {locationSelect}
                                {locationError}
                            </GridItem>
                        </GridWrapper>
                    </Card>
                </Container>
            );
        }

        let loadingText = '';

        if (this.props.loading) loadingText = 'Loading Schedule(s)';
        else if (this.props.locationsLoading) loadingText = 'Loading Locations';
        else if (this.props.loadingDCs) loadingText = 'Loading DCs';
        else if (this.props.loadingLocationTypes) loadingText = 'Loading Location Types';
        else if (this.props.loadingDC2StoreDriveTimes) loadingText = 'Loading DC Drive Times';

        const loading = (
            <ProgressIndicator page loading={loadingText !== ''}>
                <ProgressSpinner light />
                {loadingText}
            </ProgressIndicator>
        );

        let saveMessage = '';
        if (this.props.saving) saveMessage = <Fragment>Saving {this.state.schedule && this.state.schedule !== null ? this.state.schedule.name : ''}</Fragment>;
        if (this.props.regenerating) {
            const option = this.props.locations.filter(m => m.id === this.props.id)[0];
            let locationName = '';
            if (!option) locationName = locationName.locationName;

            saveMessage = <Fragment>Regenerating all schedules {locationName !== '' ? `for ${locationName}` : ''}</Fragment>;
        }

        const saving = (
            <ProgressIndicator page loading={this.props.regenerating || this.props.saving}>
                <ProgressSpinner light />
                {saveMessage}
            </ProgressIndicator>
        );

        let inputBoxes = null;
        let actionButtons = null;
        let routeDetails = null;
        if (this.state.scheduleDetails && this.state.scheduleTypes && this.state.schedule) {
            inputBoxes = Object.keys(this.state.scheduleDetails)
                .filter(arr => this.state.info.find(obj => obj.code === arr))
                .map(obj => {
                    const info = this.state.scheduleDetails[obj];
                    let size = '1/6';
                    if (info.elementType === 'textarea') size = '1/3';

                    const scheduleName = this.state.scheduleDetails['name'].value;
                    let id = null;
                    if (this.state.editMode) id = this.state.scheduleTypeId === null ? this.state.schedule.scheduleTypeId - 1 : this.state.scheduleTypeId - 1;
                    else id = this.state.schedule ? this.state.schedule.scheduleTypeId - 1 : this.state.scheduleTypeId - 1;

                    // we put the scheduleTypeId from the state schedule as the name will still hold the old type name
                    let type = this.state.scheduleTypes[this.state.schedule.scheduleTypeId - 1].label;
                    const name = scheduleName.split(type);
                    type = this.state.scheduleTypes[id].label;
                    const value = [name[0].trim(), '\n', type, name[1]].join('');

                    let input = null;
                    if (info.elementType === 'textarea') {
                        const editIcon = isReadOnly ? null : (
                            <Edit
                                style={{ color: this.state.editMode ? '#e53500' : '#8e8e8e' }}
                                className={[classes.labelView, classes.btn, this.state.editMode ? classes.active : ''].join(' ')}
                                onClick={this.state.editMode ? () => this.openModal('edit') : null}
                            />
                        );
                        input = (
                            <Fragment>
                                <Input
                                    key={obj}
                                    id={obj}
                                    disabled={true}
                                    className={classes.labelView}
                                    elementType={info.elementType}
                                    elementConfig={info.elementConfig}
                                    value={value}
                                    label={info.elementConfig.label}
                                />
                                {editIcon}
                            </Fragment>
                        );
                    }
                    else
                        input = (
                            <Input
                                key={obj}
                                id={obj}
                                disabled={true}
                                className={[classes.labelView, 'smallDate'].join(' ')}
                                elementType={info.elementType}
                                elementConfig={info.elementConfig}
                                value={obj === 'deactiveDate' ? this.state.endDate : this.state.startDate}
                                label={info.elementConfig.label}
                                dateChanged={event => this.dateChangedHandler(event, obj === 'deactiveDate' ? 'endDate' : 'startDate')}
                                onFocused={event => this.focusedHandler(event, obj === 'deactiveDate' ? 'endDate' : 'startDate')}
                            />
                        );

                    return (
                        <ListGroupItem key={obj} size={size}>
                            {input}
                        </ListGroupItem>
                    );
                });

            actionButtons = (
                <ListGroupItem className={classes.actionButtons}>
                    <label style={{ textAlign: 'center', width: '100%', position: 'absolute', marginTop: '-28px' }} className="ln-c-label">
                        Actions
                    </label>
                    {this.state.editMode ? null : (
                        <IconButton
                            disabled={this.state.scheduleOptions === null}
                            type="button"
                            onClick={this.viewClickHandler}
                            variant="outlined"
                            label="View"
                        >
                            <Calendar />
                        </IconButton>
                    )}
                    <IconButton
                        disabled={this.state.scheduleOptions === null || isReadOnly}
                        style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
                        type="button"
                        variant="outlined"
                        label={!this.state.editMode ? 'Edit' : 'Cancel'}
                        onClick={() => this.toggleEditMode('cancel')}
                    >
                        {!this.state.editMode ? <Edit /> : <Cancel />}
                    </IconButton>
                    <IconButton disabled={!this.state.editMode} type="button" variant="outlined" onClick={this.saveSchedule} label="Save">
                        <Tick />
                    </IconButton>
                </ListGroupItem>
            );

            routeDetails = (
                this.state.editMode? 
                    <LinkButton onClick={this.handleRouteSetup}>{this.state.isCreateMode ? 'Setup Route' : 'View Route'}</LinkButton> : '' 
                
                );
        }

        let tableContainer = null;
        if (obj && obj !== 'error') {
            if (!this.props.loading && !this.props.saving) tableContainer = <h4 className={'ln-u-push-lg'}>{`No schedules found for ${obj.label}`}</h4>;
            else if (this.props.loading || this.props.saving) tableContainer = <h4 className={'ln-u-push-lg'}>{`Retrieving schedules for ${obj.label}`}</h4>;
        }

        if (this.state.scheduleDetails) {
            let cycle = null;
            let scheduleHeader = null;

            scheduleHeader = (
                <TableHeader>
                    <TableHeaderRow>
                        <TableCell></TableCell>
                        <TableHeaderCell className={classes.noWrap} align="center">
                            <div style={{ marginLeft: '-40px' }}>
                                <Button
                                    disabled={isReadOnly}
                                    style={{ float: 'left', color: this.state.editMode ? '#f06c00' : '#8e8e8e' }}
                                    className={[classes.btn, this.state.editMode ? classes.active : '', 'mini-button'].join(' ')}
                                    onClick={this.addWeek}
                                    type="button"
                                    icon="pi pi-plus"
                                    tooltip="Add Week"
                                    tooltipOptions={{ position: 'top' }}
                                />
                                <Button
                                    disabled={isReadOnly}
                                    style={{ float: 'left', color: this.state.editMode ? '#f06c00' : '#8e8e8e' }}
                                    className={[classes.btn, this.state.editMode ? classes.active : '', 'mini-button'].join(' ')}
                                    onClick={this.reverseWeeks}
                                    type="button"
                                    icon="pi pi-sort-alt"
                                    tooltip="Reverse Weeks"
                                    tooltipOptions={{ position: 'top' }}
                                />
                                <Button
                                    disabled={isReadOnly}
                                    style={{ float: 'left', color: this.state.editMode ? '#f06c00' : '#8e8e8e' }}
                                    className={[classes.btn, this.state.editMode ? classes.active : '', 'mini-button'].join(' ')}
                                    onClick={this.toggleConfirmation}
                                    type="button"
                                    icon="pi pi-trash"
                                    tooltip="Delete Week/Schedule"
                                    tooltipOptions={{ position: 'top' }}
                                />
                                <Button
                                    disabled={isReadOnly}
                                    style={{ float: 'left', color: this.state.editMode ? '#f06c00' : '#8e8e8e' }}
                                    className={[classes.btn, this.state.editMode ? classes.active : '', 'mini-button'].join(' ')}
                                    onClick={this.addAdditionalWindow}
                                    type="button"
                                    icon="pi pi-calendar-plus"
                                    tooltip="Add Additional Window"
                                    tooltipOptions={{ position: 'top' }}
                                />
                            </div>
                            Desc
                        </TableHeaderCell>
                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(d => (
                            <TableHeaderCell key={d} align="center">
                                {d}
                            </TableHeaderCell>
                        ))}
                    </TableHeaderRow>
                </TableHeader>
            );

            cycle = Object.keys(this.state.scheduleDetails.cycle).map((week, ind) => (
                <Fragment key={week}>
                    {this.state.scheduleRows.map(row => (
                        <ScheduleRow
                            disabled={!this.state.editMode}
                            parameters={this.state.parameters}
                            cycle={this.state.scheduleDetails.cycle[week]}
                            click={this.handleDetailViewToggle}
                            deleteAdditional={event => this.deleteAdditionalWindow(event)}
                            heading={row.heading}
                            rowState={this.state.weeks.find(el => el.id === ind + 1).isOpen ? '' : classes.detailRow}
                            headingState={this.state.weeks.find(el => el.id === ind + 1).isOpen ? classes.isOpen : ''}
                            key={row.name}
                            value={row.name}
                            isInput={row.input}
                            week={ind}
                            focus={event => this.onFocusedHandler(event)}
                            changed={(event, field) => this.inputChangedHandler(event, field, week, row.name)}
                        />
                    ))}
                </Fragment>
            ));

            tableContainer = (
                <TableContainer className={classes.scheduleTableContainer}>
                    {scheduleHeader}
                    <TableBody>{cycle}</TableBody>
                </TableContainer>
            );
        }

        let scheduleOptionsList = null;
        let scheduleActions = null;

        if (this.state.scheduleOptions !== null && this.state.scheduleOptions.length > 0) {
            scheduleOptionsList = (
                <RadioButtonField
                    className={classes.scheduleOptionList}
                    label="Schedule List"
                    name="ScheduleFields"
                    options={this.state.scheduleOptions}
                    fullWidth
                    outlined
                    onClick={e => this.onChangeScheduleHandler(e)}
                />
            );
        }

        if (!this.props.loading)
            scheduleActions = (
                <ListGroup type="full" inline>
                    <ListGroupItem>
                        <IconButton
                            disabled={isReadOnly}
                            onClick={this.openModal}
                            label="Create New Schedule"
                            variant="outlined"
                        >
                            <Plus />
                        </IconButton>
                    </ListGroupItem>
                    <ListGroupItem>
                        <IconButton
                            disabled={isReadOnly}
                            onClick={this.openModalCopy}
                            label="Copy Schedule"
                            variant="outlined"
                        >
                            <Plus />
                        </IconButton>
                    </ListGroupItem>
                    <ListGroupItem>
                        <IconButton
                            disabled={isReadOnly}
                            onClick={() => this.props.onRegenerateSchedule(this.props.id)}
                            label="Regenerate Schedule"
                            variant="outlined"
                        >
                            <Delivery />
                        </IconButton>
                    </ListGroupItem>
                    <ListGroupItem>
                        <IconButton
                            disabled={isReadOnly}
                            onClick={this.viewLocation}
                            label="View Location Details"
                            variant="outlined"
                        >
                            <StoreLocation />
                        </IconButton>
                    </ListGroupItem>
                </ListGroup>
            );

        let view = (
            <Container softsize="def" style={{ minWidth: '940px' }}>
                <Card>
                    <Form soft="lg">
                        <FlagWrapper>
                            <FlagBody>
                                <GridWrapper verticalAlign="middle" horizontalAlign="centre">
                                    <GridItem size="3/12">
                                        <h3>Schedules</h3>
                                    </GridItem>
                                    <GridItem size="9/12">
                                        {scheduleActions}
                                    </GridItem>
                                </GridWrapper>
                            </FlagBody>
                        </FlagWrapper>
                        <GridWrapper>
                            <GridItem size="3/12" style={{ minWidth: '300px' }}>
                                {locationSelect}
                                {scheduleOptionsList}
                            </GridItem>
                            <GridItem size="9/12" style={{ maxWidth: 'calc(100% - 300px)' }}>
                                <ListGroup className={[classes.scheduleHeader, 'scheduleHeader'].join(' ')} type="full" inline>
                                    {inputBoxes}
                                    {actionButtons}
                                </ListGroup>
                                {routeDetails}
                                {tableContainer}
                            </GridItem>
                        </GridWrapper>
                    </Form>
                </Card>
            </Container>
        );

        // eslint-disable-next-line no-mixed-operators, no-extra-parens
        if ((this.state.scheduleOptions === null && this.props.id === null) || obj === 'error') view = locationSelect;

        let loadingv = null;
        if (this.props.loading || this.props.locationsLoading || this.props.loadingDCs) loadingv = loading;

        let page = (
            <Fragment key="schedule">
                {newScheduleForm}
                {confirmationModal}
                {scheduleRouteModal}
                {loadingv}
                {saving}
                {view}
            </Fragment>
        );

        if (this.state.schedulesView) {
            const wcDate = moment().startOf('week'); // this.state.schedule.activeDate.substr(0, 10);
            page = this.loadSchedules(this.props.id, wcDate);
        }

        return (
            <Fragment>
                {page}
                <Growl className="customGrowl" ref={el => this.growl = el}></Growl>
            </Fragment>
        );
    }
}

Schedule.propTypes = {
    dc2StoreDriveTimes: PropTypes.array,
    history: PropTypes.object.isRequired,
    id: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    loading: PropTypes.bool,
    loadingDC2StoreDriveTimes: PropTypes.bool,
    loadingDCs: PropTypes.bool,
    loadingLocationTypes: PropTypes.bool,
    loadingTypes: PropTypes.bool,
    locations: PropTypes.array,
    locationsLoading: PropTypes.bool,
    match: PropTypes.object,
    onGetDC2StoreDriveTimes: PropTypes.func,
    onGetLocations: PropTypes.func.isRequired,
    onGetScheduleTypes: PropTypes.func,
    onGetSchedules: PropTypes.func,
    onGetYearStartExceptions: PropTypes.func,
    onRegenerateSchedule: PropTypes.func,
    onResetRegenerateSchedule: PropTypes.func,
    onResetSchedule: PropTypes.func,
    onSaveSchedule: PropTypes.func,
    onSetAuthRedirectPath: PropTypes.func.isRequired,
    onSetLocation: PropTypes.func,
    regenerateSuccess: PropTypes.bool,
    regenerating: PropTypes.bool,
    roles: PropTypes.array,
    saveError: PropTypes.bool,
    saveSuccess: PropTypes.bool,
    saving: PropTypes.bool,
    schedules: PropTypes.object,
    types: PropTypes.array,
    yearStartExceptions: PropTypes.array,
    yearStartExceptionsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        dc2StoreDriveTimes: state.location.dc2StoreDriveTimes,
        emailSent: state.email.emailSent,
        loadingDCs: state.location.loadingDCs,
        id: state.location.id,
        isAuthenticated: state.auth.givenName !== null,
        loading: state.schedule.loading,
        loadingTypes: state.aipSchedule.loadingTypes,
        locationDCTypes: state.location.locationDCTypes,
        locations: state.location.locationHeaders,
        loadingLocations: state.location.loading,
        regenerateSuccess: state.schedule.regenerateSuccess,
        regenerateError: state.schedule.regenerateError,
        regenerating: state.schedule.regenerating,
        roles: state.auth.roles,
        saveError: state.schedule.saveError,
        saveSuccess: state.schedule.saveSuccess,
        saving: state.schedule.saving,
        schedules: state.schedule.schedules,
        types: state.aipSchedule.types,
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading,
        yearStartExceptionsError: state.general.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetLocations: () => dispatch(actions.getLocationHeaders()),
        onGetDC2StoreDriveTimes: id => dispatch(actions.getDC2StoreDriveTimes(id)),
        onGetSchedules: id => dispatch(actions.getSchedules(id)),
        onGetScheduleTypes: () => dispatch(actions.getScheduleTypes()),
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions()),
        onSaveSchedule: schedule => dispatch(actions.saveSchedule(schedule)),
        onRegenerateSchedule: id => dispatch(actions.regenerateSchedule(id)),
        onResetRegenerateSchedule: () => dispatch(actions.resetRegenerateScheduleSuccess()),
        onResetSchedule: () => dispatch(actions.resetScheduleSuccess()),
        onSetAuthRedirectPath: path => dispatch(actions.setAuthRedirectPath(path)),
        onSetLocation: id => dispatch(actions.setLocation(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Schedule, axios));

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.scss';
import { ListGroup, ListGroupItem } from '@jsluna/list';
import { AutocompleteField } from '@jsluna/autocomplete';
import { FormGroup, SelectField, SwitchField, TextAreaField, TextInputField } from '@jsluna/form';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from '@jsluna/date-picker';

import SingleDatePickerWithLabel from './SingleDatePickerWithLabel/SingleDatePickerWithLabel';

import moment from 'moment';

import { connect } from 'react-redux';
import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

moment.locale('en');
// ISO-8601, Europe (removed)
moment.updateLocale('en', {
    week: {
        dow: 0, // First day of week is Sunday
        doy: 4 // First week of year must contain 4 January (7 + 1 - 4)
    }
});

const Input = props => {
    const inputClasses = [classes.InputElement];
    let inputElement = null;

    const [weekNumberValue, setWeekNumberValue] = useState();
    const [previousValue, setPreviousValue] = useState();

    const { value, elementType } = props;

    const doUpdate = () => {
        if (previousValue !== value) {
            // [TODO]    Find if any of the exceptions apply to the 'value' and if so calculate the weeks passed since that exception to gain the week number
            //          If not then calculate how many weeks have passed since the second sunday of march in the year of 'value'

            setPreviousValue(value);

            let yearStart;
            const weekStart = moment(value).startOf('week').add(3, 'hour');
            let weekIsCalculated = false;
            let calculatedYearStart;

            // Get Calculated Value
            if (!yearStart) {
                const thisYear = new Date(moment(weekStart).year(), 2, 7);
                thisYear.setDate(7 - thisYear.getDay());
                const thisYearStart = moment(thisYear);

                const prevYear = new Date(moment(weekStart).year() - 1, 2, 7);
                prevYear.setDate(7 + (7 - prevYear.getDay()));
                const prevYearStart = moment(prevYear);

                if (weekStart < thisYearStart) {
                    calculatedYearStart = prevYearStart;
                }
                else {
                    calculatedYearStart = thisYearStart;
                }
            }

            // Try to find yearStart from data
            /* eslint-disable no-extra-parens */
            if (props.yearStartExceptions) {
                props.yearStartExceptions.forEach(e => {
                    const nextException = props.yearStartExceptions.filter(y => moment(y.dateRequested).year() === moment(e.dateRequested).year() + 1);
                    if (
                        (moment(e.dateRequested) <= moment(weekStart) && moment(e.dateRequested).year() === moment(weekStart).year() - 1 &&
                            calculatedYearStart.year() === moment(e.dateRequested).year()) ||

                        (moment(e.dateRequested) <= moment(weekStart) && moment(e.dateRequested).year() === moment(weekStart).year()) ||

                        (moment(e.dateRequested) <= moment(weekStart) &&
                            moment(e.dateRequested).year() === moment(weekStart).year() + 1 &&
                            (moment(weekStart).month() < 3 || (nextException && moment(weekStart) < moment(nextException)))) ||

                        (moment(weekStart).month() === 3 &&
                            calculatedYearStart < moment(weekStart) &&
                            calculatedYearStart.year() === moment(e.dateRequested).year())
                    )
                        yearStart = e.dateRequested;
                });
            }
            /* eslint-enable no-extra-parens */

            // if not set then use calculated value
            if (!yearStart) {
                yearStart = calculatedYearStart;
                weekIsCalculated = true;
            }

            setWeekNumberValue({ weekNumber: weekStart.diff(yearStart, 'week') + 1, isCalculated: weekIsCalculated });
        }
    }

    if (elementType === 'date' || elementType === 'datelabelled') {
        if (!props.yearStartExceptions && !props.yearStartExceptionsLoading) {
            props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
            doUpdate();
        }
        else {
            doUpdate();
        }
    }

    if (props.invalid && props.shouldValidate && props.touched) inputClasses.push(classes.Invalid);

    let validationError = null;
    if (props.invalid && props.touched) validationError = <p className={classes.ValidationError}>Please enter a valid {props.errorMessage}</p>;

    const hasValue = !!props.value;
    let dateVal = null;

    switch (props.elementType) {
        case 'date':
            if (hasValue) dateVal = moment(props.value);

            inputElement = (
                <ListGroup inline className="dateList">
                    <ListGroupItem className={props.className}>
                        <SingleDatePicker
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                            id={props.id}
                            isOutsideRange={() => false}
                            date={dateVal}
                            focused={props.focused}
                            onDateChange={props.dateChanged}
                            // onChange={props.changed}
                            onFocusChange={props.onFocused}
                            displayFormat="DD/MM/YYYY"
                        />
                    </ListGroupItem>
                    <ListGroupItem onClick={props.wkNoClick} className="weekNumberLi">
                        <span className="weekNumber ">{dateVal === null || !weekNumberValue ? '#' : weekNumberValue.weekNumber}</span>
                    </ListGroupItem>
                </ListGroup>
            );
            break;
        case 'datelabelled':
            if (hasValue) dateVal = moment(props.value);

            inputElement = (
                <SingleDatePickerWithLabel
                    disabled={props.disabled}
                    className={props.className}
                    label={props.label}
                    displayName={props.label}
                    error={props.errorMessage}
                    readOnly={props.readOnly}
                    id={`id${props.id}`}
                    name={props.id}
                    isOutsideRange={() => false}
                    date={dateVal}
                    dateVal={dateVal}
                    focused={props.focused}
                    onDateChange={props.dateChanged}
                    onFocusChange={props.onFocused}
                    displayFormat="DD/MM/YYYY"
                    wkNoClick={props.wkNoClick}
                    wkNo={!weekNumberValue ? 0 : weekNumberValue.weekNumber}
                />
            );
            break;
        case 'input':
            inputElement = (
                <TextInputField
                    type={props.type}
                    disabled={props.disabled}
                    autoComplete={props.autoComplete}
                    error={props.errorMessage}
                    warning={props.warning}
                    className={props.className}
                    label={props.label}
                    readOnly={props.readOnly}
                    name={props.id}
                    onChange={props.changed}
                    onFocus={props.onFocused}
                    value={props.value}
                    style={props.style}
                />
            );
            break;
        case 'textarea':
            const value = props.value === null ? '' : props.value.replace('\\n', '\n');
            inputElement = (
                <TextAreaField
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    label={props.label}
                    className={props.className}
                    name={props.id}
                    onChange={props.changed}
                    value={value}
                />
            );
            break;
        case 'checkbox':
            const checked = props.value === true || props.value === 'true' || props.value === '1';
            // bodge as luna not working when unchecking from state
            const checkedClass = checked ? 'checked' : 'not-checked';

            inputElement = (
                <SwitchField
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    className={[props.className, checkedClass].join(' ')}
                    name={props.id}
                    onChange={props.changed}
                    options={[{ value: checked ? '1' : '0', defaultChecked: checked }]}
                />
            );

            break;
        case 'select':
            inputElement = (
                <SelectField
                    disabled={props.disabled}
                    style={props.style}
                    error={props.errorMessage}
                    label={props.label}
                    readOnly={props.readOnly}
                    name={props.id}
                    onChange={props.changed}
                    options={props.options}
                    value={props.value === null || !props.value ? '' : props.value.toString()}
                />
            );
            break;
        case 'autocomplete':
            if (!props.disabled) {
                let obj = null;

                if (props.value && props.value !== '') obj = { label: props.displayName, value: props.value };

                inputElement = (
                    <AutocompleteField
                        name={props.id}
                        placeholder="Select"
                        label={props.label || ''}
                        hideLabel={!props.label}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        className={props.className}
                        selectedItem={obj}
                        onChange={props.changed}
                        options={props.options}
                        onSelect={props.select}
                        clearSelection={props.select}
                        value={!obj ? '' : obj.value}
                    />
                );
            }
            else
                inputElement = (
                    <TextInputField
                        disabled={props.disabled}
                        autoComplete={props.autoComplete}
                        error={props.errorMessage}
                        warning={props.warning}
                        className={props.className}
                        label={props.label}
                        readOnly={props.readOnly}
                        name={props.id}
                        onChange={props.changed}
                        onFocus={props.onFocused}
                        value={props.displayName}
                        style={props.style}
                    />
                );
            break;
        default:
            inputElement = '';
    }

    return (
        <FormGroup name="form-group-1" label={props.title}>
            {inputElement}
            {validationError}
        </FormGroup>
    );
};

Input.propTypes = {
    autoComplete: PropTypes.string,
    changed: PropTypes.func,
    className: PropTypes.string,
    dateChanged: PropTypes.func,
    disabled: PropTypes.bool,
    displayName: PropTypes.string,
    elementType: PropTypes.string,
    errorMessage: PropTypes.string,
    focused: PropTypes.bool,
    id: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    onFocused: PropTypes.func,
    options: PropTypes.array,
    readOnly: PropTypes.bool,
    select: PropTypes.func,
    shouldValidate: PropTypes.object,
    style: PropTypes.object,
    title: PropTypes.string,
    touched: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.any,
    warning: PropTypes.string,
    wkNoClick: PropTypes.func,
    yearStartExceptions: PropTypes.array
};

const mapStateToProps = state => {
    return {
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading,
        yearStartExceptionsError: state.general.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Input, axios));

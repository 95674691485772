import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    schedules: null,
    loading: false,
    locationId: null,
    saveSuccess: false,
    saving: false,
    regenerating: false,
    regenerateSuccess: null,
    regenerateError: false
};

// const getScheduleListStart = state => updateObject(state, { loading: true });
// const getScheduleListSuccess = (state, action) => updateObject(state, { scheduleList: action.scheduleList, loading: false });
// const getScheduleListFail = state => updateObject(state, { loading: false });

/* get schedule */
const getSchedulesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        schedules: action.schedules
    });
};

const getSchedulesSuccess = (state, action) => {
    return updateObject(state, {
        schedules: action.schedules,
        locationId: action.locationId,
        loading: false
    });
};

const getSchedulesFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        schedules: action.schedules
    });
};

/* save schedule */
const saveScheduleStart = (state, action) => {
    return updateObject(state, {
        schedules: action.schedules,
        saving: true,
        saveSuccess: false
    });
};

const saveScheduleSuccess = (state, action) => {
    return updateObject(state, {
        schedules: action.schedules,
        saving: false,
        saveSuccess: action.saveSuccess
    });
};

const resetScheduleSuccess = state => {
    return updateObject(state, {
        saveSuccess: false
    });
};

const saveScheduleFail = (state, action) => {
    return updateObject(state, {
        schedules: action.schedules,
        saving: false,
        saveSuccess: false,
        error: true
    });
};

/* regenerate schedule */
const regenerateScheduleStart = state => {
    return updateObject(state, {
        regenerateSuccess: false,
        regenerating: true,
        regenerateError: false
    });
};

const regenerateScheduleSuccess = (state, action) => {
    return updateObject(state, {
        regenerateSuccess: action.regenerateSuccess,
        regenerating: false,
        regenerateError: false
    });
};

const resetRegenerateScheduleSuccess = state => {
    return updateObject(state, {
        regenerateSuccess: false
    });
};

const regenerateScheduleFail = state => {
    return updateObject(state, {
        regenerating: false,
        regenerateSuccess: false,
        regenerateError: true
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case actionTypes.GET_SCHEDULE_LIST_START: return getScheduleListStart(state, action);
        // case actionTypes.GET_SCHEDULE_LIST_SUCCESS: return getScheduleListSuccess(state, action);
        // case actionTypes.GET_SCHEDULE_LIST_FAIL: return getScheduleListFail(state, action);
        case actionTypes.GET_SCHEDULES_START: return getSchedulesStart(state, action);
        case actionTypes.GET_SCHEDULES_SUCCESS: return getSchedulesSuccess(state, action);
        case actionTypes.GET_SCHEDULES_FAIL: return getSchedulesFail(state, action);
        case actionTypes.SAVE_SCHEDULE_START: return saveScheduleStart(state, action);
        case actionTypes.SAVE_SCHEDULE_SUCCESS: return saveScheduleSuccess(state, action);
        case actionTypes.RESET_SCHEDULE_SUCCESS: return resetScheduleSuccess(state);
        case actionTypes.SAVE_SCHEDULE_FAIL: return saveScheduleFail(state, action);
        case actionTypes.REGENERATE_SCHEDULE_START: return regenerateScheduleStart(state, action);
        case actionTypes.REGENERATE_SCHEDULE_SUCCESS: return regenerateScheduleSuccess(state, action);
        case actionTypes.RESET_REGENERATE_SCHEDULE_SUCCESS: return resetRegenerateScheduleSuccess(state);
        case actionTypes.REGENERATE_SCHEDULE_FAIL: return regenerateScheduleFail(state, action);
        default: return state;
    }
};

export default reducer;

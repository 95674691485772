import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    loading: false,
    orderCycle: null
};

const getOrderCycleStart = state => updateObject(state, { loading: true });
const getOrderCycleSuccess = (state, action) => updateObject(state, { orderCycle: action.orderCycle, loading: false });
const getOrderCycleFail = state => updateObject(state, { loading: false });
const getOrderCyclesStart = state => updateObject(state, { loading: true });
const getOrderCyclesSuccess = (state, action) => updateObject(state, { orderCycles: action.orderCycles, loading: false });
const getOrderCyclesFail = state => updateObject(state, { loading: false });
const getOrderCycleCodesStart = state => updateObject(state, { loading: true });
const getOrderCycleCodesSuccess = (state, action) => updateObject(state, { orderCycleCodes: action.orderCycleCodes, loading: false });
const getOrderCycleCodesFail = state => updateObject(state, { loading: false });
const sendCodeRequestStart = state => updateObject(state, { sending: true });
const sendCodeRequestSuccess = (state, action) => updateObject(state, { success: action.success, sending: false });
const sendCodeRequestFail = state => updateObject(state, { sending: false });
const sendAllCodeRequestsStart = state => updateObject(state, { sending: true });
const sendAllCodeRequestsSuccess = (state, action) => updateObject(state, { success: action.success, sending: false });
const sendAllCodeRequestsFail = state => updateObject(state, { sending: false });
const downloadOrderCyclesStart = state => updateObject(state, { cyclesDownloading: true });
const downloadOrderCyclesSuccess = (state, action) => updateObject(state, { cyclesDownloading: false, cyclesDownload: action.cyclesDownload });
const downloadOrderCyclesFail = state => updateObject(state, { cyclesDownloading: false });
const downloadMissingStart = state => updateObject(state, { missingDownloading: true });
const downloadMissingSuccess = (state, action) => updateObject(state, { missingDownloading: false, missingDownload: action.missingDownload });
const downloadMissingFail = state => updateObject(state, { missingDownloading: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ORDER_CYCLE_START: return getOrderCycleStart(state);
        case actionTypes.GET_ORDER_CYCLE_SUCCESS: return getOrderCycleSuccess(state, action);
        case actionTypes.GET_ORDER_CYCLE_FAIL: return getOrderCycleFail(state);
        case actionTypes.GET_ORDER_CYCLES_START: return getOrderCyclesStart(state);
        case actionTypes.GET_ORDER_CYCLES_SUCCESS: return getOrderCyclesSuccess(state, action);
        case actionTypes.GET_ORDER_CYCLES_FAIL: return getOrderCyclesFail(state);
        case actionTypes.GET_ORDER_CYCLE_CODES_START: return getOrderCycleCodesStart(state);
        case actionTypes.GET_ORDER_CYCLE_CODES_SUCCESS: return getOrderCycleCodesSuccess(state, action);
        case actionTypes.GET_ORDER_CYCLE_CODES_FAIL: return getOrderCycleCodesFail(state);
        case actionTypes.SEND_CODE_REQUEST_START: return sendCodeRequestStart(state);
        case actionTypes.SEND_CODE_REQUEST_SUCCESS: return sendCodeRequestSuccess(state, action);
        case actionTypes.SEND_CODE_REQUEST_FAIL: return sendCodeRequestFail(state);
        case actionTypes.SEND_ALL_CODE_REQUESTS_START: return sendAllCodeRequestsStart(state);
        case actionTypes.SEND_ALL_CODE_REQUESTS_SUCCESS: return sendAllCodeRequestsSuccess(state, action);
        case actionTypes.SEND_ALL_CODE_REQUESTS_FAIL: return sendAllCodeRequestsFail(state);
        case actionTypes.CYCLES_DOWNLOAD_START: return downloadOrderCyclesStart(state);
        case actionTypes.CYCLES_DOWNLOAD_SUCCESS: return downloadOrderCyclesSuccess(state, action);
        case actionTypes.CYCLES_DOWNLOAD_FAIL: return downloadOrderCyclesFail(state);
        case actionTypes.DOWNLOAD_MISSING_START: return downloadMissingStart(state);
        case actionTypes.DOWNLOAD_MISSING_SUCCESS: return downloadMissingSuccess(state, action);
        case actionTypes.DOWNLOAD_MISSING_FAIL: return downloadMissingFail(state);
        default: return state;
    }
};

export default reducer;

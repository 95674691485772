import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    setLocationTrailers: [],
    trailers: [],
    trailerLocations: null,
    trailer: null,
    loadingTrailers: false,
    loadingSetLocationTrailers: false,
    setLocationTrailersItems: null,
    setLocationTrailersSuccess: null,
    setLocationTrailersFail: null,
    deletedDetailIds: null,
    deletedTrailerLocationIds: null,
    error: null,
    errorObject: null,
    selectedTrailers: null,
    selectedTrailersFromDate: null,
    selectedTrailersToDate: null,
    selectedFromDate: null,
    selectedToDate: null,
    updatedLocationTrailersItems: null,
    updateAssignedDatesSuccess: null,
    updateAssignedDatesFail: null
};

const setSelectedTrailers = (state, action) =>
    updateObject(state, {
        selectedTrailers: action.selectedTrailers,
        selectedTrailersFromDate: action.selectedTrailersFromDate,
        selectedTrailersToDate: action.selectedTrailersToDate
    });
const resetSelectedTrailers = (state, action) => updateObject(state, { selectedTrailers: action.selectedTrailers });

const setSelectedDateRange = (state, action) =>
    updateObject(state, {
        selectedFromDate: action.selectedFromDate,
        selectedToDate: action.selectedToDate
    });
const resetSelectedDateRange = (state, action) =>
    updateObject(state, {
        selectedFromDate: action.selectedFromDate,
        selectedToDate: action.selectedToDate
    });

const setTrailer = (state, action) => updateObject(state, { trailer: action.trailer });
const resetTrailer = (state, action) => updateObject(state, { trailer: action.trailer });

const getTrailersStart = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        id: action.id === undefined ? state.id : action.id
    });
};

const getTrailersSuccess = (state, action) => {
    return updateObject(state, {
        trailers: action.trailers === undefined ? state.trailers : action.trailers,
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers
    });
};

const getTrailersFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error
    });
};

const getLocationTrailersStart = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        id: action.id === undefined ? state.id : action.id
    });
};

const getLocationTrailersSuccess = (state, action) => {
    return updateObject(state, {
        locationTrailers: action.locationTrailers === undefined ? state.locationTrailers : action.locationTrailers,
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers
    });
};

const getLocationTrailersFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error
    });
};

const getTrailerLocationsStart = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers
    });
};

const getTrailerLocationsSuccess = (state, action) => {
    return updateObject(state, {
        trailerLocations: action.trailerLocations === undefined ? state.trailerLocations : action.trailerLocations,
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers
    });
};

const getTrailerLocationsFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error
    });
};

const setLocationTrailersStart = (state, action) => {
    return updateObject(state, {
        loadingSetLocationTrailers: action.loadingSetLocationTrailers === undefined ? state.loadingSetLoactionTrailers : action.loadingSetLocationTrailers
    });
};

const setLocationTrailersSuccess = (state, action) => {
    return updateObject(state, {
        setLocationTrailersItems: action.setLocationTrailersItems === undefined ? state.setLocationTrailersItems : action.setLocationTrailersItems,
        loadingSetLocationTrailers: action.loadingSetLocationTrailers === undefined ? state.loadingSetLocationTrailers : action.loadingSetLocationTrailers,
        setLocationTrailersSuccess: true,
        setLocationTrailersFail: false
    });
};

const setLocationTrailersReset = state => {
    return updateObject(state, {
        setLocationTrailersItems: null,
        loadingSetLocationTrailers: null,
        setLocationTrailersSuccess: null,
        setLocationTrailersFail: null
    });
};

const setLocationTrailersFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error,
        setLocationTrailersSuccess: false,
        setLocationTrailersFail: true
    });
};

const updateAssignedDatesStart = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers
    });
};

const updateAssignedDatesSuccess = (state, action) => {
    return updateObject(state, {
        updatedLocationTrailersItems:
            action.updatedLocationTrailersItems === undefined ? state.updatedLocationTrailersItems : action.updatedLocationTrailersItems,
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        updateAssignedDatesSuccess: true,
        updateAssignedDatesFail: false
    });
};

const updateAssignedDatesReset = state => {
    return updateObject(state, {
        updatedLocationTrailersItems: null,
        loadingTrailers: null,
        updateAssignedDatesSuccess: null,
        updateAssignedDatesFail: null
    });
};

const updateAssignedDatesFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error,
        updateAssignedDatesSuccess: false,
        updateAssignedDatesFail: true
    });
};

const setTrailerLocationsStart = (state, action) => {
    return updateObject(state, {
        loadingSetTrailerLocations: action.loadingSetTrailerLocations === undefined ? state.loadingSetTrailerLocations : action.loadingSetTrailerLocations
    });
};

const setTrailerLocationsSuccess = (state, action) => {
    return updateObject(state, {
        setTrailerLocationsItems: action.setTrailerLocationsItems === undefined ? state.setTrailerLocationsItems : action.setTrailerLocationsItems,
        loadingSetTrailerLocations: action.loadingSetTrailerLocations === undefined ? state.loadingSetTrailerLocations : action.loadingSetTrailerLocations,
        setTrailerLocationsSuccess: true,
        setTrailerLocationsFail: false
    });
};

const setTrailerLocationsReset = state => {
    return updateObject(state, {
        setTrailerLocationsItems: null,
        loadingSetTrailerLocations: null,
        setTrailerLocationsSuccess: null,
        setTrailerLocationsFail: null
    });
};

const setTrailerLocationsFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error,
        setTrailerLocationsSuccess: false,
        setTrailerLocationsFail: true
    });
};

const deleteLocationTrailersStart = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        id: action.id === undefined ? state.id : action.id
    });
};

const deleteLocationTrailersSuccess = (state, action) => {
    return updateObject(state, {
        deletedDetailIds: action.deletedDetailIds === undefined ? state.deletedDetailIds : action.deletedDetailIds,
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: false
    });
};

const deleteLocationTrailersFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error
    });
};

const deleteLocationTrailersReset = state => {
    return updateObject(state, {
        error: null,
        errorObject: null,
        deletedDetailIds: null
    });
};

const deleteTrailerLocationsStart = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers
    });
};

const deleteTrailerLocationsSuccess = (state, action) => {
    return updateObject(state, {
        deletedTrailerLocationIds: action.deletedTrailerLocationIds === undefined ? state.deletedTrailerLocationIds : action.deletedTrailerLocationIds,
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: false
    });
};

const deleteTrailerLocationsFail = (state, action) => {
    return updateObject(state, {
        loadingTrailers: action.loadingTrailers === undefined ? state.loadingTrailers : action.loadingTrailers,
        error: action.error === undefined ? state.error : action.error
    });
};

const deleteTrailerLocationsReset = state => {
    return updateObject(state, {
        error: null,
        errorObject: null,
        deletedTrailerLocationIds: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRAILERS_START:
            return getTrailersStart(state, action);
        case actionTypes.GET_TRAILERS_SUCCESS:
            return getTrailersSuccess(state, action);
        case actionTypes.GET_TRAILERS_FAIL:
            return getTrailersFail(state, action);
        case actionTypes.GET_LOCATIONTRAILERS_START:
            return getLocationTrailersStart(state, action);
        case actionTypes.GET_LOCATIONTRAILERS_SUCCESS:
            return getLocationTrailersSuccess(state, action);
        case actionTypes.GET_LOCATIONTRAILERS_FAIL:
            return getLocationTrailersFail(state, action);
        case actionTypes.GET_TRAILERLOCATIONS_START:
            return getTrailerLocationsStart(state, action);
        case actionTypes.GET_TRAILERLOCATIONS_SUCCESS:
            return getTrailerLocationsSuccess(state, action);
        case actionTypes.GET_TRAILERLOCATIONS_FAIL:
            return getTrailerLocationsFail(state, action);
        case actionTypes.SET_LOCATIONTRAILERS_START:
            return setLocationTrailersStart(state, action);
        case actionTypes.SET_LOCATIONTRAILERS_SUCCESS:
            return setLocationTrailersSuccess(state, action);
        case actionTypes.SET_LOCATIONTRAILERS_FAIL:
            return setLocationTrailersFail(state, action);
        case actionTypes.SET_LOCATIONTRAILERS_RESET:
            return setLocationTrailersReset(state, action);
        case actionTypes.SET_TRAILERLOCATIONS_START:
            return setTrailerLocationsStart(state, action);
        case actionTypes.SET_TRAILERLOCATIONS_SUCCESS:
            return setTrailerLocationsSuccess(state, action);
        case actionTypes.SET_TRAILERLOCATIONS_FAIL:
            return setTrailerLocationsFail(state, action);
        case actionTypes.SET_TRAILERLOCATIONS_RESET:
            return setTrailerLocationsReset(state, action);
        case actionTypes.UPDATE_ASSIGNED_DATES_START:
            return updateAssignedDatesStart(state, action);
        case actionTypes.UPDATE_ASSIGNED_DATES_SUCCESS:
            return updateAssignedDatesSuccess(state, action);
        case actionTypes.UPDATE_ASSIGNED_DATES_FAIL:
            return updateAssignedDatesFail(state, action);
        case actionTypes.UPDATE_ASSIGNED_DATES_RESET:
            return updateAssignedDatesReset(state, action);
        case actionTypes.DELETE_LOCATIONTRAILERS_START:
            return deleteLocationTrailersStart(state, action);
        case actionTypes.DELETE_LOCATIONTRAILERS_SUCCESS:
            return deleteLocationTrailersSuccess(state, action);
        case actionTypes.DELETE_LOCATIONTRAILERS_FAIL:
            return deleteLocationTrailersFail(state, action);
        case actionTypes.DELETE_LOCATIONTRAILERS_RESET:
            return deleteLocationTrailersReset(state, action);
        case actionTypes.DELETE_TRAILERLOCATIONS_START:
            return deleteTrailerLocationsStart(state, action);
        case actionTypes.DELETE_TRAILERLOCATIONS_SUCCESS:
            return deleteTrailerLocationsSuccess(state, action);
        case actionTypes.DELETE_TRAILERLOCATIONS_FAIL:
            return deleteTrailerLocationsFail(state, action);
        case actionTypes.DELETE_TRAILERLOCATIONS_RESET:
            return deleteTrailerLocationsReset(state, action);
        case actionTypes.SET_SELECTED_TRAILERS:
            return setSelectedTrailers(state, action);
        case actionTypes.RESET_SELECTED_TRAILERS:
            return resetSelectedTrailers(state, action);
        case actionTypes.SET_SELECTED_DATERANGE:
            return setSelectedDateRange(state, action);
        case actionTypes.RESET_SELECTED_DATERANGE:
            return resetSelectedDateRange(state, action);
        case actionTypes.SET_TRAILER:
            return setTrailer(state, action);
        case actionTypes.RESET_TRAILER:
            return resetTrailer(state, action);
        default:
            return state;
    }
};

export default reducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions/index';

import {
    ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper,
    FilledButton, IconButton, TextButton
} from '@jsluna/button';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { FormGroup } from '@jsluna/form';
import { Modal, ModalHeading } from '@jsluna/modal';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import {
    TableBody, TableCell, TableContainer, TableHeader, TableHeaderCell,
    TableHeaderRow, TableRow
} from '@jsluna/table';
import { Download, NewWindow } from '@jsluna/icons';

import { userCanEdit } from '../../shared/utility';

import Input from '../../components/UI/Input/Input';

import classes from './Uploads.module.scss';

import saveAs from 'file-saver';
import moment from 'moment';

moment.locale('en-GB');

class Uploads extends Component {
    state = {
        cyclesDownloadEnabled: false,
        cycleFocused: false,
        downloadEnabled: false,
        downloadMissingEnabled: false,
        downloadModalOpen: false,
        downloadWeeks: 1,
        downloadWeeksValid: true,
        focused: false,
        missingFocused: false,
        missingDownloadEnabled: false,
        modalOpen: false,
        schedulesDownloadEnabled: false,
        schedulesFocused: false,
        selCycleDate: moment(),
        selDate: moment(),
        selMissingDate: moment(),
        selSchedulesDate: moment(),
        transDownloadEnabled: false,
        transSchedulesFocused: false,
        selTransSchedulesDate: moment()
    };

    componentDidMount() {
        this.props.onGetMissingCodes(this.state.selDate.format('YYYY-MM-DD'));
    }

    componentDidUpdate(_, prevState) {
        if (!this.props.fileLoading && this.props.fileData && this.state.downloadEnabled)
            this.saveDownload();

        if (!this.props.cyclesDownloading && this.props.cyclesDownload && this.state.cyclesDownloadEnabled)
            this.saveCyclesDownload();

        if (!this.props.missingDownloading && this.props.missingDownload && this.state.missingDownloadEnabled)
            this.saveMissingDownload();

        if (!prevState.selDate.isSame(this.state.selDate))
            this.props.onGetMissingCodes(this.state.selDate.format('YYYY-MM-DD'));

        if (!this.props.schedulesLoading && this.props.schedulesFileData && this.state.schedulesDownloadEnabled)
            this.saveSchedulesDownload();

        if (!this.props.transDownloading && this.props.transData && this.state.transDownloadEnabled)
            this.saveTransportDownload();
    }

    handleDownload = () => {
        this.setState({ downloadEnabled: true });
        // send data to api
        this.props.onDownloadCodes(this.state.selDate);
    };

    handleCyclesDownload = () => {
        this.setState({ cyclesDownloadEnabled: true });
        // send data to api
        this.props.onDownloadCycles(this.state.selCycleDate);
    };

    handleMissingDownload = () => {
        this.setState({ missingDownloadEnabled: true });
        this.props.onDownloadMissing(this.state.selMissingDate);
    }

    saveDownload = () => {
        const blob = new Blob([this.props.fileData], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'AIP_Codes.xlsx');
        this.setState({ downloadEnabled: false });
    };

    saveCyclesDownload = () => {
        const blob = new Blob([this.props.cyclesDownload], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'AIP_Cycles.xlsx');
        this.setState({ cyclesDownloadEnabled: false });
    };

    saveMissingDownload = () => {
        const blob = new Blob([this.props.missingDownload], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'Missing_Code_Detail.xlsx');
        this.setState({ missingDownloadEnabled: false });
    };

    dateChangedHandler = (event, type) => this.setState({ [`sel${type}Date`]: event });

    missingClickHandler = () => this.setState({ modalOpen: true });

    requestHandler = () => {
        // send request for missing codes
        this.props.onSendAllRequests(this.state.selDate.format('YYYY-MM-DD'));
        this.setState({ modalOpen: false });
    }

    schedulesDownloadHandler = () => {
        const downloadDate = this.state.selSchedulesDate.toDate();

        this.setState({ schedulesDownloadEnabled: true, downloadModalOpen: false });
        this.props.onSchedulesDownload(downloadDate, this.state.downloadWeeks);
    }

    downloadWeeksChangedHandler = event => {
        let valid = true;
        const val = +event.target.value;

        if (isNaN(val))
            valid = false;

        if (valid)
            if (val < 1 || val > 99)
                valid = false;

        this.setState({ downloadWeeksValid: valid, downloadWeeks: val });
    }

    saveSchedulesDownload = () => {
        const blob = new Blob([this.props.schedulesFileData], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'Schedules.xlsx');
        this.setState({ schedulesDownloadEnabled: false });
    }

    transportDownloadHandler = () => {
        this.setState({ transDownloadEnabled: true });
        this.props.onTransportDownload(this.state.selTransSchedulesDate.format('YYYY-MM-DD'));
    }

    saveTransportDownload = () => {
        const blob = new Blob([this.props.transData], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'Transport Schedule.xlsx');
        this.setState({ transDownloadEnabled: false });
    }

    render() {
        const isUserRole = userCanEdit(this.props.roles);
        const downloadInProgress =
            this.props.fileLoading ||
            this.props.cyclesDownloading ||
            this.props.schedulesLoading ||
            this.props.transDownloading ||
            this.props.missingDownloading;

        const downloading = (
            <ProgressIndicator page loading={downloadInProgress}>
                <ProgressSpinner />
                Creating Download...
            </ProgressIndicator>
        );

        const loading = (
            <ProgressIndicator page loading={this.props.codesLoading}>
                <ProgressSpinner light />
                Checking for missing codes...
            </ProgressIndicator>
        );

        const sending = (
            <ProgressIndicator page loading={this.props.sending}>
                <ProgressSpinner light />
                Sending missing codes requests...
            </ProgressIndicator>
        );

        const options = [];
        for (let o = 1; o < 25; o++) options.push({ label: o.toString(), value: o });

        const downloadModal = (
            <Modal
                fullScreen restrictClose alert
                className={classes.modalWidth}
                handleClose={() => this.setState({ downloadModalOpen: false })}
                open={this.state.downloadModalOpen} >
                <ModalHeading element="h3">How Many Weeks?</ModalHeading>
                <p>Please select the number of consecutive weeks you would like to download.</p>
                <Input
                    style={{ width: '8rem' }}
                    elementType="select"
                    options={options}
                    id="downloadWeeks"
                    errorMessage={this.state.downloadWeeksValid ? '' : 'Invalid number of weeks'}
                    invalid={this.state.downloadWeeksValid}
                    changed={event => this.downloadWeeksChangedHandler(event)}
                    value={this.state.downloadWeeks.toString()} />
                <ButtonGroupWrapper actionBar>
                    <ButtonGroupPrimary>
                        <FilledButton onClick={this.schedulesDownloadHandler} disabled={!this.state.downloadWeeksValid}>Download</FilledButton>
                    </ButtonGroupPrimary>
                    <ButtonGroupSecondary>
                        <TextButton onClick={() => this.setState({ downloadModalOpen: false })}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                </ButtonGroupWrapper>
            </Modal>
        );

        const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        let missingModal = null;

        if (this.props.cycles)
            missingModal = (
                <Modal
                    fullScreen alert
                    handleClose={() => this.setState({ modalOpen: false })}
                    open={this.state.modalOpen}>
                    <ModalHeading element="h3">Missing AIP Codes</ModalHeading>
                    <p>The following order cycles have no matching AIP codes:</p>
                    <TableContainer>
                        <TableHeader>
                            <TableHeaderRow>
                                {[1, 2].map(w => weekDays.map(wd => <TableHeaderCell key={`${wd}${w}`}>{wd}</TableHeaderCell>))}
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody>{
                            this.props.cycles.map(c => {
                                const days = Array.from(c);
                                const repeat = [1];
                                if (days.length < 8) repeat.push(2);

                                return (
                                    <TableRow key={c}>{
                                        repeat.map(r => days.map((d, idx) =>
                                            <TableCell key={`${idx}_${r}`} className={`ln-u-color-grey${idx % 2 === 0 ? '-dark' : ''}`}>{d}</TableCell>))
                                    }
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </TableContainer>
                    <ButtonGroupWrapper actionBar>
                        <ButtonGroupPrimary>
                            <FilledButton onClick={this.requestHandler}>Request</FilledButton>
                        </ButtonGroupPrimary>
                        <ButtonGroupSecondary>
                            <TextButton onClick={() => this.setState({ modalOpen: false })}>Cancel</TextButton>
                        </ButtonGroupSecondary>
                    </ButtonGroupWrapper>
                </Modal>
            );

        let missing = null;

        if ((this.props.cycles || []).length > 0) {
            const missingCodes = this.props.cycles;

            missing = (
                <GridItem size="1/1">
                    <h6
                        onClick={this.missingClickHandler}
                        className="ln-u-color-red ln-u-text-align-center">
                        {missingCodes.length} code{missingCodes.length > 1 ? 's' : ''} missing for this period!
                    </h6>
                </GridItem>
            );
        }

        const content = (
            <Container className="ln-u-soft-lg">
                {downloading}
                {loading}
                {missingModal}
                {sending}
                <GridWrapper>
                    <GridItem size="1/1">
                        <h4>Downloads and Uploads</h4>
                    </GridItem>
                    <GridItem size="1/1">
                        <GridWrapper equalHeight element="ul">
                            <GridItem size="1/3" element="li">
                                <div className="ln-u-bg-color-white ln-u-soft ln-u-push-top">
                                    <GridWrapper>
                                        <GridItem size="1/1" className="ln-u-text-align-center">
                                            <h5>Download Schedules</h5>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <FormGroup label="Select the Week Commencing date" name="schdownload">
                                                <Input
                                                    elementType="date"
                                                    id="schedulesDate"
                                                    value={this.state.selSchedulesDate}
                                                    focused={this.state.schedulesFocused}
                                                    dateChanged={event => this.dateChangedHandler(event, 'Schedules')}
                                                    onFocused={foc => this.setState({ schedulesFocused: foc.focused })}
                                                     />
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <IconButton
                                                className="ln-u-push-top"
                                                onClick={() => this.setState({ downloadModalOpen: true })}
                                                variant="outlined"
                                                label="Download"
                                                hideLabel >
                                                <Download />
                                            </IconButton>
                                        </GridItem>
                                    </GridWrapper>
                                </div>
                            </GridItem>
                            <GridItem size="1/3" element="li">
                                <div className="ln-u-bg-color-white ln-u-soft ln-u-push-top">
                                    <GridWrapper>
                                        <GridItem size="1/1" className="ln-u-text-align-center">
                                            <h5>Download Transport Schedules</h5>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <FormGroup label="Select the Week Commencing date" name="tschdownload">
                                                <Input
                                                    elementType="date"
                                                    id="tschedulesDate"
                                                    value={this.state.selTransSchedulesDate}
                                                    focused={this.state.transSchedulesFocused}
                                                    dateChanged={event => this.dateChangedHandler(event, 'TransSchedules')}
                                                    onFocused={foc => this.setState({ transSchedulesFocused: foc.focused })}
                                                     />
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <IconButton
                                                className="ln-u-push-top"
                                                onClick={this.transportDownloadHandler}
                                                variant="outlined"
                                                label="Download"
                                                hideLabel >
                                                <Download />
                                            </IconButton>
                                        </GridItem>
                                    </GridWrapper>
                                </div>
                            </GridItem>
                            <GridItem size="1/3" element="li">
                                <div className="ln-u-bg-color-white ln-u-soft ln-u-push-top">
                                    <GridWrapper>
                                        <GridItem size="1/1" className="ln-u-text-align-center">
                                            <h5>Download Codes</h5>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <FormGroup label="Select the Week Commencing date" name="acdownload">
                                                <Input
                                                    elementType="date"
                                                    id="aipCodesDate"
                                                    value={this.state.selDate}
                                                    focused={this.state.focused}
                                                    dateChanged={event => this.dateChangedHandler(event, '')}
                                                    onFocused={foc => this.setState({ focused: foc.focused })}
                                                     />
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <IconButton
                                                className="ln-u-push-top"
                                                onClick={this.handleDownload}
                                                variant="outlined"
                                                label="Download"
                                                hideLabel >
                                                <Download />
                                            </IconButton>
                                        </GridItem>
                                        {missing}
                                    </GridWrapper>
                                </div>
                            </GridItem>
                            <GridItem size="1/3" element="li">
                                <div className="ln-u-bg-color-white ln-u-soft ln-u-push-top">
                                    <GridWrapper>
                                        <GridItem size="1/1" className="ln-u-text-align-center">
                                            <h5>Download Missing Code Detail</h5>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <FormGroup label="Select the Week Commencing date" name="acdownload">
                                                <Input
                                                    elementType="date"
                                                    id="aipMissingCodesDate"
                                                    value={this.state.selMissingDate}
                                                    focused={this.state.missingFocused}
                                                    dateChanged={event => this.dateChangedHandler(event, 'Missing')}
                                                    onFocused={foc => this.setState({ missingFocused: foc.focused })}
                                                    />
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <IconButton
                                                className="ln-u-push-top"
                                                onClick={this.handleMissingDownload}
                                                variant="outlined"
                                                label="Download"
                                                hideLabel >
                                                <Download />
                                            </IconButton>
                                        </GridItem>
                                        {missing}
                                    </GridWrapper>
                                </div>
                            </GridItem>
                            <GridItem size="1/3" element="li">
                                <div className="ln-u-bg-color-white ln-u-soft ln-u-push-top">
                                    <GridWrapper>
                                        <GridItem size="1/1" className="ln-u-text-align-center">
                                            <h5>Download Cycles</h5>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <FormGroup label="Select the Week Commencing date" name="acdownload">
                                                <Input
                                                    elementType="date"
                                                    id="aipCyclesDate"
                                                    value={this.state.selCycleDate}
                                                    focused={this.state.cycleFocused}
                                                    dateChanged={event => this.dateChangedHandler(event, 'Cycle')}
                                                    onFocused={foc => this.setState({ cycleFocused: foc.focused })}
                                                     />
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem size="1/2" className="ln-u-push-top ln-u-text-align-center">
                                            <IconButton
                                                className="ln-u-push-top"
                                                onClick={this.handleCyclesDownload}
                                                variant="outlined"
                                                label="Download"
                                                hideLabel >
                                                <Download />
                                            </IconButton>
                                        </GridItem>
                                        {missing}
                                    </GridWrapper>
                                </div>
                            </GridItem>
                            <GridItem size="1/3" element="li" className="ln-u-text-align-center">
                                <div className="ln-u-bg-color-white ln-u-soft ln-u-push-top">
                                    <GridWrapper>
                                        <GridItem size="1/1">
                                            <IconButton
                                                disabled={!isUserRole}
                                                onClick={() => this.props.history.push('/aipchange')}
                                                variant="text"
                                                label="Go to Change Request" >
                                                <NewWindow />
                                            </IconButton>
                                        </GridItem>
                                        <GridItem size="1/1">
                                            <IconButton
                                                disabled={!isUserRole}
                                                onClick={() => this.props.history.push('/newcode')}
                                                variant="text"
                                                label="Request New Code" >
                                                <NewWindow />
                                            </IconButton>
                                        </GridItem>
                                        <GridItem size="1/1">
                                            <IconButton
                                                onClick={() => this.props.history.push('/compare')}
                                                variant="text"
                                                label="Compare Cycles" >
                                                <NewWindow />
                                            </IconButton>
                                        </GridItem>
                                        <GridItem size="1/1">
                                            <IconButton
                                                onClick={() => this.props.history.push('/massupload')}
                                                variant="text"
                                                label="Mass Uploads" >
                                                <NewWindow />
                                            </IconButton>
                                        </GridItem>
                                    </GridWrapper>
                                </div>
                            </GridItem>
                        </GridWrapper>
                    </GridItem>
                </GridWrapper>
                {downloadModal}
            </Container>
        );

        return content;
    }
}

Uploads.propTypes = {
    codesLoading: PropTypes.bool,
    cycles: PropTypes.array,
    cyclesDownload: PropTypes.any,
    cyclesDownloading: PropTypes.bool,
    fileData: PropTypes.any,
    fileLoading: PropTypes.bool,
    history: PropTypes.any,
    missingDownloading: PropTypes.bool,
    onDownloadCodes: PropTypes.func.isRequired,
    onDownloadCycles: PropTypes.func.isRequired,
    onDownloadMissing: PropTypes.func.isRequired,
    onGetMissingCodes: PropTypes.func.isRequired,
    onSchedulesDownload: PropTypes.func.isRequired,
    onSendAllRequests: PropTypes.func.isRequired,
    onTransportDownload: PropTypes.func.isRequired,
    roles: PropTypes.array,
    schedulesFileData: PropTypes.any,
    schedulesLoading: PropTypes.bool,
    sending: PropTypes.bool,
    transData: PropTypes.any,
    transDownloading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        codesLoading: state.aipSchedule.codesLoading,
        cycles: state.aipSchedule.cycles,
        cyclesDownload: state.orderCycle.cyclesDownload,
        cyclesDownloading: state.orderCycle.cyclesDownloading,
        fileData: state.aipSchedule.fileData,
        fileLoading: state.aipSchedule.fileLoading,
        missingDownload: state.orderCycle.missingDownload,
        missingDownloading: state.orderCycle.missingDownloading,
        roles: state.auth.roles,
        schedulesFileData: state.aipSchedule.schedulesFileData,
        schedulesLoading: state.aipSchedule.schedulesLoading,
        sending: state.orderCycle.sending,
        transData: state.aipSchedule.transportData,
        transDownloading: state.aipSchedule.transDownloading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDownloadCodes: downloadDate => dispatch(actions.codesDownload(downloadDate)),
        onDownloadCycles: downloadDate => dispatch(actions.downloadOrderCycles(downloadDate)),
        onDownloadMissing: downloadDate => dispatch(actions.downloadMissing(downloadDate)),
        onGetMissingCodes: wcDate => dispatch(actions.getMissingCodes(wcDate)),
        onSchedulesDownload: (downloadDate, downloadWeeks) => dispatch(actions.schedulesDownload(downloadDate, downloadWeeks)),
        onSendAllRequests: wcDate => dispatch(actions.sendAllCodeRequests(wcDate)),
        onTransportDownload: wcDate => dispatch(actions.transportDownload(wcDate))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Uploads, axios));

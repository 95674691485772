import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    page: null,
    count: null
};

const getAuditLogCountStart = state => updateObject(state, { gettingCount: true });
const getAuditLogCountSuccess = (state, action) => updateObject(state, { gettingCount: false, count: action.count });
const getAuditLogCountFail = state => updateObject(state, { gettingCount: false, countError: true });

const getAuditLogPageStart = state => updateObject(state, { gettingPage: true });
const getAuditLogPageSuccess = (state, action) => updateObject(state, { gettingPage: false, page: action.page });
const getAuditLogPageFail = state => updateObject(state, { gettingPage: false, pageError: true });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AUDIT_LOG_COUNT_START: return getAuditLogCountStart(state);
        case actionTypes.GET_AUDIT_LOG_COUNT_SUCCESS: return getAuditLogCountSuccess(state, action);
        case actionTypes.GET_AUDIT_LOG_COUNT_FAIL: return getAuditLogCountFail(state);
        case actionTypes.GET_AUDIT_LOG_PAGE_START: return getAuditLogPageStart(state);
        case actionTypes.GET_AUDIT_LOG_PAGE_SUCCESS: return getAuditLogPageSuccess(state, action);
        case actionTypes.GET_AUDIT_LOG_PAGE_FAIL: return getAuditLogPageFail(state);
        default: return state;
    }
};

export default reducer;

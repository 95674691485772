import React, { useEffect, useState } from 'react';
import {
    ButtonGroupPrimary,
    ButtonGroupSecondary,
    ButtonGroupWrapper,
    FilledButton,
    OutlinedButton,
    TextButton
} from '@jsluna/button';
import { Card } from '@jsluna/card';
import {
    Container,
    GridItem,
    GridWrapper
} from '@jsluna/grid';
import {
    ListGroup,
    ListGroupItem
} from '@jsluna/list';
import {
    ProgressIndicator,
    ProgressSpinner
} from '@jsluna/progress';
import { Calendar, Minus, Plus } from '@jsluna/icons';
import { Modal, ModalHeading } from '@jsluna/modal';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import axios from '../../../axios';
import { connect } from 'react-redux';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import PropTypes from 'prop-types';
import moment from 'moment';
import TrailerSelection from '../../TrailerSelection/TrailerSelection';
import LocationsSelection from '../LocationsSelection/LocationsSelection';
import DateRangeSelection from '../../DateRangeSelection/DateRangeSelection';

import LocationSelect from '../../Location/LocationSelect';
import TrailerSelect from './TrailerSelect';

import * as actions from '../../../store/actions';

import classes from './LocationTrailers.module.scss';

import { Growl } from 'primereact/growl';

// import PropTypes from 'prop-types';

const LocationTrailers = props => {
    const {
        assignSelectedLocations,
        assignSelectedLocationsFromDate,
        assignSelectedLocationsToDate,
        assignSelectedTrailers,
        assignSelectedTrailersFromDate,
        assignSelectedTrailersToDate,
        selectedMasterLocationId,
        selectedMasterTrailerId,
        trailers,
        trailersError,
        trailersIsError,
        locations,
        onClearDates,
        onClearLocationId,
        onClearTrailerId,
        onGetLocationTrailers,
        onGetTrailerLocations,
        onSetLocationTrailers,
        onSetLocationTrailersReset,
        onSetTrailerLocations,
        onSetTrailerLocationsReset,
        onDeleteLocationTrailers,
        onDeleteLocationTrailersReset,
        onDeleteTrailerLocations,
        onDeleteTrailerLocationsReset,
        onSetSelectedLocations,
        onSetSelectedTrailers,
        isLoading,
        setLocationTrailersFail,
        setLocationTrailersItems,
        setLocationTrailersSuccess,
        setTrailerLocationsFail,
        setTrailerLocationsItems,
        setTrailerLocationsSuccess,
        unAssignedDetails,
        unAssignedLocationDetails,
        updatedSelectedFromDate,
        updatedSelectedToDate,
        onUpdateAssignedDates,
        onUpdateAssignedDatesReset,
        updateAssignedDatesFail,
        updateAssignedDatesSuccess,
        updatedAssignedDatesItems,
        yearStartExceptions,
        yearStartExceptionsLoading
    } = props;
    let growl;

    const [selectedTrailers, setSelectedTrailers] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [showTrailerSelectModal, setShowTrailerSelectModal] = useState(false);
    const [showUnAssignModal, setShowUnAssignModal] = useState(false);
    const [showChangeDatesModal, setShowChangeDatesModal] = useState(false);
    const [showLocationSelectModal, setShowLocationSelectModal] = useState(false);
    const [showLocationUnAssignModal, setShowLocationUnAssignModal] = useState(false);
    const [locationRows, setLocationRows] = useState(null);
    const [trailerRows, setTrailerRows] = useState(null);
    const [selectedMasterLocation, setSelectedMasterLocation] = useState(null);
    const [selectedMasterTrailer, setSelectedMasterTrailer] = useState(null);
    const [yearStartExceptionsState, setYearStartExceptionsState] = useState(null);

    /* Get YearStartExceptions */
    useEffect(() => {
        if (yearStartExceptions && !yearStartExceptionsLoading && !yearStartExceptionsState) {
            setYearStartExceptionsState(yearStartExceptions);
        }
    }, [yearStartExceptionsState, setYearStartExceptionsState, yearStartExceptions, yearStartExceptionsLoading]);

    /* Trigger view to assign trailers to single location */
    useEffect(() => {
        if (selectedMasterLocationId && selectedMasterLocation !== selectedMasterLocationId) {
            setSelectedMasterLocation(selectedMasterLocationId);
            onGetLocationTrailers(selectedMasterLocationId);
            setSelectedMasterTrailer(null);
            onClearTrailerId();
            setLocationRows(null);
        }
        else if (
            selectedMasterTrailerId &&
            selectedMasterTrailer &&
            (!selectedMasterLocationId || selectedMasterLocationId < 1) &&
            trailerRows &&
            trailerRows.length > 0
        ) {
            setTrailerRows(null);
        }
    }, [
        selectedMasterTrailer,
        selectedMasterTrailerId,
        selectedMasterLocationId,
        onGetLocationTrailers,
        selectedMasterLocation,
        setSelectedMasterLocation,
        trailerRows,
        setLocationRows,
        setTrailerRows,
        onClearTrailerId
    ]);

    /* Trigger view to assign locations to single trailer */
    useEffect(() => {
        if (selectedMasterTrailerId && selectedMasterTrailer !== selectedMasterTrailerId) {
            setSelectedMasterTrailer(selectedMasterTrailerId);
            onGetTrailerLocations(selectedMasterTrailerId);
            onClearLocationId();
            setSelectedMasterLocation(null);
            setTrailerRows(null);
        }
        else if (
            selectedMasterLocationId &&
            selectedMasterLocation &&
            (!selectedMasterTrailerId || selectedMasterTrailerId < 1) &&
            locationRows &&
            locationRows.length > 0
        ) {
            setLocationRows(null);
        }
    }, [
        selectedMasterLocation,
        selectedMasterLocationId,
        selectedMasterTrailerId,
        onGetTrailerLocations,
        selectedMasterTrailer,
        setSelectedMasterTrailer,
        setTrailerRows,
        locationRows,
        setLocationRows,
        onClearLocationId
    ]);

    useEffect(() => {
        if (trailers && trailers.length > 0) {
            setTrailerRows(trailers);
        }
        else if (trailers) {
            setTrailerRows([]);
        }
    }, [trailers, setTrailerRows]);

    useEffect(() => {
        if (locations && locations.length > 0) {
            setLocationRows(locations);
        }
        else if (locations) {
            setLocationRows([]);
        }
    }, [locations, setLocationRows]);

    useEffect(() => {
        if (updatedSelectedFromDate && updatedSelectedFromDate !== null && updatedSelectedToDate && updatedSelectedToDate !== null) {
            if (trailerRows && trailerRows.length > 0 && selectedTrailers && selectedTrailers.length > 0) {
                onUpdateAssignedDates(
                    selectedTrailers.map(t => {
                        return {
                            detailId: t.detailId,
                            trailerId: t.trailerId,
                            code: t.code,
                            description: t.description,
                            fromDate: updatedSelectedFromDate,
                            toDate: updatedSelectedToDate
                        };
                    })
                );
            }
            else if (locationRows && locationRows.length > 0 && selectedLocations && selectedLocations.length > 0) {
                onUpdateAssignedDates(
                    selectedLocations.map(t => {
                        return {
                            detailId: t.detailId,
                            locationHeaderId: t.locationHeaderId,
                            locationNo: t.locationNo,
                            locationName: t.locationName,
                            fromDate: updatedSelectedFromDate,
                            toDate: updatedSelectedToDate
                        };
                    })
                );
            }
            onClearDates();
        }
    }, [trailerRows, selectedTrailers, locationRows, selectedLocations, onUpdateAssignedDates, onClearDates, updatedSelectedToDate, updatedSelectedFromDate]);

    useEffect(() => {
        if (updateAssignedDatesSuccess === true) {
            growl.show({ severity: 'success', summary: 'Dates updated.' });
            if (trailerRows && trailerRows.length > 0) {
                const newTrailerRows = trailerRows.map(t => {
                    const updatedItem = updatedAssignedDatesItems.filter(a => a.detailId === t.detailId);
                    if (updatedItem.length > 0) {
                        return { ...t, fromDate: updatedItem[0].fromDate, toDate: updatedItem[0].toDate };
                    }
                    return t;
                });
                setTrailerRows(newTrailerRows);
                setSelectedTrailers([]);
            }
            else if (locationRows && locationRows.length > 0) {
                const newLocationRows = locationRows.map(t => {
                    const updatedItem = updatedAssignedDatesItems.filter(a => a.detailId === t.detailId);
                    if (updatedItem.length > 0) {
                        return { ...t, fromDate: updatedItem[0].fromDate, toDate: updatedItem[0].toDate };
                    }
                    return t;
                });
                setLocationRows(newLocationRows);
                setSelectedLocations([]);
            }
        }
        else if (updateAssignedDatesFail === true) {
            growl.show({ severity: 'error', summary: 'Date range update failed' });
            onUpdateAssignedDatesReset();
        }
        onUpdateAssignedDatesReset();
    }, [updateAssignedDatesSuccess, updateAssignedDatesFail, onUpdateAssignedDatesReset, growl, locationRows, trailerRows, updatedAssignedDatesItems]);

    useEffect(() => {
        if (assignSelectedTrailers && assignSelectedTrailers.length > 0) {
            onSetLocationTrailers(
                selectedMasterLocationId,
                assignSelectedTrailers.map(i => i.id ? i.id : i),
                assignSelectedTrailersFromDate,
                assignSelectedTrailersToDate
            );
            onSetSelectedTrailers(null);
        }
    }, [
        assignSelectedTrailers,
        onSetSelectedTrailers,
        onSetLocationTrailers,
        selectedMasterLocationId,
        assignSelectedTrailersFromDate,
        assignSelectedTrailersToDate
    ]);

    useEffect(() => {
        if (assignSelectedLocations && assignSelectedLocations.length > 0) {
            onSetTrailerLocations(
                selectedMasterTrailerId,
                assignSelectedLocations.map(i => i.id ? i.id : i),
                assignSelectedTrailersFromDate,
                assignSelectedTrailersToDate
            );
            onSetSelectedLocations(null);
        }
    }, [
        assignSelectedLocations,
        assignSelectedLocationsFromDate,
        assignSelectedLocationsToDate,
        onSetSelectedLocations,
        onSetTrailerLocations,
        selectedMasterTrailerId,
        assignSelectedTrailersFromDate,
        assignSelectedTrailersToDate
    ]);

    useEffect(() => {
        if (setLocationTrailersSuccess === true && setLocationTrailersItems && setLocationTrailersItems.length > 0) {
            onSetLocationTrailersReset();
            setTrailerRows(setLocationTrailersItems.concat(trailerRows));
            growl.show({ severity: 'success', summary: 'Trailers assigned' });
        }
        else if (setLocationTrailersFail === true) {
            onSetLocationTrailersReset();
            growl.show({ severity: 'error', summary: 'Assigning trailers failed' });
        }
    }, [
        assignSelectedTrailers,
        onSetSelectedTrailers,
        setLocationTrailersItems,
        selectedTrailers,
        growl,
        trailerRows,
        setLocationTrailersFail,
        setLocationTrailersSuccess,
        onSetLocationTrailersReset
    ]);

    useEffect(() => {
        if (setTrailerLocationsSuccess === true && setTrailerLocationsItems && setTrailerLocationsItems.length > 0) {
            onSetTrailerLocationsReset();
            setLocationRows(setTrailerLocationsItems.concat(locationRows));
            growl.show({ severity: 'success', summary: 'Locations assigned' });
        }
        else if (setTrailerLocationsFail === true) {
            onSetTrailerLocationsReset();
            growl.show({ severity: 'error', summary: 'Assigning locations failed' });
        }
    }, [
        assignSelectedLocations,
        onSetSelectedLocations,
        setTrailerLocationsItems,
        selectedLocations,
        growl,
        locationRows,
        setTrailerLocationsFail,
        setTrailerLocationsSuccess,
        onSetTrailerLocationsReset
    ]);

    useEffect(() => {
        if (trailersIsError === true && unAssignedDetails && unAssignedDetails.length > 0) {
            growl.show({ severity: 'error', summary: 'Failed to un-assign locations' });
        }
        else if (trailersIsError === false && unAssignedDetails && unAssignedDetails.length > 0) {
            onDeleteTrailerLocationsReset();
            growl.show({ severity: 'success', summary: 'Locations Unassigned' });
            const newRows = locationRows.filter(r => unAssignedDetails.filter(d => d === r.detailId).length === 0);
            setShowUnAssignModal(false);
            setLocationRows(newRows);
        }
    }, [trailersError, trailersIsError, growl, locationRows, setLocationRows, onDeleteTrailerLocationsReset, unAssignedDetails]);

    useEffect(() => {
        if (trailersIsError === true && unAssignedLocationDetails && unAssignedLocationDetails.length > 0) {
            growl.show({ severity: 'error', summary: 'Failed to un-assign trailers' });
        }
        else if (trailersIsError === false && unAssignedLocationDetails && unAssignedLocationDetails.length > 0) {
            onDeleteLocationTrailersReset();
            growl.show({ severity: 'success', summary: 'Trailers Unassigned' });
            const newRows = trailerRows.filter(r => unAssignedLocationDetails.filter(d => d === r.detailId).length === 0);
            setShowUnAssignModal(false);
            setTrailerRows(newRows);
        }
    }, [trailersError, trailersIsError, growl, trailerRows, setTrailerRows, onDeleteLocationTrailersReset, unAssignedLocationDetails]);

    const loading = (
        <ProgressIndicator page loading={isLoading}>
            <ProgressSpinner light />
            Loading...
        </ProgressIndicator>
    );

    const dateTemplate = value => {
        if (value) {
            const date = moment(value);
            return <span>{date.format('DD/MM/YYYY')}</span>;
        }
    };

    const handleTrailerSelectionChange = e => {
        let value;
        if (Array.isArray(e.value)) {
            value = e.value;
        }
        else {
            value = e.data;
        }
        if (!e.originalEvent.ctrlKey) {
            if (Array.isArray(value)) {
                if (selectedTrailers && selectedTrailers.length === 1 && selectedTrailers.filter(r => r.trailerId === value[0].trailerId).length === 0) {
                    setSelectedTrailers([]);
                }
                else {
                    setSelectedTrailers(value);
                }
            }
            else {
                if (selectedTrailers && selectedTrailers.length === 1 && selectedTrailers.filter(r => r.trailerId === value.trailerId).length > 0) {
                    setSelectedTrailers([]);
                }
                else {
                    setSelectedTrailers([value]);
                }
            }
        }
        else if (Array.isArray(value)) {
            setSelectedTrailers(value);
        }
        else {
            setSelectedTrailers([value]);
        }
    };

    const handleLocationSelectionChange = e => {
        let value;
        if (Array.isArray(e.value)) {
            value = e.value;
        }
        else {
            value = e.data;
        }
        if (!e.originalEvent.ctrlKey) {
            if (Array.isArray(value)) {
                if (selectedLocations && selectedLocations.length === 1 && selectedLocations.filter(r => r.headerId === value[0].headerId).length === 0) {
                    setSelectedLocations([]);
                }
                else {
                    setSelectedLocations(value);
                }
            }
            else {
                if (selectedLocations && selectedLocations.length === 1 && selectedLocations.filter(r => r.headerId === value.headerId).length > 0) {
                    setSelectedLocations([]);
                }
                else {
                    setSelectedLocations([value]);
                }
            }
        }
        else if (Array.isArray(value)) {
            setSelectedLocations(value);
        }
        else {
            setSelectedLocations([value]);
        }
    };

    const trailerTable = (
        <DataTable
            value={trailerRows}
            responsive
            paginator
            rows={10}
            sortField="code"
            sortOrder={1}
            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
            onSelectionChange={e => handleTrailerSelectionChange(e)}
            onRowSelect={e => handleTrailerSelectionChange(e)}
            selectionMode="multiple"
            selection={selectedTrailers}
        >
            <Column field="code" header="Code" filter sortable bodyStyle={{ textAlign: 'left' }} style={{ textAlign: 'left', width: '200px' }} />
            <Column field="fromDate" header="From Date" filter sortable body={r => dateTemplate(r.fromDate)} style={{ width: '150px' }} />
            <Column field="toDate" header="To Date" filter sortable body={r => dateTemplate(r.toDate)} style={{ width: '150px' }} />
            <Column
                field="description"
                header="Description"
                filter
                sortable
                filterMatchMode="contains"
                bodyStyle={{ textAlign: 'left' }}
                style={{ textAlign: 'left' }}
            />
        </DataTable>
    );
    const locationTable = (
        <DataTable
            value={locationRows}
            responsive
            paginator
            rows={10}
            sortField="locationNo"
            sortOrder={1}
            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
            onSelectionChange={e => handleLocationSelectionChange(e)}
            onRowSelect={e => handleLocationSelectionChange(e)}
            selectionMode="multiple"
            selection={selectedLocations}
        >
            <Column field="locationNo" header="No" filter sortable bodyStyle={{ textAlign: 'left' }} style={{ textAlign: 'left', width: '200px' }} />
            <Column field="fromDate" header="From Date" filter sortable body={r => dateTemplate(r.fromDate)} style={{ width: '150px' }} />
            <Column field="toDate" header="To Date" filter sortable body={r => dateTemplate(r.toDate)} style={{ width: '150px' }} />
            <Column
                field="locationName"
                header="Name"
                filter
                sortable
                filterMatchMode="contains"
                bodyStyle={{ textAlign: 'left' }}
                style={{ textAlign: 'left' }}
            />
        </DataTable>
    );

    const deleteSelectedTrailers = () => {
        onDeleteLocationTrailers(
            selectedMasterLocation,
            selectedTrailers.map(t => t.detailId)
        );
    };

    const deleteSelectedLocations = () => {
        onDeleteTrailerLocations(
            selectedMasterTrailer,
            selectedLocations.map(t => t.detailId)
        );
    };

    const trailerUnAssignModal = (
        <Modal small handleClose={() => setShowUnAssignModal(!showUnAssignModal)} open={showUnAssignModal}>
            <ModalHeading element="h3">Un Assign Trailer{selectedTrailers.length > 1 ? 's' : ''}</ModalHeading>
            <p>Please click 'UN ASSIGN' again to confirm Un-assigning the below trailer{selectedTrailers.length > 1 ? 's' : ''}</p>
            <ul>
                {selectedTrailers.map((r, i) => {
                    return <li key={i}>{r.description}</li>;
                })}
            </ul>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => deleteSelectedTrailers()}>Un Assign</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setShowUnAssignModal(!showUnAssignModal)}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    const locationUnAssignModal = (
        <Modal small handleClose={() => setShowLocationUnAssignModal(!showLocationUnAssignModal)} open={showLocationUnAssignModal}>
            <ModalHeading element="h3">Un Assign Location{selectedLocations.length > 1 ? 's' : ''}</ModalHeading>
            <p>Please click 'UN ASSIGN' again to confirm Un-assigning the below location{selectedLocations.length > 1 ? 's' : ''}</p>
            <ul>
                {selectedLocations.map((r, i) => {
                    return <li key={i}>{r.locationName}</li>;
                })}
            </ul>
            <ButtonGroupWrapper>
                <ButtonGroupPrimary>
                    <FilledButton onClick={() => deleteSelectedLocations()}>Un Assign</FilledButton>
                </ButtonGroupPrimary>
                <ButtonGroupSecondary>
                    <TextButton onClick={() => setShowLocationUnAssignModal(!showLocationUnAssignModal)}>Cancel</TextButton>
                </ButtonGroupSecondary>
            </ButtonGroupWrapper>
        </Modal>
    );

    const selectionPageContent = (
        <Card>
            <GridWrapper>
                <GridItem size="1/4">
                    <LocationSelect />
                </GridItem>
                <GridItem size="1/2">
                    <TrailerSelect />
                </GridItem>
            </GridWrapper>
        </Card>
    );

    const trailerPageContent = (
        <Card>
            <GridWrapper>
                {selectionPageContent}
                <GridItem size="1/2">
                    <OutlinedButton onClick={() => setShowTrailerSelectModal(true)} style={{ marginLeft: '1rem' }} disabled={!selectedMasterLocation}>
                        <Plus className="ln-u-margin-right" />
                        Assign
                    </OutlinedButton>
                    <OutlinedButton
                        onClick={() => setShowUnAssignModal(true)}
                        style={{ marginLeft: '1rem' }}
                        disabled={!selectedTrailers || selectedTrailers.length === 0}
                    >
                        <Minus className="ln-u-margin-right" />
                        Un Assign
                    </OutlinedButton>
                    <OutlinedButton
                        onClick={() => setShowChangeDatesModal(true)}
                        style={{ marginLeft: '1rem' }}
                        disabled={!selectedTrailers || selectedTrailers.length === 0}
                    >
                        <Calendar className="ln-u-margin-right" />
                        Change Dates
                    </OutlinedButton>
                </GridItem>
                <GridItem size="1/1" className={classes.lunaDataTableStyle}>
                    {trailerTable}
                </GridItem>
            </GridWrapper>
        </Card>
    );
    const locationPageContent = (
        <Card>
            <GridWrapper>
                {selectionPageContent}
                <GridItem size="1/2">
                    <OutlinedButton onClick={() => setShowLocationSelectModal(true)} style={{ marginLeft: '1rem' }} disabled={!selectedMasterTrailer}>
                        <Plus className="ln-u-margin-right" />
                        Assign
                    </OutlinedButton>
                    <OutlinedButton
                        onClick={() => setShowLocationUnAssignModal(true)}
                        style={{ marginLeft: '1rem' }}
                        disabled={!selectedLocations || selectedLocations.length === 0}
                    >
                        <Minus className="ln-u-margin-right" />
                        Un Assign
                    </OutlinedButton>
                    <OutlinedButton
                        onClick={() => setShowChangeDatesModal(true)}
                        style={{ marginLeft: '1rem' }}
                        disabled={!selectedLocations || selectedLocations.length === 0}
                    >
                        <Calendar className="ln-u-margin-right" />
                        Change Dates
                    </OutlinedButton>
                </GridItem>
                <GridItem size="1/1" className={classes.lunaDataTableStyle}>
                    {locationTable}
                </GridItem>
            </GridWrapper>
        </Card>
    );

    const trailerSelectModal = () => {
        if (trailerRows === null) {
            return null;
        }
        else {
            return <TrailerSelection show={showTrailerSelectModal} close={() => setShowTrailerSelectModal(false)} assignedTrailers={trailerRows} />;
        }
    };

    const locationSelectModal = () => {
        if (locationRows === null) {
            return null;
        }
        else {
            return <LocationsSelection show={showLocationSelectModal} close={() => setShowLocationSelectModal(false)} assignedLocations={locationRows} />;
        }
    };

    const datesSelectModal = () => {
        if (trailerRows === null && locationRows === null) {
            return null;
        }
        else {
            return (
                <DateRangeSelection show={showChangeDatesModal} close={() => setShowChangeDatesModal(false)} yearStartExceptions={yearStartExceptionsState} />
            );
        }
    };

    return (
        <Container className="ln-u-bg-color-white">
            <ListGroup type="full" inline>
                <ListGroupItem element="a" href="#">
                    <h3 className="ln-u-flush-bottom">Trailer Management</h3>
                </ListGroupItem>
            </ListGroup>
            {isLoading
                ? loading
                : trailerRows && trailerRows !== null
                ? trailerPageContent
                : locationRows && locationRows !== null
                ? locationPageContent
                : selectionPageContent}
            {trailerSelectModal()}
            {locationSelectModal()}
            {trailerUnAssignModal}
            {locationUnAssignModal}
            {datesSelectModal()}
            <Growl className="customGrowl" ref={el => growl = el}></Growl>
        </Container>
    );
};

LocationTrailers.propTypes = {
    assignSelectedLocations: PropTypes.array,
    assignSelectedLocationsFromDate: PropTypes.any,
    assignSelectedLocationsToDate: PropTypes.any,
    assignSelectedTrailers: PropTypes.array,
    assignSelectedTrailersFromDate: PropTypes.any,
    assignSelectedTrailersToDate: PropTypes.any,
    isLoading: PropTypes.bool,
    locations: PropTypes.array,
    onClearDates: PropTypes.func,
    onClearLocationId: PropTypes.func,
    onClearTrailerId: PropTypes.func,
    onDeleteLocationTrailers: PropTypes.func,
    onDeleteLocationTrailersReset: PropTypes.func,
    onDeleteTrailerLocations: PropTypes.func,
    onDeleteTrailerLocationsReset: PropTypes.func,
    onGetLocationTrailers: PropTypes.func,
    onGetTrailerLocations: PropTypes.func,
    onSetLocationTrailers: PropTypes.func,
    onSetLocationTrailersReset: PropTypes.func,
    onSetSelectedLocations: PropTypes.func,
    onSetSelectedTrailers: PropTypes.func,
    onSetTrailerLocations: PropTypes.func,
    onSetTrailerLocationsReset: PropTypes.func,
    onUpdateAssignedDates: PropTypes.func,
    onUpdateAssignedDatesReset: PropTypes.func,
    selectedMasterLocationId: PropTypes.number,
    selectedMasterTrailerId: PropTypes.number,
    setLocationTrailersFail: PropTypes.bool,
    setLocationTrailersItems: PropTypes.array,
    setLocationTrailersSuccess: PropTypes.bool,
    setTrailerLocationsFail: PropTypes.bool,
    setTrailerLocationsItems: PropTypes.array,
    setTrailerLocationsSuccess: PropTypes.bool,
    trailers: PropTypes.array,
    trailersError: PropTypes.object,
    trailersIsError: PropTypes.bool,
    unAssignedDetails: PropTypes.array,
    unAssignedLocationDetails: PropTypes.array,
    updateAssignedDatesFail: PropTypes.bool,
    updateAssignedDatesSuccess: PropTypes.bool,
    updatedAssignedDatesItems: PropTypes.array,
    updatedSelectedFromDate: PropTypes.any,
    updatedSelectedToDate: PropTypes.any,
    yearStartExceptions: PropTypes.array,
    yearStartExceptionsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        selectedMasterLocationId: state.location.id,
        selectedMasterTrailerId: state.trailer.trailer,
        assignSelectedLocations: state.location.selectedLocations,
        assignSelectedLocationsFromDate: state.location.selectedLocationsFromDate,
        assignSelectedLocationsToDate: state.location.selectedLocationsToDate,
        assignSelectedTrailers: state.trailer.selectedTrailers,
        assignSelectedTrailersFromDate: state.trailer.selectedTrailersFromDate,
        assignSelectedTrailersToDate: state.trailer.selectedTrailersToDate,
        isLoading: state.trailer.loadingTrailers,
        updatedSelectedFromDate: state.trailer.selectedFromDate,
        updatedSelectedToDate: state.trailer.selectedToDate,
        setLocationTrailersSuccess: state.trailer.setLocationTrailersSuccess,
        setLocationTrailersItems: state.trailer.setLocationTrailersItems,
        setLocationTrailersFail: state.trailer.setLocationTrailersFail,
        setTrailerLocationsSuccess: state.trailer.setTrailerLocationsSuccess,
        setTrailerLocationsItems: state.trailer.setTrailerLocationsItems,
        setTrailerLocationsFail: state.trailer.setTrailerLocationsFail,
        trailersError: state.trailer.errorObject,
        trailersIsError: state.trailer.error,
        trailers: state.trailer.locationTrailers,
        locations: state.trailer.trailerLocations,
        unAssignedDetails: state.trailer.deletedDetailIds,
        unAssignedLocationDetails: state.trailer.deletedTrailerLocationIds,
        updateAssignedDatesSuccess: state.trailer.updateAssignedDatesSuccess,
        updateAssignedDatesFail: state.trailer.updateAssignedDatesFail,
        updatedAssignedDatesItems: state.trailer.updatedLocationTrailersItems,
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading,
        yearStartExceptionsError: state.general.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClearDates: () => dispatch(actions.setSelectedDateRange(null, null)),
        onClearLocationId: () => dispatch(actions.setLocation(null)),
        onClearTrailerId: () => dispatch(actions.setTrailer(null)),
        onGetLocationTrailers: locationId => dispatch(actions.getLocationTrailers(locationId)),
        onGetTrailerLocations: trailerId => dispatch(actions.getTrailerLocations(trailerId)),
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions()),
        onSetLocationTrailers: (locationId, trailers, dateFrom, dateTo) => dispatch(actions.setLocationTrailers(locationId, trailers, dateFrom, dateTo)),
        onSetLocationTrailersReset: () => dispatch(actions.setLocationTrailersReset()),
        onSetTrailerLocations: (trailerId, trailers, dateFrom, dateTo) => dispatch(actions.setTrailerLocations(trailerId, trailers, dateFrom, dateTo)),
        onSetTrailerLocationsReset: () => dispatch(actions.setTrailerLocationsReset()),
        onDeleteLocationTrailers: (locationId, detailIds) => dispatch(actions.deleteLocationTrailers(locationId, detailIds)),
        onDeleteLocationTrailersReset: () => dispatch(actions.deleteLocationTrailersReset()),
        onDeleteTrailerLocations: (trailerId, detailIds) => dispatch(actions.deleteTrailerLocations(trailerId, detailIds)),
        onDeleteTrailerLocationsReset: () => dispatch(actions.deleteTrailerLocationsReset()),
        onSetSelectedTrailers: trl => dispatch(actions.setSelectedTrailers(trl)),
        onSetSelectedLocations: loc => dispatch(actions.setSelectedLocations(loc)),
        onUpdateAssignedDates: data => dispatch(actions.updateAssignedDates(data)),
        onUpdateAssignedDatesReset: () => dispatch(actions.updateAssignedDatesReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(LocationTrailers, axios));


import React, { useState } from 'react';// useEffect
import { Link } from 'react-router-dom'; // withRouter
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import axios from '../../axios';
// import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
// import * as actions from '../../store/actions/index';

import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { Modal } from '@jsluna/modal';
import { SiteLayout } from '@jsluna/site-layout';
import ArgHeader from '../../containers/ArgHeader/ArgHeader';
import '@jsluna/style/css/main.css';
import classes from './Layout.module.scss';
import SiteSelection from '../../containers/SiteSelection/SiteSelection';

/* eslint-disable react-hooks/rules-of-hooks */
const layout = props => {

    const [isModalOpen, setModalState] = useState(false);
    const [isMenuOpen, setMenuModalState] = useState(false);
    const [menuList, setMenuListState] = useState([]);

    const siteModalHandler = () => setModalState(!isModalOpen);

    const menuModalHandler = (obj) => {
        setMenuModalState(!isMenuOpen);
        if (!isMenuOpen)
            setMenuListState(obj);
    };

    const menuItems = () => {
        if (!menuList)
            return;

        return menuList.map((m, ind) =>
            <GridItem key={ind} element="li" size="1/3">
                <Link
                    onClick={menuModalHandler}
                    to={m.to}>
                    <Card className={classes.cardLink} style={{ height: '100%' }}>
                        <h5>{m.title}</h5>
                        <p>{m.description}</p>
                    </Card>
                </Link>
            </GridItem>
        );
    };

    return (
        <SiteLayout stickyHeader className="ln-u-bg-color-grey-v-light">
            <ArgHeader open={siteModalHandler} openMenu={menuModalHandler} />
            <Container soft size="lg">
                {props.children}
            </Container>
            <SiteSelection show={isModalOpen} close={siteModalHandler} />
            <Modal
                className={classes.modal_bg}
                hideCloseButton
                handleClose={menuModalHandler}
                open={isMenuOpen} >
                <GridWrapper matrix element="ul" equalHeight className="ln-u-bg-color-grey-v-light ln-u-soft">
                    {menuItems()}
                </GridWrapper>
            </Modal>
        </SiteLayout>
    );
};

layout.propTypes = {
    children: PropTypes.node
};

export default layout; // withrouter

import { Account, ArrowDown, Settings } from '@jsluna/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class Dropdown extends Component {

    state = {
        isOpen: false,
        icons: {
            Account,
            Settings
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        const el = this.listWrapper.current.getBoundingClientRect();
        if (this.state.isOpen) {
            if (el.width > 10 && el.x + el.width > window.innerWidth){
                const pos = window.innerWidth - this.listWrapper.current.parentElement.getBoundingClientRect().x;
                this.listWrapper.current.style.left = pos - el.width - 0.1 + 'px';// -(el.width/2)
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = this.setWrapperRef.bind(this);
    handleClickOutside = this.handleClickOutside.bind(this);
    listWrapper = React.createRef();

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState(() => ({
                isOpen: false,
                dropDownClass: ''
            }));
        }
    }

    selectItem(title, id, stateKey, label) {
        this.setState({
            headerTitle: label ? title : this.state.headerTitle,
            isOpen: false
        }, this.props.resetThenSet(id, stateKey));
    }

    toggleList() {
        if (this.props.name === 'Login') {
            this.props.onLogin();
        }
        else {
            this.setState(prevState => ({
                isOpen: !prevState.isOpen,
                dropDownClass: !prevState.isOpen ? ' is-open' : ''
            }));
        }
    }

    headerIcon(props) {
        if (!props)
            return <ArrowDown className="spinIcon" />;

        const TagName = this.state.icons[this.props.icon];

        return <TagName className="ln-u-margin-left" />;
    }

    hasHeaderIcon(props) {
        if (!props)
            return 'has_dropdown';

        return '';
    }

    render() {

        const { list } = this.props;
        const { isOpen } = this.state;

        return (
            <li ref={this.setWrapperRef} className={this.hasHeaderIcon(this.props.icon)}>
                <button onClick={() => this.toggleList()} data-id="2" type="button"
                    className={`sa-c-navigation__link sa-c-navigation__dropdown-toggle ${this.state.dropDownClass}`}>
                    {this.props.name}
                    {this.headerIcon(this.props.icon)}
                </button>
                <div ref={this.listWrapper} className="sa-c-navigation__dropdown">
                    {isOpen && <ul className="">
                        {list.map((item) => {
                            const TagName = this.state.icons[item.icon];

                            return (
                                <li key={item.id} onClick={() => this.selectItem(item.title, item.id, item.key, item.label)}>
                                    <Link to={item.to === undefined ? '' : item.to}
                                        onClick={item.click}>
                                        {TagName !== undefined ? <TagName className="ln-u-margin-right" /> : ''}
                                        {item.title} {item.selected}</Link>
                                </li>
                            );
                        })}
                    </ul>}
                </div>
            </li>
        );
    }
}

Dropdown.propTypes = {
    // arrow: PropTypes.string,
    // children: PropTypes.node,
    // click: PropTypes.func,
    icon: PropTypes.string,
    // label: PropTypes.bool,
    list: PropTypes.array,
    // logOutHandler: PropTypes.func,
    name: PropTypes.string,
    onLogin: PropTypes.func,
    resetThenSet: PropTypes.func
    // show: PropTypes.bool,
    // title: PropTypes.string,
    // to: PropTypes.string
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: () => dispatch(actions.login())
    };
};

export default connect(null, mapDispatchToProps)(Dropdown);


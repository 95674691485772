import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    userSites: [],
    error: null,
    loading: false
};

const getUserSitesStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};

const getUserSiteSuccess = (state, action) => {
    return updateObject(state, {
        userSites: action.userSites,
        error: null,
        loading: false
    });
};

const getUserSiteFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_SITES_START:
        case actionTypes.UPDATE_USER_SITE_START:
            return getUserSitesStart(state, action);
        case actionTypes.GET_USER_SITES_SUCCESS:
        case actionTypes.UPDATE_USER_SITE_SUCCESS:
            return getUserSiteSuccess(state, action);
        case actionTypes.GET_USER_SITES_FAIL:
        case actionTypes.UPDATE_USER_SITE_FAIL:
            return getUserSiteFail(state, action);
        default: return state;
    }
};

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../../store/actions/index';

import Backdrop from './Backdrop/Backdrop';
import Toolbar from './Toolbar/Toolbar';

class ArgHeader extends Component {

    state = {
        sideDrawerOpen: false
    };

    componentDidMount() {
        this.props.onAuth();
        this.props.onGetSites();
        if (!this.props.missingTrailers)
            this.props.onGetMissingTrailers();
    }

    componentDidUpdate() {
        document.body.classList.toggle('has-overlay', this.state.sideDrawerOpen);
    }

    drawerToggleClickHandler = () => {
        this.setState((prevState) => ({ sideDrawerOpen: !prevState.sideDrawerOpen }));
        document.body.classList.toggle('has-overlay', !this.state.sideDrawerOpen);
    };

    logOutHandler = () => this.props.onLogout();

    loginHandler = () => this.props.onLogin();

    backdropClickHandler = () => this.setState({ sideDrawerOpen: false });

    render() {

        const currentSite = this.props.userSites.filter(m => m.isActive)[0];

        return (
            <div style={{ height: '100%' }}>
                <Toolbar
                    drawerClickHandler={this.drawerToggleClickHandler}
                    logOutHandler={this.logOutHandler}
                    loginHandler={() => this.loginHandler()}
                    openSiteHandler={this.props.open}
                    openMenuHandler={this.props.openMenu}
                    changeSiteHandler={this.changeSiteHandler}
                    userMenuOpen={this.state.userMenuOpen}
                    show={this.state.sideDrawerOpen}
                    username={this.props.username}
                    currentSite={currentSite}
                />
                <Backdrop
                    show={this.state.sideDrawerOpen}
                    click={this.backdropClickHandler} />
            </div>
        );
    }
}

ArgHeader.propTypes = {
    missingTrailers: PropTypes.array,
    onAuth: PropTypes.func.isRequired,
    onGetMissingTrailers: PropTypes.func,
    onGetSites: PropTypes.func,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func,
    open: PropTypes.func,
    openMenu: PropTypes.func,
    userSites: PropTypes.array,
    username: PropTypes.string
};

const mapStateToProps = state => {
    return {
        username: state.auth.givenName,
        userSites: state.user.userSites,
        missingTrailers: state.location.missingTrailers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: () => dispatch(actions.auth()),
        onLogout: () => dispatch(actions.logout()),
        onGetSites: () => dispatch(actions.getUserSites()),
        onGetMissingTrailers: () => dispatch(actions.getMissingTrailers())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArgHeader);

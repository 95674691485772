import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    weekNumber: null,
    weekNumbers: null,
    error: null,
    exceptions: null,
    loading: null
};

const getWeekNumberStart = state => {
    return updateObject(state, { error: null, loading: true });
};

const getWeekNumberSuccess = (state, action) => {
    return updateObject(state, {
        weekNumber: action.weekNumber,
        error: null,
        loading: false
    });
};

const getWeekNumberFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getWeekNumbersStart = state => {
    return updateObject(state, { error: null, loading: true });
};

const getWeekNumbersSuccess = (state, action) => {
    return updateObject(state, {
        weekNumbers: action.weekNumbers,
        error: null,
        loading: false
    });
};

const getWeekNumbersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getYearStartExceptionsStart = state => {
    return updateObject(state, { error: null, loading: true });
};

const getYearStartExceptionsSuccess = (state, action) => {
    return updateObject(state, {
        exceptions: action.exceptions,
        error: null,
        loading: false
    });
};

const getYearStartExceptionsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_YEAR_START_EXCEPTIONS_START:
            return getYearStartExceptionsStart(state, action);
        case actionTypes.GET_YEAR_START_EXCEPTIONS_SUCCESS:
            return getYearStartExceptionsSuccess(state, action);
        case actionTypes.GET_YEAR_START_EXCEPTIONS_FAIL:
            return getYearStartExceptionsFail(state, action);
        case actionTypes.GET_WEEK_NUMBER_START:
            return getWeekNumberStart(state, action);
        case actionTypes.GET_WEEK_NUMBER_SUCCESS:
            return getWeekNumberSuccess(state, action);
        case actionTypes.GET_WEEK_NUMBER_FAIL:
            return getWeekNumberFail(state, action);
        case actionTypes.GET_WEEK_NUMBERS_START:
            return getWeekNumbersStart(state, action);
        case actionTypes.GET_WEEK_NUMBERS_SUCCESS:
            return getWeekNumbersSuccess(state, action);
        case actionTypes.GET_WEEK_NUMBERS_FAIL:
            return getWeekNumbersFail(state, action);
        default:
            return state;
    }
};

export default reducer;

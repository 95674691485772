import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios';

export const setLocation = id => {
    return dispatch => {
        if (id === null) {
            dispatch({
                type: actionTypes.RESET_LOCATION,
                id,
                locations: []
            });
        }
        else {
            dispatch({
                type: actionTypes.SET_LOCATION,
                id
            });
        }
    };
};

export const clearLocations = () => {
    return dispatch => {
        dispatch(getLocationHeadersSuccess([]));
    };
};

export const setSelectedLocations = (locations, fromDate, toDate) => {
    return dispatch => {
        if (locations === null) {
            dispatch({
                type: actionTypes.RESET_SELECTED_LOCATIONS,
                selectedLocations: locations
            });
        }
        else {
            dispatch({
                type: actionTypes.SET_SELECTED_LOCATIONS,
                selectedLocations: locations,
                selectedLocationsFromDate: fromDate,
                selectedLocationsToDate: toDate
            });
        }
    };
};

export const getLocations = () => {
    const url = '/location';

    return dispatch => {
        dispatch(getLocationsStart());
        axios
            .get(url)
            .then(res => {
                const locations = Object.keys(res.data)
                    .map(key => ({ ...res.data[key], id: res.data[key].id }))
                    .sort((a, b) => +a.locationNo - +b.locationNo);

                dispatch(getLocationsSuccess(locations));
            })
            .catch(err => dispatch(getLocationsFail(err)));
    };
};

export const getLocationsPage = pageInfo => {
    const url = 'location/locationsPage';

    return dispatch => {
        dispatch(getLocationsPageStart());
        axios
            .post(url, {
                filter: pageInfo.filter,
                includeInactive: pageInfo.includeInactive,
                page: pageInfo.currentPage,
                pageSize: pageInfo.pageSize,
                sortField: pageInfo.sortField,
                sortDirection: pageInfo.sortDirection
            })
            .then(res => {
                const locations = Object.keys(res.data.locations)
                    .map(key => ({ ...res.data.locations[key], id: res.data.locations[key].id }))
                    .sort((a, b) => +a.locationNo - +b.locationNo);

                dispatch(getLocationsPageSuccess({
                    filtered: res.data.filtered ? 1 : 0,
                    includeInactive: res.data.includeInactive,
                    locations,
                    currentPage: res.data.page,
                    pageSize: res.data.pageSize,
                    sortKey: res.data.sortKey,
                    totalRecords: res.data.totalRecords
                }));
            })
            .catch(err => dispatch(getLocationsPageFail(err)));
    };
};

export const getLocation = (id, saving) => {
    const url = `/location/${id}`;

    return dispatch => {
        dispatch(getLocationsStart(id));
        axios
            .get(url)
            .then(res => {
                const locations = Object.keys(res.data.headerViewModel)
                    .map(key => ({ ...res.data.headerViewModel[key], id: key }))
                    .sort((a, b) => +a.locationNo - +b.locationNo);

                const selectLists = {};

                Object.keys(res.data.selectLists).forEach(m => {
                    const selectList = Object.keys(res.data.selectLists[m])
                        .map(key => ({ ...res.data.selectLists[m][key], id: key }))
                        .sort((a, b) => {
                            if (a.label > b.label) return 1;
                            if (a.label < b.label) return -1;
                            return 0;
                        });
                    selectLists[m] = selectList;
                });

                const locationParameters = Object.keys(res.data.locationParameters).map(key => ({ ...res.data.locationParameters[key], key }));

                dispatch(getLocationsSuccess(locations, locationParameters, selectLists));

                setTimeout(() => {
                    if (saving) dispatch(updateLocationDetailSuccess(true));
                }, 1500);
            })
            .catch(err => {
                dispatch(getLocationsFail(err));
            });
    };
};

export const getDCLocations = () => {
    // const url = '/location/dc';
    const url = '/location/dcs';

    return dispatch => {
        dispatch(getDCLocationsStart());
        axios
            .get(url)
            .then(res => {
                const selectLists = {};

                Object.keys(res.data.selectLists).forEach(m => {
                    const selectList = Object.keys(res.data.selectLists[m])
                        .map(key => ({ ...res.data.selectLists[m][key], id: key }))
                        .sort((a, b) => {
                            if (a.label > b.label) return 1;
                            if (a.label < b.label) return -1;
                            return 0;
                        });
                    selectLists[m] = selectList;
                });
                dispatch(getDCLocationsSuccess(selectLists));
            })
            .catch(err => dispatch(getDCLocationsFail(err)));
    };
};

export const getLocationDCs = () => {
    const url = '/location/locationdcs';

    return dispatch => {
        dispatch(getLocationDCsStart());
        axios
            .get(url)
            .then(res => dispatch(getLocationDCsSuccess(res.data)))
            .catch(err => dispatch(getLocationDCsFail(err)));
    };
};

export const getLocationHeaders = () => {
    const url = '/location/GetLocationHeaders';

    return dispatch => {
        dispatch(getLocationHeadersStart());
        axios
            .get(url)
            .then(res => {
                const locations = Object.keys(res.data)
                    .map(key => ({ ...res.data[key], id: res.data[key].id }))
                    .sort((a, b) => +a.locationNo - +b.locationNo);

                dispatch(getLocationHeadersSuccess(locations, null, null));
            })
            .catch(err => dispatch(getLocationHeadersFail(err)));
    };
};

export const summaryDownload = (filterInfo, sortInfo, includeInactive) => {
    const url = '/location/summarydownload';

    return dispatch => {
        dispatch(downloadStart());
        axios
            .post(url, {
                filterInfo,
                sortField: sortInfo.sortField,
                sortDirection: sortInfo.sortDirection,
                includeInactive
            }, { responseType: 'blob' })
            .then(response => dispatch(downloadSuccess(response.data)))
            .catch(err => dispatch(downloadFail(err)));
    };
};

export const detailDownload = (filterInfo, sortInfo, includeInactive) => {
    const url = '/location/detaildownload';

    return dispatch => {
        dispatch(downloadStart());
        axios
            .post(url, {
                filterInfo,
                sortField: sortInfo.sortField,
                sortDirection: sortInfo.sortDirection,
                includeInactive
            }, { responseType: 'blob' })
            .then(response => dispatch(downloadSuccess(response.data)))
            .catch(err => dispatch(downloadFail(err)));
    };
};

export const allDownload = (includeInactive) => {
    const url = '/location/summarydownload';

    return dispatch => {
        dispatch(downloadStart());
        axios
            .post(url, {
                filterInfo: {},
                sortField: "LocationNo",
                sortDirection: "ascending",
                includeInactive
            }, { responseType: 'blob' })
            .then(response => dispatch(downloadSuccess(response.data)))
            .catch(err => dispatch(downloadFail(err)));
    };
};

export const downloadStore = (data, mapContent) => {
    const url = '/location/downloadstore';

    return dispatch => {
        dispatch(downloadStoreStart());
        axios
            .post(url, { location: data, mapData: mapContent }, { responseType: 'blob' })
            .then(response => dispatch(downloadStoreSuccess(response.data)))
            .catch(err => dispatch(downloadStoreFail(err)));
    };
};

export const updateLocationDetails = data => {
    const url = '/location';

    return dispatch => {
        dispatch(updateLocationDetailsStart());
        axios
            .post(url, data)
            .then(res => {
                if (res.status === 200) {
                    dispatch(updateLocationDetailsSuccess(data.headerId));
                }
                else {
                    dispatch(updateLocationDetailsFail('Saving location details failed'));
                }
            })
            .catch(err => {
                dispatch(updateLocationDetailsFail(err));
            });
    };
};

// update date a single detail entry
export const updateLocationDetail = data => {
    const url = '/location/detail';

    return dispatch => {
        dispatch(updateLocationDetailStart());
        axios
            .post(url, data)
            // .then(res => dispatch(updateLocationDetailSuccess(res.status === 200)));
            .then(() => dispatch(getLocation(data.headerId, true)))
            .catch(err => dispatch(updateLocationDetailFail(err)));
    };
};

export const getLocationTypes = () => {
    const url = '/location/types';

    return dispatch => {
        dispatch(getLocationTypesStart());
        axios
            .get(url)
            .then(res => {
                const selectLists = {};
                Object.keys(res.data.selectLists).forEach(m => {
                    const selectList = Object.keys(res.data.selectLists[m])
                        .map(key => ({ ...res.data.selectLists[m][key], id: key }))
                        .sort((a, b) => {
                            if (a.label > b.label) return 1;
                            if (a.label < b.label) return -1;
                            return 0;
                        });
                    selectLists[m] = selectList;
                });
                dispatch(getLocationTypesSuccess(selectLists));
            })
            .catch(err => dispatch(getLocationTypesFail(err)));
    };
};
export const saveNewLocation = model => {
    const url = '/location/new';

    return dispatch => {
        dispatch(saveNewLocationStart());
        axios
            .post(url, model)
            .then(res => {
                if (res.data === false) dispatch(saveNewLocationFail(new Error('New location submission failed')));
                else dispatch(saveNewLocationSuccess(true));
            })
            .catch(err => dispatch(saveNewLocationFail(err)));
    };
};
export const getLocationFieldOptions = () => {
    const url = 'location/fields';

    return dispatch => {
        dispatch(getLocationFieldOptionsStart());
        axios
            .get(url)
            .then(res => dispatch(getLocationFieldOptionsSuccess(res.data)))
            .catch(err => dispatch(getLocationFieldOptionsFail(err)));
    };
};
export const addNewField = model => {
    const url = 'location/newfield';

    return dispatch => {
        dispatch(addNewFieldStart());
        axios
            .post(url, model)
            .then(res => {
                if (!res.data) dispatch(addNewFieldFail(new Error('Add new field failed')));
                else dispatch(addNewFieldSuccess(res.data));
            })

            .catch(err => dispatch(addNewFieldFail(err)));
    };
};
export const submitChangeRequest = changeRequest => {
    const url = 'location/requestchange';

    return dispatch => {
        dispatch(submitChangeRequestStart());
        axios
            .post(url, changeRequest)
            .then(res => dispatch(submitChangeRequestSuccess(res.data)))
            .catch(err => dispatch(submitChangeRequestFail(err)));
    };
};
export const getDC2StoreDriveTimes = headerId => {
    const url = `/location/DC2StoreDriveTimes/${headerId}`;

    return dispatch => {
        dispatch(getDC2StoreDriveTimesStart());
        axios
            .get(url)
            .then(res => dispatch(getDC2StoreDriveTimesSuccess(res.data)))
            .catch(err => dispatch(getDC2StoreDriveTimesFail(err)));
    };
};
export const getOutstandingChangeRequestCount = () => {
    const url = '/location/locationchangerequestcount';

    return dispatch => {
        dispatch(getOutstandingChangeRequestCountStart());
        axios
            .get(url)
            .then(res => dispatch(getOutstandingChangeRequestCountSuccess(res.data)))
            .catch(err => dispatch(getOutstandingChangeRequestCountFail(err)));
    };
};
export const getOutstandingChangeRequests = () => {
    const url = '/location/locationchangerequests';

    return dispatch => {
        dispatch(getOutstandingChangeRequestsStart());
        axios
            .get(url)
            .then(res => dispatch(getOutstandingChangeRequestsSuccess(res.data)))
            .catch(err => dispatch(getOutstandingChangeRequestsFail(err)));
    };
};
export const requestRejection = model => {
    const url = '/location/rejectrequest';

    return dispatch => {
        dispatch(requestRejectionStart());
        axios
            .post(url, model)
            .then(res => dispatch(requestRejectionSuccess(res.data)))
            .catch(err => dispatch(requestRejectionFail(err)));
    };
};

export const requestAcceptance = model => {
    const url = '/location/acceptrequest';

    return dispatch => {
        dispatch(requestAcceptanceStart());
        axios
            .post(url, model)
            .then(res => dispatch(requestAcceptanceSuccess(res.data)))
            .catch(err => dispatch(requestAcceptanceFail(err)));
    };
};
export const getLocationSummary = () => {
    const url = '/location/getsummary';

    return dispatch => {
        dispatch(getLocationSummaryStart());
        axios
            .get(url)
            .then(res => dispatch(getLocationSummarySuccess(res.data)))
            .catch(err => dispatch(getLocationSummaryFail(err)));
    };
};

export const getLocationParameters = (locationId, fromDate, toDate, parameterName) => {
    let url = `location/parameters/${locationId}/`;

    if (parameterName) url = `${url}${parameterName}/`;

    url = `${url}${fromDate}/${toDate}`;

    return dispatch => {
        dispatch(getLocationParametersStart());
        axios
            .get(url)
            .then(res => dispatch(getLocationParametersSuccess(res.data)))
            .catch(err => dispatch(getLocationParametersFail(err)));
    };
};

export const getMissingDriveTimes = () => {
    const url = 'location/getmissingdrivetimes';

    return dispatch => {
        dispatch(getMissingDriveTimesStart());
        axios
            .get(url)
            .then(res => dispatch(getMissingDriveTimesSuccess(res.data)))
            .catch(err => dispatch(getMissingDriveTimesFail(err)));
    };
};

export const getLocationsStart = id => ({
    type: actionTypes.GET_LOCATIONS_START,
    id,
    loadingLocations: true
});

// export const getLocationsSuccess = (locations, locationDCTypes, locationTypes, locationParameters, locationTrailers) => ({
//     type: actionTypes.GET_LOCATIONS_SUCCESS,
//     locations: locations,
//     locationDCTypes: locationDCTypes,
//     locationTypes: locationTypes,
//     locationParameters: locationParameters,
//     locationTrailers: locationTrailers,
//     loadingLocations: false
// });

export const getLocationsSuccess = (locations, locationParameters, selectLists) => ({
    type: actionTypes.GET_LOCATIONS_SUCCESS,
    locations: locations,
    locationParameters: locationParameters,
    loadingLocations: false,
    locationSelectLists: selectLists
    // locationDCTypes: locationDCTypes,
    // locationTypes: locationTypes,
    // locationTrailers: locationTrailers
});

export const getLocationsFail = error => ({
    type: actionTypes.GET_LOCATIONS_FAIL,
    error: true,
    errorObject: error,
    loadingLocations: false
});

export const getDCLocationsSuccess = selectList => ({
    type: actionTypes.GET_DCLOCATIONS_SUCCESS,
    locationSelectLists: selectList
});

export const getDCLocationsStart = () => ({ type: actionTypes.GET_DCLOCATIONS_START });
export const getDCLocationsFail = error => ({ type: actionTypes.GET_DCLOCATIONS_FAIL, error: true, errorObject: error });

export const getLocationHeadersSuccess = locationHeaders => ({
    type: actionTypes.GET_LOCATIONHEADERS_SUCCESS,
    locationHeaders: locationHeaders,
    loadingLocations: false
});

export const getLocationHeadersStart = () => ({
    type: actionTypes.GET_LOCATIONHEADERS_START,
    loadingLocations: true
});

export const getLocationHeadersFail = error => ({
    type: actionTypes.GET_LOCATIONHEADERS_FAIL,
    error: true,
    errorObject: error
});

export const updateLocationDetailsStart = () => ({ type: actionTypes.UPDATE_LOCATION_DETAILS_START });
export const updateLocationDetailsSuccess = id => ({ type: actionTypes.UPDATE_LOCATION_DETAILS_SUCCESS, id: id });
export const updateLocationDetailsFail = () => ({ type: actionTypes.UPDATE_LOCATION_DETAILS_FAIL });
export const updateLocationDetailsReset = () => ({ type: actionTypes.UPDATE_LOCATION_DETAILS_RESET });

export const updateLocationDetailStart = () => ({ type: actionTypes.UPDATE_LOCATION_DETAIL_START });
export const updateLocationDetailSuccess = () => ({ type: actionTypes.UPDATE_LOCATION_DETAIL_SUCCESS });
export const updateLocationDetailFail = () => ({ type: actionTypes.UPDATE_LOCATION_DETAIL_FAIL });

export const downloadStart = () => ({ type: actionTypes.DOWNLOAD_START });
export const downloadSuccess = fileData => ({ type: actionTypes.DOWNLOAD_SUCCESS, fileData: fileData });
export const downloadFail = error => ({ type: actionTypes.DOWNLOAD_FAIL, error: true, errorObject: error });

export const downloadStoreStart = () => ({ type: actionTypes.DOWNLOAD_STORE_START });
export const downloadStoreSuccess = fileData => ({ type: actionTypes.DOWNLOAD_STORE_SUCCESS, fileData: fileData });
export const downloadStoreFail = error => ({ type: actionTypes.DOWNLOAD_STORE_FAIL, error: true, errorObject: error });

// export const updateScheduleStart = () => ({ type: actionTypes.UPDATE_SCHEDULE_START });
// export const updateScheduleSuccess = (schedule) => ({ type: actionTypes.UPDATE_SCHEDULE_SUCCESS, schedule: schedule });
// export const updateScheduleFail = (error) => ({ type: actionTypes.UPDATE_SCHEDULE_FAIL, error: true, errorObject: error });

export const getLocationTypesStart = () => ({ type: actionTypes.GET_LOCATION_TYPES_START });
export const getLocationTypesSuccess = selectList => ({
    type: actionTypes.GET_LOCATION_TYPES_SUCCESS,
    locationSelectLists: selectList
});

export const getLocationTypesFail = error => ({ type: actionTypes.GET_LOCATION_TYPES_FAIL, error: true, errorObject: error });

export const saveNewLocationStart = () => ({ type: actionTypes.SAVE_NEW_LOCATION_START });
export const saveNewLocationSuccess = success => ({ type: actionTypes.SAVE_NEW_LOCATION_SUCCESS, saveNewSuccess: success });
export const saveNewLocationFail = error => ({ type: actionTypes.SAVE_NEW_LOCATION_FAIL, error: true, errorObject: error });

export const getLocationDCsStart = () => ({ type: actionTypes.GET_LOCATION_DCS_START });
export const getLocationDCsSuccess = locationDCs => ({ type: actionTypes.GET_LOCATION_DCS_SUCCESS, locationDCs: locationDCs });
export const getLocationDCsFail = error => ({ type: actionTypes.GET_LOCATION_DCS_FAIL, error: true, errorObject: error });

export const getLocationFieldOptionsStart = () => ({ type: actionTypes.GET_LOCATION_FIELD_OPTIONS_START });
export const getLocationFieldOptionsSuccess = fields => ({ type: actionTypes.GET_LOCATION_FIELD_OPTIONS_SUCCESS, fieldOptions: fields });
export const getLocationFieldOptionsFail = error => ({ type: actionTypes.GET_LOCATION_FIELD_OPTIONS_FAIL, error: true, errorObject: error });

export const addNewFieldStart = () => ({ type: actionTypes.ADD_NEW_FIELD_START });
export const addNewFieldSuccess = success => ({ type: actionTypes.ADD_NEW_FIELD_SUCCESS, addNewFieldSuccess: success });
export const addNewFieldFail = error => ({ type: actionTypes.ADD_NEW_FIELD_FAIL, error: true, errorObject: error });

export const submitChangeRequestStart = () => ({ type: actionTypes.SUBMIT_CHANGE_REQUEST_START });
export const submitChangeRequestSuccess = success => ({ type: actionTypes.SUBMIT_CHANGE_REQUEST_SUCCESS, requestSuccess: success });
export const submitChangeRequestFail = error => ({ type: actionTypes.SUBMIT_CHANGE_REQUEST_FAIL, submitError: true, errorObject: error });

export const getDC2StoreDriveTimesStart = () => ({ type: actionTypes.GET_DC2STORE_DRIVE_TIMES_START });
export const getDC2StoreDriveTimesSuccess = dc2StoreDriveTimes => {
    return {
        type: actionTypes.GET_DC2STORE_DRIVE_TIMES_SUCCESS,
        dc2StoreDriveTimes: dc2StoreDriveTimes
    };
};
export const getDC2StoreDriveTimesFail = error => ({ type: actionTypes.GET_DC2STORE_DRIVE_TIMES_FAIL, error: true, errorObject: error });

export const getOutstandingChangeRequestCountStart = () => ({ type: actionTypes.GET_LOCATION_CHANGE_REQUEST_COUNT_START });
export const getOutstandingChangeRequestCountSuccess = requestCount => ({
    type: actionTypes.GET_LOCATION_CHANGE_REQUEST_COUNT_SUCCESS,
    requestCount: requestCount
});
export const getOutstandingChangeRequestCountFail = error => ({ type: actionTypes.GET_LOCATION_CHANGE_REQUEST_COUNT_FAIL, requestCountError: error });

export const getOutstandingChangeRequestsStart = () => ({ type: actionTypes.GET_LOCATION_CHANGE_REQUESTS_START });
export const getOutstandingChangeRequestsSuccess = requests => ({ type: actionTypes.GET_LOCATION_CHANGE_REQUESTS_SUCCESS, requests: requests });
export const getOutstandingChangeRequestsFail = error => ({ type: actionTypes.GET_LOCATION_CHANGE_REQUESTS_FAIL, requestsError: error });

export const requestRejectionStart = () => ({ type: actionTypes.SEND_REQUEST_REJECTION_START });
export const requestRejectionSuccess = success => ({ type: actionTypes.SEND_REQUEST_REJECTION_SUCCESS, rejectionSent: success });
export const requestRejectionFail = error => ({ type: actionTypes.SEND_REQUEST_REJECTION_FAIL, rejectionSendError: error });

export const requestAcceptanceStart = () => ({ type: actionTypes.SEND_REQUEST_ACCEPTANCE_START });
export const requestAcceptanceSuccess = success => ({ type: actionTypes.SEND_REQUEST_ACCEPTANCE_SUCCESS, acceptanceSent: success });
export const requestAcceptanceFail = error => ({ type: actionTypes.SEND_REQUEST_ACCEPTANCE_FAIL, acceptanceSendError: error });

export const getLocationSummaryStart = () => ({ type: actionTypes.GET_LOCATION_SUMMARY_START });
export const getLocationSummarySuccess = summaryData => ({ type: actionTypes.GET_LOCATION_SUMMARY_SUCCESS, summaryData: summaryData });
export const getLocationSummaryFail = error => ({ type: actionTypes.GET_LOCATION_SUMMARY_FAIL, summaryError: error });

export const getLocationParametersStart = () => ({ type: actionTypes.GET_LOCATION_PARAMETERS_START });
export const getLocationParametersSuccess = parameters => ({ type: actionTypes.GET_LOCATION_PARAMETERS_SUCCESS, parameters: parameters });
export const getLocationParametersFail = error => ({ type: actionTypes.GET_LOCATION_PARAMETERS_FAIL, parametersError: true, parametersErrorObject: error });

export const getMissingDriveTimesStart = () => ({ type: actionTypes.GET_MISSING_DRIVE_TIMES_START });
export const getMissingDriveTimesSuccess = missing => ({ type: actionTypes.GET_MISSING_DRIVE_TIMES_SUCCESS, missingDriveTimes: missing });
export const getMissingDriveTimesFail = error => ({
    type: actionTypes.GET_MISSING_DRIVE_TIMES_FAIL,
    missingDriveTimesError: true,
    missingDriveTimesErrorObject: error
});

export const getMissingTrailersStart = () => ({
    type: actionTypes.GET_MISSING_TRAILERS,
    loadingMissingTrailers: true,
    missingTrailers: null,
    missingTrailersError: null
});

export const getMissingTrailersSuccess = data => ({
    type: actionTypes.GET_MISSING_TRAILERS,
    loadingMissingTrailers: false,
    missingTrailers: data
});

export const getMissingTrailersFail = error => ({
    type: actionTypes.GET_MISSING_TRAILERS,
    loadingMissingTrailers: false,
    missingTrailers: null,
    missingTrailersError: error
});

export const getMissingTrailers = () => {
    const url = 'location/getmissingtrailers';

    return dispatch => {
        dispatch(getMissingTrailersStart());
        axios
            .get(url)
            .then(res => dispatch(getMissingTrailersSuccess(res.data)))
            .catch(err => dispatch(getMissingTrailersFail(err)));
    };
};

export const getLocationsPageStart = () => ({ type: actionTypes.GET_LOCATIONS_PAGE_START });
export const getLocationsPageSuccess = locationsPage => ({ type: actionTypes.GET_LOCATIONS_PAGE_SUCCESS, locationsPage: locationsPage });
export const getLocationsPageFail = error => ({ type: actionTypes.GET_LOCATIONS_PAGE_FAIL, error: true, errorObject: error });

import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios';

export const setSelectedTrailers = (trailers, fromDate, toDate) => {
    return dispatch => {
        if (trailers === null) {
            dispatch({
                type: actionTypes.RESET_SELECTED_TRAILERS,
                selectedTrailers: trailers
            });
        }
        else {
            dispatch({
                type: actionTypes.SET_SELECTED_TRAILERS,
                selectedTrailers: trailers,
                selectedTrailersFromDate: fromDate,
                selectedTrailersToDate: toDate
            });
        }
    };
};

export const setSelectedDateRange = (fromDate, toDate) => {
    return dispatch => {
        if (fromDate === null && toDate === null) {
            dispatch({
                type: actionTypes.RESET_SELECTED_DATERANGE,
                selectedFromDate: fromDate,
                selectedToDate: toDate
            });
        }
        else {
            dispatch({
                type: actionTypes.SET_SELECTED_DATERANGE,
                selectedFromDate: fromDate,
                selectedToDate: toDate
            });
        }
    };
};

export const setTrailer = trailer => {
    return dispatch => {
        if (trailer === null) {
            dispatch({
                type: actionTypes.RESET_TRAILER,
                trailer: trailer
            });
        }
        else {
            dispatch({
                type: actionTypes.SET_TRAILER,
                trailer: trailer
            });
        }
    };
};

export const getTrailersStart = id => ({
    type: actionTypes.GET_TRAILERS_START,
    id,
    loadingTrailers: true
});

export const getTrailersSuccess = trailers => ({
    type: actionTypes.GET_TRAILERS_SUCCESS,
    trailers: trailers,
    loadingTrailers: false
});

export const getTrailersFail = error => ({
    type: actionTypes.GET_TRAILERS_FAIL,
    error: true,
    errorObject: error,
    loadingTrailers: false
});

export const getTrailers = () => {
    const url = '/trailer';

    return dispatch => {
        dispatch(getTrailersStart());
        axios
            .get(url)
            .then(res => {
                const trailers = res.data;
                dispatch(getTrailersSuccess(trailers));
            })
            .catch(err => dispatch(getTrailersFail(err)));
    };
};

export const getLocationTrailersStart = id => ({
    type: actionTypes.GET_LOCATIONTRAILERS_START,
    id,
    loadingTrailers: true
});

export const getLocationTrailersSuccess = trailers => ({
    type: actionTypes.GET_LOCATIONTRAILERS_SUCCESS,
    locationTrailers: trailers,
    loadingTrailers: false
});

export const getLocationTrailersFail = error => ({
    type: actionTypes.GET_LOCATIONTRAILERS_FAIL,
    error: true,
    errorObject: error,
    loadingTrailers: false
});

export const getLocationTrailers = id => {
    const url = '/trailer/forlocation/' + id;

    return dispatch => {
        dispatch(getLocationTrailersStart());
        axios
            .get(url)
            .then(res => {
                const trailers = res.data;
                dispatch(getLocationTrailersSuccess(trailers));
            })
            .catch(err => dispatch(getLocationTrailersFail(err)));
    };
};
export const getTrailerLocationsStart = id => ({
    type: actionTypes.GET_TRAILERLOCATIONS_START,
    id,
    loadingTrailers: true
});

export const getTrailerLocationsSuccess = locations => ({
    type: actionTypes.GET_TRAILERLOCATIONS_SUCCESS,
    trailerLocations: locations,
    loadingTrailers: false
});

export const getTrailerLocationsFail = error => ({
    type: actionTypes.GET_TRAILERLOCATIONS_FAIL,
    error: true,
    errorObject: error,
    loadingTrailers: false
});

export const getTrailerLocations = id => {
    const url = '/trailer/locations/' + id;

    return dispatch => {
        dispatch(getTrailerLocationsStart());
        axios
            .get(url)
            .then(res => {
                const locations = res.data;
                dispatch(getTrailerLocationsSuccess(locations));
            })
            .catch(err => dispatch(getTrailerLocationsFail(err)));
    };
};
// updateAssignedDates

export const updateAssignedDatesStart = () => ({
    type: actionTypes.UPDATE_ASSIGNED_DATES_START,
    loadingTrailers: true
});

export const updateAssignedDatesSuccess = details => ({
    type: actionTypes.UPDATE_ASSIGNED_DATES_SUCCESS,
    updatedLocationTrailersItems: details,
    loadingTrailers: false,
    updateAssignedDatesSuccess: true,
    updateAssignedDatesFail: false
});

export const updateAssignedDatesFail = error => ({
    type: actionTypes.UPDATE_ASSIGNED_DATES_FAIL,
    error: true,
    errorObject: error,
    loadingTrailers: false,
    updateAssignedDatesSuccess: false,
    updateAssignedDatesFail: true
});

export const updateAssignedDatesReset = () => ({
    type: actionTypes.UPDATE_ASSIGNED_DATES_RESET,
    error: null,
    errorObject: null,
    loadingTrailers: null,
    updateAssignedDatesSuccess: null,
    updateAssignedDatesFail: null
});

export const updateAssignedDates = details => {
    const url = '/trailer/locationDetails/';

    return dispatch => {
        dispatch(updateAssignedDatesStart());
        axios
            .patch(url, details)
            .then(res => {
                const newDetils = res.data;
                dispatch(updateAssignedDatesSuccess(newDetils));
            })
            .catch(err => dispatch(updateAssignedDatesFail(err)));
    };
};
export const setLocationTrailersStart = (id, trailers) => ({
    type: actionTypes.SET_LOCATIONTRAILERS_START,
    id,
    setLocationTrailersItems: trailers,
    loadingSetLocationTrailers: true
});

export const setLocationTrailersSuccess = trailers => ({
    type: actionTypes.SET_LOCATIONTRAILERS_SUCCESS,
    setLocationTrailersItems: trailers,
    loadingSetLocationTrailers: false,
    setLocationTrailersSuccess: true,
    setLocationTrailersFail: false
});

export const setLocationTrailersFail = error => ({
    type: actionTypes.SET_LOCATIONTRAILERS_FAIL,
    error: true,
    errorObject: error,
    loadingSetLocationTrailers: false,
    setLocationTrailersSuccess: false,
    setLocationTrailersFail: true
});

export const setLocationTrailersReset = () => ({
    type: actionTypes.SET_LOCATIONTRAILERS_RESET,
    error: null,
    errorObject: null,
    loadingSetLocationTrailers: null,
    setLocationTrailersSuccess: null,
    setLocationTrailersFail: null
});

export const setLocationTrailers = (id, trailers, fromDate, toDate) => {
    const url = '/trailer/forlocation/' + id;

    return dispatch => {
        dispatch(setLocationTrailersStart(id, trailers));
        axios
            .patch(
                url,
                trailers.map(t => {
                    return { trailerId: t, fromDate: fromDate, toDate: toDate };
                })
            )
            .then(res => {
                const trailers = res.data;
                dispatch(setLocationTrailersSuccess(trailers));
            })
            .catch(err => dispatch(setLocationTrailersFail(err)));
    };
};
export const setTrailerLocationsStart = (trailerId, locations) => ({
    type: actionTypes.SET_TRAILERLOCATIONS_START,
    trailerId,
    setTrailerLocationsItems: locations,
    loadingSetTrailerLocations: true
});

export const setTrailerLocationsSuccess = locations => ({
    type: actionTypes.SET_TRAILERLOCATIONS_SUCCESS,
    setTrailerLocationsItems: locations,
    loadingSetTrailerLocations: false,
    setTrailerLocationsSuccess: true,
    setTrailerLocationsFail: false
});

export const setTrailerLocationsFail = error => ({
    type: actionTypes.SET_TRAILERLOCATIONS_FAIL,
    error: true,
    errorObject: error,
    loadingSetTrailerLocations: false,
    setTrailerLocationsSuccess: false,
    setTrailerLocationsFail: true
});

export const setTrailerLocationsReset = () => ({
    type: actionTypes.SET_TRAILERLOCATIONS_RESET,
    error: null,
    errorObject: null,
    loadingSetTrailerLocations: null,
    setTrailerLocationsSuccess: null,
    setTrailerLocationsFail: null
});

export const setTrailerLocations = (trailerId, locations, fromDate, toDate) => {
    const url = '/trailer/locations/' + trailerId;

    return dispatch => {
        dispatch(setTrailerLocationsStart(trailerId, locations));
        axios
            .patch(
                url,
                locations.map(l => {
                    return { locationHeaderId: l, fromDate: fromDate, toDate: toDate };
                })
            )
            .then(res => {
                const ret = res.data;
                dispatch(setTrailerLocationsSuccess(ret));
            })
            .catch(err => dispatch(setTrailerLocationsFail(err)));
    };
};

export const deleteLocationTrailersStart = id => ({
    type: actionTypes.DELETE_LOCATIONTRAILERS_START,
    id,
    loadingTrailers: true
});

export const deleteLocationTrailersSuccess = detailIds => ({
    type: actionTypes.DELETE_LOCATIONTRAILERS_SUCCESS,
    loadingTrailers: false,
    error: false,
    deletedDetailIds: detailIds
});

export const deleteLocationTrailersFail = error => ({
    type: actionTypes.DELETE_LOCATIONTRAILERS_FAIL,
    error: true,
    errorObject: error,
    loadingTrailers: false
});

export const deleteLocationTrailersReset = () => ({
    type: actionTypes.DELETE_LOCATIONTRAILERS_RESET
});

export const deleteLocationTrailers = (id, detailIds) => {
    const url = '/trailer/forlocation/' + id;

    return dispatch => {
        dispatch(deleteLocationTrailersStart());
        axios
            .delete(url, { data: detailIds })
            .then(() => {
                dispatch(deleteLocationTrailersSuccess(detailIds));
            })
            .catch(err => dispatch(deleteLocationTrailersFail(err)));
    };
};
export const deleteTrailerLocationsStart = id => ({
    type: actionTypes.DELETE_TRAILERLOCATIONS_START,
    id,
    loadingTrailers: true
});

export const deleteTrailerLocationsSuccess = detailIds => ({
    type: actionTypes.DELETE_TRAILERLOCATIONS_SUCCESS,
    loadingTrailers: false,
    error: false,
    deletedTrailerLocationIds: detailIds
});

export const deleteTrailerLocationsFail = error => ({
    type: actionTypes.DELETE_TRAILERLOCATIONS_FAIL,
    error: true,
    errorObject: error,
    loadingTrailers: false
});

export const deleteTrailerLocationsReset = () => ({
    type: actionTypes.DELETE_TRAILERLOCATIONS_RESET
});

export const deleteTrailerLocations = (id, detailIds) => {
    const url = '/trailer/locations/' + id;

    return dispatch => {
        dispatch(deleteLocationTrailersStart());
        axios
            .delete(url, { data: detailIds })
            .then(() => {
                dispatch(deleteLocationTrailersSuccess(detailIds));
            })
            .catch(err => dispatch(deleteLocationTrailersFail(err)));
    };
};

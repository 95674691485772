import React, { Component } from 'react';
import { connect } from 'react-redux';

import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions';

import PropTypes from 'prop-types';

import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { IconButton } from '@jsluna/button';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { TabLink, TabPanel, Tabs } from '@jsluna/tabs';
import { Download } from '@jsluna/icons';

import Input from '../../../components/UI/Input/Input';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FileUpload } from 'primereact/fileupload';

import { getToken } from '../../../adalConfig';
import { updateObject } from '../../../shared/utility';

import uniqBy from 'lodash-es/uniqBy';
import moment from 'moment';

/* eslint-disable-next-line no-unused-vars */
import classes from './Compare.module.scss';

moment.locale('en-GB');

class Compare extends Component {
    state = {
        activeTab: 'missingFromUpload',
        baseUrl: `${axios.defaults.baseURL}aipschedule/checkcycles`,
        counts: {
            diffs: {
                stores: 0,
                cycles: 0
            },
            uploaded: {
                stores: 0,
                cycles: 0
            },
            generated: {
                stores: 0,
                cycles: 0
            }
        },
        differenceTableValues: [],
        missingFromUpload: [],
        missingFromGenerated: [],
        token: null,
        noresults: false,
        uploading: false,
        url: null,
        wcDate: moment(),
        wcDateFocused: false
    }

    componentDidMount() {
        // background load the headers
        this.props.onGetLocationHeaders();
        this.setPathParams();

        // add the auth token to the state
        getToken().then(token => this.setState({token: token}));

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading)
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
    }

    componentDidUpdate(_, prevState) {
        if (prevState.wcDate.format('YYYY-MM-DD') !== this.state.wcDate.format('YYYY-MM-DD'))
            this.setPathParams();
    }

    tabs = [
        { name: 'Missing from Upload', key: 'missingFromUpload' },
        { name: 'Missing from Generated', key: 'missingFromGenerated' },
        { name: 'Mismatches', key: 'mismatches' }
    ];

    setPathParams() {
        // add the path params
        const wcDate = this.state.wcDate.format('YYYY-MM-DD');
        const url = `${this.state.baseUrl}/${wcDate}`;

        this.setState({ url: url });
    }

    beforeSend = event => {
        // used to get the token here, but as it's an async call this function has to be
        // made async to await it, which causes the code to continue before this func has
        // finished. Can't use .then() for the same reason. Token is not retrieved during
        // component mount and stored in the state
        event.xhr.setRequestHeader('Authorization', `Bearer ${this.state.token}`);
        this.setState({ uploading: true });
    };

    afterUpload = event => {
        if (event.xhr.response) {
            const results = JSON.parse(event.xhr.response);
            const missingFromUpload = results.missingFromUpload || [];
            const missingFromGenerated = results.missingFromGenerated || [];
            const differences = results.differences || [];
            const missingUploadLocations = uniqBy(missingFromUpload, m => m.locationId);
            const missingGeneratedLocations = uniqBy(missingFromGenerated, m => m.locationId);

            const missingUploadMapped = missingUploadLocations.map(m => {
                const mUpload = missingFromUpload.filter(mf => mf.locationId === m.locationId).map(mp => mp.site).join(', ');
                const uloc = this.props.locations.find(l => l.id === m.locationId);

                return {
                    locationId: m.locationId,
                    locationNo: uloc ? uloc.locationNo : 'Unknown',
                    locationName: uloc ? uloc.locationName : 'Unknown',
                    sites: mUpload
                };
            });

            const missingGeneratedMapped = missingGeneratedLocations.map(m => {
                const mGenerated = missingFromGenerated.filter(mf => mf.locationId === m.locationId).map(mp => mp.site).join(', ');
                const mloc = this.props.locations.find(l => l.id === m.locationId);

                return {
                    locationId: m.locationId,
                    locationNo: mloc ? mloc.locationNo : 'Unknown',
                    locationName: mloc ? mloc.locationName : 'Unknown',
                    sites: mGenerated
                };
            });

            const fieldCount = 14;

            const cycleErrors = differences.filter(d => d.uploaded.length !== fieldCount || d.generated.length !== fieldCount);

            // count cycle errors by store and add them to the generated tab
            if (cycleErrors.length > 0) {
                uniqBy(cycleErrors, c => c.locationId)
                    .forEach(d => {
                        const mloc = this.props.locations.find(l => l.id === d.locationId);
                        missingGeneratedMapped.push({
                            locationId: d.locationId,
                            locationNo: mloc ? mloc.locationNo : 'Unknown',
                            locationName: mloc ? mloc.locationName : 'Unknown',
                            sites: 'Contains cycle errors'
                        });
                    });
            }

            const diffTableValues = differences.map(d => {
                const dloc = this.props.locations.find(l => l.id === d.locationId);
                const wcDate = moment(d.wcDate);
                const gen = [...d.generated];
                const upl = [...d.uploaded];
                let fields = [];
                gen.forEach((g, idx) => {
                    const date = moment(wcDate).add(idx, 'days');
                    fields = updateObject(fields, { [date.format('YYYY-MM-DD')]: `${g} | ${upl[idx]}` });
                });

                const orderedFields = [];
                Object.keys(fields)
                    .sort((a, b) => {
                        if (moment(a).isAfter(moment(b)))
                            return 1;

                        if (moment(a).isBefore(moment(b)))
                            return -1;

                        return 0;
                    })
                    .forEach(key => orderedFields[key] = fields[key]);

                return {
                    Store: dloc ? dloc.locationNo : 'Unknown',
                    Name: dloc ? dloc.locationName : 'Unknown',
                    Cycle: d.site,
                    ...orderedFields
                };
            });

            let diffStoreCount = 0;
            let genStoreCount = 0;
            let uplStoreCount = 0;

            if (differences.length > 0)
                diffStoreCount = uniqBy(differences, d => d.locationId).length;

            if (missingFromGenerated.length > 0)
                genStoreCount = uniqBy(missingFromGenerated, g => g.locationId).length;

            if (missingFromUpload.length > 0)
                uplStoreCount = uniqBy(missingFromUpload, u => u.locationId).length;

            const counts = {
                diffs: {
                    stores: diffStoreCount,
                    cycles: differences.length
                },
                uploaded: {
                    stores: uplStoreCount,
                    cycles: missingFromUpload.length
                },
                generated: {
                    stores: genStoreCount,
                    cycles: missingFromGenerated.length
                }
            };

            this.setState({
                differenceTableValues: diffTableValues,
                missingFromGenerated: missingGeneratedMapped,
                missingFromUpload: missingUploadMapped,
                noresults: false,
                uploading: false,
                counts: counts
            });
        }
        else
            this.setState({ uploading: false, noresults: true });
    };

    resetMsg = () => this.setState({ uploading: false });

    focusedHandler = event => this.setState({ wcDateFocused: event.focused });

    dateChangedHandler = event => this.setState({ wcDate: event });

    cellTemplate = (rowData, col) => {
        const isDateCol = !isNaN(+col.field.substring(0, 1));

        if (!isDateCol)
            return <span>{rowData[col.field]}</span>;
        else {
            const g = rowData[col.field];
            const u = rowData[col.field];

            if (!g || !u)
                return <span style={{ color: 'red', fontWeight: 'bold' }}>#ERR</span>;

            const gval = g.substr(0, 1);
            const uval = u.substr(-1);

            if (+gval !== +uval)
                return <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData[col.field]}</span>;
            else
                return <span>{rowData[col.field]}</span>;
        }
    }

    handleDownload = () => {
        this.diffTable.exportCSV();
    }

    render() {
        const loading =
            <ProgressIndicator page loading={this.state.uploading}>
                <ProgressSpinner light />
                Comparing cycles...
            </ProgressIndicator>;

        const uploader =
            <FileUpload
                name="compareupload"
                onBeforeSend={event => this.beforeSend(event)}
                onUpload={event => this.afterUpload(event)}
                onSelect={event => this.resetMsg(event)}
                url={this.state.url}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />;

        const diffCols = this.state.differenceTableValues.length > 0 ?
            Object.keys(
                this.state.differenceTableValues[0]).map(
                    key => <Column key={key} field={key}
                        header={isNaN(+key.substr(1, 1)) ? key : moment(key).format('DD-MMM')} body={this.cellTemplate} />)
            : null;

        const noresults = this.state.noresults ? <p>No matching generated codes found</p> : ' ';

        const tabDetail = {
            missingFromGeneratedTab:
                this.state.missingFromGenerated.length > 0 ?
                    <TabPanel>
                        <Card>
                            <h3>Found in the upload but not generated code found ({this.state.counts.generated.cycles} Cycles,
                                    &nbsp;{this.state.counts.generated.stores} Locations)</h3>
                            <DataTable value={this.state.missingFromGenerated} scrollable={true} scrollHeight="175px">
                                <Column field="locationNo" header="Location" />
                                <Column field="locationName" header="Name" />
                                <Column field="sites" header="Cycles" />
                            </DataTable>
                        </Card>
                    </TabPanel>
                    : null,
            missingFromUploadTab:
                this.state.missingFromUpload.length > 0 ?
                    <TabPanel>
                        <Card>
                            <h3>Found in generated codes but missing from the upload ({this.state.counts.uploaded.cycles} Cycles,
                                    &nbsp;{this.state.counts.uploaded.stores} Locations)</h3>
                            <DataTable value={this.state.missingFromUpload} scrollable={true} scrollHeight="175px">
                                <Column field="locationNo" header="Location" />
                                <Column field="locationName" header="Name" />
                                <Column field="sites" header="Cycles" />
                            </DataTable>
                        </Card>
                    </TabPanel>
                    : null,
            mismatchesTab:
                this.state.differenceTableValues.length > 0 ?
                    <TabPanel>
                        <Card>
                            <GridWrapper>
                                <GridItem size="5/6">
                                    <h3>Uploaded codes that don't match the generated codes ({this.state.counts.diffs.cycles} Cycles,
                                        &nbsp;{this.state.counts.diffs.stores} Locations)</h3>
                                </GridItem>
                                <GridItem size="1/6">
                                    <IconButton
                                        onClick={this.handleDownload}
                                        variant="outlined"
                                        label="Download"
                                        hideLabel={true} >
                                        <Download />
                                    </IconButton>
                                </GridItem>
                            </GridWrapper>
                            <DataTable
                                value={this.state.differenceTableValues}
                                scrollable={true} scrollHeight="350px"
                                ref={el => this.diffTable = el}>
                                {diffCols}
                            </DataTable>
                        </Card>
                    </TabPanel>
                    : null
        };

        const activeTab = tabDetail[`${this.state.activeTab}Tab`];

        return (
            this.props.loading ? loading :
                <Container>
                    <h4 className="ln-u-push-sm">Order Cycle Comparison</h4>
                    <GridWrapper className="ln-u-push-top">
                        <GridItem size="1/2">
                            <GridWrapper>
                                <GridItem size="1/1">
                                    {uploader}
                                </GridItem>
                                <GridItem size="1/1">
                                    {noresults}
                                </GridItem>
                            </GridWrapper>
                        </GridItem>
                        <GridItem size="1/2">
                            <Input
                                label="Week Commencing Date"
                                id="wcDate"
                                dateChanged={event => this.dateChangedHandler(event)}
                                onFocused={event => this.focusedHandler(event)}
                                focused={this.state.wcDateFocused}
                                value={this.state.wcDate}
                                elementType="datelabelled"
                                yearStartExceptions = {this.props.yearStartExceptions} />
                        </GridItem>
                        <GridItem size="1/1" className="ln-u-push-top">
                            <Tabs>
                                {this.tabs.map(tab => (
                                    <TabLink
                                        key={tab.key}
                                        onClick={() => this.setState({ activeTab: tab.key })}
                                        active={this.state.activeTab === tab.key} >
                                        {tab.name}
                                    </TabLink>
                                ))}
                            </Tabs>
                            {activeTab}
                        </GridItem>
                    </GridWrapper>
                    {loading}
                </Container>
        );
    }
}

Compare.propTypes = {
    loading: PropTypes.bool,
    locations: PropTypes.array,
    onGetLocationHeaders: PropTypes.func.isRequired,
    onGetYearStartExceptions: PropTypes.func.isRequired,
    yearStartExceptions: PropTypes.array,
    yearStartExceptionsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        loading: state.location.loading,
        locations: state.location.locationHeaders,
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading,
        yearStartExceptionsError: state.general.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetLocationHeaders: () => dispatch(actions.getLocationHeaders()),
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Compare, axios));

import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const getAuditLogCount = filters => {
    const url = 'auditlog/getlogrecordcount';

    if (!filters)
        filters = {temp:{value:'', matchMode:''}};

    return dispatch => {
        dispatch(getAuditLogCountStart());
        axios.post(url, filters)
            .then(res => dispatch(getAuditLogCountSuccess(res.data)))
            .catch(err => dispatch(getAuditLogCountFail(err)));
    };
};

export const getAuditLogCountStart = () => ({ type: actionTypes.GET_AUDIT_LOG_COUNT_START });
export const getAuditLogCountSuccess = count => ({ type: actionTypes.GET_AUDIT_LOG_COUNT_SUCCESS, count: count});
export const getAuditLogCountFail = error => ({ type: actionTypes.GET_AUDIT_LOG_COUNT_FAIL, countError: true, countErrorObject: error });

export const getAuditLogPage = (size, pageNumber, filters) => {
    const url = 'auditlog/getlogpage';

    return dispatch => {
        dispatch(getAuditLogPageStart());
        axios.post(url, { size: size, page: pageNumber, filter: filters })
            .then(res => dispatch(getAuditLogPageSuccess(res.data)))
            .catch(err => dispatch(getAuditLogPageFail(err)));
    };
};

export const getAuditLogPageStart = () => ({ type: actionTypes.GET_AUDIT_LOG_PAGE_START });
export const getAuditLogPageSuccess = page => ({ type: actionTypes.GET_AUDIT_LOG_PAGE_SUCCESS, page: page});
export const getAuditLogPageFail = error => ({ type: actionTypes.GET_AUDIT_LOG_PAGE_FAIL, pageError: true, pageErrorObject: error });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from '../../../axios';
import * as actions from '../../../store/actions';

import { AutocompleteField } from '@jsluna/autocomplete';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { FormGroup } from '@jsluna/form';
import { IconButton } from '@jsluna/button';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';

import { Cancel, Messages, Plus, Tick } from '@jsluna/icons';

import Input from '../../../components/UI/Input/Input';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

import uuid from 'uuid/v1';
import camelCase from 'lodash-es/camelCase';
import moment from 'moment';

moment.locale('en-GB');

class AIPChange extends Component {

    state = {
        changes: [],
        displayInfo: [],
        locationOptions: [],
        storeNumber: '',
        lastSent: null,
        sent: false,
        sendAll: false,
        wcDate: moment().add(7, 'd').startOf('week'),
        wcDateFocused: false
    };

    componentDidMount() {
        // get data required for lookups
        this.props.onGetGeneratedCodes(moment().format('YYYY-MM-DD'));
        this.props.onGetLocationDCs();
        this.props.onGetProfileCodes();
        this.props.onGetLocations();

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading)
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
    }

    componentDidUpdate(_, prevState) {
        // initialise select lists
        if (this.props.orderCycleCodes && this.state.orderCycleOptions.length === 0)
            this.setOrderCycleOptions();

        if (this.props.locations.length > 0 && this.state.locationOptions.length === 0)
            this.setLocationOptions();

        // signify status of request submission
        if (!this.props.sending && !this.state.sent) {
            if (this.props.sendSuccess)
                this.successGrowl();
            else if (this.props.sendFail)
                this.failGrowl();
        }

        if (prevState.wcDate !== this.state.wcDate)
            this.props.onGetGeneratedCodes(this.state.wcDate.format('YYYY-MM-DD'));
    }

    setOrderCycleOptions() {
        this.setState({
            orderCycleOptions: this.props.orderCycleCodes.map(oc => ({
                label: oc,
                value: oc
            }))
        });
    }

    setLocationOptions() {
        this.setState({
            locationOptions: this.props.locations.map(loc => ({
                label: `${loc.locationNo} ${loc.locationName}`,
                value: loc.locationNo
            }))
        });
    }

    successGrowl() {
        this.growl.show({severity: 'success', summary: 'AIP Change Request', detail: 'Change request successfully sent.'});

        if (this.state.lastSent) {
            // update the sent flag in the displayed data
            const displayInfo = [...this.state.displayInfo];
            const lastSent = displayInfo.find(d => d.id === this.state.lastSent);
            lastSent.submitted = true;

            // update the sent flag in changes too, to ensure it's retained when displayed data is rebuilt
            const changes = [...this.state.changes];
            const keyRec = changes.find(c => c.id === this.state.lastSent);
            keyRec.submitted = true;
            this.setState({ displayInfo: displayInfo, lastSent: null, sent: true, changes: changes });
        }

        if (this.state.sendAll) {
            const displayInfo = [...this.state.displayInfo];
            displayInfo.forEach(d => d.submitted = true);

            // update the sent flag in changes too, to ensure it's retained when displayed data is rebuilt
            const changes = [...this.state.changes];
            changes.forEach(c => c.submitted = true);
            this.setState({ displayInfo: displayInfo, lastSent: null, sent: true, changes: changes, sendAll: false });
        }
    }

    failGrowl () {
        this.growl.show({severity: 'error', summary: 'AIP Change Request', detail: 'Change Request sending failed. Please try again.'});
    }

    noCodesGrowl () {
        this.growl.show({severity: 'warn', summary: 'Missing Codes', detail: 'No codes found for this store'});
    }

    emailCellTemplate = rowData => {
        return (
            <IconButton
                disabled={this.state.changes.length === 0}
                onClick={() => this.rowEmailClickHandler(rowData)}
                label="Send"
                hideLabel={true}
                variant="outlined">
                <Messages />
            </IconButton>
        );
    }

    sentCellTemplate = rowData => {
        let sent = null;

        if (rowData.submitted)
            sent =
                <div style={{textAlign:'center'}}>
                    <Tick className="ln-u-color-green" />
                </div>;

        return sent;
    }

    rowEmailClickHandler = rowData => {
        const idRec = this.state.changes.find(c => c.id === rowData.id);
        const profiles = this.state.changes.filter(c => c.locationNo === idRec.locationNo && c.startDate === idRec.startDate);

        // remember what we just sent so we can acknowledge sending success of the correct record
        this.setState({ lastSent: rowData.id, sent: false });

        // send to the API for transmission by email
        this.props.onSendRequests(profiles);
    }

    sendAllHandler = () => {
        this.setState({ sent: false, sendAll: true });
        this.props.onSendRequests(this.state.changes);
    }

    acSelectedHandler = event => {
        if (event)
            this.setState({ storeNumber: event.value });
    }

    addRequest = () => {
        const dcInfo = this.props.locationDCs.find(d => +d.locationno === +this.state.storeNumber);

        // record the requested change
        const changes = [...this.state.changes];

        const header = this.props.locations.find(l => l.locationNo === this.state.storeNumber);
        if (header) {
            const codes = this.props.generatedCodes.find(gc => gc.locationId === header.headerId);

            this.props.profileCodes.forEach(profileCode => {
                let gCode, sDate, eDate, ket, rdc;

                if (codes) {
                    gCode = codes[camelCase(profileCode.generatedName)];
                    sDate = codes.activeDate;
                    eDate = codes.deactiveDate;
                    ket = codes.svcCode;
                    rdc = codes.sdcCode;
                }

                const newRequest = {
                    profile: profileCode.code,
                    profileName: profileCode.name,
                    storeNumber: header.locationNo,
                    storeDesc: header.locationName,
                    orderCycle: gCode,
                    startDate: sDate,
                    endDate: eDate,
                    submitted: false,
                    id: uuid()
                };

                // handle Kettering codes
                if (['RDC', 'Kettering'].includes(profileCode.name) && codes) {
                    if (dcInfo.svc === dcInfo.sdc) {
                        ket = codes.sdcCode;
                        rdc = codes.svcCode;
                    }

                    if (profileCode.name === 'RDC')
                        newRequest.orderCycle = rdc;
                    else
                        newRequest.orderCycle = ket;
                }

                if (newRequest.orderCycle)
                    changes.push(newRequest);
            });

            if (!codes)
                this.noCodesGrowl();
        }

        const displayInfo = [];

        changes.forEach(c => {
            const key = `${c.storeNumber}${c.startDate}`;
            if (!displayInfo.find(d => d.key === key))
                displayInfo.push({key: key});

            const entry = displayInfo.find(d => d.key === key);
            entry[c.profileName.replace(' ', '')] = c.orderCycle;
            entry.storeDesc = `${c.storeNumber} ${c.storeDesc}`;
            entry.startDate = moment(c.startDate).format('DD/MM/YY');
            entry.startDateMoment = moment(c.startDate);
            entry.endDate = moment(c.endDate).format('DD/MM/YY');
            entry.id = c.id;
            entry.storeNumber = c.storeNumber;
            entry.submitted = c.submitted;
        });

        displayInfo.sort((a, b) => +a.storeNumber - +b.storeNumber);
        this.setState({ changes: changes, displayInfo: displayInfo, storeNumber: '' });
    }

    dateChangedHandler = event => {
        this.setState({ wcDate: event });
    }

    focusedHandler = event => this.setState({ wcDateFocused: event.focused });

    render() {
        const dataLoading = this.props.aipLoading || this.props.orderCycleLoading || this.props.locationLoading || this.props.dcsLoading;

        const loading = (
            <ProgressIndicator page loading={dataLoading}>
                <ProgressSpinner light />
                Loading...
            </ProgressIndicator>
        );

        const sending = (
            <ProgressIndicator page loading={this.props.sending}>
                <ProgressSpinner light />
                Sending requests...
            </ProgressIndicator>
        );

        const header =
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <IconButton
                    disabled={this.state.changes.length === 0}
                    style={{ float: 'left' }}
                    onClick={this.sendAllHandler}
                    label="Send All"
                    variant="outlined">
                    <Messages />
                </IconButton>
                AIP Code Changes
                <IconButton
                    disabled={this.state.changes.length === 0}
                    style={{ float: 'right' }}
                    onClick={() => this.setState({ changes: [], displayInfo: [] })}
                    label="Clear"
                    variant="outlined">
                    <Cancel />
                </IconButton>
            </div>;

        // show requests as they are added
        const requests = (
            <DataTable value={this.state.displayInfo} responsive paginator rows={7} header={header}>
                <Column field="storeDesc" header="Store" style={{textAlign:'center'}} />
                <Column field="RDC" header="RDC Code" style={{textAlign:'center'}} />
                <Column field="Kettering" header="KET Code" style={{textAlign:'center'}} />
                <Column field="Barton" header="BAR Code" style={{textAlign:'center'}} />
                <Column field="HighRisk" header="High Risk Code" style={{textAlign:'center'}} />
                <Column field="Assay" header="ASSAY Code" style={{textAlign:'center'}} />
                <Column field="startDate" header="Start Date" style={{textAlign:'center'}} />
                <Column field="endDate" header="End Date" style={{textAlign:'center'}} />
                <Column body={this.emailCellTemplate} header="Send" style={{textAlign: 'center' }} />
                <Column body={this.sentCellTemplate} header="Sent" stype={{textAlign: 'center' }} />
            </DataTable>
        );

        let content = (
            <Container className="ln-u-push-top">
                {sending}
                <h4>AIP Change Request Form</h4>
                <GridWrapper>
                    <GridItem size="1/4">
                        <Input
                            label="Week Commencing"
                            id="wcDate"
                            dateChanged={event => this.dateChangedHandler(event)}
                            onFocused={event => this.focusedHandler(event)}
                            focused={this.state.wcDateFocused}
                            value={this.state.wcDate}
                            // invalid={!this.state.isValidDateRange}
                            // errorMessage={this.getErrorMessage('StartDate')}
                            elementType="datelabelled"
                            yearStartExceptions = {this.props.yearStartExceptions} />
                    </GridItem>
                    <GridItem size="1/3">
                        <AutocompleteField
                            name="storeSelect"
                            placeholder="Please select"
                            options={this.state.locationOptions}
                            onSelect={event => this.acSelectedHandler(event)}
                            label="Store" />
                    </GridItem>
                    <GridItem size="1/12">
                        <FormGroup name="addButton" label="Add">
                            <IconButton
                                disabled={!this.state.storeNumber}
                                style={{ display: 'block' }}
                                onClick={this.addRequest}
                                variant="outlined"
                                hideLabel={true}
                                label="Add">
                                <Plus />
                            </IconButton>
                        </FormGroup>
                    </GridItem>
                    <GridItem size="1/1">
                        {requests}
                    </GridItem>
                </GridWrapper>
                <Growl ref={el => this.growl = el}></Growl>
            </Container>
        );

        if (dataLoading)
            content = loading;

        return content;
    }
}

AIPChange.propTypes = {
    aipLoading: PropTypes.bool,
    dcsLoading: PropTypes.bool,
    generatedCodes: PropTypes.array,
    locationDCs: PropTypes.array,
    locationLoading: PropTypes.bool,
    locations: PropTypes.array,
    onGetGeneratedCodes: PropTypes.func.isRequired,
    onGetLocationDCs: PropTypes.func.isRequired,
    onGetLocations: PropTypes.func.isRequired,
    onGetProfileCodes: PropTypes.func.isRequired,
    onGetYearStartExceptions: PropTypes.func.isRequired,
    onSendRequests: PropTypes.func.isRequired,
    orderCycleCodes: PropTypes.array,
    orderCycleLoading: PropTypes.bool,
    profileCodes: PropTypes.array,
    sendFail: PropTypes.bool,
    sendSuccess: PropTypes.bool,
    sending: PropTypes.bool,
    yearStartExceptions: PropTypes.array,
    yearStartExceptionsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        aipLoading: state.aipSchedule.loading,
        dcsLoading: state.location.dcsLoading,
        generatedCodes: state.aipSchedule.codes,
        locationDCs: state.location.locationDCs,
        locations: state.location.locations,
        locationLoading: state.location.loading,
        sending: state.aipSchedule.sending,
        orderCycleCodes: state.orderCycle.orderCycleCodes,
        orderCycleLoading: state.orderCycle.loading,
        profileCodes: state.aipSchedule.profileCodes,
        sendFail: state.aipSchedule.error,
        sendSuccess: state.aipSchedule.success,
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading,
        yearStartExceptionsError: state.general.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetGeneratedCodes: wcDate => dispatch(actions.getGeneratedCodes(wcDate)),
        onGetLocationDCs: () => dispatch(actions.getLocationDCs()),
        onGetLocations: () => dispatch(actions.getLocations()),
        onGetProfileCodes: () => dispatch(actions.getProfileCodes()),
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions()),
        onSendRequests: requests => dispatch(actions.sendRequests(requests))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AIPChange, axios));

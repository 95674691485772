import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    emailSent: false
};

const emailStart = state => updateObject(state, { emailSent: true });
const emailSuccess = state => updateObject(state, { emailSent: false });
const emailFail = state => updateObject(state, { emailSent: false });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EMAIL_START: return emailStart(state, action);
        case actionTypes.EMAIL_SUCCESS: return emailSuccess(state, action);
        case actionTypes.EMAIL_FAIL: return emailFail(state, action);

        default: return state;
    }
};

export default reducer;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Accordion, AccordionItem } from '@jsluna/accordion';
import { ButtonGroupPrimary, ButtonGroupWrapper, FilledButton, IconButton } from '@jsluna/button';
import { Card } from '@jsluna/card';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { Fieldset, Form, FormGroup, Legend } from '@jsluna/form';
import { Section } from '@jsluna/section';
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { Cancel, Delete, Download, Edit, LandlinePhone, Plus } from '@jsluna/icons';

import AccordionHeader from './AccordionHeader/AccordionHeader';
import Confirmation from '../../components/UI/Confirmation/Confirmation';
import LocationSelect from './LocationSelect';

import axios from '../../axios';
import moment from 'moment';

import Input from '../../components/UI/Input/Input';
import Save from '../../components/UI/Icons/Save';

import { getDayName, isValidDate, minsToHHMM, removeSpecialCharacters, toggleDates } from '../../shared/utility';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { userCanEdit as checkRoles, isValidTime, sortByKey, sortByNestedKey, updateObject } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import './Location.scss';
import classes from './Location.module.scss';

import Map from '../../components/Map/Map';
import ScrollToTop from '../../hoc/ScrollToTop/ScrollToTop';

import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';

import groupBy from 'lodash-es/groupBy';

import saveAs from 'file-saver';

moment.locale('en-GB');

// set to true to save a single location detail at a time - TODO: either remove or have as a config setting for greater control - shouldn't be required.
const SAVE_ONE = false;

class Location extends Component {
    state = {
        downloadEnabled: false,
        storeDetails: [],
        storeInfo: null,
        submitted: false,
        deleteConfirmation: false,
        fixedHeader: 65,
        showNoData: false,
        dateInputValue: {
            id: null,
            field: null,
            value: null,
            valid: false,
            yearStartExceptions: null
        },
        updateRequired: false,
        property: null
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        if (!this.state.storeInfo) {
            const id = this.props.match.params.id;

            if (!isNaN(id)) {
                this.props.onGetLocations(+id);
                this.props.onGetAllLocations();
            }
            else if (this.props.id !== null) {
                this.props.onResetLocation();
            }
        }

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading) {
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
        }
    }

    componentDidUpdate(prevProps) {
        /* eslint-disable-next-line no-extra-parens */
        if (this.props.id && prevProps.id !== this.props.id && !this.props.loadingLocations) {
            this.props.onGetLocations(+this.props.id);
            this.props.onGetAllLocations();
            return;
        }

        if (!this.props.yearStartExceptions && !this.props.yearStartExceptionsLoading) {
            this.props.onGetYearStartExceptions('2000-01-02', '2999-12-29');
        }

        /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
        if ((!this.props.loadingLocations && !this.state.storeInfo) || prevProps.locations !== this.props.locations) {
            this.populateState();
        }

        if (!this.props.fileLoading && this.props.fileData && this.state.downloadEnabled) this.saveDownload();

        if (!this.props.loadingLocations) {
            if (this.props.updatingSuccess) {
                this.successGrowl('Save Change', 'sent');
                this.props.onUpdateLocationDetailsReset();
            }
            else if (this.props.updatingSuccess === false) {
                this.failGrowl('Save Change');
                this.props.onUpdateLocationDetailsReset();
            }
        }

        if (!this.props.submitting && !this.state.submitted) {
            if (this.props.requestSuccess) this.successGrowl('Change Request Submission', 'submitted');
            else if (this.props.requestFail) this.failGrowl('Change Request Submission');
        }

        document.querySelectorAll('.DateInput_input').forEach(item => {
            item.addEventListener('keyup', this.setDateInputValue);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    setDateInputValue = e => {
        const obj = this.state.dateInputValue;
        let val = e.target.value;
        let valid = e.target.value === '' && obj.field !== 'fromDate';
        if (isValidDate(val)) {
            val = moment(val, 'DD/MM/YYYY');
            valid = true;
        }
        const upd = updateObject(obj, {
            value: val,
            valid,
            yearStartExceptions: this.props.yearStartExceptions
        });
        this.setState(
            () => ({ dateInputValue: upd }),
            () => {
                const d = this.state.dateInputValue;
                this.dateChangedHandler(d.value, d.id, d.field);
            }
        );
    };

    handleScroll = e => {
        const pos = e.currentTarget.pageYOffset;
        this.setState({ fixedHeader: pos, position: pos >= 365 ? 'absolute' : 'initial' });
    };

    successGrowl(msg, stateVar, severity) {
        if (this.growl)
            this.growl.show({
                severity: !severity ? 'success' : severity,
                summary: 'Information',
                detail: msg
            });
        this.setState({ [stateVar]: true });
    }

    failGrowl(msg) {
        if (this.growl)
            this.growl.show({
                severity: 'error',
                summary: msg,
                detail: `${msg} failed.`
            });
    }

    saveDownload = () => {
        const blob = new Blob([this.props.fileData], {
            type: 'application/vnd.ms-excel'
        });
        saveAs(blob, 'Location.xlsx');
        this.setState({ downloadEnabled: false });
    };

    userCanEdit = () => checkRoles(this.props.roles);

    populateState = () => {
        if (!this.props.locations || this.props.locations.length === 0) return;

        let formDetailsElementsArray = [];
        const location = this.props.locations[0];

        if (!location) return;

        let leadTimes = [];
        let cutOffTimes = [];

        if (this.props.locationParameters) {
            leadTimes = this.props.locationParameters.filter(p => p.shortName === 'csoLeadTime');
            cutOffTimes = this.props.locationParameters.filter(p => p.shortName === 'csoCutOffTime');
        }

        const addFormDetails = (key, storeDetails, special) => {
            if (
                !special &&
                /* eslint-disable-next-line no-extra-parens */
                ((storeDetails.fieldOptionShortName === 'csoLeadTime' && leadTimes.length !== 0) ||
                    /* eslint-disable-next-line no-extra-parens */
                    (storeDetails.fieldOptionShortName === 'csoCutOffTime' && cutOffTimes.length !== 0))
            ) {
                return;
            }

            const arrayObject = {
                key: storeDetails.detailId === 0 ? new Date().valueOf() + key + 1 : storeDetails.detailId,
                metaData: {
                    id: (storeDetails.fieldOptionShortName || '').replace(' ', ''),
                    name: storeDetails.fieldOption,
                    fieldTypeId: storeDetails.fieldTypeId,
                    fieldType: storeDetails.fieldType,
                    valid: true,
                    detailId: storeDetails.detailId,
                    headerId: storeDetails.headerId,
                    fieldOptionId: storeDetails.fieldOptionId,
                    isDeleted: false,
                    readOnly: true
                },
                value: {
                    elementType: storeDetails.inputType,
                    elementConfig: {
                        type: storeDetails.inputType,
                        placeholder: storeDetails.fieldOption
                    },
                    value: storeDetails.value,
                    days: storeDetails.days,
                    daysConfig: storeDetails.daysConfig,
                    validation: {
                        required: storeDetails.fieldOptionShortName !== 'generalInfo'
                    },
                    valid: true,
                    touched: false,
                    displayValue: {
                        no: storeDetails.locationLinkNo,
                        name: storeDetails.fieldOptionId === 3 ? storeDetails.locationType : storeDetails.locationLinkName
                    }
                },
                fromDate: {
                    value: storeDetails.fromDate,
                    validation: {
                        required: true
                    },
                    focused: false,
                    valid: true,
                    touched: false,
                    valueType: 'date',
                    yearStartExceptions: this.props.yearStartExceptions
                },
                toDate: {
                    value: storeDetails.toDate,
                    validation: {
                        required: true
                    },
                    focused: false,
                    valid: true,
                    touched: false,
                    valueType: 'date',
                    yearStartExceptions: this.props.yearStartExceptions
                }
            };
            formDetailsElementsArray.push(arrayObject);
        };

        // add cso lead and cut off times
        if (leadTimes !== null && leadTimes.length > 0) {
            const fieldOption = leadTimes[0];
            const grouped = groupBy(sortByKey(leadTimes, 'day'), 'locationDetailId');

            Object.keys(grouped).forEach((g, ind) => {
                grouped[g].forEach(d => d.valueValid = true);
                const storeDetails = {
                    fieldOption: fieldOption.name,
                    fieldOptionShortName: fieldOption.shortName,
                    fieldOptionId: fieldOption.fieldId,
                    fieldType: fieldOption.fieldType,
                    fieldTypeId: fieldOption.fieldTypeId,
                    detailId: g,
                    inputType: fieldOption.inputType,
                    headerId: grouped[g][0].headerId,
                    value: grouped[g][0].dcNo,
                    days: grouped,
                    daysConfig: 'number',
                    locationLinkNo: grouped[g][0].dcNo,
                    locationLinkName: this.props.locationSelectLists['servicingDC'].filter(m => m.value === grouped[g][0].dcNo)[0].label,
                    fromDate: grouped[g][0].fromDate,
                    toDate: grouped[g][0].toDate
                };
                addFormDetails(`${fieldOption.fieldId + ind}`, storeDetails, true);
            });
        }

        if (cutOffTimes !== null && cutOffTimes.length > 0) {
            const fieldOption = cutOffTimes[0];
            const grouped = groupBy(sortByKey(cutOffTimes, 'day'), 'locationDetailId');

            Object.keys(grouped).forEach((g, ind) => {
                grouped[g].forEach(d => d.valueValid = true);
                const storeDetails = {
                    fieldOption: fieldOption.name,
                    fieldOptionShortName: fieldOption.shortName,
                    fieldOptionId: fieldOption.fieldId,
                    fieldType: fieldOption.fieldType,
                    fieldTypeId: fieldOption.fieldTypeId,
                    detailId: g,
                    inputType: fieldOption.inputType,
                    headerId: grouped[g][0].headerId,
                    value: grouped[g][0].dcNo,
                    days: grouped,
                    daysConfig: 'time',
                    locationLinkNo: grouped[g][0].dcNo,
                    locationLinkName: this.props.locationSelectLists['servicingDC'].filter(m => m.value === grouped[g][0].dcNo)[0].label,
                    fromDate: grouped[g][0].fromDate,
                    toDate: grouped[g][0].toDate
                };

                addFormDetails(`${fieldOption.fieldId + ind}`, storeDetails, true);
            });
        }

        const sortedDetails = sortByKey(
            location.detail.filter(l => l.fieldOptionShortName !== null),
            'fieldOption'
        );

        // populate the store details alphabetically excluding the general info
        const sortedOptions = [...sortedDetails.filter(m => m.fieldOptionShortName !== 'generalInfo')];

        for (const key in sortedOptions) {
            const storeDetails = sortedOptions[key];
            addFormDetails(key, storeDetails);
        }

        formDetailsElementsArray = sortByNestedKey('metaData.name', formDetailsElementsArray);

        // always want to display the general info at the bottom
        const generalInfo = [...sortedDetails.filter(m => m.fieldOptionShortName === 'generalInfo')];

        for (const key in generalInfo) {
            const storeDetails = generalInfo[key];
            addFormDetails(key, storeDetails);
        }

        const formInfoElementsArray = [];
        const address = location.address;

        // remove any undefined values and leading/trailing spaces,
        // and format the address, ignoring the lat/lon part and phone numbers (object)
        let addressDetail = '';
        let phoneNumbers = '';
        if (address) {
            addressDetail = Object.values(address)
                .filter(a => a && typeof a !== 'object')
                .map(a => a = a.trim())
                .join('\n');

            phoneNumbers = location.address.phoneNumbers;
        }

        formInfoElementsArray.push({
            key: new Date().valueOf(),
            metaData: {
                id: location.id,
                headerId: location.headerId,
                locationNo: location.locationNo,
                name: 'Address',
                valid: true,
                brand: 1,
                placeId: location.placeId || 'Unknown'
            },
            value: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'input',
                    placeholder: 'Address Line 1'
                },
                value: addressDetail,
                phone: phoneNumbers,
                validation: {
                    required: false
                },
                valid: false,
                touched: false,
                displayValue: {
                    no: '',
                    name: ''
                }
            }
        });

        this.setState({
            storeDetails: formDetailsElementsArray,
            storeInfo: formInfoElementsArray
        });
    };

    requestHandler = () => {
        const model = this.mapToLocationHeaderViewModel();
        this.setState({ submitted: false });
        this.props.onSubmitChangeRequest(model);
    };

    updateLocationHandler = event => {
        event.preventDefault();
        const detail = this.mapToLocationHeaderViewModel();
        // send to back end then notify of success
        this.props.onUpdateLocationDetails(detail);
        this.setState({ updateRequired: false });
    };

    inputChangedHandler = (event, inputIdentifier, field, isInfo, daysObj) => {
        let obj = this.state.storeDetails;
        if (isInfo !== undefined && isInfo !== null) obj = this.state.storeInfo;

        const index = obj.findIndex(o => o.key === inputIdentifier);

        if (obj[index].metaData.readOnly && this.userCanEdit()) return;

        let val = removeSpecialCharacters(event.target.value) || null;

        if (obj[index].value.elementType === 'checkbox') val = obj[index].value.value === '1' ? '0' : '1';

        let updatedFormElement;

        if ((obj[index].metaData.id === 'csoLeadTime' || obj[index].metaData.id === 'csoCutOffTime') && daysObj) {
            const { day } = daysObj;

            let valid = true;
            if (obj[index][field].daysConfig === 'time') valid = isValidTime(val, true);

            // update the individual day for the current dc and day
            const updateDay = updateObject(obj[index][field]['days'][inputIdentifier][day], {
                value: val,
                valueValid: valid
            });

            // update the dc day
            const updateDC = updateObject(obj[index][field]['days'][inputIdentifier], {
                [day]: updateDay
            });

            // update the days object for the dc
            const updateDays = updateObject(obj[index][field]['days'], {
                [inputIdentifier]: Object.values(updateDC)
            });

            // update the form element with the days object
            updatedFormElement = updateObject(obj[index][field], {
                days: updateDays,
                valid: true,
                touched: true
            });
        }
        else {
            updatedFormElement = updateObject(obj[index][field], {
                value: val,
                valid: true,
                touched: true
            });
        }

        const updatedRecordInfo = updateObject(obj[index], {
            [field]: updatedFormElement
        });

        const updatedstoreDetails = updateObject(obj, {
            [index]: updatedRecordInfo
        });

        if (isInfo !== undefined && isInfo !== null) this.setState(() => ({ storeInfo: Object.values(updatedstoreDetails) }));
        else
            this.setState(() => ({
                storeDetails: Object.values(updatedstoreDetails)
            }));
    };

    optionChangedHandler = (event, inputIdentifier, field, isInfo) => {
        if (!event) return;

        let obj = this.state.storeDetails;
        if (isInfo !== undefined) obj = this.state.storeInfo;

        const index = obj.findIndex(o => o.key === inputIdentifier);

        if (obj[index].metaData.readOnly && this.userCanEdit()) return;

        const updatedFormElement = updateObject(obj[index][field], {
            value: event.value || null,
            valid: true, // checkValidity(event.target.value, this.state.storeDetails[index].validation),
            touched: true
        });

        const updatedRecordInfo = updateObject(obj[index], {
            [field]: updatedFormElement
        });

        const updatedstoreDetails = updateObject(obj, {
            [index]: updatedRecordInfo
        });

        // let formIsValid = true;
        // for (const ind in updatedstoreDetails) {
        //     formIsValid = updatedstoreDetails[ind].metaData.valid && formIsValid;
        // }

        if (isInfo !== undefined) this.setState(() => ({ storeInfo: Object.values(updatedstoreDetails) }));
        else
            this.setState(() => ({
                storeDetails: Object.values(updatedstoreDetails)
            }));
    };

    dateChangedHandler = (event, inputIdentifier, field) => {
        const dateObj = this.state.dateInputValue;
        const isMoment = event instanceof moment;
        const valid = dateObj.valid || isMoment;
        /* eslint-disable-next-line no-extra-parens */
        let val = valid ? (isMoment ? event : dateObj.value) : null;

        const obj = this.state.storeDetails;
        const index = obj.findIndex(o => o.key === inputIdentifier);

        /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
        if ((dateObj.valid && dateObj.value !== '') || isMoment) {
            if (field === 'fromDate') val = val.startOf('week').format();
            else val = val.endOf('week').format();
        }

        const updatedFormElement = updateObject(obj[index][field], {
            value: val,
            focused: false,
            valid: valid,
            touched: true
        });

        const updatedRecordInfo = updateObject(obj[index], {
            [field]: updatedFormElement
        });

        const updatedstoreDetails = updateObject(obj, {
            [index]: updatedRecordInfo
        });

        this.setState(() => ({ storeDetails: Object.values(updatedstoreDetails) }));
    };

    focusedHandler = (event, inputIdentifier, field, isInfo) => {
        this.setState({
            dateInputValue: {
                id: inputIdentifier,
                field: field
            }
        });
        let obj = this.state.storeDetails;
        if (isInfo !== undefined) obj = this.state.storeInfo;

        const index = obj.findIndex(o => o.key === inputIdentifier);

        let element = this.state.storeDetails[index][field];
        if (isInfo) element = this.state.storeInfo[index][field];

        element.focused = event.focused;
        /* eslint-disable-next-line react/no-unused-state */
        this.setState({ element });
    };

    mapToLocationDetailViewModel = detail => {
        let value = detail.value.value;
        if (value === 'false') value = 0;

        if (value === 'true') value = 1;

        return {
            dateBound: null,
            detailId: detail.metaData.detailId,
            fieldOption: detail.metaData.name,
            fieldOptionId: detail.metaData.fieldOptionId,
            fieldType: detail.metaData.fieldType,
            fieldTypeId: detail.metaData.fieldTypeId,
            fromDate: detail.fromDate.value,
            headerId: detail.metaData.headerId,
            inputType: detail.value.elementType,
            isDeleted: detail.metaData.isDeleted,
            isRequired: detail.value.validation.required,
            readOnly: detail.metaData.readOnly,
            toDate: detail.toDate.value,
            value: value,
            days: !detail.value.days ? null : detail.value.days[detail.key]
        };
    };

    mapToLocationHeaderViewModel = () => {
        const storeDetails = this.state.storeDetails.filter(m => m.metaData.updated);
        const storeInfo = this.state.storeInfo[0];
        const storeDetail = storeDetails.map(sd => ({
            dateBound: null,
            detailId: sd.metaData.detailId,
            fieldOption: sd.metaData.name,
            fieldOptionId: sd.metaData.fieldOptionId,
            fieldType: sd.metaData.fieldType,
            fieldTypeId: sd.metaData.fieldTypeId,
            fromDate: sd.fromDate.value,
            headerId: sd.metaData.headerId,
            inputType: sd.value.elementType,
            isDeleted: sd.metaData.isDeleted,
            isRequired: sd.value.validation.required,
            locationLinkNo: sd.value.displayValue.no,
            readOnly: sd.metaData.readOnly,
            toDate: sd.toDate.value,
            value: sd.value.value
        }));

        const header = {
            headerId: storeInfo.metaData.headerId,
            locationNo: storeInfo.metaData.locationNo,
            locationName: storeInfo.metaData.locationName,
            locationType: null,
            division: null,
            region: null,
            address: {
                addressLineOne: null,
                addressLineTwo: null,
                addressLineThree: storeInfo.value.value,
                city: null,
                county: null,
                country: null,
                phoneNumbers: null,
                postCode: null,
                position: {
                    latitude: null,
                    longitude: null
                }
            },
            detail: storeDetail
        };

        return header;
    };

    handleDownload = () => {
        // get current data
        const mapContent = document.getElementsByTagName('canvas')[0].toDataURL();
        const detail = this.mapToLocationHeaderViewModel();

        this.setState({ downloadEnabled: true });
        // send data to api
        this.props.onDownload(detail, mapContent);
    };

    deleteLink = key => {
        const obj = this.state.storeDetails;

        const index = obj.findIndex(o => o.key === key);

        const updatedFormElement = updateObject(obj[index]['metaData'], {
            isDeleted: true
        });

        const updatedRecordInfo = updateObject(obj[index], {
            metaData: updatedFormElement
        });

        const updatedstoreDetails = updateObject(obj, {
            [index]: updatedRecordInfo
        });

        this.setState({ storeDetails: Object.values(updatedstoreDetails) });

        const filter = this.state.storeDetails.filter(m => m.metaData.id === updatedFormElement.id && m.key !== key && !m.metaData.isDeleted);
        if (!filter || filter.length === 0) {
            const item = obj[index];
            const newItem = {
                key: new Date().valueOf(),
                metaData: {
                    id: item.metaData.id,
                    name: item.metaData.name,
                    fieldTypeId: item.metaData.fieldTypeId,
                    fieldType: item.metaData.fieldType,
                    valid: true,
                    detailId: null,
                    headerId: item.metaData.headerId,
                    fieldOptionId: item.metaData.fieldOptionId,
                    isDeleted: false,
                    readOnly: true
                },
                value: {
                    elementType: item.value.elementType,
                    elementConfig: {
                        type: item.value.type,
                        placeholder: item.value.name
                    },
                    value: null,
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false,
                    displayValue: {
                        no: item.metaData.locationLinkNo,
                        name: item.metaData.fieldOptionId === 3 ? item.metaData.locationType : item.metaData.locationLinkName
                    }
                },
                fromDate: {
                    value: null,
                    validation: {
                        required: true
                    },
                    focused: false,
                    valid: false,
                    touched: false
                },
                toDate: {
                    value: null,
                    validation: {
                        required: true
                    },
                    focused: false,
                    valid: false,
                    touched: false
                }
            };

            this.setState(state => ({
                storeDetails: [...state.storeDetails, newItem]
            }));
        }

        this.setState(
            () => ({ deleteConfirmation: false }),
            () => this.deleteSave(key)
        );
    };

    toggleDeleteConfirmation = obj => {
        this.setState({
            deleteConfirmation: !this.state.deleteConfirmation,
            selectedStoreObj: obj
        });
    };

    addNewLink = formElement => {
        const days = {};
        const newId = new Date().valueOf().toString();
        console.log(newId);
        let daysConfig = null;
        if (formElement.metaData.id === 'csoLeadTime' || formElement.metaData.id === 'csoCutOffTime') {
            days[newId] = [];
            daysConfig = formElement.metaData.fieldOptionId === 'csoCutOffTime' ? 'time' : '';
            [0, 1, 2, 3, 4, 5, 6].forEach(d => {
                days[newId].push({
                    day: d,
                    value: '',
                    valueValid: true
                });
            });
        }

        let value = '';
        if (formElement.value.elementType === 'checkbox') value = '1';

        const item = {
            key: newId,
            metaData: {
                id: formElement.metaData.id,
                name: formElement.metaData.name,
                fieldTypeId: formElement.metaData.fieldTypeId,
                fieldType: formElement.metaData.fieldType,
                valid: true,
                detailId: 0,
                headerId: formElement.metaData.headerId,
                fieldOptionId: formElement.metaData.fieldOptionId,
                isDeleted: false,
                readOnly: false,
                unsaved: true
            },
            value: {
                elementType: formElement.value.elementType,
                elementConfig: {
                    type: formElement.value.type,
                    placeholder: formElement.value.name
                },
                value: value,
                days: days,
                daysConfig: daysConfig,
                validation: {
                    required: true
                },
                valid: false,
                touched: false,
                displayValue: {
                    no: formElement.metaData.locationLinkNo,
                    name: formElement.metaData.fieldOptionId === 3 ? formElement.metaData.locationType : formElement.metaData.locationLinkName
                }
            },
            fromDate: {
                value: null,
                validation: {
                    required: true
                },
                focused: false,
                valid: false,
                touched: false,
                valueType: 'date',
                yearStartExceptions: this.props.yearStartExceptions
            },
            toDate: {
                value: null,
                validation: {
                    required: true
                },
                focused: false,
                valid: false,
                touched: false,
                valueType: 'date',
                yearStartExceptions: this.props.yearStartExceptions
            }
        };

        this.setState(state => ({
            storeDetails: [...state.storeDetails, item],
            storeDetailsTemp: state.storeDetails,
            property: formElement.metaData.id
        }));
    };

    viewSchedule = () => {
        this.props.history.push(`/schedule/${this.props.id !== null ? this.props.id : this.props.match.params.id}`);
    };

    editInfo = () => {
        this.props.history.push(`/location/edit/${this.props.id !== null ? this.props.id : this.props.match.params.id}`);
    };

    deleteSave = key => {
        // if in edit mode then save, else delete
        const obj = this.state.storeDetails;
        const index = obj.findIndex(o => o.key === key);

        const validAll = this.validateAll(obj[index]);

        if (!obj[index].metaData.isDeleted && !validAll) return;

        if (obj[index].metaData.readOnly && !obj[index].metaData.isDeleted) this.toggleDeleteConfirmation(obj[index]);
        else {
            // if you want to save an individual record at a time then you can use SAVE_ONE
            if (SAVE_ONE) {
                const data = this.mapToLocationDetailViewModel(obj[index]);
                this.setState(
                    () => ({
                        storeInfo: null,
                        storeDetails: []
                    }),
                    () => this.props.onUpdateLocationDetail(data)
                );
            }
            else {
                const updatedFormElement = updateObject(obj[index].metaData, { readOnly: true, updated: true });
                const updatedFormElementValue = updateObject(obj[index].value, { value: removeSpecialCharacters(obj[index].value.value) });
                const updatedRecordInfo = updateObject(obj[index], { metaData: updatedFormElement, value: updatedFormElementValue });
                const updatedstoreDetails = updateObject(obj, { [index]: updatedRecordInfo });
                this.setState({ storeDetails: Object.values(updatedstoreDetails), updateRequired: true });
                this.successGrowl('Please save your changes', 'updateRequired', 'info');
            }
        }
    };

    validateAll = obj => {
        const datesValid = this.checkDateRange(obj);
        const valueValid = this.checkValue(obj.value);

        const allValid = datesValid && valueValid;

        let errorMessage = 'There were errors found with the ';
        if (!datesValid) errorMessage += 'dates';

        if (!valueValid) {
            if (!datesValid) errorMessage += ' and the ';
            errorMessage += 'value(s)';
        }

        if (!allValid)
            this.growl.show({
                severity: 'error',
                summary: 'Errors Found',
                detail: errorMessage
            });

        return allValid;
    };

    checkDateRange = obj => {
        let valid = true;

        valid = obj.fromDate !== null;

        if (obj.fromDate !== null) {
            const start = moment(obj.fromDate.value).startOf('day');
            const end = moment(obj.toDate.value).startOf('day');

            if (start.isAfter(end.startOf('day')) || start.isBefore(moment('2019-01-06'))) valid = false;

            let validStart = true,
                validEnd = false;

            validStart = start.format('dddd') === 'Sunday';
            // validEnd = obj.toDate.value === null || end.format('dddd') === 'Saturday';
            validEnd = obj.toDate.valid; // === null || end.format('dddd') === 'Saturday';

            return valid && validStart && validEnd;
        }
    };

    checkValue = obj => {
        let valid = true;
        /* eslint-disable-next-line no-extra-parens */
        valid = (obj.value !== null && obj.validation.required) || !obj.validation.required;

        if (obj.value !== null) {
            if (obj.elementType === 'select' || obj.elementType === 'autocomplete') valid = obj.value !== '';
        }

        if (obj.days !== null && Object.keys(obj.days).length > 0) {
            Object.keys(obj.days).forEach(d => {
                obj.days[d].forEach(d => {
                    if (valid && !d.valueValid) valid = false;
                });
            });
        }

        return valid;
    };

    editCancel = key => {
        // set the read only state on the object property
        const obj = this.state.storeDetails;
        const index = obj.findIndex(o => o.key === key);
        const isReadOnly = obj[index].metaData.readOnly;
        // if we are not readonly (edit button clicked) store the store details should we need to reset them
        // if we are in edit mode and the cancel button is clicked then put the state back to its original state
        if (isReadOnly) {
            this.setState({ storeDetailsTemp: obj });
            const updatedFormElement = updateObject(obj[index].metaData, {
                readOnly: !isReadOnly
            });
            const updatedRecordInfo = updateObject(obj[index], {
                metaData: updatedFormElement
            });
            const updatedstoreDetails = updateObject(obj, {
                [index]: updatedRecordInfo
            });
            this.setState(() => ({
                storeDetails: Object.values(updatedstoreDetails)
            }));
        }
        else this.setState(() => ({ storeDetails: this.state.storeDetailsTemp.filter(m => !(m.key === key && m.metaData.unsaved)) }));
    };

    getCurrentFormElement = formDetailsElementsArray => {
        const elements = formDetailsElementsArray.details.filter(m => !m.metaData.isDeleted);
        let current = elements[0];

        if (elements.length > 1) {
            const tmpEles = elements.filter(
                m => moment(m.fromDate.value).isSameOrBefore(moment(), 'day') && moment(m.toDate.value).isSameOrAfter(moment(), 'day')
            );
            if (tmpEles.length > 1) {
                tmpEles.sort((a, b) => {
                    const ad = moment(a.fromDate.value);
                    const bd = moment(b.fromDate.value);

                    if (ad.isBefore(bd)) return 1;
                    if (ad.isAfter(bd)) return -1;
                    return 0;
                });
                current = tmpEles[0];
            }
        }
        return current;
    };

    handleNewLocationClick = () => this.props.history.push('/locations/newlocation');

    toggleDateHandler = (thisDate, key, field) => {
        const date = toggleDates(field, field === 'fromDate' ? thisDate : null, field === 'toDate' ? thisDate : null, true);
        this.dateChangedHandler(date, key, field);
    };

    render() {
        const userCanEdit = this.userCanEdit();

        const pleaseWait = (
            <ProgressIndicator page loading={this.props.loadingLocations}>
                <ProgressSpinner color="light" />
                Loading...
            </ProgressIndicator>
        );

        let details = (
            <Container soft size="xs">
                <ScrollToTop>
                    <Section>
                        <GridWrapper equalHeight className={[classes.sa_wrapper, 'ln-u-bg-color-white'].join(' ')}>
                            <GridItem size="1/2">
                                <GridWrapper key="storeInfo">
                                    <GridItem size="5/6">
                                        <h3>Select Location</h3>
                                        <LocationSelect />
                                    </GridItem>
                                </GridWrapper>
                            </GridItem>
                            <GridItem size="1/2">
                                <GridWrapper key="storeInfo">
                                    <GridItem size="1/1" className="ln-u-text-align-right">
                                        <Button
                                            onClick={this.handleNewLocationClick}
                                            type="button"
                                            icon="pi pi-plus"
                                            tooltip="Add New Location"
                                            tooltipOptions={{ position: 'top' }}
                                        />
                                    </GridItem>
                                </GridWrapper>
                            </GridItem>
                            {pleaseWait}
                        </GridWrapper>
                    </Section>
                </ScrollToTop>
            </Container>
        );

        const downloading = (
            <ProgressIndicator page loading={this.props.fileLoading}>
                <ProgressSpinner light />
                Creating Download...
            </ProgressIndicator>
        );

        const updating = (
            <ProgressIndicator page loading={this.props.updating || this.props.updating}>
                <ProgressSpinner light />
                Saving...
            </ProgressIndicator>
        );

        const submitting = (
            <ProgressIndicator page loading={this.props.submitting}>
                <ProgressSpinner light />
                Submitting change request...
            </ProgressIndicator>
        );

        const formDetailGroupedElementsArray = [];
        const formInfoElementsArray = [];

        if (this.state.storeDetails && this.props.locations.length > 0) {
            // group the store details immutable
            const groupedStoreDetails = this.state.storeDetails.reduce(
                (groups, item) => ({
                    ...groups,
                    /* eslint-disable-next-line no-extra-parens */
                    [item.metaData.id]: [...(groups[item.metaData.id] || []), item]
                }),
                {}
            );

            for (const key in groupedStoreDetails)
                formDetailGroupedElementsArray.push({
                    id: key,
                    details: groupedStoreDetails[key]
                });

            for (const key in this.state.storeInfo)
                formInfoElementsArray.push({
                    id: key,
                    config: this.state.storeInfo[key]
                });
        }

        const requestButton = userCanEdit ? <span></span> : <FilledButton onClick={this.requestHandler}>Request Change</FilledButton>;

        let storeDetails = (
            <Card>
                <h4>There are no location details set</h4>
            </Card>
        );

        // #region storeDetails
        if (formDetailGroupedElementsArray.length > 0)
            storeDetails = (
                <Fragment>
                    <Fieldset>
                        <FormGroup name="form-group-1">
                            {formDetailGroupedElementsArray.map(formDetailsElementsArray => {
                                const property = formDetailsElementsArray.id;
                                const formElement = this.getCurrentFormElement(formDetailsElementsArray);
                                const dummy = formDetailsElementsArray.details[0];
                                // if (!formElement) return;
                                const id = formElement.key;
                                const roButtons = formElement.metaData.fieldOptionId === 0;
                                const ro = (roButtons || formElement.metaData.readOnly) && userCanEdit;
                                const editCancel = ro ? <Edit /> : <Cancel />;
                                const deleteSave = ro ? <Delete /> : <Save />;
                                const editCancelLabel = ro ? 'Edit' : 'Cancel';
                                const deleteSaveLabel = ro ? 'Delete' : 'Save';

                                let x = 0;
                                let item = null;

                                if (formElement.value.elementConfig.type === 'textarea') {
                                    const buttons = (
                                        <GridWrapper>
                                            <GridItem size="1/4">
                                                <IconButton
                                                    disabled={!userCanEdit}
                                                    variant="text"
                                                    label={editCancelLabel}
                                                    hideLabel
                                                    readOnly={roButtons}
                                                    onClick={() => this.editCancel(id)}
                                                >
                                                    {editCancel}
                                                </IconButton>
                                            </GridItem>
                                            <GridItem size="1/4" style={{ marginLeft: '1rem' }}>
                                                <IconButton
                                                    disabled={!userCanEdit}
                                                    variant="text"
                                                    label={deleteSaveLabel}
                                                    hideLabel
                                                    onClick={() => this.deleteSave(formElement.key)}
                                                >
                                                    {deleteSave}
                                                </IconButton>
                                            </GridItem>
                                        </GridWrapper>
                                    );

                                    const elementName = formElement.metaData.name.replace(' ', '');

                                    const valueInput = (
                                        <GridItem key="2" size="2/3" element="li" className={classes.sa_align_center}>
                                            <Input
                                                disabled={!userCanEdit && ro}
                                                readOnly={ro && userCanEdit}
                                                key={`${elementName}Value${id}`}
                                                id={`${elementName}Value${id}`}
                                                options={
                                                    formElement.value.elementType === 'select' ? this.props.locationSelectLists[formElement.metaData.id] : null
                                                }
                                                elementType={formElement.value.elementType}
                                                elementConfig={formElement.value.elementConfig}
                                                value={formElement.value.value}
                                                invalid={!formElement.value.valid}
                                                shouldValidate={formElement.value.validation}
                                                touched={formElement.value.touched}
                                                errorMessage={formElement.value.valueType}
                                                changed={event => this.inputChangedHandler(event, id, 'value')}
                                                yearStartExceptions={formElement.value.yearStartExceptions}
                                            />
                                        </GridItem>
                                    );

                                    return (
                                        <div style={{ paddingTop: '1rem', paddingLeft: '0.5rem' }} key={id + x++}>
                                            <GridItem key="1" size="1/4" element="li" className={classes.sa_field_heading}>
                                                {formElement.metaData.name}
                                                {buttons}
                                            </GridItem>
                                            {valueInput}
                                        </div>
                                    );
                                }
                                else {
                                    let linkButton = (
                                        <IconButton
                                            disabled={!userCanEdit && ro}
                                            onClick={() => this.addNewLink(dummy)}
                                            variant="outlined"
                                            label="Add new link"
                                        >
                                            <Plus />
                                        </IconButton>
                                    );

                                    const location = this.props.locations.filter(m => m.headerId === +this.props.id);
                                    let locationName = '';
                                    if (location.length > 0) locationName = location[0].locationName;

                                    const fieldOptionDetails = formDetailsElementsArray.details.filter(
                                        m =>
                                            !m.metaData.isDeleted &&
                                            /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
                                            ((m.metaData.detailId !== null &&
                                                m.metaData.detailId >= 0 &&
                                                m.metaData.id !== 'csoLeadTime' &&
                                                /* eslint-disable-next-line no-mixed-operators */
                                                m.metaData.id !== 'csoCutOffTime') ||
                                                /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
                                                ((m.metaData.fieldOptidionId === 'csoLeadTime' || m.metaData.id === 'csoCutOffTime') && m.value.value !== null))
                                    );

                                    const count = formDetailsElementsArray.details.filter(
                                        m =>
                                            /* eslint-disable-next-line no-mixed-operators, no-extra-parens */
                                            (m.metaData.detailId !== null &&
                                                m.metaData.detailId >= 0 &&
                                                m.metaData.id !== 'csoLeadTime' &&
                                                /* eslint-disable-next-line no-mixed-operators */
                                                m.metaData.id !== 'csoCutOffTime' &&
                                                /* eslint-disable-next-line no-mixed-operators */
                                                !m.metaData.isDeleted) ||
                                                /* eslint-disable-next-line no-extra-parens */
                                            ((m.metaData.id === 'csoLeadTime' || m.metaData.id === 'csoCutOffTime') &&
                                                /* eslint-disable-next-line no-mixed-operators */
                                                m.value.value !== null)
                                    ).length;

                                    item = (
                                        <Accordion multipleOpen={false} className={classes.accordion} key={property + id} titleElement="h3">
                                            <AccordionItem
                                                defaultOpen={property === this.state.property}
                                                id={`accordion-item-${id}`}
                                                className={classes.sa_c_accordion__body}
                                                style={{ display: count > 0 || this.state.showNoData ? 'block' : 'none' }}
                                                title={<AccordionHeader locationName={locationName} count={count} info={formElement} />}
                                            >
                                                {!fieldOptionDetails || fieldOptionDetails.length === 0 ? (
                                                    <p>There are no links for this field at present. Click the add new link button to create a new link</p>
                                                ) : (
                                                    <Fragment>
                                                        {formDetailsElementsArray.id === 'schedule' ? null : (
                                                            <GridItem key="1" size="1/6" element="li" className={classes.sa_field_heading}>
                                                                Actions
                                                            </GridItem>
                                                        )}
                                                        <GridItem
                                                            key="2"
                                                            size="2/5"
                                                            element="li"
                                                            className={[classes.sa_align_center, classes.sa_field_heading].join(' ')}
                                                        >
                                                            Value
                                                        </GridItem>
                                                        <GridItem
                                                            key="3"
                                                            size="1/5"
                                                            element="li"
                                                            className={[classes.sa_align_center, classes.sa_field_heading].join(' ')}
                                                        >
                                                            Date From
                                                        </GridItem>
                                                        <GridItem
                                                            key="4"
                                                            size="1/5"
                                                            element="li"
                                                            className={[classes.sa_align_center, classes.sa_field_heading].join(' ')}
                                                        >
                                                            Date To
                                                        </GridItem>
                                                    </Fragment>
                                                )}
                                                {/* eslint-disable no-mixed-operators, no-extra-parens */
                                                formDetailsElementsArray.details
                                                    .filter(
                                                        m =>
                                                            (m.metaData.detailId !== null &&
                                                                m.metaData.detailId >= 0 &&
                                                                m.metaData.id !== 'csoLeadTime' &&
                                                                m.metaData.id !== 'csoCutOffTime' &&
                                                                !m.metaData.isDeleted) ||
                                                            ((m.metaData.id === 'csoLeadTime' || m.metaData.id === 'csoCutOffTime') && m.value.value !== null)
                                                    )
                                                    .map(formElement => {
                                                        /* eslint-enable no-mixed-operators, no-extra-parens */
                                                        const elementName = formElement.metaData.name.replace(' ', '');
                                                        const key = formElement.key;
                                                        // readonly - fieldOptionId 0 is added through a union query for schedules
                                                        const roButtons = formElement.metaData.fieldOptionId === 0;
                                                        const ro = (roButtons || formElement.metaData.readOnly) && userCanEdit;

                                                        let displayNam = formElement.value.value;

                                                        if (Object.keys(this.props.locationSelectLists).some(m => m === formElement.metaData.id)) {
                                                            const selectedItem = this.props.locationSelectLists[formElement.metaData.id].find(
                                                                t => t.value === formElement.value.value
                                                            );
                                                            if (selectedItem) displayNam = selectedItem.label;
                                                        }

                                                        let weekInputs = null;
                                                        if (
                                                            (formElement.metaData.id === 'csoLeadTime' || formElement.metaData.id === 'csoCutOffTime') &&
                                                            formElement.value.days !== null
                                                        ) {
                                                            const wkInputs = formElement.value.days[
                                                                formElement.metaData.detailId === 0 ? formElement.key : formElement.metaData.detailId
                                                            ].map((d, ind) => {
                                                                const displayName = getDayName(ind, true);
                                                                let value = d.value;
                                                                if (formElement.value.daysConfig === 'time' && d.valueValid && d.value !== '') {
                                                                    value = minsToHHMM(d.value);
                                                                }
                                                                const updateObjectInfo = {
                                                                    dc: +formElement.value.value,
                                                                    day: ind
                                                                };
                                                                return (
                                                                    <GridItem
                                                                        key={ind}
                                                                        size="1/8"
                                                                        className={[classes.sa_align_center_p_7, classes.daysInput].join(' ')}
                                                                    >
                                                                        <Input
                                                                            autoComplete="off"
                                                                            errorMessage={d.valueValid ? '' : 'Invalid ' + formElement.value.daysConfig}
                                                                            type={formElement.value.daysConfig === 'time' ? null : formElement.value.daysConfig}
                                                                            className={[d.valueValid ? '' : classes.invalid, formElement.value.daysConfig].join(
                                                                                ' '
                                                                            )}
                                                                            locationName={locationName}
                                                                            disabled={ro || !this.userCanEdit()}
                                                                            readOnly={ro}
                                                                            key={`${elementName}Value${key}`}
                                                                            id={`${elementName}Value${key}`}
                                                                            options={null}
                                                                            elementType={'input'}
                                                                            elementConfig={formElement.value.daysConfig}
                                                                            value={value}
                                                                            invalid={!formElement.value.valid}
                                                                            shouldValidate={formElement.value.validation}
                                                                            touched={formElement.value.touched}
                                                                            label={displayName}
                                                                            changed={event =>
                                                                                this.inputChangedHandler(event, key, 'value', null, updateObjectInfo)
                                                                            }
                                                                            yearStartExceptions={formElement.value.yearStartExceptions}
                                                                        />
                                                                    </GridItem>
                                                                );
                                                            });

                                                            weekInputs = (
                                                                <Fragment>
                                                                    <GridItem size="1/8">&nbsp;</GridItem>
                                                                    {wkInputs}
                                                                </Fragment>
                                                            );
                                                        }

                                                        const valueInput = (
                                                            // eslint-disable-next-line max-len
                                                            <GridItem
                                                                key="2"
                                                                size={formDetailsElementsArray.id === 'Schedule' ? '3/5' : '2/5'}
                                                                element="li"
                                                                className={classes.sa_align_left}
                                                            >
                                                                <Input
                                                                    autoComplete="off"
                                                                    locationName={locationName}
                                                                    disabled={!userCanEdit || ro}
                                                                    /* eslint-disable-next-line max-len, no-extra-parens */
                                                                    readOnly={ro && userCanEdit}
                                                                    key={`${elementName}Value${key}`}
                                                                    id={`${elementName}Value${key}`}
                                                                    options={
                                                                        formElement.value.elementType === 'select' ||
                                                                        formElement.value.elementType === 'autocomplete'
                                                                            ? this.props.locationSelectLists[formElement.metaData.id]
                                                                            : null
                                                                    }
                                                                    elementType={formElement.value.elementType}
                                                                    elementConfig={formElement.value.elementConfig}
                                                                    value={formElement.value.value}
                                                                    invalid={!formElement.value.valid}
                                                                    shouldValidate={formElement.value.validation}
                                                                    touched={formElement.value.touched}
                                                                    errorMessage={formElement.value.valueType}
                                                                    displayName={displayNam}
                                                                    className={formElement.value.elementType === 'checkbox' ? classes.cbCenter : null}
                                                                    changed={event => this.inputChangedHandler(event, key, 'value')}
                                                                    select={option => this.optionChangedHandler(option, key, 'value')}
                                                                    yearStartExceptions={formElement.value.yearStartExceptions}
                                                                />
                                                            </GridItem>
                                                        );

                                                        // loop to avoid code duplication
                                                        const dateInputs = ['fromDate', 'toDate'].map((d, index) => {
                                                            // done like this as using ternary statements here seems to confuse the vscode formatter
                                                            let fHandler = () => {};
                                                            let dHandler = () => {};
                                                            let wkHandler = () => {};
                                                            // let cHandler = () => { };

                                                            // if not readonly we need the event handlers
                                                            if (!ro || !userCanEdit) {
                                                                fHandler = event => this.focusedHandler(event, key, d);
                                                                dHandler = event => this.dateChangedHandler(event, key, d);
                                                                wkHandler = () => this.toggleDateHandler(formElement[d].value, key, d);
                                                                // dHandler = () => { };
                                                                // cHandler = (event) => this.inputChangedHandler(event, key, d);
                                                            }

                                                            return (
                                                                <GridItem key={index + 3} size="1/5" element="li" className={classes.sa_align_center_p_7}>
                                                                    <Input
                                                                        // error={formElement[d].valid}
                                                                        errorMessage={formElement[d].valueType}
                                                                        className={formElement[d].valid ? '' : classes.invalid}
                                                                        /* eslint-disable no-extra-parens, max-len*/
                                                                        disabled={!userCanEdit && (ro || formElement.metaData.fieldOptionId === 0)}
                                                                        readOnly={
                                                                            (ro && userCanEdit) || (formElement.metaData.fieldOptionId === 0 && !userCanEdit)
                                                                        }
                                                                        /* eslint-enable no-extra-parens, max-len */
                                                                        key={`${elementName}${d}${key}`}
                                                                        id={`${elementName}${d}${key}`}
                                                                        elementType="date"
                                                                        elementConfig={formElement[d].elementConfig}
                                                                        value={formElement[d].value}
                                                                        invalid={!formElement[d].valid}
                                                                        shouldValidate={formElement[d].validation}
                                                                        touched={formElement[d].touched}
                                                                        focused={formElement[d].focused}
                                                                        onFocused={fHandler}
                                                                        // changed={cHandler}
                                                                        dateChanged={dHandler}
                                                                        wkNoClick={wkHandler}
                                                                        yearStartExceptions={formElement[d].yearStartExceptions}
                                                                    />
                                                                </GridItem>
                                                            );
                                                        });

                                                        const editCancel = ro ? <Edit /> : <Cancel />;
                                                        const deleteSave = ro ? <Delete /> : <Save />;
                                                        const editCancelLabel = ro ? 'Edit' : 'Cancel';
                                                        const deleteSaveLabel = ro ? 'Delete' : 'Save';

                                                        let buttons = (
                                                            <GridWrapper>
                                                                <GridItem size="1/3" className={classes.sa_align_center}>
                                                                    <IconButton
                                                                        disabled={!userCanEdit}
                                                                        variant="text"
                                                                        label={editCancelLabel}
                                                                        hideLabel
                                                                        readOnly={roButtons}
                                                                        onClick={() => this.editCancel(key)}
                                                                    >
                                                                        {editCancel}
                                                                    </IconButton>
                                                                </GridItem>
                                                                <GridItem size="1/3" className={classes.sa_align_center_p_5}>
                                                                    <IconButton
                                                                        disabled={!userCanEdit}
                                                                        variant="text"
                                                                        label={deleteSaveLabel}
                                                                        hideLabel
                                                                        onClick={() => this.deleteSave(key)}
                                                                    >
                                                                        {deleteSave}
                                                                    </IconButton>
                                                                </GridItem>
                                                            </GridWrapper>
                                                        );

                                                        if (elementName === 'Schedule') {
                                                            buttons = null;
                                                            linkButton = null;
                                                        }

                                                        if (!ro) linkButton = null;

                                                        return (
                                                            <Fragment key={property + id + x++}>
                                                                {buttons === null ? null : (
                                                                    <GridItem
                                                                        key="1"
                                                                        size="1/6"
                                                                        element="li"
                                                                        className={[classes.sa_align_center, classes.sa_field_heading].join(' ')}
                                                                    >
                                                                        {buttons || <span></span>}
                                                                    </GridItem>
                                                                )}
                                                                {valueInput}
                                                                {dateInputs}
                                                                {weekInputs}
                                                            </Fragment>
                                                        );
                                                        // }
                                                    })}
                                                {linkButton}
                                            </AccordionItem>
                                        </Accordion>
                                    );
                                }
                                return item;
                            })}
                            <ButtonGroupWrapper>
                                <ButtonGroupPrimary>{requestButton}</ButtonGroupPrimary>
                            </ButtonGroupWrapper>
                        </FormGroup>
                    </Fieldset>
                </Fragment>
            );
        // #endregion

        if (!this.props.loadingLocations && this.props.locations.length === 1 && this.props.id !== null) {
            const location = this.props.locations[0];
            let lon;
            let lat;
            // let storeName;

            if (location) {
                lon = location.address.position.longitude;
                lat = location.address.position.latitude;
                // storeName = `${location.locationNo} ${location.locationName}`;
            }

            const locationSelect = <LocationSelect />;

            let locationBrandType = '';
            if (location.brandName) locationBrandType = location.brandName;
            if (location.locationType) locationBrandType = location.locationType;

            const storeInfo = (
                <GridWrapper equalHeight className={[classes.sa_wrapper, 'ln-u-bg-color-white'].join(' ')}>
                    <GridItem size="1/2">
                        {formInfoElementsArray.map(formElement => {
                            const ro = formElement.config.metaData.readOnly && userCanEdit;
                            const elementName = formElement.config.metaData.name.replace(' ', '');
                            const key = formElement.config.key;
                            return (
                                <GridWrapper key="storeInfo">
                                    <GridItem size="6/6">
                                        <h3>Location Info</h3>
                                    </GridItem>
                                    <GridItem size="5/6">{locationSelect}</GridItem>
                                    <GridItem size="1/1" style={{ paddingBottom: '10px' }}>
                                        <GridWrapper>
                                            <GridItem size="1/4">
                                                <b>Place Id:</b>
                                            </GridItem>
                                            <GridItem size="3/4">
                                                {formElement.config.metaData.placeId}
                                            </GridItem>
                                        </GridWrapper>
                                    </GridItem>
                                    <GridItem size="1/1" className={classes.sa_textarea}>
                                        <Input
                                            readOnly={!userCanEdit && ro}
                                            key={`${elementName}Value${key}`}
                                            id={`${elementName}Value${key}`}
                                            elementName={formElement.config.metaData}
                                            elementType={formElement.config.value.elementType}
                                            elementConfig={formElement.config.value.elementConfig}
                                            value={formElement.config.value.value}
                                            invalid={!formElement.config.value.valid}
                                            shouldValidate={formElement.config.value.validation}
                                            touched={formElement.config.value.touched}
                                            errorMessage={formElement.config.value.valueType}
                                            changed={event => this.inputChangedHandler(event, key, 'value', true)}
                                            yearStartExceptions={formElement.config.value.yearStartExceptions}
                                        />
                                    </GridItem>
                                    <GridItem size="1/1" style={{ paddingBottom: '10px' }}>
                                        <GridWrapper>
                                            <GridItem size="1/8">
                                                <LandlinePhone />
                                            </GridItem>
                                            <GridItem size="7/8">
                                                {formElement.config.value.phone.map((num, idx) => (
                                                    <span key={`phone${idx}`} className={classes.phoneNumber}>
                                                        {num}
                                                    </span>
                                                ))}
                                            </GridItem>
                                        </GridWrapper>
                                    </GridItem>
                                </GridWrapper>
                            );
                        })}
                    </GridItem>
                    <GridItem size="1/2">
                        <GridWrapper>
                            <GridItem size="1/1">
                                <h3 className="ln-u-text-align-right">{locationBrandType}</h3>
                            </GridItem>
                            <GridItem size="1/1">
                                <IconButton onClick={this.handleDownload} variant="outlined" label="Download" hideLabel>
                                    <Download />
                                </IconButton>
                                <IconButton className="ln-u-margin-left" onClick={this.editInfo} variant="outlined" label="Edit" hideLabel>
                                    <Edit />
                                </IconButton>
                            </GridItem>
                            <GridItem size="1/1">
                                <Map zoom={15} latitude={lat} longitude={lon} updateMap brand={location.brandId} />
                            </GridItem>
                        </GridWrapper>
                    </GridItem>
                </GridWrapper>
            );

            const deleteConfirmation = {
                message: '',
                action: null,
                heading: '',
                isOpen: this.state.deleteConfirmation,
                handleClose: () => this.toggleDeleteConfirmation()
            };

            if (this.state.deleteConfirmation) {
                const sd = this.state.selectedStoreObj;
                const value =
                    sd.value.displayValue.name === null
                        ? sd.value.displayValue.format === ''
                            ? sd.value.value === 1
                                ? 'Yes'
                                : 'No'
                            : sd.value.displayValue.format
                        : sd.value.displayValue.name;
                deleteConfirmation.heading = `Delete ${sd.metaData.name} Link`;
                deleteConfirmation.message = (
                    <Fragment>
                        <p>
                            <br />
                            This will delete the <strong>{sd.metaData.name}</strong> link, with the value of <strong>{value}</strong>.
                        </p>
                        <p>
                            Active from: <strong>{moment(sd.fromDate.value).format('DD/MM/YYYY')}</strong> - to:{' '}
                            <strong>{moment(sd.toDate.valeue).format('DD/MM/YYYY')}</strong>.
                        </p>
                        <p>Are you sure ?</p>
                    </Fragment>
                );
                deleteConfirmation.action = () => this.deleteLink(sd.key);
            }

            const confirmationModal = (
                <Confirmation
                    confirmation={deleteConfirmation}
                    toggleClose={deleteConfirmation.handleClose}
                    isOpen={deleteConfirmation.isOpen}
                    clicked={deleteConfirmation.action}
                />
            );

            details = (
                <Container soft size="xs">
                    <ScrollToTop>
                        {downloading}
                        {updating}
                        {submitting}
                        <Form soft="lg" style={{ position: 'relative' }} onSubmit={this.updateLocationHandler}>
                            <Section>{storeInfo}</Section> {/* style={{ display: this.state.position === 'absolute' ? 'none' : 'block' }} */}
                            <div
                                className={['detailsHeader', classes.fixedHeader, this.state.position === 'absolute' ? classes.stick : ''].join(' ')}
                                // onScroll={this.handleScroll}
                                style={{ position: this.state.position, top: this.state.fixedHeader + 'px' }}
                            >
                                {/* <Fieldset>
                                    <FormGroup name="detailsFormGroup"> */}
                                <Card>
                                    <GridWrapper>
                                        <GridItem size="1/3">
                                            <Legend fontStyle="h2">Location Details</Legend>
                                        </GridItem>
                                        <GridItem size="2/3" style={{ textAlign: 'right' }}>
                                            <div className="ln-u-display-1" style={{ display: 'inherit' }}>
                                                {this.state.updateRequired ? 'Please save your changes' : ''}
                                            </div>
                                            <Button
                                                type="submit"
                                                icon="pi pi-save"
                                                disabled={!this.state.updateRequired}
                                                tooltip="Save Location Details"
                                                tooltipOptions={{ position: 'top' }}
                                            />
                                            <Button
                                                onClick={() => this.setState(() => ({ storeDetails: this.state.storeDetailsTemp, updateRequired: false }))}
                                                type="button"
                                                icon="pi pi-undo"
                                                disabled={!this.state.updateRequired}
                                                tooltip="Undo Changes"
                                                tooltipOptions={{ position: 'top' }}
                                            />
                                            <Button
                                                onClick={() => this.setState({ showNoData: !this.state.showNoData })}
                                                className={this.state.showNoData ? 'buttonActive' : ''}
                                                type="button"
                                                icon="pi pi-list"
                                                tooltip="Toggle Unused Fields"
                                                tooltipOptions={{ position: 'top' }}
                                            />
                                            <Button
                                                onClick={this.viewSchedule}
                                                type="button"
                                                icon="pi pi-calendar"
                                                tooltip="View Schedules"
                                                tooltipOptions={{ position: 'top' }}
                                            />
                                            {/* <FilledButton onClick={this.viewSchedule}>View Schedule</FilledButton> */}
                                        </GridItem>
                                    </GridWrapper>
                                    <h3
                                        style={{
                                            paddingLeft: '1.5rem',
                                            paddingRight: '3.25rem',
                                            backgroundColor: '#F6F6F6',
                                            paddingTop: '1rem'
                                        }}
                                    >
                                        <GridItem key="1" size="1/5" element="li" style={{ whiteSpace: 'nowrap' }} className={classes.sa_align_left}>
                                            Location Info
                                        </GridItem>
                                        <GridItem key="2" size="2/5" element="li" className={classes.sa_align_center}>
                                            Value
                                        </GridItem>
                                        <GridItem key="3" size="1/5" element="li" className={classes.sa_align_center}>
                                            Date From
                                        </GridItem>
                                        <GridItem key="4" size="1/5" element="li" className={classes.sa_align_center}>
                                            Date To
                                        </GridItem>
                                    </h3>
                                    {/* </FormGroup>
                                </Fieldset> */}
                                </Card>
                            </div>
                            <Section className={['detailsSection', this.state.position === 'absolute' ? 'fixedHeader' : ''].join(' ')}>{storeDetails}</Section>
                        </Form>
                    </ScrollToTop>
                    {confirmationModal}
                    <Growl ref={el => this.growl = el}></Growl>
                </Container>
            );
        }

        return details;
    }
}

Location.propTypes = {
    fileData: PropTypes.any,
    fileLoading: PropTypes.bool,
    history: PropTypes.object,
    id: PropTypes.number,
    loadingLocations: PropTypes.bool,
    locationParameters: PropTypes.array,
    locationSelectLists: PropTypes.object,
    locations: PropTypes.array,
    match: PropTypes.object,
    onDownload: PropTypes.func,
    onGetAllLocations: PropTypes.func,
    onGetLocations: PropTypes.func,
    onGetYearStartExceptions: PropTypes.func,
    onResetLocation: PropTypes.func,
    onSubmitChangeRequest: PropTypes.func,
    onUpdateLocationDetail: PropTypes.func,
    onUpdateLocationDetails: PropTypes.func,
    onUpdateLocationDetailsReset: PropTypes.func,
    requestFail: PropTypes.bool,
    requestSuccess: PropTypes.bool,
    roles: PropTypes.array,
    submitting: PropTypes.bool,
    updating: PropTypes.bool,
    updatingSuccess: PropTypes.bool,
    yearStartExceptions: PropTypes.array,
    yearStartExceptionsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        fileData: state.location.fileData,
        fileLoading: state.location.fileLoading,
        id: state.location.id,
        loadingLocations: state.location.loadingLocations,
        locations: state.location.locations,
        locationParameters: state.location.locationParameters,
        locationSelectLists: state.location.locationSelectLists,
        requestFail: state.location.submitError,
        requestSuccess: state.location.requestSuccess,
        roles: state.auth.roles,
        submitting: state.location.submitting,
        updating: state.location.updating,
        updatingSuccess: state.location.updatingSuccess,
        yearStartExceptions: state.general.exceptions,
        yearStartExceptionsLoading: state.general.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetLocations: id => dispatch(actions.getLocation(id)),
        onGetAllLocations: () => dispatch(actions.getLocationHeaders()),
        onSubmitChangeRequest: changeRequest => dispatch(actions.submitChangeRequest(changeRequest)),
        onUpdateLocationDetail: detail => dispatch(actions.updateLocationDetail(detail)),
        onUpdateLocationDetails: location => dispatch(actions.updateLocationDetails(location)),
        onUpdateLocationDetailsReset: () => dispatch(actions.updateLocationDetailsReset()),
        onDownload: (data, mapContent) => dispatch(actions.downloadStore(data, mapContent)),
        onResetLocation: () => dispatch(actions.setLocation(null)),
        onGetYearStartExceptions: () => dispatch(actions.getYearStartExceptions())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Location, axios));

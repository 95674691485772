import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeading } from '@jsluna/modal';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { GridItem, GridWrapper } from '@jsluna/grid';

import PropTypes from 'prop-types';

import * as actions from '../../store/actions/index';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios';

import moment from 'moment';
import Input from '../../components/UI/Input/Input';
import { toggleDates, updateObject } from '../../shared/utility';
moment.locale('en-GB');
class DateRangeSelection extends Component {
    state = {
        endDate: moment('2999-12-28'),
        endDateFocused: false,
        startDateFocused: false,
        startDate: moment().startOf('week'),
        isValidDateRange: false,
        isValidStartDate: false,
        isValidEndDate: false,
        yearStartExceptions: null
    };

    componentDidMount() {
        this.checkDateRange();
    }

    componentDidUpdate() {}
    focusedHandler = (event, identifier) => this.setState({ [`${identifier}Focused`]: event.focused });
    toggleDateHandler = identifier => {
        const date = toggleDates(identifier, this.state.startDate, this.state.endDate);
        this.dateChangedHandler(date, identifier);
    };
    onFocusedHandler = event => event.currentTarget.select();

    dateChangedHandler = (event, identifier) => {
        const touched = updateObject(this.state.touched, { [identifier]: true });
        this.setState(
            () => ({ [identifier]: event, touched }),
            () => this.checkDateRange()
        );
    };

    checkDateRange = () => {
        let valid = true;

        let start = moment().startOf('day');
        let end = moment().startOf('day');

        if (this.state.startDate) start = this.state.startDate.startOf('day');
        if (this.state.endDate) end = this.state.endDate.startOf('day');

        if (start.isAfter(end.startOf('day')) || start.isBefore(moment('2019-01-06'))) valid = false;

        let validStart = false;
        let validEnd = false;

        if (this.state.startDate) validStart = this.state.startDate.format('dddd') === 'Sunday';
        if (this.state.endDate) validEnd = this.state.endDate.format('dddd') === 'Saturday';

        this.setState({
            isValidDateRange: valid,
            isValidStartDate: validStart,
            isValidEndDate: validEnd
        });

        return valid && validStart && validEnd;
    };

    getErrorMessage = type => {
        let errorMessage = '';

        if (!this.state.isValidDateRange) errorMessage = 'Invalid date range';

        if (!this.state[`isValid${type}`]) errorMessage = `Must be a ${type === 'StartDate' ? 'Sunday' : 'Saturday'}`;

        return errorMessage;
    };

    setSelectedDatesHandler = () => {
        this.props.onSetSelectedDates(this.state.startDate, this.state.endDate);
        this.props.close();
    };

    render() {
        return (
            <Modal handleClose={this.props.close} open={this.props.show}>
                <ModalHeading element="h3">Enter Dates</ModalHeading>
                <GridWrapper>
                    <GridItem size="1/2">
                        <Input
                            label="Start Date"
                            id="startDate"
                            dateChanged={event => this.dateChangedHandler(event, 'startDate')}
                            onFocused={event => this.focusedHandler(event, 'startDate')}
                            focused={this.state.startDateFocused}
                            value={this.state.startDate}
                            invalid={!this.state.isValidDateRange}
                            errorMessage={this.getErrorMessage('StartDate')}
                            elementType="datelabelled"
                            wkNoClick={() => this.toggleDateHandler('startDate')}
                            yearStartExceptions={this.props.yearStartExceptions}
                        />
                    </GridItem>
                    <GridItem size="1/2">
                        <Input
                            label="End Date"
                            id="endDate"
                            dateChanged={event => this.dateChangedHandler(event, 'endDate')}
                            onFocused={event => this.focusedHandler(event, 'endDate')}
                            focused={this.state.endDateFocused}
                            value={this.state.endDate}
                            invalid={!this.state.isValidDateRange}
                            errorMessage={this.getErrorMessage('EndDate')}
                            elementType="datelabelled"
                            wkNoClick={() => this.toggleDateHandler('endDate')}
                            yearStartExceptions={this.state.yearStartExceptions}
                        />
                    </GridItem>
                </GridWrapper>
                <ButtonGroupWrapper actionBar>
                    <ButtonGroupPrimary>
                        <FilledButton onClick={this.setSelectedDatesHandler}>Apply</FilledButton>
                    </ButtonGroupPrimary>
                    <ButtonGroupSecondary>
                        <TextButton onClick={this.props.close}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                </ButtonGroupWrapper>
            </Modal>
        );
    }
}

DateRangeSelection.propTypes = {
    close: PropTypes.func,
    onSetSelectedDates: PropTypes.func,
    show: PropTypes.bool,
    yearStartExceptions: PropTypes.array
};

const mapStateToProps = state => {
    return {
        isLoading: !state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetTrailers: () => dispatch(actions.getTrailers()),
        onUpdateSite: site => dispatch(actions.updateUserSite(site)),
        onSetSelectedDates: (fromDate, toDate) => dispatch(actions.setSelectedDateRange(fromDate, toDate))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(DateRangeSelection, axios));

import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '@jsluna/form';
import { ListGroup, ListGroupItem } from '@jsluna/list';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from '@jsluna/date-picker';

import moment from 'moment';

moment.locale('en');
// ISO-8601, Europe
moment.updateLocale('en', {
    week: {
        dow: 0, // First day of week is Sunday
        doy: 4 // First week of year must contain 4 January (7 + 1 - 4)
    }
});

const singleDatePickerWithLabel = ({
    className,
    dateVal,
    /* eslint-disable-next-line no-unused-vars */
    displayName,
    element,
    error,
    info,
    label,
    name,
    validationFirst,
    warning,
    wkNoClick,
    wkNo,
    ...rest
}) => (
    <FormGroup label={label} error={error} warning={warning} info={info} className={className} name={name} validationFirst={validationFirst} element={element}>
        <ListGroup inline className="dateList">
            <ListGroupItem className="dateInput">
                <SingleDatePicker {...rest} />
            </ListGroupItem>
            <ListGroupItem onClick={wkNoClick} className="weekNumberLi">
                <span className="weekNumber">{dateVal === null ? '#' : wkNo}</span>
            </ListGroupItem>
        </ListGroup>
    </FormGroup>
);

const getPropTypes = () => {
    /* eslint-disable-next-line react/forbid-foreign-prop-types */
    const propTypes = { ...FormGroup.propTypes };
    delete propTypes.children;

    return propTypes;
};

singleDatePickerWithLabel.propTypes = {
    ...getPropTypes(),
    info: PropTypes.node,
    label: PropTypes.string.isRequired,
    wkNo: PropTypes.number
};

export default singleDatePickerWithLabel;

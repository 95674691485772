import React from 'react';
import PropTypes from 'prop-types';

import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { Modal, ModalHeading} from '@jsluna/modal';

const confirmation = props => (
    <Modal
        fullScreen
        restrictClose
        alert
        handleClose={props.toggleClose}
        open={props.isOpen} >
        <ModalHeading element="h3">{props.confirmation.heading}</ModalHeading>
        {props.confirmation.message}
        <ButtonGroupWrapper actionBar>
            <ButtonGroupPrimary>
                <FilledButton onClick={props.clicked}>Yes</FilledButton>
            </ButtonGroupPrimary>
            <ButtonGroupSecondary>
                <TextButton onClick={props.toggleClose}>No</TextButton>
            </ButtonGroupSecondary>
        </ButtonGroupWrapper>
    </Modal>
);

export default confirmation;

confirmation.propTypes = {
    clicked: PropTypes.func,
    confirmation: PropTypes.object,
    heading: PropTypes.string,
    isOpen: PropTypes.bool,
    toggleClose: PropTypes.func
};

import React, { Fragment } from 'react';
import { GridItem } from '@jsluna/grid';
import classes from '../Location.module.scss';
import PropTypes from 'prop-types';

const AccordionHeader = (props) => {

    const { name, fieldTypeId, fieldOptionId } = props.info.metaData;
    const { value, elementType } = props.info.value;
    const fromDate = props.info.fromDate.value;
    const toDate = props.info.toDate.value;

    let displayValue = !value ? '' : value; // || elementType === 'checkbox'
    switch (fieldTypeId) {
        case 2:
            displayValue = !props.info.value.displayValue ? '' : props.info.value.displayValue.name;
            break;
        case 3:
            displayValue = !props.info.value.displayValue.name ? '' : `${props.info.value.displayValue.name} (${props.info.value.displayValue.no})`;
            break;
        default:
            break;
    }

    // location format
    if (fieldOptionId === 11)
        displayValue = props.info.value.displayValue.format;

    /* eslint-disable-next-line no-mixed-operators */
    if ((displayValue || '') === '' || +fieldOptionId === 1016 || +fieldOptionId === 1017)
        displayValue = '- - -';

    /* eslint-disable-next-line no-mixed-operators */
    if (+fieldOptionId === 1016 || +fieldOptionId === 1017 && props.count > 0)
        displayValue = `Multi (${props.count})`;

    if (elementType === 'checkbox')
        displayValue = +displayValue === 1 ? 'Yes' : 'No';

    if (name === 'Schedule') {
        displayValue = <Fragment>{props.locationName}<br />{displayValue.substr(props.locationName.length + 1)}</Fragment>;
    }

    return (
        <Fragment>
            <GridItem style={{ whiteSpace: 'nowrap' }} key="1" size="1/5" element="li"
                className={classes.sa_align_left}>{`${name}  (${props.count})`}</GridItem>
            <GridItem key="2" size="2/5" element="li" className={classes.sa_align_center}>{displayValue}</GridItem>
            <GridItem key="3" size="1/5" element="li" className={classes.sa_align_center}>
                {fromDate ? new Date(fromDate).toLocaleDateString() : '- - -'}
            </GridItem>
            <GridItem key="4" size="1/5" element="li" className={classes.sa_align_center}>
                {toDate ? new Date(toDate).toLocaleDateString() : '- - -'}
            </GridItem>
        </Fragment>
    );
};

AccordionHeader.propTypes = {
    count: PropTypes.number,
    displayValue: PropTypes.string,
    info: PropTypes.object,
    locationName: PropTypes.string
};

export default AccordionHeader;

import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const getWeekNumberStart = () => ({ type: actionTypes.GET_WEEK_NUMBER_START });
export const getWeekNumberSuccess = weekNumber => ({ type: actionTypes.GET_WEEK_NUMBER_SUCCESS, weekNumber: weekNumber });
export const getWeekNumberFail = error => ({ type: actionTypes.GET_WEEK_NUMBER_FAIL, error: error });

export const getWeekNumber = date => {
    const url = '/generalData/weeknumber/' + date;

    return dispatch => {
        dispatch(getWeekNumberStart());

        axios
            .get(url)
            .then(res => {
                const weekNumber = res.data;
                dispatch(getWeekNumberSuccess(weekNumber));
            })
            .catch(err => dispatch(getWeekNumberFail(err)));
    };
};

export const getWeekNumbersStart = () => ({ type: actionTypes.GET_WEEK_NUMBERS_START });
export const getWeekNumbersSuccess = weekNumbers => ({ type: actionTypes.GET_WEEK_NUMBERS_SUCCESS, weekNumbers: weekNumbers });
export const getWeekNumbersFail = error => ({ type: actionTypes.GET_WEEK_NUMBERS_FAIL, error: error });

export const getWeekNumbers = (fromDate, toDate) => {
    const url = '/generalData/weeknumbers/' + fromDate + '/' + toDate;

    return dispatch => {
        dispatch(getWeekNumbersStart());

        axios
            .get(url)
            .then(res => {
                const weekNumbers = res.data;
                dispatch(getWeekNumbersSuccess(weekNumbers));
            })
            .catch(err => dispatch(getWeekNumbersFail(err)));
    };
};

export const getYearStartExceptionsStart = () => ({ type: actionTypes.GET_YEAR_START_EXCEPTIONS_START });
export const getYearStartExceptionsSuccess = exceptions => ({ type: actionTypes.GET_YEAR_START_EXCEPTIONS_SUCCESS, exceptions: exceptions });
export const getYearStartExceptionsFail = error => ({ type: actionTypes.GET_YEAR_START_EXCEPTIONS_FAIL, error: error });

export const getYearStartExceptions = () => {
    const url = '/generalData/yearstartexceptions/';

    return dispatch => {
        dispatch(getYearStartExceptionsStart());

        axios
            .get(url)
            .then(res => {
                const exceptions = res.data;
                dispatch(getYearStartExceptionsSuccess(exceptions));
            })
            .catch(err => dispatch(getYearStartExceptionsFail(err)));
    };
};

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// this has to be a Pascal case name to prevent react-hooks/rules-of-hooks making compilation fail
const ScrollToTop = ({ children, location: { pathname }}) => {
    useEffect(() => window.scrollTo(0, 0), [pathname]);
    return children || null;
};

export default withRouter(ScrollToTop);
